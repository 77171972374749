import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';


@Pipe({
    name: 'sexLabel'
})
export class SexLabelPipe implements PipeTransform {

    @memo({resolver: (...args) => JSON.stringify(args)})
    transform(sex: string, isDiagnosis): any {
        if (!isDiagnosis || sex === 'non-binary') {
            return sex;
        }
        return 'man' === sex ? 'male' : 'female';
    }

}
