<div class="ios-fix">
    <div class="mat-headline popup-header" mat-dialog-title>
        Welcome to Caregiver Network
        <button mat-dialog-close class="closeButton">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div class="mat-headline join-message row-title">Activate for Free or Login.</div>
        <div class="row-description">Joining is free, Simply register, add your profile, and have full access.</div>
        <div class="button-container">
            <button (click)="close(actions.join)" id="btn-join" color="primary" mat-stroked-button>Join Today.<br>It's
                Free
            </button>
            <button (click)="close(actions.login)" id="btn-login" color="primary" mat-stroked-button>Login</button>
        </div>
        <div id="close-button-container">
            <button mat-stroked-button mat-dialog-close color="primary">Close</button>
        </div>
        <div id="about-link-wrapper">
            <button (click)="close(actions.about)" class="btn-fake-link btn-flat">About Us</button>
        </div>
        <app-disclaimer></app-disclaimer>
    </div>
</div>
