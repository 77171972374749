import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class LocationService {

    readonly maxAge = 25;

    private history: string[] = [];

    private current: string;

    previous$ = new BehaviorSubject<string>(null);

    private pushToHistory(uri: string) {
        if (this.history.length === this.maxAge) {
            this.history.shift();
        }
        this.history.push(uri);
        this.previous$.next(uri);
    }

    constructor(private router: Router) {
    }

    push(uri: string) {
        if (this.current === uri) {
            return;
        }
        if (this.current) {
            this.pushToHistory(this.current);
        }
        this.current = uri;
    }

    getPrevious(): string {
        return this.history.length ? this.history[this.history.length - 1] : null;
    }

    pop(): string | null {
        this.current = null;
        const uri = this.history.length ? this.history.pop() : null;
        this.previous$.next(this.getPrevious());
        return uri;
    }

    back(fallback) {
        this.current = null;
        if (!this.history.length) {
            this.router.navigateByUrl(fallback);
            return;
        }
        this.router.navigateByUrl(this.history.pop());
        this.previous$.next(this.getPrevious());
    }
}
