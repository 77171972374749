import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FeedRecordType, PublicUserModel, UserProfileFeedRecordDto, UserProfileViewModel } from '@core/models';


export enum UserProfileAdditionalInfoBlock {
    followings,
    feedQuestions,
    feedNeedHelpSettings,
    feedCanHelpSettings
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent {

    @Input()
    isCurrentUser = false;

    @Input()
    profile: UserProfileViewModel;

    @Input()
    isWhoIFollowExpanded: boolean;

    @Input()
    expandedAdditionalInfoBlock: UserProfileAdditionalInfoBlock;

    @Input()
    expandedProfileFeedRecord: UserProfileFeedRecordDto;

    @Input()
    currentUserId: number;

    @Output()
    toggleFollow$ = new EventEmitter<{ user: PublicUserModel, isFollow: boolean }>();

    @Output()
    reportAMember$ = new EventEmitter<PublicUserModel>();

    @Output()
    deleteFeedRecord$ = new EventEmitter<{ id: number, recordType: FeedRecordType }>();

    @Output()
    whoIFollowBlockTriggerClick$ = new EventEmitter<void>();

    @Output()
    additionalInfoBlockTriggerClick$ = new EventEmitter<UserProfileAdditionalInfoBlock>();

    @Output()
    feedRecordClick$ = new EventEmitter<UserProfileFeedRecordDto>();
}
