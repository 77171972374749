import { createAction, props } from '@ngrx/store';


export const prefix = '[Video contest agreement guard]';

export const getStatus = createAction(`${prefix} get status`);

export const agreementStatusLoaded = createAction(
    `${prefix} get status`,
    props<{ isAgreementSigned: boolean }>()
);
