import { Injectable, OnDestroy } from '@angular/core';


import { Subject } from 'rxjs';


@Injectable()
export abstract class WithDestroyedSubjectComponent implements OnDestroy {

    protected destroyed$ = new Subject<boolean>();

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
