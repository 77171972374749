import { Religion } from '@core/models/religion.model';


export enum AffirmationReaction {
    like = 'LIKE',
    disLike = 'DISLIKE',
    none = 'NONE'
}

export interface Affirmation {
    id: number,
    religion: Religion,
    text: string,
    reaction: AffirmationReaction
}
