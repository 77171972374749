import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TagWithChildrenModel } from '@core/models';


@Component({
    selector: 'app-search-existing-tag',
    templateUrl: './search-existing-tag.component.html',
    styleUrls: ['./search-existing-tag.component.scss']
})
export class SearchExistingTagComponent {
    expandedTagId: number = null;

    @Input()
    criteria: string;

    @Input()
    tags: TagWithChildrenModel[];

    @Output()
    submitted$ = new EventEmitter<string>();

    @Output()
    searched$ = new EventEmitter<string>();

    @Output()
    searchCleared$ = new EventEmitter<string>();

    @Output()
    closed$ = new EventEmitter<void>();
}
