import { Action, createReducer, on } from '@ngrx/store';


import { AppInitActions } from '@core/store/actions';


export interface State {
    isAuthChecked: boolean;
}

export const initialState: State = {
    isAuthChecked: false
};

const AppInitReducer = createReducer(
    initialState,
    on(
        AppInitActions.userNotAuthenticated,
        AppInitActions.userAuthenticated,
        (): State => ({isAuthChecked: true})
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return AppInitReducer(state, action);
}

export const getIsAuthChecked = (state: State) => state.isAuthChecked;
