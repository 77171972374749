<mat-menu #manageMenu="matMenu" class="manage-feed-settings-menu">
    <ng-template matMenuContent>
        <ul>
            <li>
                <a (click)="$event.stopPropagation()"
                   [routerLink]="feedRoute"
                   [fragment]="feedRouteFragment"
                >Enter</a>
            </li>
            <li>
                <a (click)="$event.stopPropagation()"
                   [routerLink]="editRoute"
                   rel="nofollow"
                >Edit</a>
            </li>
            <li>
                <button class="btn-flat" (click)="onDelete($event);">Delete</button>
            </li>
        </ul>
    </ng-template>
</mat-menu>
<div class="wrapper" @voidAnimation>
    <div class="main" (click)="click$.emit({id: data.id, recordType: recordType})">
        <span class="setting-title-wrapper">
            <mat-icon [class.expanded]="isExpanded">play_arrow</mat-icon>
            <span class="title" [class.active]="isExpanded">
                {{ data.tag.fullTitle }}
            </span>
        </span>
        <button *ngIf="isCurrentUser"
                mat-stroked-button
                color="primary"
                (click)="$event.stopPropagation();"
                [matMenuTriggerFor]="manageMenu"
                #menuTrigger="matMenuTrigger"
                [class.active]="isMenuOpen()"
        >manage</button>
    </div>
    <div id="statement" *ngIf="isExpanded" @gridRowAnimation>
        <app-feed-record-text
            [class.with-contact-request-button]="!isCurrentUser"
            [data]="data"
            [recordType]="recordType"
        ></app-feed-record-text>
        <ng-content *ngIf="!isCurrentUser"></ng-content>
    </div>
</div>
