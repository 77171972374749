import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import { FeatureFlags } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {

    private featureFlags: FeatureFlags;


    isFeatureEnabled(feature: keyof FeatureFlags | (keyof FeatureFlags)[]): boolean {
        if (!this.featureFlags) {
            return false;
        }
        if (!Array.isArray(feature)) {
            return this.featureFlags[feature];
        } else {
            return feature.some((name) => this.isFeatureEnabled(name));
        }
    }

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {
        this.store.pipe(select(fromRoot.getFeatureFlags)).subscribe((featureFlags) => this.featureFlags = featureFlags);
    }
}
