import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';


declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {

    public static readonly PROVIDER_ID = 'APPLE';

    constructor(private clientId: string, private scope: string, private redirectURI: string) {
        super();
    }

    initialize(): Promise<void> {
        return new Promise((resolve) => {
            this.loadScript(AppleLoginProvider.PROVIDER_ID,
                'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
                () => {
                    AppleID.auth.init({
                        clientId: this.clientId,
                        scope: this.scope,
                        redirectURI: this.redirectURI,
                        usePopup: true
                    });
                    resolve();
                });
        });
    }

    signIn(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            AppleID.auth.signIn()
                .then((response) => {
                    const user = new SocialUser();
                    user.provider = AppleLoginProvider.PROVIDER_ID;
                    user.email = response.user?.email || null;
                    user.idToken = response.authorization.id_token;
                    resolve(user);
                })
                .catch(() => {
                    reject();
                });
        });
    }

    getLoginStatus(): Promise<SocialUser> {
        return Promise.resolve(new SocialUser());
    }

    signOut(revoke?: boolean): Promise<any> {
        return Promise.resolve();
    }
}
