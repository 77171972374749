import { createAction, props } from '@ngrx/store';


export const prefix = '[Active Call]';

export const candidate = createAction(
    `${prefix} candidate`,
    props<{ callId: number, sdp: string }>()
);

export const offer = createAction(
    `${prefix} offer`,
    props<{ userId: number, sdp: string }>()
);

export const negotiate = createAction(
    `${prefix} negotiate`,
    props<{ callId: number, offer: string }>()
);

export const answer = createAction(
    `${prefix} answer`,
    props<{ callId: number, sdp: string }>()
);

export const decline = createAction(
    `${prefix} decline`,
    props<{ callId: number }>()
);

export const end = createAction(
    `${prefix} end`,
    props<{ callId: number }>()
);

export const connected = createAction(
    `${prefix} connected`,
    props<{ callId: number }>()
);
