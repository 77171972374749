import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { getSortByDateComparer } from '@core/store/sort-comparer-by-date';
import { QuestionModel } from '@core/models';
import { AppInitActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { AuthApiActions } from '@core/store/auth/actions';
import { UserApiActions } from '@core/store/current-user/actions';


export type State = EntityState<QuestionModel>;

export const adapter = createEntityAdapter<QuestionModel>({
    sortComparer: getSortByDateComparer('updatedOn')
});

export const initialState = adapter.getInitialState();

const FeedQuestionsReducer = createReducer(
    initialState,
    on(
        AppInitActions.userNotAuthenticated,
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        (): State => initialState
    ),
    on(
        AppInitActions.userAuthenticated,
        UserApiActions.userProfileLoadSucceeded,
        (state: State, {questions}): State => adapter.setAll(questions, state)
    ),
    on(UserApiActions.createQuestionSucceeded, (state, {question}): State => adapter.addOne(question, state)),
    on(UserApiActions.editQuestionSucceeded, (state, {id, text, updatedOn}): State => {
        return adapter.updateOne({id, changes: {text, updatedOn}}, state);
    }),
    on(UserApiActions.deleteQuestionSucceeded, (state, {id}): State => adapter.removeOne(id, state)),
);

export function reducer(state: State | undefined, action: Action) {
    return FeedQuestionsReducer(state, action);
}

export const {
    selectAll: getFeedQuestions,
    selectEntities: getFeedQuestionsEntities
} = adapter.getSelectors();
