import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import memo from 'memo-decorator';

import { FeedRecordType, QuestionModel, UserSettingsViewModel } from '@core/models';


@Component({
    selector: 'app-feed-record-text',
    templateUrl: './feed-record-text.component.html',
    styleUrls: ['./feed-record-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedRecordTextComponent {

    readonly recordTypes = FeedRecordType;

    @Input()
    data: UserSettingsViewModel | QuestionModel;

    @Input()
    recordType: FeedRecordType;

    @memo({resolver: (...args) => JSON.stringify(args)})
    getGenders(isForMan: boolean, isForWoman: boolean, isForNonBinary: boolean): string[] {
        const genders = [];
        if (isForMan) {
            genders.push('man');
        }
        if (isForWoman) {
            genders.push('woman');
        }
        if (isForNonBinary) {
            genders.push('non-binary');
        }
        return genders;
    }
}
