import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';

import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as fromRoot from '@core/store';
import { PublicUserModel } from '@core/models';
import { PublicUsersApiActions } from '@core/store/actions';
import { ReportAMemberPopupActions } from '@core/store/user/actions';
import { messages } from '@core/messages';


@Component({
    selector: 'app-report-a-member-popup',
    templateUrl: './report-a-member.component.html',
    styleUrls: ['./report-a-member.component.scss']
})
export class ReportAMemberComponent implements OnInit {
    @ViewChild(FormGroupDirective)
    formDirective: FormGroupDirective;

    user: PublicUserModel;
    isDisabled = false;
    form = new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required),
        singleUserBlock: new UntypedFormControl(false),
    });

    static show(
        matDialog: MatDialog,
        user: PublicUserModel
    ): MatDialogRef<ReportAMemberComponent> {
        return matDialog.open(ReportAMemberComponent, {
            data: {
                user
            },
            panelClass: [
                'custom-popup',
                'with-overlapping-header',
                'with-increased-width'
            ]
        });
    }

    constructor(
        private store: Store<fromRoot.State>,
        private actions: Actions,
        private dialogRef: MatDialogRef<ReportAMemberComponent>,
        private snackBarService: MatSnackBar,
        private errorStateMatcher: ErrorStateMatcher,
        @Inject(MAT_DIALOG_DATA)data: { user: PublicUserModel }
    ) {
        this.user = data.user;
    }

    ngOnInit(): void {
        this.actions.pipe(
            ofType(
                PublicUsersApiActions.reportUserSucceeded,
                PublicUsersApiActions.blockUserSucceeded
            ),
            tap(() => {
                this.close(true);
                this.snackBarService.open(`Report a member have been sent.`);
            })
        ).subscribe();
        this.actions.pipe(
            ofType(
                PublicUsersApiActions.reportUserFailed,
                PublicUsersApiActions.blockUserFailed
            ),
            tap(() => {
                this.isDisabled = false;
                this.snackBarService.open(messages.global.error);
            })
        ).subscribe();
    }

    hasError(control: AbstractControl): boolean {
        return this.errorStateMatcher.isErrorState(control, this.formDirective);
    }

    submit() {
        if (this.isDisabled) {
            return;
        }
        if (!this.form.valid) {
            return;
        }
        this.isDisabled = true;
        if (this.form.get('singleUserBlock').value) {
            this.store.dispatch(ReportAMemberPopupActions.blockUser({
                    userId: this.user.id,
                    text: this.form.get('text').value
                }
            ));
            return;
        }

        this.store.dispatch(ReportAMemberPopupActions.reportUser({
                userId: this.user.id,
                text: this.form.get('text').value
            }
        ));
    }

    close(isSucceeded?: boolean) {
        this.dialogRef.close(isSucceeded);
    }
}
