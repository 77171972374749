import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import * as fromAuth from '@core/store/auth';
import { ErrorType } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class RegistrationErrorGuard implements CanActivate {
    constructor(
        private store: Store<fromAuth.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(select(fromAuth.getRegistrationErrorPageError))
            .pipe(
                first(),
                map((error) => {
                    if (error && (error.type === ErrorType.externalUserAlreadyExists || error.type === ErrorType.externalUserNotfound)
                    ) {
                        return true;
                    }
                    const parts = state.url.split('/');
                    if (parts.length > 1) {
                        parts.pop();
                    }
                    return this.router.createUrlTree(parts);
                })
            );
    }
}
