import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { TypedAction } from '@ngrx/store/src/models';

import { AuthService } from '@core/services';
import { AuthState } from '@core/store/auth';
import {
    AuthApiActions,
    ForgotPasswordPageActions,
    LoginPageActions,
    RegistrationErrorPageActions,
    RegistrationSocialPageActions,
    RegistrationPageActions,
    ResetPasswordPageActions
} from '@core/store/auth/actions';
import { CaregiversSupportingCaregiversActions, OrganizationLandingPageActions, LearnWithRegistrationActions } from '@core/store/landing';
import { ErrorType, ExternalSocialNetwork, UserBlockingReason } from '@core/models';
import { AboutUsPageActions, PrivateMessagesWsActions } from '@core/store/actions';
import { WelcomePageActions } from '@core/store/onboarding/actions';


@Injectable()
export class AuthEffects {

    register$ = createEffect(() => this.actions$.pipe(
        ofType(RegistrationPageActions.submitted),
        exhaustMap(({model, location}) =>
            this.authService.register(model).pipe(
                map(({token}) => AuthApiActions.registrationSucceeded({
                    token,
                    email: model.email,
                    username: model.username,
                    location
                })),
                catchError((e) => of(AuthApiActions.registrationFailed({error: e.error})))
            )
        )
    ));

    login$ = createEffect(() => this.actions$.pipe(
        ofType(LoginPageActions.submitted),
        switchMap(({username, password}) =>
            this.authService.login(username, password).pipe(
                map((response) => AuthApiActions.loginSucceeded(response)),
                catchError((error) => {
                    return of(this.onLoginError(error, null, username));
                })
            )
        )
    ));

    forgotPassword$ = createEffect(() => this.actions$.pipe(
        ofType(ForgotPasswordPageActions.submitted),
        switchMap(({email}) => this.authService.resetPassword(email)
            .pipe(
                map(() => AuthApiActions.forgotPasswordSucceeded()),
                catchError((e) => of(AuthApiActions.forgotPasswordFailed({error: e.error || true})))
            )
        )
    ));

    resetPassword$ = createEffect(() => this.actions$.pipe(
        ofType(ResetPasswordPageActions.submitted),
        switchMap(({password, token}) =>
            this.authService.updatePassword(password, token).pipe(
                map(() => AuthApiActions.resetPasswordSucceeded()),
                catchError(() => of(AuthApiActions.resetPasswordFailed()))
            )
        )
    ));

    logout$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.logoutSucceeded,
            PrivateMessagesWsActions.reportUserReceived
        ),
        tap(() => this.authService.invalidateToken())
    ), {dispatch: false});

    removeCampaignSessionKey$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.registrationSucceeded),
        tap(() => this.authService.removeCampaignSessionKey())
    ), {dispatch: false});

    registrationWithSocialNetwork$ = createEffect(() => this.actions$.pipe(
        ofType(
            RegistrationSocialPageActions.registrationWithSocialNetworkSubmitted
        ),
        exhaustMap(({externalSocialNetwork, model, location}) => {
            return this.authService.registerWithSocialNetwork(externalSocialNetwork, model).pipe(
                map(({token}) => AuthApiActions.registrationWithSocialNetworkSucceeded({
                    externalSocialNetwork,
                    token,
                    email: model.email,
                    username: model.username,
                    location
                })),
                catchError((e) => of(AuthApiActions.registrationWithSocialNetworkFailed({
                    externalSocialNetwork,
                    error: e.error
                })))
            );
        })
    ));

    validateToken$ = createEffect(() => this.actions$.pipe(
        ofType(
            RegistrationPageActions.submittedWithSocialNetwork,
            RegistrationErrorPageActions.submittedRegistrationWithSocialNetwork,
            WelcomePageActions.submittedWithSocialNetwork,
            AboutUsPageActions.submittedWithSocialNetwork,
            OrganizationLandingPageActions.submittedWithSocialNetwork,
            CaregiversSupportingCaregiversActions.submittedWithSocialNetwork,
            LearnWithRegistrationActions.submittedWithSocialNetwork
        ),
        switchMap(({externalSocialNetwork, email, token, location}) => {
            return this.authService.validateToken(externalSocialNetwork, token).pipe(
                map(() => AuthApiActions.validateTokenSucceeded({externalSocialNetwork, email, token, location})),
                catchError((e) => {
                    return of(AuthApiActions.validateTokenFailed({externalSocialNetwork, error: e.error}));
                })
            );
        })
    ));

    loginWithSocialNetwork$ = createEffect(() => this.actions$.pipe(
        ofType(
            LoginPageActions.loginWithSocialNetwork,
            RegistrationErrorPageActions.submittedLoginWithSocialNetwork
        ),
        switchMap(({externalSocialNetwork, token}) =>
            this.authService.loginWithSocialNetwork(externalSocialNetwork, token).pipe(
                map((response) => AuthApiActions.loginWithSocialNetworkSucceeded({externalSocialNetwork, token: response.token})),
                catchError((error) => {
                    return of(this.onLoginError(error, externalSocialNetwork));
                })
            )
        )
    ));

    tokenValidated$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.validateTokenSucceeded,
        ),
        tap(() => {
            this.router.navigate(['/auth/registration/social'], {
                queryParams: {redirectTo: this.router.url}
            });
        })
    ), {dispatch: false});

    userAlreadyExists$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.validateTokenFailed
        ),
        filter(error => error.error.type === ErrorType.externalUserAlreadyExists),
        tap(() => {
            this.router.navigate(['/auth/registration/error'], {
                queryParams: {redirectTo: this.router.url}
            });
        })
    ), {dispatch: false});

    socialUserNotRegistered$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.loginWithSocialNetworkFailed
        ),
        filter(e => e.error.type === ErrorType.externalUserNotfound),
        tap(() => {
            this.router.navigate(['/auth/login/error'], {
                queryParams: {redirectTo: this.router.url}
            });
        })
    ), {dispatch: false});

    private onLoginError(error, externalSocialNetwork: ExternalSocialNetwork, username?: string): TypedAction<string> {
        if (error.status === 403) {
            return AuthApiActions.userBlocked({
                reason: UserBlockingReason.authGlobalBlocked,
                username: username || error.error.username
            });
        }

        if (externalSocialNetwork) {
            return AuthApiActions.loginWithSocialNetworkFailed({
                externalSocialNetwork,
                error: error.error
            });
        }

        return AuthApiActions.loginFailed({error: error.error});
    }

    constructor(
        private store: Store<AuthState>,
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) {
    }
}
