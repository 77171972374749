import { UntypedFormGroup, ValidationErrors } from '@angular/forms';


export class PasswordsMatchValidator {

    static errorKey = 'passwordsMatch';

    public static validate(control: UntypedFormGroup): ValidationErrors | null {
        const password = control.get('password');
        const rePassword = control.get('rePassword');
        let error;
        if (password.value && rePassword.value && password.value !== rePassword.value) {
            error = {[PasswordsMatchValidator.errorKey]: true};
            rePassword.setErrors(error);
        } else {
            error = null;
            if (rePassword.hasError(PasswordsMatchValidator.errorKey)) {
                rePassword.setErrors(null);
            }
        }
        return error;
    }
}
