import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Actions, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import * as fromRoot from '@core/store';


@Injectable({
    providedIn: 'root'
})
export class PageLabelService {
    private lastFromRouterData: string;
    private pageLabelSubject$ = new BehaviorSubject<string | SafeHtml | null>(null);
    public pageLabel$ = this.pageLabelSubject$.asObservable();

    set(title?: string | SafeHtml) {
        this.pageLabelSubject$.next(title || null);
    }

    setWithSanitizer(title: string) {
        this.set(this.domSanitizer.bypassSecurityTrustHtml(title));
    }

    constructor(
        private domSanitizer: DomSanitizer,
        private store: Store<fromRoot.State>,
        private actions$: Actions
    ) {
        this.store
            .pipe(select(fromRoot.getRouteData))
            .subscribe((data: Params) => {
                this.lastFromRouterData = (data && data.pageLabel) || null;
            });
        this.actions$
            .pipe(ofType(ROUTER_NAVIGATED))
            .subscribe(() => {
                this.set(this.lastFromRouterData);
            });
    }
}
