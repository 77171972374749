import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';


@Pipe({
    name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

    @memo({resolver: (date) => format(parseISO(date), 'yyyy-MM-dd')})
    transform(date: string, returnZero = false): number {
        const diff = differenceInCalendarDays(new Date(), parseISO(date));
        if (diff) {
            return diff;
        }
        return returnZero ? 0 : 1;
    }

}
