import { Action, createReducer, on } from '@ngrx/store';

import { TagDescriptionModel } from '@core/models';
import { TagDescriptionGuardActions } from '@core/store/actions';


export interface State {
    description: TagDescriptionModel;
}

export const initialState: State = {
    description: null
};

const TagDescriptionReducer = createReducer(
    initialState,
    on(TagDescriptionGuardActions.loadSucceeded, (state: State, {description}): State => ({
        description
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return TagDescriptionReducer(state, action);
}

export const getDescription = (state: State): TagDescriptionModel => state?.description;

