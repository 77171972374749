import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ContestVideoModel, VideoContestStatusApiResponse } from '@core/models';


@Injectable({
    providedIn: 'root'
})

export class VideoContestService {

    private readonly apiPrefix = '/me/video-contest';

    isAgreementSigned(): Observable<{ videoContestAgreement: boolean }> {
        return this.httpClient.get<{ videoContestAgreement: boolean }>(`${this.apiPrefix}/agreement`);
    }

    signAgreement(): Observable<void> {
        return this.httpClient.post<void>(`${this.apiPrefix}/agreement`, {isSigned: true});
    }

    getContestVideos(page: number, size: number): Observable<ContestVideoModel[]> {
        const params = {
            page: page.toString(),
            size: size.toString()
        };
        return this.httpClient.get<ContestVideoModel[]>(this.apiPrefix, {params});
    }

    constructor(private httpClient: HttpClient) {
    }

    createVideoContest(title: string, description: string, uploadId: string): Observable<ContestVideoModel> {
        return this.httpClient.post<ContestVideoModel>(`${this.apiPrefix}`, {
            description,
            title,
            uploadId
        });
    }

    getStatus(): Observable<VideoContestStatusApiResponse> {
        return this.httpClient.get<VideoContestStatusApiResponse>(`/video-contest/status`);
    }
}
