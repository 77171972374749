const iconsPathPrefix = '/assets/icons/subject-icons';

export enum SubjectSvgIcon {
    'aging' = 'aging',
    'alzheimers' = 'alzheimers',
    'anxiety' = 'anxiety',
    'addiction' = 'addiction',
    'arthritis' = 'arthritis',
    'autism' = 'autism',
    'cancer' = 'cancer',
    'caregiver-issues' = 'caregiver-issues',
    'cultural-competence-now' = 'cultural-competence-now',
    'dementia' = 'dementia',
    'diabetes' = 'diabetes',
    'disabilities' = 'disabilities',
    'heart-disease' = 'heart-disease',
    'hivaidsprep' = 'hivaidsprep',
    'legal' = 'legal',
    'lgbtq-support' = 'lgbtq-support',
    'mental-health' = 'mental-health',
    'ocd' = 'ocd',
    'parkinsons' = 'parkinsons',
    'rare-diseases' = 'rare-diseases',
    'skin-care-and-diseases' = 'skin-care-and-diseases',
    'alternative-therapies' = 'alternative-therapies',
    'social-justice-now' = 'social-justice-now',
    'veterans' = 'veterans',
    'vision' = 'vision'
}

export const subjectSvgIconsMap: { readonly [key in SubjectSvgIcon]: string; } = {
    [SubjectSvgIcon['heart-disease']]: `${iconsPathPrefix}/HeartDisease.svg`,
    [SubjectSvgIcon.alzheimers]: `${iconsPathPrefix}/Alzheimers.svg`,
    [SubjectSvgIcon.dementia]: `${iconsPathPrefix}/Dementia.svg`,
    [SubjectSvgIcon.arthritis]: `${iconsPathPrefix}/Arthritis.svg`,
    [SubjectSvgIcon.autism]: `${iconsPathPrefix}/Autism.svg`,
    [SubjectSvgIcon.cancer]: `${iconsPathPrefix}/Cancer.svg`,
    [SubjectSvgIcon['rare-diseases']]: `${iconsPathPrefix}/RareDiseases.svg`,
    [SubjectSvgIcon.diabetes]: `${iconsPathPrefix}/Diabetes.svg`,
    [SubjectSvgIcon.vision]: `${iconsPathPrefix}/Vision.svg`,
    [SubjectSvgIcon['mental-health']]: `${iconsPathPrefix}/MentalHealth.svg`,
    [SubjectSvgIcon.anxiety]: `${iconsPathPrefix}/Anxiety.svg`,
    [SubjectSvgIcon.addiction]: `${iconsPathPrefix}/Addiction.svg`,
    [SubjectSvgIcon.ocd]: `${iconsPathPrefix}/OCD.svg`,
    [SubjectSvgIcon.disabilities]: `${iconsPathPrefix}/Disabilities.svg`,
    [SubjectSvgIcon.parkinsons]: `${iconsPathPrefix}/Parkinson.svg`,
    [SubjectSvgIcon.legal]: `${iconsPathPrefix}/Legal.svg`,
    [SubjectSvgIcon['caregiver-issues']]: `${iconsPathPrefix}/Caregiver.svg`,
    [SubjectSvgIcon.veterans]: `${iconsPathPrefix}/Veterans.svg`,
    [SubjectSvgIcon.hivaidsprep]: `${iconsPathPrefix}/HIV.svg`,
    [SubjectSvgIcon.aging]: `${iconsPathPrefix}/Aging.svg`,
    [SubjectSvgIcon['cultural-competence-now']]: `${iconsPathPrefix}/Cultural.svg`,
    [SubjectSvgIcon['skin-care-and-diseases']]: `${iconsPathPrefix}/SkinCare.svg`,
    [SubjectSvgIcon['alternative-therapies']]: `${iconsPathPrefix}/AlternativeTherapies.svg`,
    [SubjectSvgIcon['lgbtq-support']]: `${iconsPathPrefix}/LGBTQ.svg`,
    [SubjectSvgIcon['social-justice-now']]: `${iconsPathPrefix}/SocialJustice.svg`,
};
