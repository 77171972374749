import { createAction, props } from '@ngrx/store';


export const prefix = '[Search By Subject page]';

export const init = createAction(`${prefix} init`);

export const searched = createAction(
    `${prefix} searched`,
    props<{ criteria: string }>()
);

export const searchCleared = createAction(`${prefix} search cleared`);
