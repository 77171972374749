import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromAuth from '@core/store/auth';
import * as fromRoot from '@core/store';
import { AuthApiActions, ForgotPasswordPageActions } from '@core/store/auth/actions';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';
import { WINDOW } from '@core/window';


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseAuthContainerComponent implements OnInit {

    error$ = this.store.pipe(select(fromAuth.getForgotPasswordPageError));

    isPending$ = this.store.pipe(select(fromAuth.getForgotPasswordPageIsPending));

    isSent = false;

    email: string;

    constructor(
        @Optional()
        @Inject(WINDOW) private window: Window | null,
        private actions$: Actions,
        protected router: Router,
        protected store: Store<fromRoot.State>
    ) {
        super(router, store);
    }


    ngOnInit() {
        this.store.dispatch(ForgotPasswordPageActions.init());
        this.actions$.pipe(
            ofType(AuthApiActions.forgotPasswordSucceeded),
            takeUntil(this.destroyed$)
        ).subscribe(() => this.isSent = true);
    }


    onSubmit($data: { email: string }) {
        this.email = $data.email;
        this.store.dispatch(ForgotPasswordPageActions.submitted($data));
    }
}
