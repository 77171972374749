import { Component } from '@angular/core';


@Component({
    selector: 'app-btn-geolocation-help',
    templateUrl: './btn-geolocation-help.component.html',
    styleUrls: ['./btn-geolocation-help.component.scss']
})
export class BtnGeolocationHelpComponent {
}
