import { Directive, ElementRef, HostListener, Input, OnChanges, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

import { takeUntil } from 'rxjs/operators';

import { LocationService } from '@core/services';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';


@Directive({
    selector: 'a[appLocationBack], button[appLocationBack]'
})
export class LocationBackDirective extends WithDestroyedSubjectComponent implements OnChanges {

    private readonly isLink: boolean;

    private url: string;

    private fallbackUri: string;

    private getFallbackUrl(): string {
        return this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree(
            [this.fallbackUri],
            {relativeTo: this.activatedRoute}
        )));
    }

    private updateHtml() {
        if (this.isLink) {
            (this.elementRef.nativeElement as HTMLLinkElement).href = this.url || this.getFallbackUrl();
        }
    }

    @Input()
    set fallback(fallback: string) {
        this.fallbackUri = fallback;
        this.updateHtml();
    }

    @HostListener('click', ['$event'])
    onClick($event: MouseEvent) {
        $event.preventDefault();
        this.locationService.back(this.getFallbackUrl());
    }

    ngOnChanges(): any {
        this.updateHtml();
    }

    setCurrentUrl(url: string) {
        this.url = url;
        this.updateHtml();
    }

    constructor(
        private locationService: LocationService,
        private elementRef: ElementRef<HTMLLinkElement | HTMLButtonElement>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private locationStrategy: LocationStrategy
    ) {
        super();
        this.isLink = this.elementRef.nativeElement.nodeName.toLowerCase() === 'a';
        this.locationService.previous$.pipe(takeUntil(this.destroyed$)).subscribe((uri) => this.setCurrentUrl(uri));
    }
}
