import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { RecaptchaComponent } from 'ng-recaptcha';

import {
    EmailUniqueValidator,
    EmailValidator,
    PasswordFormatValidator,
    PasswordsMatchValidator,
    UsernameFormatValidator,
    UsernameUniqueValidator
} from '@shared/form-validators';
import { environment } from 'src/environments/environment';
import { ExternalSocialNetwork, RegistrationModel } from '@core/models';
import { messages } from '@core/messages';
import { SocialButtonSignInResult } from '@shared/components';
import { hasActiveSocialNetworksProviders } from '@core/utils/social-networks';


@Component({
    selector: 'app-registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent {
    readonly key = environment.recaptchaKey;
    readonly hints = messages.form.hints;

    isLoginWithSocialSelected = hasActiveSocialNetworksProviders();

    isPasswordHidden = true;

    isRePasswordHidden = true;

    isPending = false;

    isEmailTaken = false;

    isUsernameTaken = false;

    form: UntypedFormGroup = new UntypedFormGroup({
        username: new UntypedFormControl(
            '',
            {
                updateOn: 'blur',
                validators: Validators.compose([
                    Validators.required,
                    UsernameFormatValidator.validate,
                    (): ValidationErrors | null => {
                        return this.isUsernameTaken ? {usernameUnique: true} : null;
                    }
                ]),
                asyncValidators: this.usernameUniqueValidator.getValidator()
            },
        ),
        email: new UntypedFormControl(
            '',
            {
                updateOn: 'blur',
                validators: Validators.compose([
                    Validators.required,
                    EmailValidator.validate,
                    (): ValidationErrors | null => {
                        return this.isEmailTaken ? {emailUnique: true} : null;
                    }
                ]),
                asyncValidators: this.emailUniqueValidator.getValidator()
            }),
        password: new UntypedFormControl(
            '',
            Validators.compose([
                Validators.required,
                PasswordFormatValidator.validate
            ])
        ),
        rePassword: new UntypedFormControl(
            '',
            Validators.compose([Validators.required])
        ),
        recaptchaResponse: new UntypedFormControl('')
    }, PasswordsMatchValidator.validate);

    @ViewChild('captcha')
    captcha: RecaptchaComponent;

    @Input()
    set usernameTakenError(input) {
        if (!input) {
            return;
        }
        this.isUsernameTaken = true;
        this.form.get('username').setErrors({usernameUnique: true});
        this.form.get('username').markAsDirty();
    }

    @Input()
    set emailTakenError(input) {
        if (!input) {
            return;
        }
        this.isEmailTaken = true;
        this.form.get('email').setErrors({emailUnique: true});
        this.form.get('email').markAsDirty();
    }

    @Input()
    set pending(isPending: boolean) {
        this.isPending = isPending;
        if (isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    @Input()
    set data(data: RegistrationModel) {
        if (data) {
            this.form.patchValue({...data, recaptchaResponse: null});
        }
    }

    @Input()
    redirectTo: string;

    @Output()
    submitted = new EventEmitter<RegistrationModel>();

    @Output()
    signedInSocial = new EventEmitter<{ email: string, username: string, token: string, externalSocialNetwork: ExternalSocialNetwork }>();

    constructor(
        private usernameUniqueValidator: UsernameUniqueValidator,
        private emailUniqueValidator: EmailUniqueValidator
    ) {
    }

    onSubmit() {
        if (this.form.valid) {
            this.form.get('recaptchaResponse').setValue('');
            this.captcha.execute();
        }
    }

    submit() {
        this.submitted.emit({
            username: this.form.get('username').value,
            email: this.form.get('email').value,
            password: this.form.get('password').value,
            recaptchaResponse: this.form.get('recaptchaResponse').value
        });
    }

    resolved(captchaResponse: string) {
        if (captchaResponse) {
            this.submit();
        }
    }

    onSocialSignedIn(model: SocialButtonSignInResult) {
        this.signedInSocial.emit({
            username: this.form.get('username').errors ? null : this.form.get('username').value,
            ...model
        });
    }

    onRegistrationTypeChanged() {
        this.isLoginWithSocialSelected = !this.isLoginWithSocialSelected
    }
}
