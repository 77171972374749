<div class="call-info">
    <app-user-avatar
        id="avatar"
        [user]="user"
        [isOnline]="true"
        [asLink]="false"
    ></app-user-avatar>
    <div class="call-icon">
        <app-incoming-call-icon></app-incoming-call-icon>
        <div>
            <app-username class="username" [user]="user"></app-username>
            <div>is calling you...</div>
        </div>
    </div>
</div>
<div class="call-buttons">
    <button mat-flat-button class="btn-chat"
            [routerLink]="['/me/conversations', user.id]"
            (click)="onCloseClick()"
    >Go to chat</button>
    <button mat-flat-button color="warn" (click)="onDeclineClick()">Decline</button>
    <a mat-flat-button color="accent"
            [queryParams]="{callId: callId}"
            [routerLink]="['/me/conversations/' + user.id]"
            (click)="onCloseClick()"
    >Accept call</a>
</div>
<div class="close-btn" (click)="matSnackBarRef.dismiss()"></div>

