import { UserSettingsModel } from '@core/models/user-settings.model';

export enum RegistrationSource {
    landingVideoContest = 'landing-video-contest'
}

export interface RegistrationModel {
    username: string;
    email: string;
    password: string;
    recaptchaResponse: string;
    settings?: UserSettingsModel;
}
