import { UserSettingsModel } from '@core/models/user-settings.model';
import { ConversationApiModel, ConversationFlatModel, flattenConversationApiModels } from '@core/models/conversation.model';
import { QuestionModel } from '@core/models/question.model';


export enum UserRole {
    canHelp = 'CAN_HELP',
    needHelp = 'NEED_HELP',
    browse = 'BROWSE'
}

export enum UserBlockingReason {
    blockedByMe = 'BLOCKED_BY_ME',
    blockedMe = 'BLOCKED_ME',
    globalBlocked = 'BLOCKED_GLOBAL',
    authGlobalBlocked = 'AUTH_GLOBAL_BLOCKED'
}

export enum RegistrationType {
    siteRegistrationForm = 'EXTEND_A_TOUCH',
    facebook = 'FACEBOOK',
    google = 'GOOGLE',
    microsoft = 'MICROSOFT',
    apple = 'APPLE'
}

export type ExternalSocialNetwork =
    RegistrationType.facebook
    | RegistrationType.microsoft
    | RegistrationType.google
    | RegistrationType.apple;

export const RegistrationTypeText: {
    readonly [key in RegistrationType.facebook
        | RegistrationType.google
        | RegistrationType.microsoft
        | RegistrationType.apple]: string
} = {
    [RegistrationType.google]: 'Google',
    [RegistrationType.facebook]: 'Facebook',
    [RegistrationType.microsoft]: 'Microsoft',
    [RegistrationType.apple]: 'Apple'
};

export type UserRoleRouteParameter = 'need-help' | 'can-help';

export type UserRoleText = 'get help' | 'give help' | 'questions';

export const userRoleRouteParameter: { readonly [key in UserRole.canHelp | UserRole.needHelp]: UserRoleRouteParameter; } = {
    [UserRole.needHelp]: 'need-help',
    [UserRole.canHelp]: 'can-help',
};

export const userRoleText: { readonly [key in UserRole]: UserRoleText; } = {
    [UserRole.browse]: 'questions',
    [UserRole.needHelp]: 'get help',
    [UserRole.canHelp]: 'give help',
};

export interface UserModel {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    photo: string;
    createdOn: string;
    email: string;
    isFounding: boolean;
    isFacilitator: boolean;
    registrationType: RegistrationType;
}

export interface UserProfileApiModel extends UserModel {
    settings: UserSettingsModel[];
    questions: QuestionModel[];
    conversations: ConversationApiModel[];
    followings: PublicUserModel[];
    blocks: UserBlockModel[];
    preferences: Preferences;
    latitude: number;
    longitude: number;
}

export interface AfterFlattenUserProfileApiModel {
    user: UserModel;
    settings: UserSettingsModel[];
    questions: QuestionModel[];
    conversations: ConversationFlatModel[];
    users: PublicUserModel[];
    blocks: number[];
    geolocation: Geolocation;
    preferences: Preferences;
}

export type PublicUserModel = Pick<UserModel, 'id' | 'username' | 'photo' | 'createdOn' | 'isFounding' | 'isFacilitator'> & {
    isFollowed: boolean;
    distance?: number;
    lastActivity: string;
};

export type UserPersonalInfoModel = Pick<UserModel, 'username' | 'email'>;

export interface UserBlockModel {
    userId: number;
    blockedId: number;
}

export interface Geolocation {
    latitude: number;
    longitude: number;
}
export interface Preferences {
    isShowMemberToolsPage: boolean;
    isSendAffirmations: boolean;
    isShowAffirmationPopup: boolean;
    isSendNewChatMassageNotification: boolean;
}

export function flattenUserProfileApiModel(user: UserProfileApiModel): AfterFlattenUserProfileApiModel {
    const conversations = flattenConversationApiModels(user.id, user.conversations);
    const result: AfterFlattenUserProfileApiModel = {
        user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            email: user.email,
            photo: user.photo,
            createdOn: user.createdOn,
            isFounding: user.isFounding,
            isFacilitator: user.isFacilitator,
            registrationType: user.registrationType
        },
        settings: [
            ...user.settings
        ],
        questions: [
            ...user.questions
        ],
        conversations: [
            ...conversations.conversations
        ],
        blocks: user.blocks.map(block => block.blockedId === user.id ? block.userId : block.blockedId),
        users: [],
        geolocation: null,
        preferences: user.preferences
    };

    if (user.latitude && user.longitude) {
        result.geolocation = {
            latitude: user.latitude,
            longitude: user.longitude
        };
    }

    const users: { [key: number]: PublicUserModel } = {};

    users[user.id] = {
        id: user.id,
        username: user.username,
        photo: user.photo,
        createdOn: user.createdOn,
        isFounding: user.isFounding,
        isFacilitator: user.isFacilitator,
        isFollowed: false,
        lastActivity: null
    };

    conversations.users.forEach((publicUser) => users[publicUser.id] = publicUser);
    user.followings.forEach((following) => {
        users[following.id] = {
            ...following,
            isFollowed: true
        };
    });

    result.users = Object.values(users);
    return result;
}

