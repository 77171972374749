import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { exhaustMap, filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

import * as fromRoot from '@core/store';
import * as fromAuth from '@core/store/auth';
import * as fromCurrentUser from '@core/store/current-user';
import { EmailUniqueValidator, UsernameUniqueValidator } from '@shared/form-validators';
import { ErrorType, ExternalSocialNetwork, RegistrationSource, ServerErrorModel, SocialRegistrationModel } from '@core/models';
import { AuthApiActions, RegistrationSocialPageActions } from '@core/store/auth/actions';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { WINDOW } from '@core/window';
import { messages } from '@core/messages';
import { afterRegistrationRedirectPath } from '@core/defaults';


@Component({
    selector: 'app-registration-last-step',
    templateUrl: './registration-social.component.html',
    styleUrls: ['./registration-social.component.scss']
})
export class RegistrationSocialComponent extends BaseAuthContainerComponent implements OnInit {
    isPending$ = this.store.pipe(select(fromAuth.getRegistrationSocialPageIsPending));

    usernameTakenError$ = new BehaviorSubject<{ error: boolean }>(null);

    emailTakenError$ = new BehaviorSubject<{ error: boolean }>(null);

    externalSocialNetwork: ExternalSocialNetwork;

    location: RoiLocation;

    model: SocialRegistrationModel = {
        email: null,
        settings: null,
        token: null,
        username: null
    };

    redirectUrl = afterRegistrationRedirectPath;

    private onError(error: ServerErrorModel) {
        switch (error.type) {
            case ErrorType.emailAlreadyExists:
                this.emailTakenError$.next({error: true});
                break;
            case ErrorType.usernameAlreadyExists:
                this.usernameTakenError$.next({error: true});
                break;
            default:
                this.window?.alert(messages.global.error);
        }
    }

    constructor(
        private usernameUniqueValidator: UsernameUniqueValidator,
        private emailUniqueValidator: EmailUniqueValidator,
        private actions$: Actions,
        @Inject(WINDOW) @Optional() private window: Window,
        protected store: Store<fromRoot.State>,
        protected router: Router
    ) {
        super(router, store);
    }

    ngOnInit() {
        combineLatest([
            this.store.pipe(select(fromAuth.getRegistrationSocialPageEmail)),
            this.store.pipe(select(fromAuth.getRegistrationSocialPageEnteredUsername)),
            this.store.pipe(select(fromAuth.getRegistrationSocialPageExternalUserToken)),
            this.store.pipe(select(fromAuth.getRegistrationSocialPageExternalSocialNetwork)),
            this.store.pipe(select(fromAuth.getRegistrationSocialPageLocation)),
            this.store.pipe(select(fromRoot.getRouteQueryParams))
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([email, enteredUsername, token, externalSocialNetwork, location, queryParams]) => {
                this.externalSocialNetwork = externalSocialNetwork;
                this.location = location;
                this.model = {
                    ...this.model,
                    email: email || null,
                    username: enteredUsername || null,
                    token
                };

                if (queryParams?.redirectTo && queryParams.redirectTo.includes(RegistrationSource.landingVideoContest)) {
                    this.redirectUrl = '/me/video-contest/new';
                }
            });
        this.store.pipe(
            select(fromAuth.getRegistrationSocialPageError),
            filter(error => !!error),
            takeUntil(this.destroyed$)
        ).subscribe((error) => this.onError(error));

        this.actions$.pipe(
            ofType(AuthApiActions.registrationWithSocialNetworkSucceeded),
            exhaustMap(() => {
                return this.store.pipe(
                    select(fromCurrentUser.getUser),
                    filter((user) => !!user)
                );
            }),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.router.navigateByUrl(this.redirectUrl);
        });
    }

    onPersonalDataSubmit({email, username}: { email: string, username: string }) {
        this.usernameTakenError$.next(null);
        this.emailTakenError$.next(null);
        this.model.email = email;
        this.model.username = username;
        this.store.dispatch(RegistrationSocialPageActions.registrationWithSocialNetworkSubmitted({
            externalSocialNetwork: this.externalSocialNetwork,
            model: this.model,
            location: this.location
        }));
    }
}
