import { createAction, props } from '@ngrx/store';

import { PublicUserModel, PublicUserProfileFlattenModel } from '@core/models';


export const prefix = '[Public users API]';

export const followUserSucceeded = createAction(
    `${prefix} follow user succeeded`,
    props<{ user: PublicUserModel }>()
);

export const followUserFailed = createAction(
    `${prefix}  follow user failed`,
    props<{ error: any }>()
);

export const unFollowUserSucceeded = createAction(
    `${prefix} un follow user succeeded`,
    props<{ user: PublicUserModel }>()
);

export const unFollowUserFailed = createAction(
    `${prefix}  un follow user failed`,
    props<{ error: any }>()
);

export const sendUserFeedSettingsInteractionSucceeded = createAction(
    `${prefix}  send user feed setting interaction succeeded`
);

export const sendUserFeedSettingsInteractionFailed = createAction(
    `${prefix}  send user feed setting interaction failed`,
    props<{ error: any }>()
);

export const sendUserFeedQuestionInteractionSucceeded = createAction(
    `${prefix}  send user feed question interaction succeeded`
);

export const sendUserFeedQuestionInteractionFailed = createAction(
    `${prefix}  send user feed question interaction failed`,
    props<{ error: any }>()
);
export const reportUserSucceeded = createAction(
    `${prefix} report user succeeded`
);

export const reportUserFailed = createAction(
    `${prefix} report user failed`,
    props<{ error: any }>()
);

export const blockUserSucceeded = createAction(
    `${prefix} block user succeeded`,
    props<{ userId: number }>()
);

export const blockUserFailed = createAction(
    `${prefix} block user failed`,
    props<{ error: any }>()
);

export const loadProfileSucceeded = createAction(
    `${prefix} load public user profile succeeded`,
    props<PublicUserProfileFlattenModel>()
);

export const loadProfileFailed = createAction(
    `${prefix} load public user profile failed`,
    props<{ error: any }>()
);
