import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { InviteFriendService } from '@core/services/';
import { InviteFriendApiActions, InviteFriendPageActions } from '@core/store/actions';


@Injectable()
export class InviteFriendApiEffects {
    sendTellAFriendRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InviteFriendPageActions.submitted),
            exhaustMap(({text, emails}) => {
                return this.tellAFriendService.send(text, emails).pipe(
                    map(() => InviteFriendApiActions.sendInviteFriendSucceeded()),
                    catchError((error) => of(InviteFriendApiActions.sendInviteFriendFailed({error: error.error})))
                );
            })
        );
    });


    constructor(
        private actions$: Actions,
        private tellAFriendService: InviteFriendService,
    ) {
    }
}
