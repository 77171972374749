import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

export function MSALConfigFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.microsoftClientId,
            redirectUri: environment.baseHref,
        },
    });
}
