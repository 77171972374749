<div class="title">Most popular posts</div>
<ng-container *ngIf="!isLoading else spinner">
    <div class="postsWrapper" *ngIf="postsPreparedForTabs.length else empty">
        <mat-tab-group [selectedIndex]="activeTabIndex">
            <mat-tab *ngFor="let postsRow of postsPreparedForTabs">
                <ng-container *ngFor="let post of postsRow">
                    <app-blog-post [post]="post"></app-blog-post>
                </ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="points">
        <div class="point"
             [class.active]="index === activeTabIndex"
             *ngFor="let number of postsPreparedForTabs; let index = index"
             (click)="activeTabIndex = index">
        </div>
    </div>
</ng-container>
<ng-template #empty>
    <div class="not-found"><span>No posts found</span></div>
</ng-template>
<ng-template #spinner>
    <mat-spinner diameter="40"></mat-spinner>
</ng-template>
