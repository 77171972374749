import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PublicChatApiActions, PublicChatPageActions, PublicChatUsersPopupActions, PublicChatWsActions } from '@core/store/feed/actions';
import { environment } from 'src/environments/environment';


export interface State extends EntityState<number> {
    isCountLoading: boolean;
    isUsersLoading: boolean;
    hasMore: boolean;
    onlineCount: number;
    page: number;
    error: string;
}

const adapter = createEntityAdapter<number>({
    selectId: (entity) => entity
});

export const initialState: State = adapter.getInitialState({
    isCountLoading: false,
    isUsersLoading: false,
    hasMore: false,
    onlineCount: 0,
    page: 1,
    error: null,
});

const publicChatUsersReducer = createReducer(
    initialState,
    on(PublicChatPageActions.init, () => ({
        ...initialState,
        isCountLoading: true
    })),
    on(
        PublicChatUsersPopupActions.loadMoreUsers,
        (state): State => ({
            ...state,
            isUsersLoading: true,
        })),
    on(
        PublicChatApiActions.loadOnlineUsersCountSucceeded,
        (state, {count}): State => ({
            ...state,
            isCountLoading: false,
            onlineCount: count
        })
    ),
    on(
        PublicChatApiActions.loadUsersSucceeded,
        (state, {users}): State => {
            const ids = users.map((user) => user.id);
            return adapter.addMany(ids, {
                ...state,
                page: state.page + 1,
                isUsersLoading: false,
                hasMore: users.length === environment.feed.publicChat.usersOnlinePageSize,
                error: null
            });
        }
    ),
    on(
        PublicChatApiActions.loadOnlineUsersCountFailed,
        (state, {error}): State => ({
            ...state,
            isCountLoading: false,
            error
        })
    ),
    on(
        PublicChatApiActions.loadUsersFailed,
        (state, {error}): State => ({
            ...state,
            isUsersLoading: false,
            error
        })
    ),
    on(
        PublicChatWsActions.userSubscribeReceived,
        (
            state: State,
            {user}
        ): State => {
            if (!state.ids.length) {
                return {
                    ...state,
                    onlineCount: state.onlineCount + 1
                };
            } else {
                return adapter.addOne(user.id, {
                    ...state,
                    onlineCount: state.onlineCount + 1
                });
            }
        }
    ),
    on(
        PublicChatWsActions.userUnsubscribeReceived,
        (state: State, {userId}): State => adapter.removeOne(userId, {
            ...state,
            onlineCount: state.onlineCount - 1
        })
    ),
    on(
        PublicChatUsersPopupActions.destroyed,
        (state): State => {
            if (state.ids.length === state.onlineCount) {
                return state;
            }
            return adapter.removeAll({
                ...state,
                hasMore: true,
                page: 1
            });
        }
    )
);

export const reducer = (state: State | undefined, action: Action) => {
    return publicChatUsersReducer(state, action);
};

export const {
    selectAll: getChatUsers
} = adapter.getSelectors();

export const getHasMore = (state) => state.hasMore;
export const getIsUsersLoading = (state) => state.isUsersLoading;
export const getIsCountLoading = (state) => state.isCountLoading;
export const getOnlineCount = (state) => state.onlineCount;
export const getPage = (state) => state.page;

