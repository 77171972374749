import { SettingsPageActions } from '@core/store/feed/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { Action, createReducer, on } from '@ngrx/store';


export interface State {
    isSubmitted: boolean;
    isSucceeded: boolean;
    isCancelled: boolean;
    isDeleted: boolean;
}

export const initialState: State = {
    isSucceeded: false,
    isSubmitted: false,
    isCancelled: false,
    isDeleted: false
};


const SettingsPageReducer = createReducer(
    initialState,
    on(
        SettingsPageActions.pageInit,
        UserApiActions.saveSettingsFailed,
        UserApiActions.deleteSettingsFailed,
        () => initialState
    ),
    on(
        SettingsPageActions.helpSettingsSubmitted,
        (): State => ({isSubmitted: true, isSucceeded: false, isCancelled: false, isDeleted: false})
    ),
    on(
        UserApiActions.deleteSettingsSucceeded,
        (): State => ({...initialState, isSubmitted: false, isDeleted: true})
    ),
    on(
        UserApiActions.saveHelpSettingsSucceeded,
        (): State => ({isSubmitted: true, isSucceeded: true, isCancelled: false, isDeleted: false})
    ),
    on(
        SettingsPageActions.helpSettingsCancelled,
        (): State => ({isSubmitted: true, isSucceeded: false, isCancelled: true, isDeleted: false})
    )
);

export function reducer(state: State | undefined, action: Action) {
    return SettingsPageReducer(state, action);
}


export const getIsSubmitted = (state: State) => state.isSubmitted;
export const getIsSucceeded = (state: State) => state.isSucceeded;
export const getIsCancelled = (state: State) => state.isCancelled;
export const getIsDeleted = (state: State) => state.isDeleted;
