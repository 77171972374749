import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PublicUserModel } from '@core/models';


@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersListComponent {
    @Input()
    currentUserId: number;

    @Input()
    users: PublicUserModel[];

    @Input()
    showFollowButton = true;

    @Input()
    showPrivateMessageButton = false;

    @Input()
    showDistance = true;

    @Output()
    toggleFollow$ = new EventEmitter<{ user: PublicUserModel, isFollow: boolean }>();

    trackByUserId(index: number, user: PublicUserModel) {
        return user.id;
    }

}
