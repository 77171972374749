import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, skip, take } from 'rxjs/operators';

import { PrivateMessagesWsActions } from '@core/store/actions';
import { CallActions, CallApiActions } from '@core/store/conversations/actions';
import * as fromRoot from '@core/store';
import { CallMessagePayloadWithSdp, UserModel } from '@core/models';
import { WINDOW } from '@core/window';
import { environment } from 'src/environments/environment';
import { ActiveCallRef, CallType } from '@core/services/active-call/active-call-ref';
import { messages } from '@core/messages';
import { UserMediaService } from '@core/services/user-media/user-media.service';


@Injectable({
    providedIn: 'root'
})
export class ActiveCallService {
    readonly outgoingCallNotRespondedMaxTimeSec = environment.outgoingCallNotRespondedMaxTimeSec || 20;

    private audioElement: HTMLAudioElement = new Audio();

    private activeCall: ActiveCallRef;

    private currentUser: UserModel;
    private activeCallId: number;

    private timer;

    private outgoingIceCandidatesBuffer: string[] = [];
    private incomingIceCandidatesBuffer: CallMessagePayloadWithSdp[] = [];
    private outgoingCallNotRespondTimer$: Subscription;

    callNotRespond$ = new Subject<number>();
    isCallActive$ = new BehaviorSubject<boolean>(false);
    volumeLevel$ = new BehaviorSubject<number>(1);
    isMuted$ = new BehaviorSubject<boolean>(false);

    activeCallTime$ = new BehaviorSubject<string>('00:00');

    static secondsToHHMMSS(sourceSeconds: number): string {
        const hours = Math.floor(sourceSeconds / 3600);
        const minutes = Math.floor((sourceSeconds - (hours * 3600)) / 60);
        const seconds = sourceSeconds - (hours * 3600) - (minutes * 60);
        let hoursString = '';
        if (hours) {
            hoursString = hours > 9 ? `${hours}:` : `0${hours}:`;
        }
        const minutesString = minutes > 9 ? `${minutes}:` : `0${minutes}:`;
        const secondsString = seconds > 9 ? `${seconds}` : `0${seconds}`;

        return hoursString + minutesString + secondsString;
    }

    private startOutgoingCallNotRespondTimer(callId: number) {
        this.outgoingCallNotRespondTimer$ = timer(this.outgoingCallNotRespondedMaxTimeSec * 1000)
            .subscribe(() => {
                this.callNotRespond$.next(callId);
            });
    }

    private sendIceCandidatesFromBuffer(): void {
        if (this.activeCallId) {
            this.outgoingIceCandidatesBuffer.forEach((candidate) => {
                this.store.dispatch(CallActions.candidate({callId: this.activeCallId, sdp: candidate}));
            });
            this.outgoingIceCandidatesBuffer = [];
        }
    }

    private addIceCandidatesFromBuffer(): void {
        if (this.activeCallId) {
            this.incomingIceCandidatesBuffer.forEach(({callId, sdp}) => {
                if (callId === this.activeCallId) {
                    this.activeCall.handleICECandidateMessage(JSON.parse(sdp));
                }
            });
            this.incomingIceCandidatesBuffer = [];
        }
    }

    private startTimer(): void {
        let counter = 1;
        this.timer = setInterval(() => this.activeCallTime$.next(ActiveCallService.secondsToHHMMSS(counter++)), 1000);
    }

    private createActiveCall(callType: CallType): void {
        this.outgoingIceCandidatesBuffer = [];
        this.outgoingIceCandidatesBuffer = [];
        this.activeCall = new ActiveCallRef(
            environment.calls.RTCPeerConfiguration,
            this.window,
            this.userMedia,
            callType
        );

        this.activeCall.newIceCandidate$.subscribe((sdp) => {
            if (sdp) {
                if (this.activeCallId) {
                    this.store.dispatch(CallActions.candidate({callId: this.activeCallId, sdp: JSON.stringify(sdp)}));
                } else {
                    this.outgoingIceCandidatesBuffer.push(JSON.stringify(sdp));
                }
            }
        });

        this.activeCall.offer$.pipe(
            filter((offer) => !!offer),
            skip(1),
        ).subscribe((offer) => {
            this.store.dispatch(CallActions.negotiate({callId: this.activeCallId, offer: JSON.stringify(offer)}));
        });

        this.activeCall.ended$.subscribe({
            next: () => {
                this.endCall(false);
                this.cleanup();
            },
            error: (e) => this.handleCallError(e)
        });
        this.activeCall.connected$.pipe(
            filter((value) => !!value)
        ).subscribe(() => {
            this.store.dispatch(CallActions.connected({callId: this.activeCallId}));
        });

        this.startTimer();
    }

    private handleCallError(error) {
        switch (error.name) {
            case 'NotFoundError':
                alert('Unable to start a call because no microphone was found.');
                break;
            case 'PermissionDeniedError':
            case 'NotAllowedError':
                alert('Please grant access to your microphone before proceeding.');
                break;
        }
        this.cleanup();
    }

    private playInboundCallSound(): void {
        if (!this.activeCall) {
            this.audioElement.src = '/assets/audio/ring.ogg';
            this.audioElement.setAttribute('loop', 'true');
            this.audioElement.play();
        }
    }

    private playOutboundCallSound(): void {
        this.audioElement.src = '/assets/audio/outgoing-call.ogg';
        this.audioElement.setAttribute('loop', 'true');
        this.audioElement.play();
    }

    private pauseAudio(): void {
        if (this.audioElement) {
            this.audioElement.pause();
            this.audioElement.currentTime = 0;
        }
    }

    private stopTimer() {
        clearInterval(this.timer);
        this.activeCallTime$.next('00:00');
    }

    private cleanup() {
        this.stopTimer();
        this.pauseAudio();
        if (this.activeCall) {
            this.activeCall = null;
            this.isCallActive$.next(false);
            this.isMuted$.next(false);
        }
        this.activeCallId = null;
        this.outgoingIceCandidatesBuffer = [];
        this.incomingIceCandidatesBuffer = [];
        this.outgoingCallNotRespondTimer$?.unsubscribe();
    }

    constructor(
        @Optional() @Inject(WINDOW) private window: Window,
        private userMedia: UserMediaService,
        private matSnackBar: MatSnackBar,
        private http: HttpClient,
        private actions$: Actions,
        private store: Store<fromRoot.State>
    ) {
        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callAcceptedReceived),
        ).subscribe(({callId, sdp}) => {
            if (this.activeCall && this.activeCallId === callId && this.activeCall.type === 'outbound') {
                this.activeCall.setRemoteDescription(JSON.parse(sdp));
                this.outgoingCallNotRespondTimer$?.unsubscribe();
            }
        });

        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callNegotiateReceived),
        ).subscribe(({callId, offer}) => {
            if (this.activeCall && this.activeCallId === callId) {
                this.activeCall.setRemoteDescription(JSON.parse(offer));
            }
        });

        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callIceCandidateReceived),
        ).subscribe(({callId, sdp}) => {
            if (!this.activeCall) {
                this.incomingIceCandidatesBuffer.push({callId, sdp});
            }
            if (this.activeCall && this.activeCallId === callId) {
                this.activeCall.handleICECandidateMessage(JSON.parse(sdp));
            }
        });

        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callEndedReceived),
        ).subscribe(({callId}) => {
            if (this.activeCall && this.activeCallId === callId) {
                this.activeCall.endCall();
            }
        });

        this.actions$.pipe(
            ofType(
                PrivateMessagesWsActions.callEndedReceived,
                CallApiActions.sendDeclineSucceeded,
                PrivateMessagesWsActions.callAcceptedReceived
            ),
        ).subscribe(({callId}) => {
            this.pauseAudio();
        });

        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callInviteReceived),
        ).subscribe(({callId}) => {
            if (!this.activeCall) {
                this.playInboundCallSound();
            }
        });

        this.actions$.pipe(
            ofType(CallApiActions.sendOfferSucceeded),
        ).subscribe(({callId}) => {
            if (this.activeCall && !this.activeCallId) {
                this.activeCallId = callId;
                this.sendIceCandidatesFromBuffer();
                this.startOutgoingCallNotRespondTimer(this.activeCallId);
            }
        });

        this.actions$.pipe(
            ofType(PrivateMessagesWsActions.callDeclinedReceived),
        ).subscribe(({callId}) => {
            if (this.activeCall && callId === this.activeCallId) {
                this.matSnackBar.open(messages.call.declined);
            }
            this.endCall();
        });

        this.actions$.pipe(
            ofType(
                CallApiActions.sendAnswerFailed,
                CallApiActions.sendOfferFailed,
            ),
        ).subscribe(() => {
            this.matSnackBar.open('An unexpected error occurred.');
            this.activeCall.endCall();
        });
    }

    setCurrentUser(user: UserModel): void {
        this.currentUser = user;
    }

    async startCall(interlocutorId: number) {
        if (this.activeCall) {
            return;
        }
        this.createActiveCall('outbound');

        await this.activeCall.initOffer();

        this.activeCall.offer$.pipe(
            filter((offer) => !!offer),
            take(1),
        ).subscribe((offer) => {
            this.store.dispatch(CallActions.offer({userId: interlocutorId, sdp: JSON.stringify(offer)}));
            this.isCallActive$.next(true);
        });
        this.playOutboundCallSound();
    }

    async acceptInvite(callId: number, remoteOffer: string) {
        if (this.activeCall) {
            return;
        }
        this.pauseAudio();
        this.createActiveCall('inbound');

        this.activeCallId = callId;
        this.addIceCandidatesFromBuffer();

        this.activeCall.handleInviteMessage(callId, JSON.parse(remoteOffer));

        this.activeCall.offer$.pipe(
            filter((offer) => !!offer),
            take(1),
        ).subscribe((offer) => {
            this.store.dispatch(CallActions.answer({callId: this.activeCallId, sdp: JSON.stringify(offer)}));
            this.isCallActive$.next(true);
        });
    }

    endCall(endActiveCall = true): void {
        if (this.activeCallId) {
            this.store.dispatch(CallActions.end({callId: this.activeCallId}));
            this.activeCallId = null;
        }
        if (this.activeCall && endActiveCall) {
            this.activeCall.endCall();
        } else {
            this.cleanup();
        }
    }

    setVolumeLevel(level: number): void {
        this.volumeLevel$.next(level);
        if (this.activeCall) {
            this.activeCall.setVolume(level);
        }
    }

    toggleMute(): void {
        this.isMuted$.next(this.activeCall.toggleMute());
    }
}
