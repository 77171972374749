<app-search-input
    [searchCriteria]="criteria"
    (searched$)="(criteria !== $event) && searched$.emit($event)"
    (cleared$)="searchCleared$.emit()"
>
</app-search-input>
<div *ngIf="(tags && tags.length)" id="search-caption">
    type in search above for your suggestion first, to be sure it does not already exist
</div>
<div
    id="unique-addition-text"
    class="mat-headline"
    *ngIf="(tags && !tags.length)"
>
    Your addition is unique!
</div>

<div class="parent-tags">
    <app-expandable-tag
        *ngFor="let tag of tags"
        [tag]="tag"
        [showStats]="false"
        [highlightedTextInput]="criteria"
        [tagIsExpanded]="expandedTagId === tag.id"
        (toggleExpanded$)="expandedTagId = $event"
    ></app-expandable-tag>
</div>
<div id="action-buttons">
    <button
        id="btn-next"
        class="action-button"
        mat-stroked-button
        color="primary"
        [disabled]="!criteria"
        (click)="submitted$.emit(criteria)"
    >next
    </button>
    <button
        class="action-button"
        mat-stroked-button
        color="primary"
        (click)="closed$.emit()"
    >cancel
    </button>
</div>
<app-disclaimer></app-disclaimer>
