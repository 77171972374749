import { Action, createReducer, on } from '@ngrx/store';
import { VideoContestStatusModel } from '@core/models';
import { VideoContestAPIActions } from '@core/store/video-contest/actions';

export const featureKey = 'videoContestStatus';

export interface State {
    previous: VideoContestStatusModel;
    current: VideoContestStatusModel;
    next: VideoContestStatusModel;
}

export const initialState: State = {
    previous: null,
    current: null,
    next: null
};


const videoContestStatusReducer = createReducer<State, Action>(
    initialState,
    on(VideoContestAPIActions.getStatusSucceeded, (state, result): State => ({
        ...state,
        previous: result.previous,
        current: result.current,
        next: result.next
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return videoContestStatusReducer(state, action);
}

export const previous = (state: State): VideoContestStatusModel => state.previous;
export const current = (state: State): VideoContestStatusModel => state.current;
export const next = (state: State): VideoContestStatusModel => state.next;
