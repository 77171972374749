import { createAction, props } from '@ngrx/store';

import { PublicUserModel } from '@core/models';


export const prefix = '[search api actions]';

export const loadUsersSucceeded = createAction(
    `${prefix} load users succeeded`,
    props<{ users: PublicUserModel[] }>()
);

export const loadUsersFailed = createAction(
    `${prefix} load users failed`,
    props<{ error: any }>()
);
