import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { messages } from '@core/messages';
import { GeolocationPositionErrors } from '@core/services';


@Component({
    selector: 'app-geolocation-error-dialog',
    templateUrl: './geolocation-error-dialog.component.html',
    styleUrls: ['./geolocation-error-dialog.component.scss']
})
export class GeolocationErrorDialogComponent {

    private messages: { [key in GeolocationPositionErrors]: string } = {
        [GeolocationPositionErrors.PERMISSION_DENIED]: messages.geolocation.error.permissionDenied,
        [GeolocationPositionErrors.POSITION_UNAVAILABLE]: messages.geolocation.error.positionUnavailable,
        [GeolocationPositionErrors.TIMEOUT]: messages.geolocation.error.positionUnavailable,
    };

    text: string;

    static show(matDialog: MatDialog, error: GeolocationPositionErrors): MatDialogRef<GeolocationErrorDialogComponent> {
        return matDialog.open<GeolocationErrorDialogComponent, GeolocationPositionErrors>(GeolocationErrorDialogComponent, {
            data: error,
            backdropClass: 'popup-backdrop-high-opacity',
            panelClass: null,
            position: {
                top: '180px'
            },
            maxWidth: null,
            width: '360px',
        });
    }

    constructor(@Inject(MAT_DIALOG_DATA) error: GeolocationPositionErrors) {
        this.text = this.messages[error] || this.messages[GeolocationPositionErrors.PERMISSION_DENIED];
    }
}
