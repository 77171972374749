import { Component, Input, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { CustomSvgIcon } from '@material/custom-svg-icon';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Component({
    selector: 'app-header-auth',
    templateUrl: './header-auth.component.html',
    styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent extends WithDestroyedSubjectComponent implements OnInit {

    readonly logoFull = CustomSvgIcon.logoFull;

    redirectToUrl = startPageNonAuthenticatedPath;

    redirectToFragment: string;

    redirectToQueryParams: Params;

    @Input()
    showBtnClose: boolean;

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            select(fromRoot.getRouteQueryParams),
            takeUntil(this.destroyed$)
        ).subscribe((params: Params) => {
            if (params.redirectTo && !params.fromGuard) {
                const urlTree = this.router.parseUrl(params.redirectTo);
                this.redirectToFragment = urlTree.fragment;
                this.redirectToQueryParams = {...urlTree.queryParams};
                urlTree.fragment = null;
                urlTree.queryParams = {};
                this.redirectToUrl = urlTree.toString();
            } else {
                this.redirectToUrl = startPageNonAuthenticatedPath;
                this.redirectToFragment = null;
                this.redirectToQueryParams = {};
            }
        });
    }
}
