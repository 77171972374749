import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import {
    CallInformationModel,
    CallStatus,
    ChatMessageModel,
    ChatReplyOnInputFullModel,
    ChatReplyOnMessageModel,
    ConversationFlatModel,
    ConversationFullModel,
    ConversationsPageFilter,
    PublicUserModel
} from '@core/models';
import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import * as fromPrivateChatMessages from './private-chat-messages.reducer';
import * as fromConversationsList from './conversations-list.reducer';


export interface ConversationsState {
    privateChatMessages: fromPrivateChatMessages.State;
    conversationsList: fromConversationsList.State;
}

export interface State extends fromRoot.State {
    conversations: ConversationsState;
}

export function reducers(state: ConversationsState | undefined, action: Action) {
    return combineReducers({
        privateChatMessages: fromPrivateChatMessages.reducer,
        conversationsList: fromConversationsList.reducer
    })(state, action);
}


export const getInterlocutor = createSelector(
    fromRoot.getConversationInterlocutorId,
    fromRoot.getPublicUsersEntities,
    (userId, users): PublicUserModel => users && userId && users[userId]
);

export const getConversationsState = createFeatureSelector< ConversationsState>('conversations');

export const getPrivateChatMessagesState = createSelector(getConversationsState, (state) => state && state.privateChatMessages);
export const getPrivateChatHasMore = createSelector(getPrivateChatMessagesState, fromPrivateChatMessages.getHasMore);
export const getPrivateChatIsPending = createSelector(getPrivateChatMessagesState, fromPrivateChatMessages.getIsPending);
export const getPrivateChatMessagesIds = createSelector(getPrivateChatMessagesState, fromPrivateChatMessages.getPrivateChatMessagesIds);
const getReplyOnInputMessage = createSelector(getPrivateChatMessagesState, fromPrivateChatMessages.getReplyOnInputMessage);
export const getPrivateChatReplyOnInputMessage = createSelector(
    getReplyOnInputMessage,
    fromRoot.getPublicUsersEntities,
    (message, users): ChatReplyOnInputFullModel => {
        if (!users || !message) {
            return null;
        }

        return {
            replyOnType: message.replyOnType,
            date: message.date,
            text: message.text,
            replyOnId: message.replyOnId,
            userFrom: {...users[message.userFromId]}
        };
    }
);

const getMessages = createSelector(getPrivateChatMessagesState, fromPrivateChatMessages.getPrivateChatMessages);
export const getPrivateChatMessages = createSelector(
    fromRoot.getPublicUsersEntities,
    getMessages,
    fromCurrentUser.getUser,
    fromRoot.getCallInvite,
    fromRoot.getActiveCallId,
    getInterlocutor,
    (
        users,
        messages,
        currentUser,
        invite,
        activeCallId,
        conversationInterlocutor
    ): ChatMessageModel[] => {
        if (!users || !messages) {
            return null;
        }
        const result: ChatMessageModel[] = [];
        messages.forEach((message) => {
            let replyOn: ChatReplyOnMessageModel;
            let call: CallInformationModel;
            let userFrom = {...users[message.userIdFrom]};

            if (message.replyOnText) {
                replyOn = {
                    text: message.replyOnText,
                    userFrom: {...users[message.replyOnUserId]},
                    createdOn: message.replyOnCreatedOn
                };
            }
            if (message.call) {
                call = {
                    interlocutor: {...users[currentUser.id === message.userIdTo ? message.userIdFrom : message.userIdTo]},
                    ...message?.call
                };
                if (message.call.status === CallStatus.offer || call.status === CallStatus.inProgress) {
                    userFrom = {...users[currentUser.id]};
                }
            }
            result.push({
                id: message.id,
                text: message.text,
                isEdited: message.isEdited,
                userFrom,
                createdOn: message.createdOn,
                replyOn: replyOn || null,
                call: call || null
            });
        });
        if (invite && (invite.userId === conversationInterlocutor?.id)) {
            result.push({
                id: 0,
                text: '',
                isEdited: false,
                userFrom: {...users[invite.userId]},
                createdOn: invite.createdOn,
                replyOn: null,
                call: {
                    status: activeCallId ? CallStatus.inProgress : CallStatus.invite,
                    interlocutor: {...users[invite.userId]},
                    duration: null
                }
            });
        }
        return result;
    }
);

export const getConversationsPageState = createSelector(getConversationsState, (state) => state.conversationsList);
export const getConversationsIsPending = createSelector(getConversationsPageState, fromConversationsList.getIsPending);
export const getConversationsFilter = createSelector(getConversationsPageState, fromConversationsList.getFilter);

export const getConversations = createSelector(
    fromCurrentUser.getConversations,
    fromRoot.getPublicUsersEntities,
    getConversationsFilter,
    (conversations, users, filter): ConversationFullModel[] => {
        if (!conversations || !users) {
            return null;
        }
        if (ConversationsPageFilter.whoIFollow === filter) {
            conversations = conversations.filter((conversation) => users[conversation.interlocutorId].isFollowed);
        }
        return conversations.map((conversation): ConversationFullModel => ({
            id: conversation.id,
            callId: conversation?.callId,
            isRead: conversation.isRead,
            text: conversation.text,
            createdOn: conversation.createdOn,
            interlocutor: {
                ...users[conversation.interlocutorId]
            }
        }));
    }
);

export const getPrivateChatConversationModel = createSelector(
    fromRoot.getConversationInterlocutorId,
    fromCurrentUser.getConversations,
    (interlocutorId, conversations): ConversationFlatModel => {
        if (!interlocutorId || !conversations) {
            return null;
        }
        const model = conversations.find((conversation) => conversation.interlocutorId === interlocutorId);
        return model || null;
    }
);
