import { createAction, props } from '@ngrx/store';

import { ExternalSocialNetwork } from '@core/models';
import { RoiLocation } from '@core/services/roi-api/roi-location';


export const prefix = '[Landing "Caregivers supporting caregivers"]';

export const emailSubmitted = createAction(
    `${prefix} email submitted`,
    props<{ email: string, location: RoiLocation }>()
);

export const submittedWithSocialNetwork = createAction(
    `${prefix} submitted with social network`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        email: string,
        token: string,
        location: RoiLocation
    }>()
);
