import { createAction, props } from '@ngrx/store';

import { NewTagRequestPopupStep } from '@shared/containers/new-subject-request/new-tag-requestp-popup-step';


export const prefix = '[new tag request popup]';

export const init = createAction(
    `${prefix} popup init`,
    props<{ searchCriteria: string }>()
);

export const stepChanged = createAction(
    `${prefix} step changed`,
    props<{ step: NewTagRequestPopupStep }>()
);

export const searched = createAction(
    `${prefix} searched`,
    props<{ criteria: string }>()
);

export const searchCleared = createAction(`${prefix} search cleared`);

export const destroyed = createAction(`${prefix} popup destroyed`);
