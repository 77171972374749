import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';


@Directive({
    selector: '[appAdSenseHeightFix]'
})
/**
 * @description This directive is needed to mitigate adsense's js script functionality of
 * changing parent elements height/max-height values, which can lead to breaking layouts
 */
export class AdSenseHeightFixDirective implements OnInit, OnDestroy {

    private mutationObserver: MutationObserver;

    constructor(
        private element: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    private resetHeightChanges() {
        const style = this.element.nativeElement.getAttribute('style');
        if (!style || !style.includes('height')) {
            return;
        }
        this.element.nativeElement.setAttribute(
            'style',
            style.split(';').filter((part: string) => !part.trim().includes('height')).join('; ')
        )
    }

    private initObserver(): void {
        this.mutationObserver = new MutationObserver(() => this.resetHeightChanges())
        this.mutationObserver.observe(this.element.nativeElement, {
            attributes: true,
            attributeFilter: ['style'],
            attributeOldValue: true
        })
    }

    ngOnInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.initObserver();
    }

    ngOnDestroy(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
    }

}
