<div class="ios-fix">
    <div class="mat-headline popup-header" mat-dialog-title>report a member</div>
    <div mat-dialog-content>
        <div class="sub-header">
            You are choosing to ask that this member be reviewed and possibly blocked for inappropriate behavior.<br>
            Please let us know why.
        </div>
        <form (ngSubmit)="submit()" [formGroup]="form">
            <div id="textarea-wrapper">
                <textarea
                    formControlName="text"
                    placeholder="(Please enter description)"
                ></textarea>
                <mat-error *ngIf="hasError(form.get('text'))">
                    Please enter description
                </mat-error>
            </div>
            <div id="single-user-block-wrapper">
                <mat-checkbox
                    formControlName="singleUserBlock"
                >just block the member for me
                </mat-checkbox>
            </div>
        </form>
        <div class="action-buttons">
            <button
                mat-stroked-button
                color="primary"
                [disabled]="isDisabled || !form.valid"
                (click)="submit()"
            >send
            </button>
            <button
                mat-stroked-button
                color="primary"
                [disabled]="isDisabled"
                (click)="close()"
            >cancel
            </button>
        </div>
        <app-disclaimer></app-disclaimer>
    </div>
</div>
