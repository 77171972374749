import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


export const WINDOW = new InjectionToken('WindowToken');

export function windowFactory(platformId: object): Window | null {
    if (isPlatformBrowser(platformId)) {
        return window;
    }
    return null;
}

export const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [PLATFORM_ID]
};
