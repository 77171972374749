import { createAction, props } from '@ngrx/store';
import { Affirmation } from '@core/models/affirmation.model';


const prefix = '[Affirmation-exist Guard]';

export const guardTriggered = createAction(`${prefix} guard triggered`);

export const affirmationLoaded = createAction(
    `${prefix} affirmationLoaded`,
    props<{ affirmation: Affirmation }>()
);
