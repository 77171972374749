import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import * as fromPublicChatMessages from './public-chat-message.reducer';
import * as fromSettingsPage from './settings-page.reducer';
import * as fromPublicChatUsers from './public-chat-users.reducer';
import * as fromFeedQuestions from './feed-questions.reducer';
import * as fromFeedCanHelpRecords from './feed-can-help-records.reducer';
import * as fromFeedNeedHelpRecords from './feed-need-help-records.reducer';
import * as fromTagDescription from './tag-description.reducer';
import * as fromFeedAll from './feed-all.reducer';
import {
    ChatMessageModel,
    ChatReplyOnMessageModel,
    FeedRecord,
    FeedRecordType,
    FeedRecordWithQuestion,
    FeedRecordWithSetting,
    PublicUserModel,
    TagModel,
    UserRole,
    UserSettingsModel
} from '@core/models';
import { FeedPageTabs } from '@core/enums';


export interface FeedState {
    tagDescription: fromTagDescription.State;
    publicChatMessages: fromPublicChatMessages.State;
    settingsPage: fromSettingsPage.State;
    publicChatUsers: fromPublicChatUsers.State;
    feedQuestions: fromFeedQuestions.State;
    feedCanHelpRecords: fromFeedCanHelpRecords.State;
    feedNeedHelpRecords: fromFeedNeedHelpRecords.State;
    feedAllRecords: fromFeedAll.State;
}

export interface State extends fromRoot.State {
    feed: FeedState;
}

export function reducers(state: FeedState | undefined, action: Action) {
    return combineReducers({
        tagDescription: fromTagDescription.reducer,
        publicChatMessages: fromPublicChatMessages.reducer,
        settingsPage: fromSettingsPage.reducer,
        publicChatUsers: fromPublicChatUsers.reducer,
        feedQuestions: fromFeedQuestions.reducer,
        feedCanHelpRecords: fromFeedCanHelpRecords.reducer,
        feedNeedHelpRecords: fromFeedNeedHelpRecords.reducer,
        feedAllRecords: fromFeedAll.reducer,
    })(state, action);
}

export const getFeedState = createFeatureSelector< FeedState>('feed');

const getTagDescriptionState = createSelector(getFeedState, (state: FeedState): fromTagDescription.State => state.tagDescription);
export const getTagDescription = createSelector(getTagDescriptionState, fromTagDescription.getDescription);

export const getPublicChatMessagesState = createSelector(
    getFeedState,
    (state: FeedState) => state.publicChatMessages
);
export const getPublicChatMessagesIds = createSelector(
    getPublicChatMessagesState,
    fromPublicChatMessages.getPublicChatMessagesIds
);

const getMessages = createSelector(
    getPublicChatMessagesState,
    fromPublicChatMessages.getPublicChatMessages
);
export const getPublicChatMessages = createSelector(
    fromRoot.getPublicUsersEntities,
    getMessages,
    fromCurrentUser.getBlockedUsers,
    (
        users,
        messages,
        blockedUsers
    ): ChatMessageModel[] => {
        if (!users || !messages) {
            return null;
        }
        const result: ChatMessageModel[] = [];
        messages.forEach((message) => {
            if (
                !users[message.userIdFrom]
                || blockedUsers[message.userIdFrom]
                || (
                    message.replyOnUserId
                    && !users[message.replyOnUserId]
                )
            ) {
                return;
            }
            let replyOn: ChatReplyOnMessageModel;
            if (message.replyOnText && !blockedUsers[message.replyOnUserId]) {
                replyOn = {
                    text: message.replyOnText,
                    userFrom: {
                        ...users[message.replyOnUserId]
                    },
                    createdOn: message.replyOnCreatedOn
                };
            }

            result.push({
                id: message.id,
                createdOn: message.createdOn,
                text: message.text,
                isEdited: message.isEdited,
                userFrom: {
                    ...users[message.userIdFrom]
                },
                replyOn
            });
        });
        return result.length ? result : null;
    }
);
export const getPublicChatMessagesHasMore = createSelector(
    getPublicChatMessagesState,
    fromPublicChatMessages.getHasMore
);
export const getPublicChatMessagesIsPending = createSelector(
    getPublicChatMessagesState,
    fromPublicChatMessages.getIsPending
);

export const getRootTags = createSelector(
    fromRoot.getTags,
    fromRoot.getSelectedTagTree,
    (tags, selectedTagTree): TagModel[] => {
        if (!tags || !selectedTagTree) {
            return null;
        }
        const selectedRootId = selectedTagTree[0].id;
        return tags
            .filter((tag) => !tag.parentId && tag.id !== selectedRootId)
            .map((tag) => tag);
    }
);

const getSettingsPageState = createSelector(getFeedState, (state: FeedState) => state.settingsPage);
export const getSettingsPageIsSubmitted = createSelector(getSettingsPageState, fromSettingsPage.getIsSubmitted);
export const getSettingsPageIsSucceeded = createSelector(getSettingsPageState, fromSettingsPage.getIsSucceeded);
export const getSettingsPageIsCancelled = createSelector(getSettingsPageState, fromSettingsPage.getIsCancelled);
export const getSettingsPageIsDeleted = createSelector(getSettingsPageState, fromSettingsPage.getIsDeleted);

const getPublicChatUsersState = createSelector(getFeedState, (state: FeedState) => state.publicChatUsers);
export const getPublicChatUsersCountLoading = createSelector(getPublicChatUsersState, fromPublicChatUsers.getIsCountLoading);
export const getPublicChatUsersOnlineCount = createSelector(getPublicChatUsersState, fromPublicChatUsers.getOnlineCount);
export const getPublicChatUsersPage = createSelector(getPublicChatUsersState, fromPublicChatUsers.getPage);
export const getPublicChatUsersHasMore = createSelector(getPublicChatUsersState, fromPublicChatUsers.getHasMore);
export const getPublicChatUsersIsLoading = createSelector(getPublicChatUsersState, fromPublicChatUsers.getIsUsersLoading);

const getPublicChatUsersIds = createSelector(getPublicChatUsersState, fromPublicChatUsers.getChatUsers);
export const getPublicChatUsersOnline = createSelector(
    getPublicChatUsersIds,
    fromRoot.getPublicUsersEntities,
    (ids, users): PublicUserModel[] => {
        if (!ids || !users) {
            return [];
        }
        const result: PublicUserModel[] = [];
        ids.forEach(id => {
            if (users[id]) {
                result.push(users[id]);
            }
        });
        return result;
    }
);

const getCanHelpRecordsState = createSelector(getFeedState, (state: FeedState) => state.feedCanHelpRecords);
const getCanHelpRecords = createSelector(getCanHelpRecordsState, fromFeedCanHelpRecords.getRecords);

const getNeedHelpRecordsState = createSelector(getFeedState, (state: FeedState) => state.feedNeedHelpRecords);
const getNeedHelpRecords = createSelector(getNeedHelpRecordsState, fromFeedNeedHelpRecords.getRecords);

const getQuestionsState = createSelector(getFeedState, (state) => state.feedQuestions);
const getQuestions = createSelector(getQuestionsState, fromFeedQuestions.getQuestions);

const getAllState = createSelector(getFeedState, (state) => state.feedAllRecords);
const getAll = createSelector(getAllState, fromFeedAll.all);

const getIsCanHelpTabHasMore = createSelector(getCanHelpRecordsState, fromFeedCanHelpRecords.getHasMore);
const getIsNeedHelpTabHasMore = createSelector(getNeedHelpRecordsState, fromFeedNeedHelpRecords.getHasMore);
const getIsQuestionTabHasMore = createSelector(getQuestionsState, fromFeedQuestions.getHasMore);
const getIsAllTabHasMore = createSelector(getAllState, fromFeedAll.getHasMore);

const getIsCanHelpTabLoading = createSelector(getCanHelpRecordsState, fromFeedCanHelpRecords.getIsLoading);
const getIsNeedHelpTabLoading = createSelector(getNeedHelpRecordsState, fromFeedNeedHelpRecords.getIsLoading);
const getIsQuestionTabLoading = createSelector(getQuestionsState, fromFeedQuestions.getIsLoading);
const getIsAllTabLoading = createSelector(getAllState, fromFeedAll.getIsLoading);

export const getFeedCanHelpPage = createSelector(getCanHelpRecordsState, fromFeedCanHelpRecords.getPage);
export const getFeedNeedHelpPage = createSelector(getNeedHelpRecordsState, fromFeedNeedHelpRecords.getPage);
export const getFeedQuestionsPage = createSelector(getQuestionsState, fromFeedQuestions.getPage);
export const getFeedAllPage = createSelector(getAllState, fromFeedAll.getPage);

const combineTabsFlags = (canHelpValue, needHelpValue, questionsValue, allValue): { [p in FeedPageTabs]: boolean } => ({
    [FeedPageTabs.canHelp]: canHelpValue,
    [FeedPageTabs.needHelp]: needHelpValue,
    [FeedPageTabs.questions]: questionsValue,
    [FeedPageTabs.all]: allValue
});

export const getIsTabHasMore = createSelector(
    getIsCanHelpTabHasMore,
    getIsNeedHelpTabHasMore,
    getIsQuestionTabHasMore,
    getIsAllTabHasMore,
    combineTabsFlags
);
export const getIsTabLoading = createSelector(
    getIsCanHelpTabLoading,
    getIsNeedHelpTabLoading,
    getIsQuestionTabLoading,
    getIsAllTabLoading,
    combineTabsFlags
);

const composeFeedSettingRecords = (
    recordType: FeedRecordType.canHelpSetting | FeedRecordType.needHelpSetting,
    settings: UserSettingsModel[],
    publicUsers: Dictionary<PublicUserModel>,
    tag: TagModel
): FeedRecordWithSetting[] | null => {
    const result: FeedRecordWithSetting[] = [];
    if (!settings.length || !publicUsers || !tag) {
        return null;
    }
    settings.forEach((setting) => {
        if (!publicUsers[setting.userId]) {
            return;
        }
        result.push({
            recordType,
            user: publicUsers[setting.userId],
            data: {
                ...setting,
                tag
            }
        });
    });
    return result.length ? result : null;
};

export const getFeedCanHelpRecords = createSelector(
    getCanHelpRecords,
    fromRoot.getPublicUsersEntities,
    fromRoot.getSelectedTag,
    (
        settings,
        publicUsers,
        tag,
    ): FeedRecordWithSetting[] => composeFeedSettingRecords(
        FeedRecordType.canHelpSetting,
        settings,
        publicUsers,
        tag,
    )
);

export const getFeedNeedHelpRecords = createSelector(
    getNeedHelpRecords,
    fromRoot.getPublicUsersEntities,
    fromRoot.getSelectedTag,
    (
        settings,
        publicUsers,
        tag,
    ): FeedRecordWithSetting[] => composeFeedSettingRecords(
        FeedRecordType.needHelpSetting,
        settings,
        publicUsers,
        tag,
    )
);

export const getFeedQuestions = createSelector(
    getQuestions,
    fromRoot.getPublicUsersEntities,
    fromRoot.getSelectedTag,
    (
        questions,
        publicUsers,
        tag
    ): FeedRecordWithQuestion[] | null => {
        const result: FeedRecordWithQuestion[] = [];
        if (!questions.length || !publicUsers || !tag) {
            return null;
        }
        questions.forEach((question) => {
            if (!publicUsers[question.userId]) {
                return;
            }
            result.push({
                recordType: FeedRecordType.question,
                user: publicUsers[question.userId],
                data: {...question}
            });
        });
        return result.length ? result : null;
    }
);

export const getFeedAll = createSelector(
    getAll,
    fromRoot.getPublicUsersEntities,
    fromRoot.getSelectedTag,
    (
        records,
        publicUsers,
        tag
    ): FeedRecord[] | null => {
        const result: FeedRecord[] = [];
        if (!records.length || !publicUsers || !tag) {
            return null;
        }

        records.forEach((record) => {
            let recordType;
            if (record?.role) {
                recordType = record.role === UserRole.canHelp ? FeedRecordType.canHelpSetting : FeedRecordType.needHelpSetting;
            } else {
                recordType = FeedRecordType.question;
            }
            result.push({
                recordType,
                user: publicUsers[record.userId],
                data: {
                    ...record,
                    tag
                }
            });
        });

        return result.length ? result : null;
    }
);
