import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { UserSettingsModel } from '@core/models';
import { Action, createReducer, on } from '@ngrx/store';
import { AppInitActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { AuthApiActions } from '@core/store/auth/actions';


export type State = EntityState<UserSettingsModel>;

export const adapter = createEntityAdapter<UserSettingsModel>();

const initialState = adapter.getInitialState();

const FeedSettingsReducer = createReducer(
    initialState,
    on(
        AppInitActions.userNotAuthenticated,
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        (): State => initialState
    ),
    on(
        AppInitActions.userAuthenticated,
        UserApiActions.userProfileLoadSucceeded,
        (state: State, {settings}): State => adapter.setAll(settings, state)
    ),
    on(
        UserApiActions.deleteSettingsSucceeded,
        (state: State, {id}): State => adapter.removeOne(id, state)
    ),
    on(
        UserApiActions.saveHelpSettingsSucceeded,
        (state, {settings}): State => adapter.upsertOne(settings, state)
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return FeedSettingsReducer(state, action);
}

export const {
    selectAll: getSettings
} = adapter.getSelectors();
