import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { FeedApiActions, FeedPageActions, FeedPageTabsActions } from '@core/store/feed/actions';
import { environment } from 'src/environments/environment';


export interface State extends EntityState<any> {
    page: number;
    isLoading: boolean;
    hasMore: boolean;
    error: any;
}

const adapter = createEntityAdapter<any>();

export const initialState = adapter.getInitialState({
    page: 1,
    isLoading: false,
    hasMore: true,
    error: null
});

const FeedAllReducer = createReducer(
    initialState,
    on(FeedPageActions.pageInit, () => ({
        ...initialState
    })),
    on(FeedPageTabsActions.allLoadMore, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(FeedApiActions.loadFeedAllFailed, (state, error) => ({
        ...state,
        isLoading: false,
        error
    })),
    on(FeedApiActions.loadFeedAllSucceeded, (state, {records}) => adapter.upsertMany(records, {
        ...state,
        page: state.page + 1,
        isLoading: false,
        hasMore: records.length === environment.feed.pageSize,
        error: null
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return FeedAllReducer(state, action);
}

export const {
    selectAll: all
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
export const getHasMore = (state: State) => state.hasMore;
export const getPage = (state: State) => state.page;
