<span class="custom-number-container" (click)="$event.preventDefault();" [class.disabled]="isDisabled">
    <button
            [disabled]="isDisabled || (experience === 1 && period === periods.month)"
            (click)="shiftValue(-1)"
            type="button"
            class="custom-number-button decrement"
    >-</button>
    <span class="custom-number-input">{{ label }}</span>
    <button [disabled]="isDisabled || (experience > 50)"
            (click)="shiftValue(1)"
            type="button"
            class="custom-number-button increment"
    >+</button>
    <span class="period-label">total {{period.toString().toLowerCase() }}s</span>
</span>
