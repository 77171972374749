import { Pipe, PipeTransform } from '@angular/core';


import memo from 'memo-decorator';


import { UsersStatsModel } from '@core/models';
import { SearchTagModel } from '@core/models/search-tag.model';


@Pipe({
    name: 'statsSum'
})
export class StatsSumPipe implements PipeTransform {
    @memo()
    transform(tag: SearchTagModel): UsersStatsModel {
        const result = {...tag.stats};
        if (tag.child) {
            tag.child.forEach((child) => {
                Object.keys(result).forEach((key: keyof UsersStatsModel) => {
                    result[key] += +child.stats[key];
                });
            });
        }
        return result;
    }

}
