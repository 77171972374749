import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsersStatsApiModel, UsersStatsModel } from '@core/models';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class UsersStatsService {

    private static convertApiModel(fromServer: UsersStatsApiModel): UsersStatsModel {
        return {
            total: fromServer.countAllUsers,
            online: fromServer.countOnlineUsers,
            canHelp: fromServer.countCanHelpUsers,
            needHelp: fromServer.countNeedHelpUsers,
            questions: fromServer.countQuestions,
            tagId: fromServer.tagId || null
        };
    }

    getStatisticForAllTags(): Observable<UsersStatsModel[]> {
        return this.http.get<UsersStatsApiModel[]>(`/statistic`).pipe(
            switchMap((response: UsersStatsApiModel[]): Observable<UsersStatsModel[]> => {
                return of(response.map(UsersStatsService.convertApiModel));
            })
        );
    }

    constructor(private http: HttpClient) {
    }
}
