import { createAction, props } from '@ngrx/store';


const prefix = '[Public chat page]';

export const init = createAction(
    `${prefix} init`,
    props<{ tagId: number }>()
);

export const subscribe = createAction(
    `${prefix} subscribe`,
    props<{ tagId: number }>()
);

export const loadChatHistory = createAction(
    `${prefix} load chat history`,
    props<{ tagId: number }>()
);

export const sendMessage = createAction(
    `${prefix} send message`,
    props<{ tagId: number, message: string }>()
);

export const pageClosed = createAction(
    `${prefix} page closed`
);

export const deleteMessage = createAction(
    `${prefix} delete message`,
    props<{ messageId: number }>()
);

export const editMessage = createAction(
    `${prefix} edit message`,
    props<{ messageId: number, text: string }>()
);

export const replyOnMessage = createAction(
    `${prefix} reply on message`,
    props<{
        tagId: number,
        replyOnMessageId: number,
        text: string
    }>()
);
