import {
    GoogleLoginProvider,
    FacebookLoginProvider,
    SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';

import { environment } from 'src/environments/environment';
import { AppleLoginProvider } from '../providers';


export function getSocialAuthServiceConfigsFactory(): SocialAuthServiceConfig {

    const config: SocialAuthServiceConfig = {autoLogin: false, providers: []};

    if (environment.facebookClientId) {
        config.providers.push({
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId),
        });
    }

    if (environment.googleClientId) {
        config.providers.push({
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
                environment.googleClientId,
                { oneTapEnabled: false }
            )
        });
    }

    if (environment.appleAppId) {
        config.providers.push({
            id: AppleLoginProvider.PROVIDER_ID,
            provider: new AppleLoginProvider(environment.appleAppId, 'email', environment.baseHref)
        });
    }

    return config;
}
