import { Component, Input, OnInit, Output, EventEmitter, Optional, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { TagModel, UserExperiencePeriod } from '@core/models';
import { CareHistoryModel, ExperienceModel } from '@pages/user/models';
import { WINDOW } from '@core/window';


@Component({
    selector: 'app-feed-user-settings-form',
    templateUrl: './feed-user-settings-form.component.html',
    styleUrls: ['./feed-user-settings-form.component.scss']
})
export class FeedUserSettingsFormComponent implements OnInit {
    formGroup = new UntypedFormGroup({
        about: new UntypedFormControl(''),
        isForMan: new UntypedFormControl(false),
        isForWoman: new UntypedFormControl(false),
        isForNonBinary: new UntypedFormControl(false),
        hasExperience: new UntypedFormControl(false),
        experience: new UntypedFormControl({experience: 1, experiencePeriod: UserExperiencePeriod.year})
    });

    @Input()
    tag: TagModel;

    @Input()
    set data(data: CareHistoryModel) {
        this.formGroup.get('isForMan').setValue(!!data.isForMan);
        this.formGroup.get('isForWoman').setValue(!!data.isForWoman);
        this.formGroup.get('isForNonBinary').setValue(!!data.isForNonBinary);
        this.formGroup.get('about').setValue(data.about);
        const hasExperience = this.formGroup.get('hasExperience');
        const experience = this.formGroup.get('experience');
        if (!data.isForMan && !data.isForWoman && !data.isForNonBinary) {
            hasExperience.disable();
        } else {
            hasExperience.enable();
        }
        if (hasExperience.disabled) {
            experience.disable();
        } else {
            if (data.experience) {
                hasExperience.setValue(true);
                experience.setValue({
                    experience: data.experience,
                    experiencePeriod: data.experiencePeriod
                });
            } else {
                experience.disable();
            }
        }
    }

    @Input()
    isCanHelp: boolean;

    @Input()
    set disabled(disabled: boolean) {
        if (disabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    @Output()
    submit$ = new EventEmitter<CareHistoryModel>();

    constructor(@Optional() @Inject(WINDOW) private window: Window) {
    }

    ngOnInit() {
        this.formGroup.get('isForMan').valueChanges.subscribe(this.onRelatedCheckboxesChanged.bind(this));
        this.formGroup.get('isForWoman').valueChanges.subscribe(this.onRelatedCheckboxesChanged.bind(this));
        this.formGroup.get('isForNonBinary').valueChanges.subscribe(this.onRelatedCheckboxesChanged.bind(this));
        this.formGroup.get('hasExperience').valueChanges.subscribe((value) => {
            const experienceControl = this.formGroup.get('experience');
            if (value) {
                experienceControl.enable();
            } else {
                experienceControl.disable();
            }
        });
    }

    onRelatedCheckboxesChanged() {
        if (
            !this.formGroup.get('isForMan').value
            && !this.formGroup.get('isForWoman').value
            && !this.formGroup.get('isForNonBinary').value
        ) {
            this.formGroup.get('hasExperience').disable();
            this.formGroup.get('hasExperience').patchValue(false);
        } else {
            this.formGroup.get('hasExperience').enable();
            this.formGroup.get('hasExperience').patchValue(true);
        }
    }

    submit() {
        const data = {
            isForMan: this.formGroup.get('isForMan').value,
            isForWoman: this.formGroup.get('isForWoman').value,
            isForNonBinary: this.formGroup.get('isForNonBinary').value,
            about: this.formGroup.get('about').value,
            experience: null,
            experiencePeriod: null
        };
        if (this.formGroup.get('hasExperience').value) {
            const experience: ExperienceModel = this.formGroup.get('experience').value;
            data.experience = experience.experience;
            data.experiencePeriod = experience.experiencePeriod;
        }
        if (!data.about) {
            if (this.window) {
                this.window.alert(this.isCanHelp
                    ? 'A description of your experience in this subject is required'
                    : 'A statement about your need in this subject is required.'
                );
            }
            return;
        }

        this.submit$.emit(data);
    }
}
