import { Component } from '@angular/core';


@Component({
    selector: 'app-incoming-call-icon',
    templateUrl: './incoming-call-icon.component.html',
    styleUrls: ['./incoming-call-icon.component.scss']
})
export class IncomingCallIconComponent {
}
