<div id="ios-fix" class="ios-fix">
    <div mat-dialog-content>
        <app-blocked-profile
            [blockParams]=" {
                reason: reason,
                username: username
            }"
            (termsLinkClicked)="matDialogRef.close()"
        ></app-blocked-profile>
        <button
            mat-flat-button
            color="primary"
            (click)="matDialogRef.close(true)"
        >Ok</button>
    </div>
</div>
