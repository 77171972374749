import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AfterFlattenUserProfileApiModel,
    flattenUserProfileApiModel,
    Geolocation,
    Preferences,
    QuestionModel,
    UserPersonalInfoModel,
    UserProfileApiModel,
    UserSettingsModel
} from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    readonly apiPrefix = '/me';

    getCurrentUserProfile(): Observable<AfterFlattenUserProfileApiModel> {
        return this.http.get<UserProfileApiModel>(this.apiPrefix)
            .pipe(map((user) => {
                return flattenUserProfileApiModel(user);
            }));
    }

    updatePersonalInfo(user: UserPersonalInfoModel): Observable<UserPersonalInfoModel> {
        return this.http.put<UserPersonalInfoModel>(this.apiPrefix, user);
    }

    saveSettings(settings: UserSettingsModel): Observable<UserSettingsModel> {
        return this.http.post<UserSettingsModel>(`${this.apiPrefix}/settings`, settings);
    }

    editQuestion(id: number, text: string): Observable<QuestionModel> {
        return this.http.put<QuestionModel>(`${this.apiPrefix}/questions/${id}`, {text});
    }

    updatePreferences(preferences: Partial<Preferences>): Observable<Preferences> {
        return this.http.put<Preferences>(`${this.apiPrefix}/preferences/`, preferences);
    }

    createQuestion(tagId: number, text: string): Observable<QuestionModel> {
        return this.http.post<QuestionModel>(`${this.apiPrefix}/questions`, {
            tagId,
            text
        });
    }

    deleteQuestion(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiPrefix}/questions/${id}`);
    }

    deleteSettings(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiPrefix}/settings/${id}`);
    }

    changePassword(currentPassword: string, newPassword: string): Observable<{ token: string }> {
        return this.http.post<{ token: string }>(`${this.apiPrefix}/change-password`, {
            currentPassword,
            newPassword
        });
    }

    uploadAvatar(image: Blob): Observable<{ photo: string }> {
        const formData = new FormData();
        formData.append('file', image);
        return this.http.post<{ photo: string }>(`${this.apiPrefix}/photo`, formData);
    }

    updateGeolocation(geolocation: Geolocation): Observable<Geolocation> {
        return this.http.post<Geolocation>(`${this.apiPrefix}/geolocation`, {
            latitude: geolocation.latitude,
            longitude: geolocation.longitude
        });
    }

    deleteGeolocation(): Observable<void> {
        return this.http.delete<void>(`${this.apiPrefix}/geolocation`);
    }

    getDistances(): Observable<{ userId: number, distance: number }[]> {
        return this.http.get<{ userId: number, distance: number }[]>(`${this.apiPrefix}/users/distances`);
    }

    constructor(private http: HttpClient) {
    }
}
