<div class="search-input-wrapper">
    <div id="input-wrapper">
        <input type="text"
               #searchInput
               [(ngModel)]="searchCriteria"
               (keyup.enter)="onEnter()"
               (input)="updateIsDisabled()"
        >
    </div>
    <button
        *ngIf="searchCriteria"
        class="btn-clear"
        mat-icon-button
        (click)="clear()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <button class="btn-search"
            mat-flat-button
            color="primary"
            (click)="this.searched$.emit(this.searchCriteria)"
            [disabled]="isDisabled"
    >search</button>
</div>
