import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';


@Injectable()
export class HostUrlPrefixInterceptor implements HttpInterceptor {

    readonly assetsUrlPrefix = '/assets';

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            (environment.roiApiUrl && req.url.startsWith(environment.roiApiUrl))
            || (environment.blogUrl && req.url.startsWith(environment.blogUrl))
        ) {
            return next.handle(req);
        }

        let prefix: string;
        if (req.url.startsWith(this.assetsUrlPrefix)) {
            prefix = environment.baseHref;
        } else {
            prefix = environment.apiBase;
        }
        const modified = req.clone({
            url: req.url.startsWith(prefix) ? req.url : prefix + req.url
        });
        return next.handle(modified);
    }
}
