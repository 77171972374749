import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { PublicUserModel, QuestionModel, UserSettingsModel } from '@core/models';
import {
    flattenPublicUserProfileApiModel,
    PublicUserProfileApiModel,
    PublicUserProfileFlattenModel
} from '@core/models/public-user-profile.model';


@Injectable({
    providedIn: 'root'
})
export class PublicUsersService {
    public static searchPageLimit = 15;

    readonly prefix = '/users';

    constructor(private http: HttpClient) {
    }

    follow(userId: number): Observable<PublicUserModel> {
        return this.http.post<PublicUserModel>(`${this.prefix}/${userId}/follow`, null);
    }

    unFollow(userId: number): Observable<PublicUserModel> {
        return this.http.post<PublicUserModel>(`${this.prefix}/${userId}/unfollow`, null);
    }

    getUserProfile(userId: number): Observable<PublicUserProfileFlattenModel> {
        return this.http.get<PublicUserProfileApiModel>(`${this.prefix}/${userId}`).pipe(
            map((response): PublicUserProfileFlattenModel => flattenPublicUserProfileApiModel(response))
        );
    }

    searchUsers(username: string, page: number) {
        const params = {
            username,
            size: PublicUsersService.searchPageLimit.toString(),
            page: page.toString()
        };
        return this.http.get<PublicUserModel[]>(`${this.prefix}/search`, {params});
    }

    getQuestion(userId: number, questionId: number): Observable<QuestionModel> {
        return this.http.get<QuestionModel>(`${this.prefix}/${userId}/questions/${questionId}`);
    }

    getSetting(userId: number, settingsId: number): Observable<UserSettingsModel> {
        return this.http.get<UserSettingsModel>(`${this.prefix}/${userId}/settings/${settingsId}`);
    }

    sendFeedUserSettingInteracted(userId: number, settingsId: number) {
        return this.http.post<void>(`${this.prefix}/${userId}/settings/${settingsId}/interacted`, null);
    }

    sendFeedUserQuestionInteracted(userId: number, questionId: number) {
        return this.http.post<void>(`${this.prefix}/${userId}/questions/${questionId}/interacted`, null);
    }

    reportUser(userId: number, text: string) {
        return this.http.post<void>(`${this.prefix}/${userId}/report`, {
            text
        });
    }

    blockUser(userId: number, text: string) {
        return this.http.post<void>(`${this.prefix}/${userId}/block`, {
            text
        });
    }
}
