import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { VideoContestService } from '@core/services';
import { ContestVideosPageActions, VideoContestAPIActions } from '@core/store/video-contest/actions';
import { VideoContestLandingPageActions } from '@core/store/actions';

@Injectable({
    providedIn: 'root'
})
export class VideoContestStatusAPIEffects {
    getContestStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                VideoContestLandingPageActions.init,
                ContestVideosPageActions.init
            ),
            exhaustMap(() => {
                return this.videoContestService.getStatus().pipe(
                    map((status) => VideoContestAPIActions.getStatusSucceeded(status)),
                    catchError((error) => {
                        return of(VideoContestAPIActions.getStatusFailed({error: error.error}));
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private videoContestService: VideoContestService,
    ) {
    }
}
