import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromOnboarding from '@core/store/onboarding';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { TagModel, TagWithChildrenModel, UserRole } from '@core/models';
import { SubjectRequestService } from '@core/services';
import { WINDOW } from '@core/window';
import { NewTagRequestPopupActions } from '@core/store/onboarding/actions';
import { NewTagRequestPopupStep } from '@shared/containers/new-subject-request/new-tag-requestp-popup-step';
import { ParentTagSelectionComponent } from '@shared/components';


@Component({
    selector: 'app-new-subject-request',
    templateUrl: './new-subject-request.component.html',
    styleUrls: ['./new-subject-request.component.scss']
})
export class NewSubjectRequestComponent extends WithDestroyedSubjectComponent implements OnInit, OnDestroy {
    readonly steps = NewTagRequestPopupStep;
    searchCriteria$ = this.store.pipe(
        select(fromOnboarding.getNewSubjectRequestSearchCriteria)
    );
    newTagName: string;
    currentStep = this.steps.searchExistingTag;
    parentTag: TagModel;
    tags: TagWithChildrenModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { searchValue: string },
        @Optional() @Inject(WINDOW) private window: Window,
        private store: Store<fromOnboarding.State>,
        private newSubjectRequestService: SubjectRequestService,
        private snackBar: MatSnackBar,
        public matDialogRef: MatDialogRef<ParentTagSelectionComponent>
    ) {
        super();
    }

    get rootTags(): TagModel[] {
        if (!this.tags) {
            return [];
        }
        return this.tags.filter(tag => tag.parentId === null);
    }

    ngOnInit() {
        this.store.dispatch(NewTagRequestPopupActions.init({ searchCriteria: this.data.searchValue }));
        this.store.pipe(select(fromOnboarding.getTagsForNewSubjectRequest), takeUntil(this.destroyed$))
            .subscribe((tags) => this.tags = tags || []);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.store.dispatch(NewTagRequestPopupActions.destroyed());
    }

    changeStep(step: NewTagRequestPopupStep) {
        this.currentStep = step;
        this.store.dispatch(NewTagRequestPopupActions.stepChanged({ step }));
    }

    onSearchExistingTagSubmitted(newTagName: string) {
        this.newTagName = newTagName;
        this.changeStep(this.steps.parentTagSelection);
    }

    onParentTagSelected(selectedTag: TagModel) {
        this.parentTag = selectedTag;
        this.changeStep(this.steps.newSubjectConfirmation);
        fromEvent(this.window, 'beforeunload')
            .pipe(takeUntil(this.destroyed$))
            .subscribe((event: BeforeUnloadEvent) => event.returnValue = 'string');
    }

    onRequestSubmitted(userRole: UserRole) {
        this.newSubjectRequestService.create(
            this.newTagName,
            userRole,
            this.parentTag ? this.parentTag.id : null
        )
            .subscribe({
                next: () => {
                    this.snackBar.open('Your request has been sent. Thank you.');
                    this.matDialogRef.close();
                }, error: () => {
                    this.snackBar.open('An error has occurred. Please try later.');
                }
            });
    }

    onClosed() {
        if (
            this.currentStep === this.steps.newSubjectConfirmation
            && !this.window.confirm('Do you want to close this page? Changes you made will not be saved.')
        ) {
            return;
        }
        this.matDialogRef.close();
    }

    onSearch(searchedText: string) {
        this.store.dispatch(NewTagRequestPopupActions.searched({ criteria: searchedText }));
    }

    onSearchCleared() {
        this.store.dispatch(NewTagRequestPopupActions.searchCleared());
    }
}
