<footer>
    <nav>
        <a id="blog" [href]="blogUrl" target="_blank" *appFeatureFlag="'blog'">Blog Page</a>
        <a id="about" [attr.rel]="addRelNofollowAttr? 'nofollow' : null " [routerLink]="['/about']">About Us</a>
        <a id="help-desk" [attr.rel]="addRelNofollowAttr? 'nofollow' : null " [routerLink]="['/help-desk']">Contact Us</a>
        <a id="privacy" [attr.rel]="addRelNofollowAttr? 'nofollow' : null " [routerLink]="['/legal-info/privacy']">Privacy</a>
        <a id="terms" [attr.rel]="addRelNofollowAttr? 'nofollow' : null " [routerLink]="['/legal-info/terms']">Terms</a>
        <a id="invite-friend"
           *appFeatureFlag="'tell-friend'"
           appGaTrackEvent
           [attr.rel]="addRelNofollowAttr? 'nofollow' : null "
           [gaEventCategory]="'share-with-friend'"
           [gaEvent]="'click'"
           [routerLink]="['/invite-friend']"
        >Invite a friend</a>
    </nav>
    <section id="social-links">
        <a *ngIf="facebookUrl" [href]="facebookUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.facebookRound"></mat-icon>
            <span class="social-icon-title">Facebook</span>
        </a>
        <a *ngIf="linkedInUrl" [href]="linkedInUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.linkedinRound"></mat-icon>
            <span class="social-icon-title">Linkedin</span>
        </a>
        <a *ngIf="instagramUrl" [href]="instagramUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.instagramRound"></mat-icon>
            <span class="social-icon-title">Instagram</span>
        </a>
        <a *ngIf="youtubeUrl" [href]="youtubeUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.youtubeRound"></mat-icon>
            <span class="social-icon-title">Youtube</span>
        </a>
        <a *ngIf="redditUrl" [href]="redditUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.redditRound"></mat-icon>
            <span class="social-icon-title">Reddit</span>
        </a>
        <a *ngIf="mediumUrl" [href]="mediumUrl" target="_blank" class="social-link">
            <mat-icon [svgIcon]="customSvgIcons.mediumRound"></mat-icon>
            <span class="social-icon-title">Medium</span>
        </a>
    </section>
</footer>
