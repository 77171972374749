import { TagModel } from '@core/models/tag.model';
import { UserRole } from '@core/models/user.model';


export enum UserExperiencePeriod {
    year = 'YEAR',
    month = 'MONTH'
}

export type UserSettingsRole = UserRole.canHelp | UserRole.needHelp;

export interface UserSettingsModel {
    id?: number;
    userId?: number;
    tagId: number | null;
    role: UserSettingsRole;
    about: string;
    isForMan: boolean;
    isForWoman: boolean;
    isForNonBinary: boolean;
    experience: number;
    experiencePeriod: UserExperiencePeriod;
    updatedOn?: string;
}

export interface UserSettingsViewModel extends UserSettingsModel {
    tag: TagModel;
}
