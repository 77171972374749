import { createAction, props } from '@ngrx/store';
import { ReplyOnType } from '@core/models';


export const prefix = '[Private chat page]';

export const pageInit = createAction(
    `${prefix} page init`,
    props<{
        conversationId: number,
        interlocutorId: number
    }>()
);

export const pageDestroyed = createAction(`${prefix} destroyed`);

export const loadChatHistory = createAction(`${prefix} load chat history`);

export const sendMessage = createAction(
    `${prefix} send message`,
    props<{ text: string }>()
);

export const markMessagesAsRead = createAction(
    `${prefix} mark message as read`,
    props<{
        messageId: number,
        conversationId: number
    }>()
);

export const deleteMessage = createAction(
    `${prefix} delete message`,
    props<{ id: number }>()
);

export const editMessage = createAction(
    `${prefix} edit message`,
    props<{ conversationId: number, messageId: number, text: string }>()
);

export const replyOnMessage = createAction(
    `${prefix} reply on message`,
    props<{ replyOnId: number, text: string , replyOnType: ReplyOnType}>()
);

export const callButtonPressed = createAction(
    `${prefix} call button pressed`,
    props<{ status: boolean }>()
);

export const declineCallInvite = createAction(
    `${prefix} decline call invite`,
    props<{ callId: number }>()
);
