import { AfterViewInit, Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TagModel, UserRole } from '@core/models';
import { CareHistoryModel } from '@pages/user/models';
import { WINDOW } from '@core/window';
import {
    FeedUserSettingsFormComponent
} from '@pages/user/components/feed-user-settings-form/feed-user-settings-form.component';

@Component({
    selector: 'app-care-history',
    templateUrl: './care-history.component.html',
    styleUrls: ['./care-history.component.scss']
})
export class CareHistoryComponent implements AfterViewInit {

    initialForm: UntypedFormGroup;

    isCanHelp = false;

    @ViewChild(FeedUserSettingsFormComponent)
    formComponent: FeedUserSettingsFormComponent;

    @Input()
    color: string;

    @Input()
    tag: TagModel;

    @Input()
    set userRole(role) {
        this.isCanHelp = role === UserRole.canHelp;
    }

    @Input()
    data: CareHistoryModel;

    @Input()
    isEditing: boolean;

    @Output()
    saved$ = new EventEmitter<CareHistoryModel>();

    @Output()
    cancelled$ = new EventEmitter<void>();

    constructor(@Optional() @Inject(WINDOW) private window: Window) {
    }

    ngAfterViewInit(): void {
        this.initialForm = new UntypedFormGroup(this.formComponent.formGroup.controls);
    }

    submit(data) {
        this.saved$.emit(data);
    }

    cancel() {
        if (
            JSON.stringify(this.formComponent.formGroup.value) !== JSON.stringify(this.initialForm.value)
            && this.window.confirm('Your data has been changed.\nDo you want to save changes?')
        ) {
            this.formComponent.submit();
        } else {
            this.cancelled$.emit();
        }
    }

    get getTitleText() {
        return this.isCanHelp ? `Tell your story and connect with other caregivers who need help`
            : `Tell your story so other caregivers can connect with you to help.`;
    }
}
