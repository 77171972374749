import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth/auth.service';


@Injectable({
    providedIn: 'root'
})
export class UsernameUniqueValidator {

    constructor(private authService: AuthService) {
    }

    getValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return observableOf(null);
            }
            return this.authService.isUsernameUnique(control.value).pipe(
                map((data: { isAvailable: boolean }) => {
                    return data.isAvailable ? null : {usernameUnique: true};
                }),
                catchError(() => observableOf({usernameUnique: true}))
            );
        };
    }
}
