import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { FeatureFlagsService } from '@core/services';


@Injectable({
    providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate, CanLoad {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (
            !route.data.featureFlag
            || this.featureFlagsService.isFeatureEnabled(route.data.featureFlag)
        ) {
            return true;
        }
        return this.router.parseUrl('/404');
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        if (!route.data.featureFlag) {
            return true;
        }
        return this.featureFlagsService.isFeatureEnabled(route.data.featureFlag);
    }

    constructor(
        private router: Router,
        private featureFlagsService: FeatureFlagsService
    ) {
    }
}
