import { UntypedFormControl, ValidationErrors } from '@angular/forms';


export class PasswordFormatValidator {
    public static validate(control: UntypedFormControl): ValidationErrors | null {
        const regexValidators = [
            {regex: /[a-z]+/, error: {lowerCaseLetter: true}},
            {regex: /[A-Z]+/, error: {upperCaseLetter: true}},
            {regex: /\d/, error: {digit: true}},
            {regex: /\W+/, error: {notAlpha: true}},
            {regex: /.{8}/, error: {minlength: true}},
        ];

        if (control.value) {
            for (const validator of regexValidators) {
                if (!validator.regex.test(control.value)) {
                    return validator.error;
                }
            }
        }

        return null;
    }
}
