import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { UserRole } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class SubjectRequestService {
    create(newTagTitle: string, role: UserRole, parentTagId?: number): Observable<unknown> {
        return this.http.post<{ newTagTitle: string, role: UserRole, parentTagId?: number }>(
            '/tags/request-new',
            {newTagTitle, role, parentTagId}
        );
    }

    constructor(private http: HttpClient) {
    }
}
