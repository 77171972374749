import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PublicUserModel } from '@core/models';
import { IsOnlinePipe } from '@shared/pipes';


@Component({
    selector: 'app-user-profile-main-info',
    templateUrl: './user-profile-main-info.component.html',
    styleUrls: ['./user-profile-main-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileMainInfoComponent {
    @Input()
    user: PublicUserModel;

    @Input()
    isCurrentUser = false;

    @Input()
    followingsCount: number;

    constructor(private isOnlinePipe: IsOnlinePipe) {
    }

    get isOnline(): boolean {
        if (!this.user.lastActivity) {
            return this.isCurrentUser;
        }
        return ((this.isOnlinePipe.transform(this.user.lastActivity)) || this.isCurrentUser);
    }
}
