import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { PingApiActions } from '@core/store/actions';
import { PingApiService } from '@core/services/ping-api/ping-api.service';


@Injectable()
export class PingApiEffects {
    checkServerStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PingApiActions.ping),
            exhaustMap(() => {
                return this.pingApiService.ping().pipe(
                    map(() => PingApiActions.serverIsOnline()),
                    catchError(({error}) => {
                        const message = error ? (error.error + '') : null;
                        return of(PingApiActions.serverIsOffline({error: message}));
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private pingApiService: PingApiService
    ) {
    }
}
