import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { AuthService, SessionStorageService, UserService } from '@core/services/';
import { AppInitActions } from '@core/store/actions';


@Injectable()
export class AppInitEffects {

    checkAuthorization$ = createEffect(() => this.actions$.pipe(
        ofType(AppInitActions.checkAuth),
        exhaustMap(() => {
            if (null === this.authService.getToken()) {
                return of(AppInitActions.userNotAuthenticated());
            }
            return this.userService.getCurrentUserProfile().pipe(
                map((response) => AppInitActions.userAuthenticated({
                    token: this.authService.getToken(),
                    ...response
                })),
                catchError(() => of(AppInitActions.userNotAuthenticated()))
            );
        })
    ));

    setCampaignSessionKey$ = createEffect(() => this.actions$.pipe(
        ofType(AppInitActions.setCampaignSessionKey),
        tap(({campaign}) => this.sessionStorageService.setItem('campaign', campaign))
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private authService: AuthService,
        private sessionStorageService: SessionStorageService
    ) {
    }
}
