import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-forgot-password-confirmation',
    templateUrl: './forgot-password-confirmation.component.html',
    styleUrls: ['./forgot-password-confirmation.component.scss']
})
export class ForgotPasswordConfirmationComponent {

    maskedEmail: string;

    @Input()
    set email(email) {
        this.maskedEmail = ForgotPasswordConfirmationComponent.maskEmail(email);
    }

    private static maskEmail(email: string): string {
        return email.replace(/^(.).*@([^.])[^.]+\..*(.)$/, '$1*******@$2*****.**$3');
    }

}
