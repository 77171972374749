import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';

import { PrivateMessagesWsActions } from '@core/store/actions';
import {
    CallActions,
    CallApiActions,
    ConversationsApiActions,
    PrivateChatPageActions,
    PrivateChatQuestionMessageGuardActions,
    PrivateChatSettingsMessageGuardActions
} from '@core/store/conversations/actions';
import { CallStatus, ChatReplyOnInputModel, PrivateChatMessageModel } from '@core/models';
import { getSortByDateComparer, SortDirection } from '@core/store/sort-comparer-by-date';


export interface State extends EntityState<PrivateChatMessageModel> {
    isPending: boolean;
    error: any;
    hasMore: boolean;
    conversationId: number;
    replyOnInputMessage: ChatReplyOnInputModel;
}

export const adapter: EntityAdapter<PrivateChatMessageModel> = createEntityAdapter<PrivateChatMessageModel>({
    sortComparer: getSortByDateComparer('createdOn', SortDirection.asc)
});

export const initialState: State = adapter.getInitialState({
    isPending: false,
    error: null,
    hasMore: true,
    conversationId: null,
    replyOnInputMessage: null
});

const privateMessagesReducer = createReducer(
    initialState,
    on(PrivateChatPageActions.pageDestroyed, () => initialState),
    on(PrivateChatPageActions.pageInit, (state, {conversationId}): State => {
        return {
            ...initialState,
            conversationId,
            isPending: true
        };
    }),
    on(PrivateChatPageActions.loadChatHistory, (state) => ({
        ...state,
        isPending: true
    })),
    on(
        PrivateMessagesWsActions.chatMessageReceived,
        (state, {conversationId, message}
        ) => {
            if (state.conversationId && state.conversationId === conversationId) {
                return adapter.upsertOne(message, state);
            }
            return state;
        }
    ),
    on(
        ConversationsApiActions.sendMessageSucceeded,
        ConversationsApiActions.replyOnMessageSucceeded,
        (state, {message}) => {
            return adapter.upsertOne(message, state);
        }
    ),
    on(
        CallActions.offer,
        (state, {userId}) => {
            const message: PrivateChatMessageModel = {
                id: 0,
                text: 'offer',
                userIdFrom: userId,
                createdOn: new Date().toISOString(),
                isRead: false,
                isEdited: false,
                replyOnCreatedOn: null,
                replyOnText: null,
                replyOnUserId: null,
                userIdTo: userId,
                call: {
                    status: CallStatus.offer,
                    duration: null
                }
            };
            return adapter.upsertOne(message, state);
        }
    ),
    on(PrivateMessagesWsActions.callAcceptedReceived,
        (state) => {
            return adapter.updateOne({
                id: 0,
                changes: {
                    call: {
                        status: CallStatus.inProgress,
                        duration: null
                    }
                }
            }, state);
        }
    ),
    on(
        PrivateMessagesWsActions.callEndedReceived,
        PrivateMessagesWsActions.callDeclinedReceived,
        PrivateMessagesWsActions.callAcceptedFromOtherSessionReceived,
        CallApiActions.sendEndSucceeded,
        CallApiActions.sendAnswerFailed,
        CallApiActions.sendOfferFailed,
        CallApiActions.sendEndFailed,
        (state) => {
            return adapter.removeOne(0, state);
        }
    ),
    on(ConversationsApiActions.loadMessagesSucceeded, (state, {messages, hasMore}) => {
        return adapter.upsertMany(messages, {
            ...state,
            isPending: false,
            hasMore
        });
    }),
    on(
        ConversationsApiActions.deleteMessageSucceeded,
        PrivateMessagesWsActions.chatMessageDeleted,
        (state, {id}) => adapter.removeOne(id, state)
    ),
    on(
        ConversationsApiActions.editMessageSucceeded,
        PrivateMessagesWsActions.chatMessageEdited,
        (state, {messageUpdate}) => {
            return adapter.updateOne(messageUpdate, state);
        }
    ),
    on(
        ConversationsApiActions.loadMessagesFailed,
        ConversationsApiActions.sendMessageFailed,
        ConversationsApiActions.editMessageFailed,
        ConversationsApiActions.replyOnMessageFailed,
        ConversationsApiActions.deleteMessageFailed,
        (state, {error}) => ({
            ...state,
            isPending: false,
            error
        })
    ),
    on(
        PrivateChatQuestionMessageGuardActions.replyOnQuestion,
        PrivateChatSettingsMessageGuardActions.replyOnUserSetting,
        (state, {message}) => ({
            ...state,
            replyOnInputMessage: {...message}
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return privateMessagesReducer(state, action);
}

export const getHasMore = (state: State): boolean => state && state.hasMore;
export const getIsPending = (state: State): boolean => state && state.isPending;
export const getReplyOnInputMessage = (state: State): ChatReplyOnInputModel => state && state.replyOnInputMessage;


export const {
    selectAll: getPrivateChatMessages,
    selectIds: getPrivateChatMessagesIds
} = adapter.getSelectors() as EntitySelectors<PrivateChatMessageModel, State>;
