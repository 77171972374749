<button class="direction-change-arrow"
        [disabled]="selected === 0"
        (click)="change(selected - 1)"
><</button>
<div id="main">
    <span id="selected-stat">{{stats[selected].label}}</span>
    <div id="indicators">
        <button *ngFor="let stat of stats; index as i"
                (click)="change(i)" title="{{stat.label}}"
                [class.selected]="selected === i"
                class="indicator"
        >*</button>
    </div>
</div>
<button class="direction-change-arrow"
        [disabled]="selected === (stats.length - 1)"
        (click)="change(selected + 1)"
>></button>
