import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { InitialSettingsModel } from '@core/models';
import { AuthTokenInterceptor } from '@core/interceptors/auth-token.interceptor';


@Injectable({
    providedIn: 'root'
})
export class InitialSettingsService {

    readonly stateKey: StateKey<InitialSettingsModel> = makeStateKey<InitialSettingsModel>('appSettings');

    readonly prefix = '/initial';

    constructor(
        private http: HttpClient,
        private transferState: TransferState
    ) {
    }

    get(): Observable<InitialSettingsModel> {
        const settings = this.transferState.get<InitialSettingsModel>(this.stateKey, null);
        if (settings) {
            return of(settings);
        }
        return this.http.get<InitialSettingsModel>(this.prefix, {
            headers: {[AuthTokenInterceptor.HEADER_FORCE_NO_AUTH]: 'true'},
        }).pipe(tap((response) => {
            this.transferState.set(this.stateKey, response);
        }));
    }
}
