import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';

import { filterTags, TagModel } from '@core/models';
import * as fromRoot from '@core/store/';
import * as fromWordsCloud from './words-cloud.reducer';
import * as fromNewTagRequestPopup from './new-tag-request-popup.reducer';
import { environment } from 'src/environments/environment';


export interface OnboardingState {
    wordsCloud: fromWordsCloud.State;
    newSubjectRequest: fromNewTagRequestPopup.State;
}

export interface State extends fromRoot.State {
    onboarding: OnboardingState;
}

export function reducers(state: OnboardingState | undefined, action: Action): OnboardingState {
    return combineReducers({
        wordsCloud: fromWordsCloud.reducer,
        newSubjectRequest: fromNewTagRequestPopup.reducer
    })(state, action);
}

export const getOnboardingState = createFeatureSelector< OnboardingState>('onboarding');
export const getWordsCloudState = createSelector(getOnboardingState, (state) => state.wordsCloud);
export const getUserRole = createSelector(getWordsCloudState, fromWordsCloud.getUserRole);

export const getTagForHaveQuestion = createSelector(
    fromRoot.getTagsEntities,
    (tags: Dictionary<TagModel>): TagModel => tags[environment.specialTags.defaultForHaveQuestion] || null
);

export const getNewSubjectRequestState = createSelector(getOnboardingState, (state) => state.newSubjectRequest);
export const getNewSubjectRequestStep = createSelector(getNewSubjectRequestState, fromNewTagRequestPopup.getStep);
export const getNewSubjectRequestSearchCriteria = createSelector(getNewSubjectRequestState, fromNewTagRequestPopup.getSearchCriteria);

export const getTagsForNewSubjectRequest = createSelector(
    fromRoot.getTagsTrees,
    getNewSubjectRequestSearchCriteria,
    (tags, searchCriteria) => {
        if (!searchCriteria) {
            return tags;
        }
        return filterTags(tags, searchCriteria);
    }
);
