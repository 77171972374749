<app-layout-auth [showBtnClose]="true">
    <app-registration-form
        (submitted)="onRegistrationFormSubmit($event)"
        (signedInSocial)="onSignedInSocial($event)"
        [usernameTakenError]="usernameTakenError$ | async"
        [emailTakenError]="emailTakenError$ | async"
        [pending]="isPending$ | async"
        [data]="model"
        [redirectTo]="redirectToParameter"
    ><div id="title" class="color-primary with-margin-top" [innerHTML]="registrationText"></div>
    </app-registration-form>
</app-layout-auth>
