import { Action, createReducer, on } from '@ngrx/store';
import { HeaderActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { routerRequestAction } from '@ngrx/router-store';
import { AuthApiActions } from '@core/store/auth/actions';


export interface State {
    isSupTopicsListExpanded: boolean;
}

export const initialState: State = {
    isSupTopicsListExpanded: false
};

const HeaderReducer = createReducer(
    initialState,
    on(HeaderActions.feedSubTopicsListToggled, (state: State): State => ({
        ...state,
        isSupTopicsListExpanded: !state.isSupTopicsListExpanded,
    })),
    on(
        routerRequestAction,
        HeaderActions.feedSubTopicsListSelectedTagClicked,
        (state): State => ({
            ...state,
            isSupTopicsListExpanded: false
        })
    ),
    on(
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        () => initialState
    )
);

export function reducer(state: State | undefined, action: Action) {
    return HeaderReducer(state, action);
}

export const getIsSupTopicsListExpanded = (state: State) => state.isSupTopicsListExpanded;
