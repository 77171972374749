import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { hasActiveSocialNetworksProviders } from '@core/utils/social-networks';


@Directive({
    selector: '[appSocialNetworksAvailable]'
})
export class SocialNetworksAvailableDirective implements OnInit {

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) {
    }

    ngOnInit(): void {
        if (hasActiveSocialNetworksProviders()) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

}
