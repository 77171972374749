import { PublicUserModel } from '@core/models/user.model';
import { CallInformationModel } from '@core/models/private-chat-message.model';


export enum ChatMessageAction {
    copied,
    copyFailed,
    edit,
    reply,
    delete,
}

export interface ChatMessageModel {
    id: number;
    userFrom: PublicUserModel;
    text: string;
    createdOn: string;
    isEdited: boolean;
    replyOn: ChatReplyOnMessageModel | null;
    call?: CallInformationModel;
}

export type ChatReplyOnMessageModel = Pick<ChatMessageModel, 'userFrom' | 'text' | 'createdOn'>;
