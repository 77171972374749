import { TagModel, userRoleText, UserSettingsRole } from '@core/models';
import { environment } from 'src/environments/environment';


export const messages = {
    feed: {
        closeEditPageConfirmation: 'Your data has been changed.\nDo you want to save changes?',
    },
    profile: {
        deleteFeedSettingConfirmation: (role: UserSettingsRole, tag: TagModel) => {
            return `you are about to delete your ${userRoleText[role]} data in ${tag.fullTitle} subject.`;
        }
    },
    form: {
        hints: {
            username: 'Please choose a user name that is not your real name. We will check to see that it is available.',
            password: 'At least 8 characters containing a symbol, a lower and upper letter, and a number.',
            email: 'We will never share your email with anyone. It will only be used to communicate with you.'
        }
    },
    global: {
        error: 'An error has occurred. Please try again later'
    },
    feedQuestion: {
        deleteConfirmation: 'Use this delete button if your question has been answered.\n\n' +
            'If you wish to receive answers for comparison, click "cancel" to allow other members to add their answers to your questions.',
        limitReached: `You have reached a limit of ${environment.feed.questionsPerTagLimit} questions per subject. ` +
            `Feel free to ask questions in another subject or update / delete your ${environment.feed.questionsPerTagLimit} questions ` +
            `in this subject on the "View my Profile" page.`
    },
    tellFriend: {
        success: 'Your message has been sent. Thank you.'
    },
    geolocation: {
        error: {
            permissionDenied: 'You did not turn on access to geolocation. Please turn it on to proceed.',
            positionUnavailable: 'Your geolocation is not accessible. Please enable access to your geolocation and try again.'
        },
        updated: 'Your location has been updated.'
    },
    call: {
        declined: 'Call was declined.',
        confirmRedirect: 'You have an active call. If you leave this page, the call will be interrupted. Leave anyway?'
    },
    affirmations: {
        emailSendSuccess: 'The email has been sent.',
        linkCopied: 'Link has been copied.',
        textCopied: 'Affirmation text has been copied.'
    }
};
