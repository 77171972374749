import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@core/store/reducers';
import * as fromLoginPage from './login-page.reducer';
import * as fromRegistrationPage from './registration-page.reducer';
import * as fromForgotPasswordPage from './forgot-password-page.reducer';
import * as fromResetPasswordPage from './reset-password-page.reducer';
import * as fromRegistrationErrorPage from './registration-error-page.reducer';
import * as fromRegistrationSocialPage from './registration-social-page.reducer';


export interface AuthState {
    loginPage: fromLoginPage.State;
    registrationPage: fromRegistrationPage.State;
    forgotPasswordPage: fromForgotPasswordPage.State;
    resetPasswordPage: fromResetPasswordPage.State;
    registrationErrorPage: fromRegistrationErrorPage.State;
    registrationSocialPage: fromRegistrationSocialPage.State;
}


export interface State extends fromRoot.State {
    auth: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
    return combineReducers({
        loginPage: fromLoginPage.reducer,
        registrationPage: fromRegistrationPage.reducer,
        forgotPasswordPage: fromForgotPasswordPage.reducer,
        resetPasswordPage: fromResetPasswordPage.reducer,
        registrationErrorPage: fromRegistrationErrorPage.reducer,
        registrationSocialPage: fromRegistrationSocialPage.reducer
    })(state, action);
}


export const getAuthState = createFeatureSelector< AuthState>('auth');

export const getRegistrationPageState = createSelector(
    getAuthState,
    (state: AuthState): fromRegistrationPage.State => state.registrationPage
);
export const getRegistrationPageIsPending = createSelector(getRegistrationPageState, fromRegistrationPage.getIsPending);

export const getRegistrationPageError = createSelector(getRegistrationPageState, fromRegistrationPage.getError);

export const getRegistrationSocialPageState = createSelector(
    getAuthState,
    (state: AuthState): fromRegistrationSocialPage.State => state.registrationSocialPage
);
export const getRegistrationSocialPageExternalUserToken = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getSocialToken
);
export const getRegistrationSocialPageEmail = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getEmail
);
export const getRegistrationSocialPageExternalSocialNetwork = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getExternalSocialNetwork
);
export const getRegistrationSocialPageLocation = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getLocation
);
export const getRegistrationSocialPageIsPending = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getIsPending
);
export const getRegistrationSocialPageError = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getError
);
export const getRegistrationSocialPageEnteredUsername = createSelector(
    getRegistrationSocialPageState, fromRegistrationSocialPage.getEnteredUsername
);

export const getLoginPageState = createSelector(getAuthState, (state: AuthState): fromLoginPage.State => state.loginPage);
export const getLoginPageIsPending = createSelector(getLoginPageState, fromLoginPage.getIsPending);
export const getLoginPageIsError = createSelector(getLoginPageState, fromLoginPage.getIsError);
export const getLoginPageError = createSelector(getLoginPageState, fromLoginPage.getError);
export const getLoginPageRegistrationType = createSelector(getLoginPageState, fromLoginPage.getRegistrationType);

export const getForgotPasswordPageState = createSelector(
    getAuthState,
    (state: AuthState): fromForgotPasswordPage.State => state.forgotPasswordPage
);
export const getForgotPasswordPageIsPending = createSelector(getForgotPasswordPageState, fromForgotPasswordPage.getIsPending);
export const getForgotPasswordPageError = createSelector(getForgotPasswordPageState, fromForgotPasswordPage.getError);

export const getResetPasswordPageState = createSelector(
    getAuthState,
    (state: AuthState): fromResetPasswordPage.State => state.resetPasswordPage
);
export const getResetPasswordPageIsPending = createSelector(getResetPasswordPageState, fromResetPasswordPage.getIsPending);
export const getResetPasswordPageIsError = createSelector(getResetPasswordPageState, fromResetPasswordPage.getIsError);

export const getRegistrationErrorPageState = createSelector(
    getAuthState,
    (state: AuthState): fromRegistrationErrorPage.State => state.registrationErrorPage
);

export const getRegistrationErrorPageError = createSelector(getRegistrationErrorPageState, fromRegistrationErrorPage.getError);
export const getRegistrationErrorPageRegistrationType = createSelector(
    getRegistrationErrorPageState, fromRegistrationErrorPage.getRegistrationType
);
