import { createAction, props } from '@ngrx/store';


export const prefix = '[Invite Friend Api]';

export const sendInviteFriendSucceeded = createAction(
    `${prefix} send invite fiend succeeded`
);

export const sendInviteFriendFailed = createAction(
    `${prefix} send invite friend failed`,
    props<{ error: string }>()
);
