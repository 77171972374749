import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { flattenPublicChatMessages, PublicChatMessageFlatModel, PublicChatMessageFullModel, PublicUserModel } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class PublicChatService {

    readonly apiPrefix = '/chats';

    getChatHistory(chatId: number, limit: number, direction: number = 0, cursor?: number): Observable<{
        messages: PublicChatMessageFlatModel[],
        users: PublicUserModel[]
    }> {
        const params = {
            limit: limit.toString(),
            direction: direction.toString(),
            cursor: '0'
        };
        if (cursor) {
            params.cursor = cursor.toString();
        }

        return this.http.get<PublicChatMessageFullModel[]>(`${this.apiPrefix}/${chatId}/messages`, {
            params
        }).pipe(
            map((messages) => messages && flattenPublicChatMessages(messages))
        );
    }

    getOnlineUsersCount(tagId: number): Observable<{ count: number }> {
        return this.http.get<{ count: number }>(`${this.apiPrefix}/${tagId}/users/count`);
    }

    getOnlineUsers(tagId: number, page: number, size: number): Observable<PublicUserModel[]> {
        const params = {
            size: size.toString(),
            page: page.toString()
        };
        return this.http.get<PublicUserModel[]>(`${this.apiPrefix}/${tagId}/users`, {params});
    }

    sendMessage(tagId: number, message: string): Observable<PublicChatMessageFullModel> {
        return this.http.post<PublicChatMessageFullModel>(`${this.apiPrefix}/${tagId}/messages`, {
            message
        });
    }

    deleteMessage(messageId: number): Observable<void> {
        return this.http.delete<void>(`${this.apiPrefix}/messages/${messageId}`);
    }

    editMessage(messageId: number, message: string): Observable<void> {
        return this.http.put<void>(`${this.apiPrefix}/messages/${messageId}`, {
            message
        });
    }

    replyOnMessage(tagId: number, replyOnMessageId: number, message: string): Observable<PublicChatMessageFullModel> {
        return this.http.post<PublicChatMessageFullModel>(`${this.apiPrefix}/${tagId}/messages`, {
            replyOnMessageId,
            message
        });
    }

    constructor(private http: HttpClient) {
    }
}
