import { Action, createReducer, on } from '@ngrx/store';

import { AuthApiActions, ResetPasswordPageActions } from '@core/store/auth/actions';


export interface State {
    isPending: boolean;
    isError: boolean;
}


export const initialState: State = {
    isPending: false,
    isError: false
};

const ResetPasswordPageReducer = createReducer(
    initialState,
    on(ResetPasswordPageActions.init, () => initialState),
    on(ResetPasswordPageActions.submitted, (): State => {
        return {
            isPending: true,
            isError: false
        };
    }),
    on(AuthApiActions.resetPasswordSucceeded, (): State => {
        return {
            isPending: false,
            isError: false
        };
    }),
    on(AuthApiActions.resetPasswordFailed, (): State => {
        return {
            isPending: false,
            isError: true
        };
    })
);

export const reducer = (state: State | undefined, action: Action) => {
    return ResetPasswordPageReducer(state, action);
};


export const getIsError = (state: State) => state.isError;
export const getIsPending = (state: State) => state.isPending;
