import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromRoot from '@core/store';
import { CallActions, CallApiActions, ConversationsPageActions, PrivateChatPageActions } from '@core/store/conversations/actions';
import { CallApiService } from '@core/services/call-api/call-api.service';
import * as fromCurrentUser from '@core/store/current-user';
import { InviteNotificationPopupActions } from '@core/store/actions';


@Injectable()
export class CallApiEffects {
    sendOffer$ = createEffect(() => this.actions$.pipe(
        ofType(CallActions.offer),
        withLatestFrom(this.store.pipe(select(fromCurrentUser.getXAuthSocketToken))),
        concatMap(([{userId, sdp}, socketSessionId]) => {
            return this.callApiService.offer(userId, sdp, socketSessionId).pipe(
                map(({callId}) => {
                    return CallApiActions.sendOfferSucceeded({callId});
                }),
                catchError(({error}) => of(CallApiActions.sendOfferFailed(error)))
            );
        })
    ));

    sendAnswer$ = createEffect(() => this.actions$.pipe(
        ofType(CallActions.answer),
        withLatestFrom(this.store.pipe(select(fromCurrentUser.getXAuthSocketToken))),
        concatMap(([{callId, sdp}, socketSessionId]) => {
            return this.callApiService.accept(callId, sdp, socketSessionId).pipe(
                map(() => {
                    return CallApiActions.sendAnswerSucceeded();
                }),
                catchError(({error}) => of(CallApiActions.sendAnswerFailed(error)))
            );
        })
    ));

    sendCandidate$ = createEffect(() => this.actions$.pipe(
        ofType(CallActions.candidate),
        concatMap(({callId, sdp}) => {
            return this.callApiService.candidate(callId, sdp).pipe(
                map(() => {
                    return CallApiActions.sendCandidateSucceeded();
                }),
                catchError(({error}) => of(CallApiActions.sendCandidateFailed(error)))
            );
        })
    ));

    sendDecline$ = createEffect(() => this.actions$.pipe(
        ofType(
            ConversationsPageActions.declineIncomingCall,
            InviteNotificationPopupActions.decline,
            PrivateChatPageActions.declineCallInvite
        ),
        concatMap(({callId}) => {
            return this.callApiService.decline(callId).pipe(
                map(() => {
                    return CallApiActions.sendDeclineSucceeded({callId});
                }),
                catchError(({error}) => of(CallApiActions.sendDeclineFailed(error)))
            );
        })
    ));

    sendNegotiate$ = createEffect(() => this.actions$.pipe(
        ofType(CallActions.negotiate),
        concatMap(({callId, offer}) => {
            return this.callApiService.negotiate(callId, offer).pipe(
                map(() => {
                    return CallApiActions.sendNegotiateSucceeded();
                }),
                catchError(({error}) => of(CallApiActions.sendNegotiateFailed(error)))
            );
        })
    ));

    sendEnd$ = createEffect(() => this.actions$.pipe(
        ofType(CallActions.end),
        concatMap(({callId}) => {
            return this.callApiService.end(callId).pipe(
                map(() => {
                    return CallApiActions.sendEndSucceeded();
                }),
                catchError(({error}) => of(CallApiActions.sendEndFailed(error)))
            );
        })
    ));


    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private callApiService: CallApiService
    ) {
    }
}
