import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, fromEvent, lastValueFrom } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { LocalStorageService } from '@core/services';


@Injectable({
    providedIn: 'root'
})
export class UserMediaService extends WithDestroyedSubjectComponent implements OnDestroy {

    private inputDeviceId = 'default';
    private outputDeviceId = 'default';

    private inputStream: MediaStream = null;
    private mediaDevicesInfo: MediaDeviceInfo[] = null;
    private inputStreamSubject: BehaviorSubject<MediaStream> = null;

    mediaDevices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject<MediaDeviceInfo[]>(this.mediaDevicesInfo);

    inputDeviceId$: BehaviorSubject<string> = new BehaviorSubject<string>(this.inputDeviceId);

    outputDeviceId$: BehaviorSubject<string> = new BehaviorSubject<string>(this.outputDeviceId);

    get inputStream$(): BehaviorSubject<MediaStream> {
        if (null === this.inputStreamSubject) {
            this.inputStreamSubject = new BehaviorSubject<MediaStream>(null);
            this.getInputMediaStream();
        }
        return this.inputStreamSubject;
    }

    get inputStreamPromise(): Promise<MediaStream> {
        return lastValueFrom(this
            .inputStream$
            .pipe(
                filter((stream) => !!stream),
                take(1)
            ));
    }

    private setMediaDevicesInfo(mediaDevicesInfo: MediaDeviceInfo[]) {
        this.mediaDevicesInfo = mediaDevicesInfo.filter((deviceInfo) => !!deviceInfo.deviceId);
        this.mediaDevices$.next(this.mediaDevicesInfo);
    }

    private async getInputMediaStream() {
        try {
            this.inputStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.inputDeviceId } });
            this.inputStream$.next(this.inputStream);
        } catch (e) {
            this.inputStream$.error(e);
        }
    }

    private releaseStream() {
        if (this.inputStream) {
            this.inputStream.getTracks()
                .forEach((track) => track.stop());
            this.inputStream = null;
        }
    }

    private init() {
        fromEvent(navigator.mediaDevices, 'devicechange')
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.enumerateMediaDevices());

        this.enumerateMediaDevices();
    }

    private isDeviceWithIdExists(deviceId: string) {
        return this.mediaDevicesInfo.some((device) => deviceId === device.deviceId);
    }

    private restoreSelectedDeviceIdsFromLocalStorage(): void {
        const restoredInputDeviceId = this.localStorage.getItem('voiceInputDeviceId');
        const restoredOutputDeviceId = this.localStorage.getItem('voiceOutputDeviceId');

        if (restoredInputDeviceId) {
            this.setInputDevice(this.isDeviceWithIdExists(restoredInputDeviceId) ? restoredInputDeviceId : 'default');
        }

        if (restoredOutputDeviceId) {
            this.setOutputDevice(this.isDeviceWithIdExists(restoredOutputDeviceId) ? restoredOutputDeviceId : 'default');
        }
    }

    constructor(private localStorage: LocalStorageService) {
        super();
        this.init();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.inputStreamSubject) {
            this.inputStreamSubject.next(null);
            this.inputStreamSubject.complete();
            this.inputStreamSubject = null;
        }
        this.releaseStream();
    }

    async enumerateMediaDevices() {
        try {
            this.setMediaDevicesInfo(await navigator.mediaDevices.enumerateDevices());
            this.restoreSelectedDeviceIdsFromLocalStorage();
        } catch (e) {
            this.mediaDevices$.error(e);
        }
    }

    setInputDevice(deviceId: string) {
        this.inputDeviceId = deviceId;
        this.inputDeviceId$.next(this.inputDeviceId);
        if (null !== this.inputStream) {
            this.releaseStream();
            this.getInputMediaStream();
        }
        this.localStorage.setItem('voiceInputDeviceId', this.inputDeviceId);
    }

    setOutputDevice(deviceId: string) {
        this.outputDeviceId = deviceId;
        this.outputDeviceId$.next(this.outputDeviceId);
        this.localStorage.setItem('voiceOutputDeviceId', this.outputDeviceId);
    }
}
