export enum CustomSvgIcon {
    logoFull = 'logoFull',
    logoFullET = 'logoFullET',
    edit = 'edit',
    user = 'user',
    notifications = 'notifications',
    global = 'global',
    chat = 'chat',
    shield = 'shield',
    facebook = 'facebook',
    google = 'google',
    microsoft = 'microsoft',
    caregivingNetwork = 'caregivingNetwork',
    apple = 'apple',
    youtube = 'youtube',
    youtubeRound = 'youtubeRound',
    facebookRound = 'FacebookRound',
    instagramRound = 'instagramRound',
    linkedinRound = 'linkedinRound',
    checkmark = 'checkmark',
    videoContestStatus = 'videoContestStatus',
    videoContestStatusEmpty = 'videoContestStatusEmpty',
    winnerStar = 'winnerStar',
    redditRound = 'redditRound',
    mediumRound = 'mediumRound',
    arrowRight = 'arrowRight',
    email = 'email',
    thumbsUp = 'thumbsUp',
    link ='link'
}
