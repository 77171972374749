import { HttpEventType } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { last, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { MultipartFileUploadService } from '@core/services/multipart-file-upload/multipart-file-upload.service';


export enum MultipartFileUploadStatus {
    notStarted,
    inProgress,
    pausing,
    paused,
    completed,
    error,
    cancelled
}

export enum PartUploadStatus {
    pending,
    inProgress,
    completed,
    error
}

export interface Part {
    partNumber: number;
    start: number;
    end: number;
    uploaded: number;
    status: PartUploadStatus;
}

export class MultipartFileUploadRef {

    private readonly partSize = environment.videoContest.fileUpload.chunkSize;

    private fileId: string;

    parts: Part[] = [];

    status: MultipartFileUploadStatus = MultipartFileUploadStatus.notStarted;

    status$ = new BehaviorSubject<MultipartFileUploadStatus>(this.status);

    error$ = new Subject<any>();

    progress$ = new BehaviorSubject<number>(0);

    set uploadId(uploadId: string) {
        this.fileId = uploadId;
    }

    get uploadId(): string {
        return this.fileId;
    }

    private changeStatus(newStatus: MultipartFileUploadStatus) {
        if (this.status === newStatus) {
            return;
        }
        this.status = newStatus;
        this.status$.next(this.status);
        return this;
    }

    private complete() {
        this.multipartFileUploadService.complete(this.fileId)
            .subscribe({
                next: () => {
                    this.changeStatus(MultipartFileUploadStatus.completed);
                },
                error: (error) => {
                    this.onError(error.error.message);
                }
            });
    }

    private reportProgress() {
        const uploaded = this.parts.reduce((previous: number, current) => {
            if (current.status === PartUploadStatus.inProgress || current.status === PartUploadStatus.completed) {
                return previous + current.uploaded;
            }
            return previous;
        }, 0);
        this.progress$.next(Math.round((uploaded / this.file.size) * 100));
    }

    private onPartUploaded(partNumber: number) {
        const index = partNumber - 1;
        this.parts[index].status = PartUploadStatus.completed;
        this.reportProgress();

        if (this.status === MultipartFileUploadStatus.pausing) {
            this.changeStatus(MultipartFileUploadStatus.paused);
            return;
        } else if (this.status !== MultipartFileUploadStatus.inProgress) {
            return;
        }

        const completed = !this.parts.some((part) => part.status !== PartUploadStatus.completed);
        if (completed) {
            this.complete();
            return;
        }
        this.uploadNextPart();
    }

    private uploadNextPart() {
        const next = this.parts.find((part) => part.status === PartUploadStatus.pending);
        if (next) {
            this.uploadPart(next);
        }
    }

    private uploadPart(part: Part) {
        const content = this.file.slice(
            part.start,
            part.end
        );
        part.status = PartUploadStatus.inProgress;
        this.multipartFileUploadService.uploadPart(this.fileId, part.partNumber, content)
            .pipe(
                tap((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        part.uploaded = event.loaded;
                        this.reportProgress();
                    }
                }),
                last()
            )
            .subscribe({
                next: () => {
                    this.onPartUploaded(part.partNumber);
                },
                error: (error) => {
                    this.onError(error.error.message);
                }
            });
    }

    private hasInProgressParts(): boolean {
        return this.parts.some((partStatus) => partStatus.status === PartUploadStatus.inProgress);
    }

    private fillParts(fileSize: number) {
        const totalParts = Math.ceil(fileSize / this.partSize);
        for (let i = 0; i < totalParts; i++) {
            const start = i * this.partSize;
            this.parts.push({
                partNumber: i + 1,
                start,
                end: start + this.partSize,
                status: PartUploadStatus.pending,
                uploaded: 0
            });
        }
    }

    constructor(
        private file: File,
        private multipartFileUploadService: MultipartFileUploadService
    ) {
        this.fillParts(this.file.size);
    }

    start() {
        if (this.status !== MultipartFileUploadStatus.notStarted) {
            return;
        }
        this.changeStatus(MultipartFileUploadStatus.inProgress)
            .uploadNextPart();
    }

    pause() {
        if (this.status !== MultipartFileUploadStatus.inProgress) {
            return;
        }
        if (this.hasInProgressParts()) {
            this.changeStatus(MultipartFileUploadStatus.pausing);
        } else {
            this.changeStatus(MultipartFileUploadStatus.paused);
        }
    }

    resume() {
        if (this.status !== MultipartFileUploadStatus.paused) {
            return;
        }
        this.changeStatus(MultipartFileUploadStatus.inProgress);
        this.uploadNextPart();
    }

    cancel() {
        this.changeStatus(MultipartFileUploadStatus.cancelled);
        this.multipartFileUploadService.delete(this.fileId)
            .subscribe({
                next: () => {
                }, error: (error) => {
                    this.onError(error.error.message);
                }
            });
    }

    onError(error: string) {
        this.changeStatus(MultipartFileUploadStatus.error);
        this.error$.next(error);
    }
}
