import { Pipe, PipeTransform } from '@angular/core';

import memo from 'memo-decorator';

import { UserExperiencePeriod } from '@core/models';


@Pipe({
    name: 'careExperience'
})
export class CareExperiencePipe implements PipeTransform {

    @memo({resolver: (...args) => JSON.stringify(args)})
    transform(experience: number, experiencePeriod: UserExperiencePeriod): string {
        return `${experience > 50 ? '50+' : experience} ${experiencePeriod.toLowerCase()}${experience > 1 ? 's' : ''}`;
    }
}
