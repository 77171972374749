<h2 id="title" class="color-primary">
    Create a username that isn’t you real name and add the email address you want us to contact you
</h2>
<form (ngSubmit)="submit()" [formGroup]="form">
    <div class="row row-username">
        <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input (input)="isUsernameTaken = false" formControlName="username" matInput maxlength="50" minlength="3" name="username">
            <mat-error *ngIf="form.get('username').hasError('required')">
                Username is required
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('usernameUnique')">
                This Username is already taken
            </mat-error>
            <mat-error
                *ngIf="form.get('username').hasError('minlength') || form.get('username').hasError('maxLength')">
                Username should be between 3-50 characters
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('usernameFormat')">
                Username should contain only letters and numbers
            </mat-error>
        </mat-form-field>
        <mat-hint>{{ hints.username }}</mat-hint>
    </div>
    <div class="row row-email">
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input (input)="isEmailTaken = false" formControlName="email" matInput type="email">
            <mat-error *ngIf="form.get('email').hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailFormat')">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailUnique')">
                This email is already taken
            </mat-error>
        </mat-form-field>
        <mat-hint>{{ hints.email }}</mat-hint>
    </div>
    <div class="row">
        <button color="primary" mat-flat-button [disabled]="disabled">Next</button>
    </div>
</form>
