import {Inject, Injectable, Optional} from '@angular/core';

import { map} from 'rxjs/operators';

import {WINDOW} from '@core/window';
import {HttpClient} from '@angular/common/http';
import {AdApiModel, AdModel, processAdApiModels} from '@core/models';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdsService {

    private readonly endpoint = '/ads';

    private readonly googleAdsScriptSrc = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    private loadAdsenseScript(): void {
        const script = (this.window as Window).document.createElement<'script'>('script');
        script.async = true;
        script.defer = true;
        script.src = this.googleAdsScriptSrc;
        (this.window as Window).document.getElementsByTagName<'head'>('head')[0].append(script);
    }

    constructor(
        private http: HttpClient,
        @Optional() @Inject(WINDOW) private window: any
    ) {
        if (window) {
            this.loadAdsenseScript();
        }
    }

    getAds(): Observable<AdModel[]> {
        return this.http.get<{ senseClientId: string, ads: AdApiModel[] }>(this.endpoint).pipe(
            map((response) => processAdApiModels(response))
        );
    }
}
