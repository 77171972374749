import { createAction, props } from '@ngrx/store';

import { AfterFlattenUserProfileApiModel } from '@core/models';


export const prefix = '[App initialization]';

export const startAppInit = createAction(`${prefix} app init stated`);

export const finishAppInit = createAction(`${prefix} app init finished`);

export const checkAuth = createAction(`${prefix} check authentication`);

export const setCampaignSessionKey = createAction(
    `${prefix} set campaign session key`,
    props<{ campaign: string }>()
);

export const userAuthenticated = createAction(
    `${prefix} user authenticated`,
    props<AfterFlattenUserProfileApiModel & { token: string }>()
);

export const userNotAuthenticated = createAction(`${prefix} user not authenticated`);

export const loadSettings = createAction(`${prefix} load settings`);
