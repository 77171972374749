import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { exhaustMap } from 'rxjs/operators';
import * as fromRoot from '@core/store';
import { ContestStatus } from '@core/models';
import { VideoContestInProgressGuardActions } from '@core/store/actions';


@Injectable({
    providedIn: 'root'
})
export class VideoContestInProgressGuard implements CanActivate {
    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,

    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.store.pipe(select(fromRoot.getVideContestCurrent)).pipe(
            exhaustMap((currentContest) => {
                if (currentContest?.status !== ContestStatus.inProgress) {
                    this.store.dispatch(VideoContestInProgressGuardActions.showClosedContestAlert());
                    return of(this.router.parseUrl('/video-contest'));
                } else {
                    return of(true);
                }
            })
        );
    }
}
