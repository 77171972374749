<ng-container *ngIf="!asLink">
    <button
        mat-stroked-button
        color="primary"
        class="btn-private-message"
        [disabled]="disabled"
        (click)="privateMessage$.emit()"
    ><ng-content></ng-content></button>
</ng-container>
<ng-container *ngIf="asLink">
    <a
        mat-stroked-button
        color="primary"
        class="btn-private-message"
        [disabled]="disabled"
        [routerLink]="privateMessageUrl || defaultPrivateMessageLink"
    ><ng-content></ng-content></a>
</ng-container>
