import { createAction, props } from '@ngrx/store';

import { ChatReplyOnInputModel } from '@core/models';


export const prefix = '[Private chat question message guard]';

export const usersFeedQuestionInteracted = createAction(
    `${prefix} users feed question interacted`,
    props<{userId: number, questionId: number}>()
);

export const replyOnQuestion = createAction(
    `${prefix} reply on question`,
    props<{message: ChatReplyOnInputModel}>()
);
