import { createAction, props } from '@ngrx/store';

import { TagDescriptionModel } from '@core/models';


export const prefix = '[Tags API]';

export const loadDescriptionSucceeded = createAction(
    `${prefix} load description succeeded`,
    props<{ description: TagDescriptionModel }>()
);

export const loadDescriptionFailed = createAction(
    `${prefix} load description failed`,
    props<{ error: any }>()
);
