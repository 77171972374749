<header>
    <nav class="header-nav">
        <div class="part">
        </div>

        <a class="part" id="image-container" [routerLink]="['/']">
            <mat-icon [svgIcon]="logoFull" color="primary"></mat-icon>
        </a>

        <div class="part">
            <a
                *ngIf="showBtnClose"
                mat-icon-button
                class="btn-close"
                [routerLink]="redirectToUrl"
                [queryParams]="redirectToQueryParams"
                [fragment]="redirectToFragment"
            ><mat-icon>close</mat-icon></a>
        </div>
    </nav>
</header>
