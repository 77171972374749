<span class="user-avatar raw-image" *ngIf="!asLink;  else link">
    <ng-container *ngTemplateOutlet="img"></ng-container>
</span>

<ng-template #link>
    <a [routerLink]="['/users', user.id]"
       rel="nofollow"
       class=" user-avatar raw-image"
    >
        <ng-container *ngTemplateOutlet="img"></ng-container>
    </a>
</ng-template>

<ng-template #img>
    <span class="avatar-container" [ngStyle]="user && user.photo ? {backgroundImage: 'url(' + user.photo + ')'} : {}">
        <span class="is-online-icon" *ngIf="!hideOnlineIndicator && user && ((user['lastActivity'] | isOnline) || isOnline)"></span>
    </span>
</ng-template>