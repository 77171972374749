import { UntypedFormGroup, ValidationErrors } from '@angular/forms';


export class ChangePasswordFormValidator {

    public static validate(control: UntypedFormGroup): ValidationErrors | null {
        const currentPassword = control.get('currentPassword');
        const password = control.get('password');
        const rePassword = control.get('rePassword');
        let error = null;
        if (password.value && rePassword.value && password.value !== rePassword.value) {
            error = {passwordsMatch: true};
            rePassword.setErrors(error);
            return error;
        }
        if (currentPassword.value === password.value) {
            error = {passwordsAreEquals: true};
            password.setErrors(error);
        }
        return error;
    }
}
