import { Injectable } from '@angular/core';
import { avatarImages } from '@core/services/avatar-images/avatar-images';

export interface ImagesList {
    name: string;
    images: string[];
}

@Injectable({
    providedIn: 'root'
})
export class AvatarImagesService {
    getImagesList(): ImagesList[] {
        return avatarImages.map(category => ({
            name: category.title,
            images: category.files.map(filename => `${category.pathPrefix}${filename}.jpg`)
        }));
    }
}
