import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


import memo from 'memo-decorator';


@Pipe({
    name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

    readonly defaultClass = 'highlight';

    constructor(private domSanitizer: DomSanitizer) {
    }

    @memo({resolver: (...args: any[]): string => JSON.stringify(args)})
    transform(value: string, highlightText: string, highlightClass = this.defaultClass): SafeHtml {
        if (!highlightText) {
            return value;
        }
        return this.domSanitizer.bypassSecurityTrustHtml(value.replace(new RegExp(highlightText.toLowerCase(), 'gi'), (substring) => {
            return `<span class="${highlightClass}">${substring}</span>`;
        }));
    }

}
