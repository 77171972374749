import { createAction, props } from '@ngrx/store';

import { Update } from '@ngrx/entity';

import { PublicChatMessageFlatModel, PublicUserModel } from '@core/models';


export const chatMessageReceived = createAction(
    '[Public chat/WS] chat message received',
    props<{
        message: PublicChatMessageFlatModel,
        users: PublicUserModel[]
    }>()
);

export const editMessageReceived = createAction(
    '[Public chat/WS] edit message received',
    props<{ update: Update<PublicChatMessageFlatModel> }>()
);

export const deleteMessageReceived = createAction(
    '[Public chat/WS] delete message received',
    props<{ messageId: number }>()
);

export const userSubscribeReceived = createAction(
    '[Public chat/WS] user subscribe received',
    props<{ user: PublicUserModel }>()
);

export const userUnsubscribeReceived = createAction(
    '[Public chat/WS] user unsubscribe received',
    props<{ userId: number }>()
);

export const blockedUserSubscribeReceived = createAction(
    '[Public chat/WS] blocked user subscribe received',
    props<{ user: PublicUserModel }>()
);

export const blockedUserUnsubscribeReceived = createAction(
    '[Public chat/WS] blocked user unsubscribe received',
    props<{ userId: number }>()
);
