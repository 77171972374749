import { Dictionary } from '@ngrx/entity';

import { PublicUserModel, UserRole } from '@core/models/user.model';
import { UserSettingsModel, UserSettingsViewModel } from '@core/models/user-settings.model';
import { TagModel } from '@core/models/tag.model';
import { QuestionModel, QuestionModelWithTag } from '@core/models/question.model';
import { FeedRecordType } from '@core/models/feed-record.model';


export interface UserProfileFeedRecordsModel {
    [FeedRecordType.canHelpSetting]?: UserSettingsViewModel[];
    [FeedRecordType.needHelpSetting]?: UserSettingsViewModel[];
    [FeedRecordType.question]?: QuestionModelWithTag[];
}

export interface UserProfileViewModel {
    user: PublicUserModel;
    followings: PublicUserModel[];
    feedRecords: UserProfileFeedRecordsModel;
}

export interface UserProfileFeedRecordDto {
    id: number;
    recordType: FeedRecordType;
}

export function prepareUserProfileFeedRecordsModel(
    settings: UserSettingsModel[],
    questions: QuestionModel[],
    tags: Dictionary<TagModel>
): UserProfileFeedRecordsModel | null {
    const result: UserProfileFeedRecordsModel = {};
    settings.forEach((setting) => {
        if (!tags[setting.tagId]) {
            return;
        }
        const key = setting.role === UserRole.needHelp ? FeedRecordType.needHelpSetting : FeedRecordType.canHelpSetting;
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push({
            ...setting,
            tag: {...tags[setting.tagId]}
        });
    });
    if (questions) {
        questions.forEach((question) => {
            if (!tags[question.tagId]) {
                return;
            }
            if (!result[FeedRecordType.question]) {
                result[FeedRecordType.question] = [];
            }
            result[FeedRecordType.question].push({
                id: question.id,
                text: question.text,
                updatedOn: question.updatedOn,
                tag: {
                    ...tags[question.tagId]
                }
            });
        });
    }
    return Object.keys(result).length ? result : null;
}
