import {AdModel} from '@core/models/ad.model';
import {Action, createReducer, on} from '@ngrx/store';
import {AdsActions} from '@core/store/actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

export const featureKey = 'ads';

export type State = EntityState<AdModel>;

export const adapter = createEntityAdapter<AdModel>({
    selectId: (model: AdModel): string => model.slot
});

export const initialState: State = adapter.getInitialState();

const AdsReducer = createReducer(
    initialState,
    on(
        AdsActions.adsLoadSuccess,
        (state: State, {ads}) => adapter.addMany(ads, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return AdsReducer(state, action);
}
export const {
    selectAll: getAds
} = adapter.getSelectors();
