import { createAction, props } from '@ngrx/store';


import { UserSettingsModel } from '@core/models';


export const prefix = '[Feed settings page]';

export const pageInit = createAction(`${prefix} page init`);

export const helpSettingsSubmitted = createAction(
    `${prefix} help settings submitted`,
    props<{ settings: UserSettingsModel }>()
);

export const deleteBrowseSettings = createAction(
    `${prefix} delete browse settings`,
    props<{ settings: UserSettingsModel }>()
);

export const helpSettingsCancelled = createAction(`${prefix} help settings edit cancelled`);
