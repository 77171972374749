import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import * as fromCurrentUser from '@core/store/current-user';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TagQuestionExistsGuard implements CanActivate {
    constructor(
        private store: Store<fromCurrentUser.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromCurrentUser.getFeedQuestionById({id: route.params.questionId})),
            first(),
            map((question) => {
                if (question) {
                    return true;
                }
                return this.router.parseUrl('/404');
            })
        );
    }
}
