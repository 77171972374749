import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';


@Injectable()
export class AppRouteReuseStrategy implements RouteReuseStrategy {
    retrieve(route: ActivatedRouteSnapshot): null {
        return null;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (
            future.data.reuseComponentKey
            && curr.data.reuseComponentKey
            && future.data.reuseComponentKey === curr.data.reuseComponentKey
        ) {
            if (future.data.reuseOnSameParams || curr.data.reuseOnSameParams) {
                return JSON.stringify(future.params) === JSON.stringify(curr.params);
            } else {
                return true;
            }
        }
        return future.routeConfig === curr.routeConfig && !curr.data?.doNotReuseRoute;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
      // Storing a null value should erase the previously stored value.
    }

}
