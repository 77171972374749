import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CallApiService {

    readonly apiPrefix = '/call';

    offer(userId: number, sdp: string, socketSessionId: string): Observable<{ callId: number }> {
        return this.http.post<{ callId: number }>(`${this.apiPrefix}/${userId}`, {
                sdp,
                socketSessionId
            }
        );
    }

    candidate(callId: number, sdp: string): Observable<{ callId: number }> {
        return this.http.post<{ callId: number }>(`${this.apiPrefix}/${callId}/candidate`, {
            sdp
        });
    }

    accept(callId: number, sdp: string, token): Observable<void> {
        return this.http.post<void>(`${this.apiPrefix}/${callId}/accept`, {sdp, socketSessionId: token});
    }

    decline(callId: number): Observable<number> {
        return this.http.delete<number>(`${this.apiPrefix}/${callId}/decline`);
    }

    negotiate(callId: number, offer: string) {
        return this.http.post<void>(`${this.apiPrefix}/${callId}/negotiate`, {offer});
    }

    end(callId: number): Observable<void> {
        return this.http.delete<void>(`${this.apiPrefix}/${callId}/end`);
    }

    constructor(
        private http: HttpClient
    ) {
    }
}
