import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PublicUserModel, UserModel } from '@core/models';


@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {

    @Input()
    user: UserModel | PublicUserModel;

    @Input()
    isOnline = false;

    @Input()
    hideOnlineIndicator = false;

    @Input()
    asLink = false;

}
