export type AdType = 'adSense' | 'image' | 'text';

export type AdFormat = 'auto' | 'horizontal' | 'vertical' | 'rectangle';

const additionalAdConfig = {
    display: 'inline-block',
    height: 120,
    width: null
};

export interface AdModel {
    type: AdType,
    slot: string,

    image: string,
    destinationUrl: string,
    openInNewTab: boolean

    slotId: string,
    format: AdFormat,
    adSenseClientId: string,
    responsive: boolean,
    test: boolean,
    display: string,
    width: number,
    height: number,

    text: string
}

export interface AdApiModel {
    type: AdType,
    slot: string,
    slotId: string,
    format: AdFormat,
    responsive: boolean,
    testData: boolean,
    image: string,
    destinationUrl: string,
    openInNewTab: boolean,
    text: string
}

const processAdApiModel = (adApiModel: AdApiModel, adsenseClientId: string): AdModel => {
    return {
        ...adApiModel,
        ...additionalAdConfig,
        adSenseClientId: adsenseClientId,
        test: adApiModel.testData
    };
};

export const processAdApiModels = ({senseClientId, ads}: {senseClientId: string, ads: AdApiModel[]}): AdModel[] => {
    return ads
        .filter((ad: AdApiModel) => ad.type !== 'adSense' || senseClientId)
        .map((ad: AdApiModel) => processAdApiModel(ad, senseClientId));
};
