import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';


@Directive({
    selector: '[appLongTouch]'
})
export class LongTouchDirective {

    private start: number;

    @Input()
    useForceTouchIfAvailable = true;

    @Input()
    minDuration = 300;

    @Output()
    longTouch$ = new EventEmitter<void>();

    @HostListener('touchstart', ['$event'])
    onStart(event: MouseEvent) {
        this.start = event.timeStamp;
    }

    @HostListener('touchmove', ['$event'])
    onMove() {
        this.start = null;
    }

    @HostListener('touchend', ['$event'])
    onEnd(event: MouseEvent) {
        if (this.start && event.timeStamp - this.start >= this.minDuration) {
            event.preventDefault();
            this.longTouch$.next();
        }
        this.start = null;
    }

}
