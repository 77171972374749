import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';


import { select, Store } from '@ngrx/store';


import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import * as fromRoot from '@core/store';
import { takeUntil } from 'rxjs/operators';
import { startPageAuthenticatedPath, startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable()
export abstract class BaseAuthContainerComponent extends WithDestroyedSubjectComponent {

    redirectToOnSuccess: string;

    redirectToOnClose: string;

    protected constructor(protected router: Router, protected store: Store<fromRoot.State>) {
        super();
        this.store.pipe(
            select(fromRoot.getRouteQueryParams),
            takeUntil(this.destroyed$)
        ).subscribe((params: Params) => {
            this.redirectToOnSuccess = params.redirectTo || startPageAuthenticatedPath;
            this.redirectToOnClose = startPageNonAuthenticatedPath;
            if (!params.fromGuard && params.redirectTo) {
                this.redirectToOnClose = params.redirectTo;
            }
        });
    }

    redirect(onSuccess = false) {
        this.router.navigateByUrl(true === onSuccess ? this.redirectToOnSuccess : this.redirectToOnClose);
    }
}
