import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class PingApiService {
    ping(): Observable<string> {
        return this.http.get(startPageNonAuthenticatedPath, {
            responseType: 'text'
        });
    }

    constructor(private http: HttpClient) {
    }
}
