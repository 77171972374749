import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { PublicUserModel } from '@core/models';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { InviteNotificationPopupActions } from '@core/store/actions';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-invite-notification-popup',
    templateUrl: './invite-notification-popup.component.html',
    styleUrls: ['./invite-notification-popup.component.scss']
})
export class InviteNotificationPopupComponent extends WithDestroyedSubjectComponent {
    user: PublicUserModel;
    callId: number;

    constructor(
        public matDialog: MatDialog,
        public matSnackBarRef: MatSnackBarRef<InviteNotificationPopupComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: { user: PublicUserModel, callId },
        private store: Store<fromRoot.State>,
    ) {
        super();
        this.user = data.user;
        this.callId = data.callId;
    }

    onCloseClick() {
        this.matDialog.closeAll();
        this.matSnackBarRef.dismiss();
    }

    get conversationCallLink(): string {
        return `/me/conversations/${this.user.id}/call/${this.callId}`;
    }

    onDeclineClick() {
        this.store.dispatch(InviteNotificationPopupActions.decline({callId: this.callId}));
        this.matSnackBarRef.dismiss();
    }
}
