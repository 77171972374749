import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseRouterStoreState, ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { tap } from 'rxjs/operators';

import { GoogleAnalyticsService } from '@core/services/google-analitics/google-analytics.service';
import { AuthApiActions } from '@core/store/auth/actions';
import { SearchBySubjectPageActions } from '@core/store/actions';


@Injectable()
export class GoogleAnalyticsEffects {

    trackRegistration$ = createEffect(() => this.actions$.pipe(
        ofType(AuthApiActions.registrationSucceeded),
        tap(() => {
            this.googleAnalyticsService.trackEvent('success', 'register');
        })
    ), {dispatch: false});

    trackRouterNavigated$ = createEffect(() => this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(({payload}: RouterNavigatedAction<BaseRouterStoreState>) => {
            this.googleAnalyticsService.trackPageView(payload.routerState.url);
        })
    ), {dispatch: false});

    trackTagSearch$ = createEffect(() => this.actions$.pipe(
        ofType(SearchBySubjectPageActions.searched),
        tap(({criteria}) => {
            this.googleAnalyticsService.trackPageView(`/?search=${criteria}`);
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
    }
}
