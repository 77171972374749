import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '@core/store';
import { MaintenancePageComponent } from '@core/pages';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class MaintenanceModeGuard implements CanActivate, CanDeactivate<MaintenancePageComponent> {

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.store.pipe(select(fromRoot.getMaintenancePageIsLastCheckFailed),
            map((result) => result || this.router.parseUrl(startPageNonAuthenticatedPath))
        );
    }

    canDeactivate(): Observable<boolean> {
        return this.store.pipe(select(fromRoot.getMaintenancePageIsLastCheckFailed),
            map((result) => !result)
        );
    }
}
