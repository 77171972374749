import { environmentDefault } from './environments.default';


export const environment = {
    ...environmentDefault, videoContest: {
        ...environmentDefault.videoContest,
        videosS3BucketUrl: 'https://s3-us-west-1.amazonaws.com/eat-test/contest-video/'
    },
    name: 'test',
    enableServiceWorker: true,
    production: true,
    apiBase: 'https://test.caregivingnetwork.com/api',
    wsUrl: 'wss://test.caregivingnetwork.com/api/ws',
    baseHref: 'https://test.caregivingnetwork.com',
    recaptchaKey: '6LdwJO0iAAAAAMrh6XKOJs9DCpqtD4LBAj9KCHaU',
    gaMeasurementId: 'UA-147081714-1',
    facebookPixelId: null,
    assetsS3BucketUrl: 'https://eat-website-test.s3-us-west-1.amazonaws.com',
    roiApiUrl: 'https://test.alternative-spaces.com/api',
    googleClientId: '238425773217-gm6plcav83ep7mmpmhkcb0tlveutospb.apps.googleusercontent.com',
    facebookClientId: '724428785052911',
    microsoftClientId: '1de83a80-9a16-49b2-b50e-c75623dc62cc',
    appleAppId: 'com.extendatouch.authorization',
    tawkAcountKey: '606c3e84067c2605c0bfaa0f',
    tawkWidgetId: '1f2jd8ro7',
    sentryDsn: 'https://6b62f985a88e47f8b7b579abd7284a4f@sentry.office.onix.ua/49',
    logLevel: 'DEBUG',
    outgoingCallNotRespondedMaxTimeSec: 20
};
