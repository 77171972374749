import { TagModel } from '@core/models/tag.model';


export interface TagWithChildrenModel extends TagModel {
    child?: TagWithChildrenModel[];
}

export function filterTags<T extends TagWithChildrenModel>(tags: T[], searchText: string): T[] {
    const regExp = new RegExp(searchText, 'i');
    return tags.reduce((accumulator: T[], tag: T) => {
        const newTag: T = {
            ...tag,
            child: tag.child ? filterTags(tag.child, searchText) : null
        };
        if (
            newTag.child
            && newTag.child.length
            || newTag.name.match(regExp)
        ) {
            accumulator.push(newTag);
        }
        return accumulator;
    }, [] as T[]);
}
