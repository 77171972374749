import { TagModel, UserRole, userRoleRouteParameter } from '@core/models';


export const getFeedPageUrlCommands = (tag: TagModel): string[] => {
    return [
        '/community',
        'helpline',
        ...tag.uri.split('/')
    ];
};

export const getFeedPageUrl = (tag: TagModel): string => getFeedPageUrlCommands(tag).join('/');

export const getFeedSettingsPageUrlCommands = (
    role: UserRole.canHelp | UserRole.needHelp,
    tag: TagModel
): string[] => {
    return [
        ...getFeedPageUrlCommands(tag),
        'settings',
        userRoleRouteParameter[role]
    ];
};

export const getFeedQuestionPageUrlCommands = (
    tag: TagModel,
    questionId?: number
): string[] => {
    const commands = [
        ...getFeedPageUrlCommands(tag),
        'question'
    ];
    if (questionId) {
        commands.push(questionId + '');
    }
    return commands;
};
