import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '@core/window';

@Injectable({
    providedIn: 'root'
})
export class NotificationSoundService {
    private readonly audio: HTMLAudioElement;

    constructor(
        @Optional()
        @Inject(WINDOW) private window: Window | null
    ) {
        if (this.window) {
            this.audio = this.window.document.createElement('audio');
            this.audio.src = '/assets/audio/notification.wav';
        }
    }

    play() {
        if (this.audio) {
            this.audio.play();
            this.audio.muted = false;
        }
    }
}
