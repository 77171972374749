import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';


declare let Hammer: any;

@Injectable()
export class AppHammerGestureConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        return new Hammer(element, {
            touchAction: 'pan-y'
        });
    }
}
