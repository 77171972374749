import { Component, Input } from '@angular/core';

import { BlogPostModel } from '@core/models';


@Component({
    selector: 'app-blog-post',
    templateUrl: './blog-post.component.html',
    styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent {
    @Input()
    post: BlogPostModel;
}
