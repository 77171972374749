import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserBlockingReason } from '@core/models';


@Component({
    selector: 'app-blocked-profile',
    templateUrl: './blocked-profile.component.html',
    styleUrls: ['./blocked-profile.component.scss']
})
export class BlockedProfileComponent {
    readonly reasons = UserBlockingReason;

    @Input()
    blockParams: { reason: UserBlockingReason, username: string };

    @Output()
    termsLinkClicked = new EventEmitter<void>();
}
