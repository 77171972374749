import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ConfirmStepByStepGuidePageActions, QuestionPageActions, SettingsPageActions } from '@core/store/feed/actions';
import { UserService } from '@core/services';
import { UserApiActions } from '@core/store/current-user/actions';
import { ChangePasswordPageActions, PreferencesPageActions, UserPersonalInfoPage, ViewMyProfilePageActions } from '@core/store/me/actions';
import { UserPersonalInfoModel } from '@core/models';
import * as fromCurrentUser from '@core/store/current-user';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@core/store';


@Injectable()
export class UserApiEffects {
    createQuestion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(QuestionPageActions.createQuestion),
            switchMap(({tagId, text}) => {
                return this.userService.createQuestion(tagId, text).pipe(
                    map((question) => UserApiActions.createQuestionSucceeded({question})),
                    catchError((error) => of(UserApiActions.createQuestionFailed({error: error.error})))
                );
            })
        );
    });

    editQuestion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(QuestionPageActions.editQuestion),
            switchMap(({id, text}) => {
                return this.userService.editQuestion(id, text).pipe(
                    map((question) => UserApiActions.editQuestionSucceeded({
                        id: question.id,
                        text: question.text,
                        updatedOn: question.updatedOn
                    })),
                    catchError((error) => of(UserApiActions.editQuestionFailed({error: error.error})))
                );
            })
        );
    });

    deleteQuestion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                QuestionPageActions.deleteQuestion,
                ViewMyProfilePageActions.deleteFeedQuestion
            ),
            switchMap(({id}) => {
                return this.userService.deleteQuestion(id).pipe(
                    map(() => UserApiActions.deleteQuestionSucceeded({id})),
                    catchError((error) => of(UserApiActions.deleteQuestionFailed({error: error.error})))
                );
            })
        );
    });

    saveHelpSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                SettingsPageActions.helpSettingsSubmitted
            ),
            switchMap(({settings}) => {
                return this.userService.saveSettings(settings).pipe(
                    map((response) => UserApiActions.saveHelpSettingsSucceeded({settings: response})),
                    catchError((error) => of(UserApiActions.saveSettingsFailed({error: error.error})))
                );
            })
        );
    });

    deleteFeedSetting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ViewMyProfilePageActions.deleteFeedSettings),
            exhaustMap(({id}) => {
                return this.userService.deleteSettings(id).pipe(
                    map(() => UserApiActions.deleteSettingsSucceeded({id})),
                    catchError((error) => of(UserApiActions.deleteSettingsFailed({error: error.error})))
                );
            })
        );
    });

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ChangePasswordPageActions.changePasswordFormSubmitted),
            switchMap(({currentPassword, newPassword}) =>
                this.userService.changePassword(currentPassword, newPassword).pipe(
                    map((response) => UserApiActions.changingPasswordSucceeded(response)),
                    catchError((e) => observableOf(UserApiActions.changingPasswordFailed({error: e.error})))
                )
            )
        );
    });

    updatePersonalInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserPersonalInfoPage.userPersonalInfoFormSubmitted),
            exhaustMap((user: UserPersonalInfoModel) =>
                this.userService.updatePersonalInfo(user).pipe(
                    map((response) => UserApiActions.updatingPersonalInfoSucceeded(response)),
                    catchError((e) => observableOf(UserApiActions.updatingPersonalInfoFailed({error: e.error})))
                )
            )
        );
    });

    updateShowMemberToolsPagePreference$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ConfirmStepByStepGuidePageActions.ShowMemberToolsToggleChanged),
            withLatestFrom(this.store.pipe(select(fromCurrentUser.getPreferences))),
            switchMap(([{ isShowMemberToolsPage }, preferences],) => {
                return this.userService.updatePreferences({
                    ...preferences,
                    isShowMemberToolsPage
                }).pipe(
                    map((response) => UserApiActions.updatePreferencesSucceeded({preferences: response})),
                    catchError((error) => of(UserApiActions.updatePreferencesFailed({error: error.error})))
                );
            })
        );
    });

    updatePreferences$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PreferencesPageActions.SavePreferencesSubmitted),
            switchMap(({preferences}) => {
                return this.userService.updatePreferences(preferences).pipe(
                    map((response) => UserApiActions.updatePreferencesSucceeded({preferences: response})),
                    catchError((error) => of(UserApiActions.updatePreferencesFailed({error: error.error})))
                );
            })
        );
    });

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private userService: UserService
    ) {
    }
}
