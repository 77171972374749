import { Component, ElementRef, HostBinding, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import { fadeAnimation } from '@core/animations/fade.animation';
import { WINDOW } from '@core/window';
import {
    AffirmationDailyPopupService,
    FacebookPixelLoaderService,
    PrivateMessagesWsService,
    SwUpdatesService,
    YoutubeApiLoaderService
} from '@core/services';
import { CustomSvgIcon } from '@material/custom-svg-icon';
import { TawkChatLoaderService } from '@core/services/tawkChatLoader/tawk-chat-loader.service';
import { subjectSvgIconsMap } from '@material/subjext-svg-icons';
import * as fromCurrentUser from '@core/store/current-user';
import { AdSenseHeightFixDirective } from '@shared/directives';
import { filter, take, withLatestFrom } from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeAnimation]
})
export class AppComponent implements OnInit {

    private adSenseFixHeight: AdSenseHeightFixDirective;

    readonly iconsMap: { [key in CustomSvgIcon]: string } = {
        [CustomSvgIcon.logoFull]: '/assets/images/logo-full.svg',
        [CustomSvgIcon.logoFullET]: '/assets/images/welcome-page/tabs/logo-full-ET.svg',
        [CustomSvgIcon.edit]: '/assets/icons/edit.svg',
        [CustomSvgIcon.user]: '/assets/icons/user.svg',
        [CustomSvgIcon.notifications]: '/assets/icons/notifications.svg',
        [CustomSvgIcon.global]: '/assets/icons/global.svg',
        [CustomSvgIcon.chat]: '/assets/icons/chat.svg',
        [CustomSvgIcon.shield]: '/assets/icons/shield.svg',
        [CustomSvgIcon.facebook]: '/assets/icons/facebook.svg',
        [CustomSvgIcon.google]: '/assets/icons/google.svg',
        [CustomSvgIcon.microsoft]: '/assets/icons/microsoft.svg',
        [CustomSvgIcon.caregivingNetwork]: '/assets/icons/caregiving-network.svg',
        [CustomSvgIcon.apple]: '/assets/icons/apple.svg',
        [CustomSvgIcon.youtube]: '/assets/icons/youtube.svg',
        [CustomSvgIcon.checkmark]: '/assets/icons/checkmark.svg',
        [CustomSvgIcon.winnerStar]: '/assets/icons/winner-star.svg',
        [CustomSvgIcon.videoContestStatus]: '/assets/icons/video-contest-status.svg',
        [CustomSvgIcon.videoContestStatusEmpty]: '/assets/icons/video-contest-status-empty.svg',
        [CustomSvgIcon.youtubeRound]: '/assets/icons/youtube-round.svg',
        [CustomSvgIcon.facebookRound]: '/assets/icons/facebook-round.svg',
        [CustomSvgIcon.instagramRound]: '/assets/icons/instagram-round.svg',
        [CustomSvgIcon.linkedinRound]: '/assets/icons/linkedin-round.svg',
        [CustomSvgIcon.redditRound]: '/assets/icons/reddit-round.svg',
        [CustomSvgIcon.mediumRound]: '/assets/icons/medium-round.svg',
        [CustomSvgIcon.arrowRight]: '/assets/icons/arrow-right.svg',
        [CustomSvgIcon.email]: '/assets/icons/email.svg',
        [CustomSvgIcon.thumbsUp]: '/assets/icons/thumbsUp.svg',
        [CustomSvgIcon.link]: '/assets/icons/link.svg',
    };

    @HostBinding('class')
    extraClasses;

    private registerCustomIcons() {
        Object.entries({
            ...this.iconsMap,
            ...subjectSvgIconsMap
        }).forEach(([icon, url]) => {
            this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
        });
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData;
    }

    constructor(
        @Optional() @Inject(WINDOW) private window: Window | null,
        private privateMessagesWsService: PrivateMessagesWsService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private store: Store<fromRoot.State>,
        private facebookPixel: FacebookPixelLoaderService,
        private swUpdatesService: SwUpdatesService,
        private youtubeApiLoaderService: YoutubeApiLoaderService,
        private tawkChatLoaderService: TawkChatLoaderService,
        private affirmationDailyPopupService: AffirmationDailyPopupService,
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.registerCustomIcons();
    }

    ngOnInit(): void {
        this.store.pipe(select(fromRoot.getRouteData)).subscribe((data) => {
            this.extraClasses = data && data.appRootClass;
        });
        if (!this.window) {
            return;
        }
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.window.scroll(0, 0);
            }
        });

        this.store.pipe(
            select(fromCurrentUser.getToken),
            filter((token) => !!token),
            withLatestFrom(this.store.pipe(select(fromCurrentUser.getPreferences))),
            take(1)
        ).subscribe(([, preferences]) => {
            if (preferences.isShowAffirmationPopup && this.affirmationDailyPopupService.shouldShowPopup()) {
                this.affirmationDailyPopupService.showPopup();
            }
        });

        this.adSenseFixHeight = new AdSenseHeightFixDirective(this.elementRef, this.platformId);
        this.adSenseFixHeight.ngOnInit();
    }
}
