import { Inject, Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

import { filter, takeUntil } from 'rxjs/operators';

import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { WINDOW } from '@core/window';
import { TawkChatLoaderService } from '@core/services/tawkChatLoader/tawk-chat-loader.service';


@Injectable({
    providedIn: 'root'
})
export class SwUpdatesService extends WithDestroyedSubjectComponent implements OnDestroy {
    constructor(
        private swu: SwUpdate,
        private matSnackBar: MatSnackBar,
        private tawkChatLoaderService: TawkChatLoaderService,
        @Inject(WINDOW) private window: Window
    ) {
        super();
        this.swu.versionUpdates.pipe(
            takeUntil(this.destroyed$),
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        )
            .subscribe(() => {
                const ref = this.matSnackBar.open(
                    'New version is available. Please refresh the page.',
                    'refresh',
                    {
                        duration: null
                    }
                );
                tawkChatLoaderService.hideChatWidget();
                ref.onAction()
                    .subscribe(() => this.window.location.reload());
            });
    }
}
