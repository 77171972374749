import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { first, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import * as fromRoot from '@core/store';
import * as fromConversations from '@core/store/conversations';
import { PrivateChatGuardActions } from '@core/store/conversations/actions';
import { PublicUsersApiActions } from '@core/store/actions';


@Injectable({
    providedIn: 'root'
})
export class PrivateChatGuard implements CanActivate {

    constructor(
        private actions: Actions,
        private router: Router,
        private store: Store<fromRoot.State>
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromConversations.getInterlocutor),
            withLatestFrom(this.store.pipe(select(fromRoot.getConversationInterlocutorId))),
            take(1),
            switchMap(([interlocutor, interlocutorId]) => {
                if (interlocutor) {
                    return of(true);
                }
                this.store.dispatch(PrivateChatGuardActions.loadPublicUserProfile({id: interlocutorId}));
                return this.actions.pipe(
                    ofType(
                        PublicUsersApiActions.loadProfileSucceeded,
                        PublicUsersApiActions.loadProfileFailed
                    ),
                    first(),
                    map(({type}) => {
                        if (type === '[Public users API] load public user profile failed') {
                            return this.router.parseUrl('/404');
                        }
                        return true;
                    })
                );
            })
        );
    }
}
