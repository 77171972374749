import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ContestVideoModel } from '@core/models';
import { getSortByDateComparer } from '@core/store/sort-comparer-by-date';
import { ContestVideosPageActions, VideoContestAPIActions } from '@core/store/video-contest/actions';
import { AuthApiActions } from '@core/store/auth/actions';
import { PrivateMessagesWsActions } from '@core/store/actions';


export interface State extends EntityState<ContestVideoModel> {
    page: number;
    isPending: boolean;
    hasMore: boolean;
}

const entityAdapter = createEntityAdapter<ContestVideoModel>({
    sortComparer: getSortByDateComparer<ContestVideoModel, 'date'>('date')
});

const initialState: State = entityAdapter.getInitialState({
    page: 1,
    isPending: true,
    hasMore: true
});

const ContestVideosPageReducer = createReducer(
    initialState,
    on(
        AuthApiActions.logoutSucceeded,
        PrivateMessagesWsActions.reportUserReceived,
        () => initialState
    ),
    on(
        ContestVideosPageActions.init,
        ContestVideosPageActions.loadMore,
        (state: State): State => ({
            ...state,
            isPending: true
        })
    ),
    on(VideoContestAPIActions.contestVideoCreateSucceeded,
    (state: State, {contestVideo}) => {
        return entityAdapter.addOne(contestVideo, state);
    }
    ),
    on(
        VideoContestAPIActions.getContestVideosSucceeded,
        (state: State, {contestVideos, hasMore}) => {
            return entityAdapter.addMany(contestVideos, {
                ...state,
                isPending: false,
                page: state.page + 1,
                hasMore
            });
        }
    ),
    on(
        VideoContestAPIActions.getContestVideosFailed,
        (state: State) => ({
            ...state,
            isPending: false
        })
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return ContestVideosPageReducer(state, action);
}

export const {
    selectAll: getContestVideos,
    selectEntities: getContestVideosEntities,
} = entityAdapter.getSelectors();

export const getHasMore = (state: State): boolean => state.hasMore;
export const getIsPending = (state: State): boolean => state.isPending;
export const getPage = (state: State): number => state.page;
