<div id="ios-fix" class="ios-fix">
    <div mat-dialog-content>
        <section *ngIf="isYoutubeVideo; else video" class="youtube-video-wrapper">
            <youtube-player
                videoId="{{fileName}}"
                suggestedQuality="highres"
                #youTubePlayer
                [playerVars]="{rel: 0, showinfo: 0, modestbranding: 1}"
            ></youtube-player>
        </section>
        <ng-template #video>
            <section class="video-wrapper">
                <video playsinline poster="{{ s3BucketUrl }}/{{ fileName }}.jpg" controls #videoElement>
                    <source src="{{ s3BucketUrl }}/{{ fileName }}.webm" type="video/webm">
                    <source src="{{ s3BucketUrl }}/{{ fileName }}.mp4" type="video/mp4">
                </video>
            </section>
        </ng-template>
        <button id="btn-close" class="btn-flat" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

