import { Dictionary } from '@ngrx/entity';


export enum TagType {
    large = 'large',
    medium = 'medium',
    extraLarge = 'extra-large',
}

export interface TagApiModel {
    id: number;
    name: string;
    parentId: number;
    style: TagType;
    level: number;
    isDiagnosis: boolean;
    isPopular: boolean;
}

export interface TagModel extends TagApiModel {
    uri: string;
    fullTitle: string;
}

export interface TagDescriptionModel {
    id: number;
    heading: string;
    title: string;
    description: string;
    metaDescription: string;
    videoUrl: string;
}

export interface SelectedTagData {
    root: TagModel;
    leaf: TagModel | null;
    leafs: TagModel[];
}

export const fullTagTitleGlue = ' - ';

export function buildTagTree<T extends TagApiModel>(selectedTag: T, entities: Dictionary<T>): T[] | null {
    if (!selectedTag || !entities) {
        return null;
    }
    let tag = selectedTag;
    const tree: T[] = [];
    do {
        tree.unshift({...tag});
        tag = tag.parentId && entities[tag.parentId];
    } while (tag);
    return tree;
}

export function buildFullTagTitle<T extends TagApiModel>(tagTree: T[]): string {
    if (!tagTree || !tagTree.length) {
        return null;
    }
    return tagTree.map((tag: T) => tag.name).join(fullTagTitleGlue);
}

export function slugify(uri: string): string {
    return uri
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]/g, '')
        .replace(/-+/g, '-');
}

export function buildTagUri<T extends TagApiModel>(tagsTree: T[]): string {
    return tagsTree.map((tag) => slugify(tag.name)).join('/');
}

export function processTagApiModels(apiModels: TagApiModel[]): TagModel[] {
    const dictionary: Dictionary<TagApiModel> = {};
    apiModels.forEach((tag) => {
        dictionary[tag.id] = tag;
    });
    return apiModels.map((model): TagModel => {
        const tree = buildTagTree<TagApiModel>(model, dictionary);
        return {
            ...model,
            uri: buildTagUri(tree),
            fullTitle: buildFullTagTitle(tree)
        };
    });
}
