import { Component, Inject, Input, OnInit, Optional } from '@angular/core';

import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlogPostModel } from '@core/models';
import { WINDOW } from '@core/window';
import { environment } from '../../../../environments/environment';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';


@Component({
    selector: 'app-blog-posts',
    templateUrl: './blog-posts.component.html',
    styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent extends WithDestroyedSubjectComponent implements OnInit {
    postPerTab = 3;
    activeTabIndex = 0;
    blogPosts: Array<BlogPostModel> = [];
    postsPreparedForTabs: Array<Array<BlogPostModel>> = [];

    @Input()
    isLoading: boolean;

    @Input()
    set posts(posts: BlogPostModel[]) {
        this.blogPosts = posts;
        this.rebuildPosts();
    }

    private onWindowResize() {
        const screenWidth = this.window.innerWidth;
        const newPostPerTab = environment.blogPosts.find(
            ({minWidth}) => screenWidth > minWidth
        ).postsPerTab;

        if (newPostPerTab !== this.postPerTab) {
            this.postPerTab = newPostPerTab;
            this.rebuildPosts();
        }
    }

    private rebuildPosts() {
        this.postsPreparedForTabs = [];
        for (let i = 0; i < this.blogPosts.length; i += this.postPerTab) {
            this.postsPreparedForTabs.push(this.blogPosts.slice(i, i + this.postPerTab));
        }
    }

    constructor(@Optional() @Inject(WINDOW) private window: Window | null) {
        super();
    }

    ngOnInit(): void {
        if (this.window) {
            this.onWindowResize();
            fromEvent(this.window, 'resize', { passive: true })
                .pipe(takeUntil(this.destroyed$))
                .subscribe(() => this.onWindowResize());
        }
    }
}
