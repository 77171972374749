import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';


import memo from 'memo-decorator';


@Pipe({
    name: 'chatDate'
})
export class ChatDatePipe implements PipeTransform {
    readonly comparisonFormat = 'yyyy-MM-dd';
    readonly today = formatDate(new Date(), this.comparisonFormat, 'en_US');
    readonly yesterday = formatDate((new Date()).getTime() - 86400000, this.comparisonFormat, 'en_US');

    @memo()
    transform(date: string | number | Date, format: string = 'longDate'): string {
        const formatted = formatDate(date, this.comparisonFormat, 'en_US');
        if (formatted === this.today) {
            return 'Today';
        }
        if (formatted === this.yesterday) {
            return 'Yesterday';
        }
        return formatDate(date, format, 'en_US');
    }
}
