import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

import { Observable } from 'rxjs';

import * as fromRoot from '@core/store';
import { select, Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class TagRedirectGuard implements CanActivateChild {

    private redirect(destination: string): UrlTree {
        if (isPlatformServer(this.platformId)) {
            this.response.redirect(301, `${environment.baseHref}${destination}`);
        }
        return this.router.parseUrl(destination);
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Optional() @Inject(RESPONSE) private response,
        private router: Router,
        private store: Store<fromRoot.State>
    ) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromRoot.getTagsRedirectsEntities),
            withLatestFrom(this.store.pipe(select(fromRoot.getRouteParams))),
            map(([entities, params]) => {
                const tagsUris = [];
                ['firstTag', 'secondTag'].forEach((parameter) => {
                    if (params[parameter]) {
                        tagsUris.push(params[parameter]);
                    }
                });
                if (!tagsUris.length) {
                    return true;
                }
                const uri = tagsUris.join('/');
                if (!entities[uri]) {
                    return true;
                }
                return this.redirect(state.url.replace(uri, entities[uri].to));
            })
        );
    }
}

