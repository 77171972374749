import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


import { UserRole } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (route.params && route.params.role in UserRole) {
            return true;
        }
        return this.router.parseUrl('/404');
    }
}
