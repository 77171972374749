import { createAction, props } from '@ngrx/store';

import { AffirmationReaction } from '@core/models/affirmation.model';


const prefix = '[Affirmations Page]';

export const getReligions = createAction(`${prefix} get religions`);

export const religionSelected = createAction(
    `${prefix} religion selected`,
    props<{ religionId: number | null }>()
);

export const reactionClicked = createAction(
    `${prefix} reaction clicked`,
    props<{ id: number, reaction: AffirmationReaction }>()
);

export const sendEmailToMe = createAction(`${prefix} Send Email To Me`,
    props<{ affirmationId: number }>()
);
