import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '@core/window';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})

export class FacebookPixelLoaderService {

    readonly src = 'https://connect.facebook.net/en_US/fbevents.js';

    private prepareFbqFunction() {
        const handler: any = this.window.fbq = function() {
            if (handler.callMethod) {
                handler.callMethod.apply(handler, arguments);
            } else {
                handler.queue.push(arguments);
            }
        };
        /* eslint-disable no-underscore-dangle */
        if (!this.window._fbq) {
            this.window._fbq = handler;
        }
        /* eslint-enable no-underscore-dangle */
        handler.push = handler;
        handler.loaded = true;
        handler.version = '2.0';
        handler.queue = [];
        return this;
    }

    private loadScript() {
        const script = (this.window as Window).document.createElement<'script'>('script');
        script.async = true;
        script.defer = true;
        script.src = this.src;
        script.onload = () => {
            this.window.fbq('init', environment.facebookPixelId);
            this.window.fbq('track', 'PageView');
        };
        (this.window as Window).document.getElementsByTagName<'body'>('body')[0].append(script);
    }

    constructor(@Optional() @Inject(WINDOW) private window) {
        if (this.window && environment.facebookPixelId) {
            this
                .prepareFbqFunction()
                .loadScript();
        }
    }
}
