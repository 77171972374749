import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { slugify, TagModel, TagWithChildrenModel } from '@core/models';
import { getFeedPageUrlCommands } from '@core/utils';
import { SubjectSvgIcon } from '@material/subjext-svg-icons';


@Component({
    selector: 'app-words-cloud',
    templateUrl: './words-cloud.component.html',
    styleUrls: ['./words-cloud.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WordsCloudComponent {
    readonly subjectSvgIcons = SubjectSvgIcon;
    protected readonly slugify = slugify;

    @Input()
    isLoaded: boolean;

    @Input()
    selectedTagId: number;

    @Input()
    tags: TagWithChildrenModel[];

    @Output()
    selectedTagClick$ = new EventEmitter<void>();

    @Output()
    tagClicked$ = new EventEmitter<{ tag: TagModel, $event: MouseEvent }>();

    getLinkToFeed(tag: TagModel) {
        return getFeedPageUrlCommands(tag);
    }

    onClick($event: MouseEvent, tag: TagModel) {
        if (this.selectedTagId && this.selectedTagId === tag.id) {
            this.selectedTagClick$.emit();
        }
        this.tagClicked$.emit({tag, $event});
    }
}
