import { PublicUserModel } from '@core/models/user.model';
import { QuestionModelWithUserId } from '@core/models/question.model';
import { UserSettingsModel } from '@core/models/user-settings.model';
import { FeedRecordWithQuestion, FeedRecordWithSetting } from '@core/models/feed-record.model';


export const flattenFeedSettingsApiModels = (models: FeedSettingsApiModel[]): AfterFlattenFeedSettingsApiModels => {
    const result: AfterFlattenFeedSettingsApiModels = {
        users: [],
        settings: [],
    };
    if (!models || !models.length) {
        return result;
    }
    models.forEach((user) => {
        result.settings.push({...user.settings, userId: user.id});
        delete user.settings;
        result.users.push(user);
    });
    return result;
};

export const flattenFeedQuestionsApiModels = (models: FeedQuestionApiModel[]): AfterFlattenFeedQuestionsApiModels => {
    const result: AfterFlattenFeedQuestionsApiModels = {
        users: [],
        questions: []
    };
    if (!models || !models.length) {
        return result;
    }
    models.forEach((user) => {
        result.questions.push({...user.question, userId: user.id});
        delete user.question;
        result.users.push(user);
    });
    return result;
};

export const flattenFeedAllApiModels = (models: FeedRecordWithQuestion[] | FeedRecordWithSetting[]): AfterFlattenFeedAllApiModels => {
    const result: AfterFlattenFeedAllApiModels = {
        users: [],
        records: [],
    };
    if (!models || !models.length) {
        return result;
    }
    const users = {};
    models.forEach((user) => {
        if (user.question) {
            result.records.push({...user.question, userId: user.id});
        }
        if (user.settings) {
            result.records.push({...user.settings, userId: user.id});
        }

        delete user.question;
        delete user.settings;
        users[user.id] = user;
    });
    result.users = Object.values(users);
    return result;
};

export interface FeedSettingsApiModel extends PublicUserModel {
    settings: UserSettingsModel;
}

export interface FeedQuestionApiModel extends PublicUserModel {
    question: QuestionModelWithUserId;
}

export interface AfterFlattenFeedUserApiModels {
    users: PublicUserModel[];
}

export interface AfterFlattenFeedSettingsApiModels extends AfterFlattenFeedUserApiModels {
    settings: UserSettingsModel[];
}

export interface AfterFlattenFeedQuestionsApiModels extends AfterFlattenFeedUserApiModels {
    questions: QuestionModelWithUserId[];
}

export interface AfterFlattenFeedAllApiModels extends AfterFlattenFeedUserApiModels {
    records: (UserSettingsModel | QuestionModelWithUserId)[];
}
