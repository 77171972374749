<div id="buttons-wrapper">
    <button
        type="button"
        *ngIf="isGoogleLoginAvailable"
        mat-stroked-button
        [disabled]="isSingedInWindowOpen"
        (click)="signInWithGoogle()"
    >
        <mat-icon [svgIcon]="customSvgIcons.google"></mat-icon>
        <span class="button-text" [class.display-block]="!useLongLabel">
            <span *ngIf="useLongLabel">Sign {{isSignIn ? 'in' : 'up' }} with </span>Google
        </span>
    </button>
    <button
        type="button"
        [disabled]="isSingedInWindowOpen"
        *ngIf="isFacebookLoginAvailable"
        mat-stroked-button
        (click)="signInWithFacebook()"
    >
        <mat-icon [svgIcon]="customSvgIcons.facebook"></mat-icon>
        <span class="button-text" [class.display-block]="!useLongLabel">
            <span *ngIf="useLongLabel">Sign {{isSignIn ? 'in' : 'up' }} with </span>Facebook
        </span>
    </button>
    <button
        type="button"
        [disabled]="isSingedInWindowOpen"
        *ngIf="isMicrosoftLoginAvailable"
        mat-stroked-button
        (click)="signInWithMicrosoft()"
    >
        <mat-icon [svgIcon]="customSvgIcons.microsoft"></mat-icon>
        <span class="button-text" [class.display-block]="!useLongLabel">
            <span *ngIf="useLongLabel">Sign {{isSignIn ? 'in' : 'up' }} with </span>Microsoft
        </span>
    </button>
    <button
        type="button"
        [disabled]="isSingedInWindowOpen"
        *ngIf="isAppleLoginAvailable"
        mat-stroked-button
        (click)="signInWithApple()"
    >
        <mat-icon [svgIcon]="customSvgIcons.apple"></mat-icon>
        <span class="button-text" [class.display-block]="!useLongLabel">
            <span *ngIf="useLongLabel">Sign {{isSignIn ? 'in' : 'up' }} with </span>Apple
        </span>
    </button>
</div>
