import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Update } from '@ngrx/entity';

import { UserApiActions } from '@core/store/current-user/actions';
import { AuthService, UserService } from '@core/services/';
import { AppInitActions, ProfileMenuActions, WsActions } from '@core/store/actions';
import { AuthApiActions } from '@core/store/auth/actions';
import { WINDOW } from '@core/window';
import { GeolocationPageActions } from '@core/store/me/actions';
import { PublicUserModel } from '@core/models';


@Injectable()
export class CurrentUserEffects {
    putTokenToStorage$ = createEffect(() => this.actions$.pipe(
        ofType(
            AppInitActions.userAuthenticated,
            AuthApiActions.registrationSucceeded,
            AuthApiActions.loginSucceeded,
            AuthApiActions.loginWithSocialNetworkSucceeded,
            AuthApiActions.registrationWithSocialNetworkSucceeded,
            UserApiActions.changingPasswordSucceeded,
        ),
        tap(({token}) => this.authService.setToken(token))
    ), {dispatch: false});

    logout$ = createEffect(() => this.actions$.pipe(
        ofType(ProfileMenuActions.logout),
        exhaustMap(() => this.authService.logout().pipe(
            map(() => AuthApiActions.logoutSucceeded()),
            catchError(() => of(AuthApiActions.logoutFailed()))
        ))
    ));

    invalidSession$ = createEffect(() => this.actions$.pipe(
        ofType(WsActions.invalidSessionErrorReceived),
        tap(() => {
            this.window.alert('You have been logged out');
            this.authService.invalidateToken();
            this.router.navigate(['/auth/login'], {
                queryParams: {redirectTo: this.router.url}
            });
        })
    ), {dispatch: false});

    cleanUpToken$ = createEffect(() => this.actions$.pipe(
        ofType(AppInitActions.userNotAuthenticated),
        tap(() => this.authService.invalidateToken())
    ), {dispatch: false});

    loadProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthApiActions.loginSucceeded,
                AuthApiActions.loginWithSocialNetworkSucceeded,
                AuthApiActions.registrationSucceeded,
                AuthApiActions.registrationWithSocialNetworkSucceeded
            ),
            exhaustMap(() => {
                return this.userService.getCurrentUserProfile().pipe(
                    map((response) => UserApiActions.userProfileLoadSucceeded(response)),
                    catchError((error) => of(UserApiActions.userProfileLoadFailed({error: error.error})))
                );
            })
        );
    });

    updateGeolocation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                GeolocationPageActions.enableGeolocation,
                GeolocationPageActions.updateGeolocation
            ),
            exhaustMap(({geolocation}) => {
                return this.userService.updateGeolocation(geolocation).pipe(
                    map((location) => UserApiActions.updateGeolocationSucceeded({
                        geolocation: {
                            ...location
                        }
                    })),
                    catchError((error) => of(UserApiActions.updateGeolocationFailed({error: error.error})))
                );
            })
        );
    });

    deleteGeolocation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GeolocationPageActions.disableGeolocation),
            exhaustMap(() => {
                return this.userService.deleteGeolocation().pipe(
                    map(() => UserApiActions.deleteGeolocationSucceeded()),
                    catchError((error) => of(UserApiActions.deleteGeolocationFailed({error: error.error})))
                );
            })
        );
    });

    updateDistances$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GeolocationPageActions.getDistances),
            exhaustMap(({geolocation}) => {
                return this.userService.getDistances().pipe(
                    map((distances) => {
                        const updates: Update<PublicUserModel>[] = distances.map(distance => {
                            return {
                                id: distance.userId,
                                changes: {
                                    distance: distance.distance
                                }
                            };
                        });
                        return UserApiActions.updateDistancesSucceeded({updates});
                    }),
                    catchError((error) => of(UserApiActions.updateDistancesFailed({error: error.error})))
                );
            })
        );
    });

    constructor(
        @Optional() @Inject(WINDOW) private window: Window,
        private actions$: Actions,
        private authService: AuthService,
        private userService: UserService,
        public router: Router
    ) {
    }
}
