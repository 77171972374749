<div class="ios-fix">
    <div class="mat-headline popup-header" mat-dialog-title>
        Your question
    </div>
    <div mat-dialog-content>
        <div class="dialog-header">
            <mat-icon color="primary">help_outline</mat-icon>
            <div class="role-text">Please ask a question</div>
            <div class="tag-title">{{ tag.fullTitle }}</div>
        </div>
        <div class="form-wrapper">
            <form (ngSubmit)="submit()" [formGroup]="form" id="browse-about-form">
            <textarea
                formControlName="text"
                placeholder="Please ask a question"
            ></textarea>
                <mat-error
                    *ngIf="
                    errorStateMatcher.isErrorState(form.get('text'), formDirective)
                    && form.get('text').hasError('required')
                ">This field is required
                </mat-error>
            </form>
        </div>
        <div id="action-buttons">
            <button
                mat-stroked-button
                color="primary"
                form="browse-about-form"
                [disabled]="!form.valid || disabled"
            >Ask
            </button>
            <button
                *ngIf="showDeleteButton"
                mat-stroked-button
                color="primary"
                [disabled]="disabled"
                (click)="delete()"
            >Delete
            </button>
            <button
                mat-stroked-button
                color="primary"
                type="button"
                [disabled]="disabled"
                (click)="cancel()"
            >Cancel
            </button>
        </div>
        <div id="help-text">
            Click “ask” and your question will show in the feed
        </div>
        <app-disclaimer></app-disclaimer>
    </div>
</div>
