import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import { ApiErrorInterceptorAction } from '@core/store/actions';
import { environment } from 'src/environments/environment';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

    private readonly excludedUrls: string[] = [startPageNonAuthenticatedPath];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.roiApiUrl && req.url.startsWith(environment.roiApiUrl)) {
            return next.handle(req);
        }

        if (this.excludedUrls.includes(req.url)) {
            return next.handle(req);
        }
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status > 500 || error.status === 0) {
                    this.router.navigate(['/maintenance']);
                    this.store.dispatch(ApiErrorInterceptorAction.errorIntercepted());
                    return EMPTY;
                }
                return throwError(() => new HttpErrorResponse(error));
            })
        );
    }

    constructor(
        private router: Router,
        private store: Store<fromRoot.State>
    ) {
    }
}
