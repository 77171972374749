import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { callMessages, ConversationFlatModel } from '@core/models';
import { AppInitActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { AuthApiActions } from '@core/store/auth/actions';
import { ConversationsApiActions } from '@core/store/conversations/actions';
import { getSortByDateComparer } from '@core/store/sort-comparer-by-date';


export type State = EntityState<ConversationFlatModel>;

export const adapter = createEntityAdapter<ConversationFlatModel>({
    sortComparer: getSortByDateComparer('createdOn')
});

const initialState = adapter.getInitialState();

const ConversationsReducer = createReducer(
    initialState,
    on(
        AppInitActions.userNotAuthenticated,
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        (): State => initialState
    ),
    on(
        AppInitActions.userAuthenticated,
        UserApiActions.userProfileLoadSucceeded,
        (state, {conversations}): State => adapter.setAll(conversations, state)
    ),
    on(
        PrivateMessagesWsActions.newConversationReceived,
        (state, {conversation}): State => adapter.addOne(conversation, state)
    ),
    on(
        PrivateMessagesWsActions.chatMessageReceived,
        ConversationsApiActions.sendMessageSucceeded,
        ConversationsApiActions.replyOnMessageSucceeded,
        (state, {conversationId, message}) => {
            return adapter.updateOne({
                id: conversationId,
                changes: {
                    lastMessageId: message.id,
                    lastMessageUserIdFrom: message.userIdFrom,
                    text: message.call ? callMessages[message.call.status] : message.text,
                    createdOn: message.createdOn,
                    isRead: message.isRead
                }
            }, state);
        }
    ),
    on(
        ConversationsApiActions.editMessageSucceeded,
        PrivateMessagesWsActions.chatMessageEdited,
        (state, {conversationId, messageUpdate}): State => {
            if (state.entities[conversationId].lastMessageId !== messageUpdate.id) {
                return state;
            }
            return adapter.updateOne({
                id: conversationId,
                changes: {
                    text: messageUpdate.changes.text
                }
            }, state);
        }
    ),
    on(
        PrivateMessagesWsActions.chatMessageDeleted,
        (state, {conversationId, id, newConversationMessage}) => {
            if (state.entities[conversationId].lastMessageId !== id) {
                return state;
            }
            const changes: Partial<ConversationFlatModel> = {
                text: null,
                createdOn: null,
                lastMessageId: null,
                lastMessageUserIdFrom: null,
                isRead: true
            };
            if (newConversationMessage) {
                changes.text = newConversationMessage.text;
                changes.createdOn = newConversationMessage.createdOn;
                changes.lastMessageId = newConversationMessage.id;
                changes.lastMessageUserIdFrom = newConversationMessage.userIdFrom;
                changes.isRead = newConversationMessage.isRead;
            }
            return adapter.updateOne({
                id: conversationId,
                changes
            }, state);
        }
    ),
    on(
        ConversationsApiActions.markMessageAsReadSucceeded,
        (state, {conversationId}) => adapter.updateOne({
            id: conversationId,
            changes: {
                isRead: true
            }
        }, state)
    )
);

export function reducer(state: State | undefined, action: Action) {
    return ConversationsReducer(state, action);
}

export const {
    selectAll: getConversations
} = adapter.getSelectors();
