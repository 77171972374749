export abstract class Storage implements Storage {

    private storage = {};

    getItem(key: string): string | null {
        return this.storage.hasOwnProperty(key) ? this.storage[key] : null;
    }

    setItem(key: string, value: string): void {
        this.storage[key] = value;
    }

    removeItem(key: string): void {
        if (this.storage.hasOwnProperty(key)) {
            delete this.storage[key];
        }
    }

    key(index: number): string | null {
        return Object.keys(this.storage)[index] || null;
    }

    get length(): number {
        return Object.keys(this.storage).length;
    }

    clear(): void {
        this.storage = {};
    }
}
