import { createAction, props } from '@ngrx/store';

import { PublicUserModel } from '@core/models';


export const prefix = '[Search page]';

export const loadMoreUsers = createAction(
    `${prefix} load more users`,
    props<{ criteria: string }>()
);

export const newSearchCriteriaEntered = createAction(
    `${prefix} new search criteria entered`,
    props<{ criteria: string }>()
);

export const invalidCriteriaEntered = createAction(
    `${prefix} invalid criteria entered`,
    props<{ criteria: string }>()
);

export const searchCleared = createAction(`${prefix} search cleared`);

export const followUser = createAction(
    `${prefix} follow user`,
    props<{ user: PublicUserModel }>()
);

export const unFollowUser = createAction(
    `${prefix} un follow user`,
    props<{ user: PublicUserModel }>()
);
