import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';


export const fadeAnimation = trigger('fadeAnimation', [
    transition('void => *', []),
    transition('* <=> *', [
        query(
            ':enter',
            [style({
                display: '{{enterBlockDisplay}}',
                opacity: 0,
                top: 0,
                left: 0,
                position: 'absolute',
                width: '100%'
            })],
            {optional: true}
        ),
        query(
            ':leave',
            [style({display: '{{leaveBlockDisplay}}', overflow: 'hidden', height: '*', width: '*'})],
            {optional: true}
        ),
        query(':leave', animateChild(), {optional: true}),
        query(
            ':leave',
            [animate('.2s', style({opacity: 0}))],
            {optional: true}
        ),
        query(
            ':leave',
            [style({width: 0, height: 0})],
            {optional: true}
        ),
        query(
            ':enter',
            [style({position: 'static'})],
            {optional: true}
        ),
        query(
            ':enter',
            [animate('.2s', style({opacity: 1}))],
            {optional: true}
        ),
        query(':enter', animateChild(), {optional: true}),
    ], {
        params: {
            enterBlockDisplay: 'block',
            leaveBlockDisplay: 'block',
        }
    })
]);
