import { Params } from '@angular/router';

import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {Dictionary} from '@ngrx/entity';

import * as fromAppInit from './app-init.reducer';
import * as fromTags from './tags.reducer';
import * as fromPublicUsers from './public-users.reducer';
import * as fromFeatureFlags from './feature-flags.reducer';
import * as fromHeader from './header.reducer';
import * as fromSearchBySubjectPage from './search-by-subject-page.reducer';
import * as fromMaintenancePage from './maintenance-page.reducer';
import * as fromTagsRedirects from './tags-redirects.reducer';
import * as fromUsersStats from '@core/store/reducers/users-stats.reducer';
import * as fromCalls from '@core/store/reducers/calls.reducer';
import * as fromVideoContestStatus from '@core/store/reducers/video-contest-status.reducer';
import * as fromBlogPosts from '@core/store/reducers/blog-posts.reducer';
import * as fromAffirmationPopup from '@core/store/reducers/affiramtion-poup.reducer';
import * as fromAds from '@core/store/reducers/ads.reducer';

import {
    AdModel,
    buildTagTree,
    filterTags,
    PublicUserModel,
    SearchTagModel,
    SelectedTagData,
    TagModel,
    TagWithChildrenModel,
    UserRole
} from '@core/models';
import { ShowHelpForParam } from '@core/enums';


export interface State {
    appInit: fromAppInit.State;
    router: fromRouter.RouterReducerState;
    tags: fromTags.State;
    tagsStats: fromUsersStats.State;
    publicUsers: fromPublicUsers.State;
    featureFlags: fromFeatureFlags.State;
    header: fromHeader.State;
    searchBySubjectPage: fromSearchBySubjectPage.State;
    maintenancePage: fromMaintenancePage.State;
    [fromTagsRedirects.featureKey]: fromTagsRedirects.State;
    [fromCalls.featureKey]: fromCalls.State;
    [fromVideoContestStatus.featureKey]: fromVideoContestStatus.State;
    [fromBlogPosts.featureKey]: fromBlogPosts.State;
    [fromAffirmationPopup.featureKey]: fromAffirmationPopup.State;
    [fromAds.featureKey]: fromAds.State;
}

export const rootReducers: ActionReducerMap<State> = {
    router: fromRouter.routerReducer,
    appInit: fromAppInit.reducer,
    tags: fromTags.reducer,
    tagsStats: fromUsersStats.reducer,
    publicUsers: fromPublicUsers.reducer,
    featureFlags: fromFeatureFlags.reducer,
    header: fromHeader.reducer,
    maintenancePage: fromMaintenancePage.reducer,
    [fromSearchBySubjectPage.featureKey]: fromSearchBySubjectPage.reducer,
    [fromTagsRedirects.featureKey]: fromTagsRedirects.reducer,
    [fromCalls.featureKey]: fromCalls.reducer,
    [fromVideoContestStatus.featureKey]: fromVideoContestStatus.reducer,
    [fromBlogPosts.featureKey]: fromBlogPosts.reducer,
    [fromAffirmationPopup.featureKey]: fromAffirmationPopup.reducer,
    [fromAds.featureKey]: fromAds.reducer
};

export const getRouter = createFeatureSelector< fromRouter.RouterReducerState>('router');

export const {
    selectRouteParams: getRouteParams,
    selectQueryParams: getRouteQueryParams,
    selectRouteData: getRouteData
} = fromRouter.getSelectors(getRouter);


export const getConversationInterlocutorId = createSelector(
    getRouteParams,
    (params: Params): number => {
        return params && params.conversationInterlocutorId ? +params.conversationInterlocutorId : null;
    }
);

export const getShowHelpFor = createSelector(
    getRouteQueryParams,
    (params: Params): ShowHelpForParam => {
        return params && params.showHelpFor ? params.showHelpFor : null;
    }
);

export const getConversationQuestionId = createSelector(
    getRouteParams,
    (params: Params): number => {
        return params && params.questionId ? +params.questionId : null;
    }
);

export const getConversationSettingsId = createSelector(
    getRouteParams,
    (params: Params): number => {
        return params && params.settingsId ? +params.settingsId : null;
    }
);

export const getCallIdFromRoute = createSelector(
    getRouteQueryParams,
    (params: Params): number => {
        return params?.callId ? +params.callId : null;
    }
);

export const getPublicUsersState = createFeatureSelector< fromPublicUsers.State>('publicUsers');
export const getPublicUsersEntities = createSelector(getPublicUsersState, fromPublicUsers.getPublicUsersEntities);
export const getPublicUsers = createSelector(getPublicUsersState, fromPublicUsers.getPublicUsers);
export const getPublicUser = createSelector(
    getPublicUsersEntities,
    (users, {userId}: { userId: number }): PublicUserModel => {
        return userId && users[userId];
    }
);

export const getAppInitState = createFeatureSelector< fromAppInit.State>('appInit');
export const getAppInitIsAuthChecked = createSelector(getAppInitState, fromAppInit.getIsAuthChecked);

export const getFeatureFlagsState = createFeatureSelector< fromFeatureFlags.State>('featureFlags');
export const getFeatureFlags = createSelector(getFeatureFlagsState, fromFeatureFlags.getFeatureFlags);

export const getMaintenancePageState = createFeatureSelector< fromMaintenancePage.State>('maintenancePage');
export const getMaintenancePageIsLoading = createSelector(getMaintenancePageState, fromMaintenancePage.getIsLoading);
export const getMaintenancePageIsLastCheckFailed = createSelector(getMaintenancePageState, fromMaintenancePage.getIsLastCheckFailed);

export const getTagsState = createFeatureSelector< fromTags.State>('tags');
export const getTagsIsLoaded = createSelector(getTagsState, fromTags.getIsLoaded);
export const getTagsUris = createSelector(getTagsState, fromTags.getTagsUris);
export const getTags = createSelector(getTagsState, fromTags.getTags);
export const getTagsEntities = createSelector(getTagsState, fromTags.getTagsEntities);
export const getTagsTrees = createSelector(
    getTags,
    (tags): TagWithChildrenModel[] => {
        return tags.filter(tag => !tag.parentId)
            .map(tag => ({
                    ...tag,
                    child: tags.filter(child => child.parentId === tag.id)
                })
            );
    }
);

const getTagsRedirectsState = createFeatureSelector< fromTagsRedirects.State>(fromTagsRedirects.featureKey);

export const getTagsRedirectsEntities = createSelector(getTagsRedirectsState, fromTagsRedirects.getRedirectsEntities);

export const getAppInitIsCompleted = createSelector(
    getAppInitIsAuthChecked,
    getTagsIsLoaded,
    (
        isAuthChecked,
        isTagsLoaded,
    ): boolean => isAuthChecked && isTagsLoaded
);

export const getRoleFromRoute = createSelector(getRouteData, (data): UserRole => data && data.role);

export const getSelectedTag = createSelector(
    getTagsEntities,
    getTagsUris,
    getRouteParams,
    (entities, uris, routerParams): TagModel | null => {
        if (!entities || !uris || !routerParams) {
            return null;
        }
        const parts = [];
        ['firstTag', 'secondTag', 'thirdTag'].forEach((param) => {
            if (!routerParams[param]) {
                return;
            }
            parts.push(routerParams[param]);
        });
        if (!parts.length) {
            return null;
        }
        const tagUri = parts.join('/');
        return uris[tagUri] && entities[uris[tagUri]];
    }
);


export const getSelectedTagTree = createSelector(
    getSelectedTag,
    getTagsEntities,
    getTags,
    (selectedTag: TagModel, entities: Dictionary<TagModel>) => buildTagTree(selectedTag, entities)
);

export const getSelectedTagData = createSelector(
    getSelectedTagTree,
    getTags,
    (tree: TagModel[], tags: TagModel[]): SelectedTagData => {
        if (!tree) {
            return null;
        }
        return {
            root: {...tree[0]},
            leaf: tree[1] ? {...tree[1]} : null,
            leafs: [...tags.filter((tag) => tag.parentId === tree[0].id)]
        };
    }
);

export const getUsersStatsState = createFeatureSelector< fromUsersStats.State>('tagsStats');
export const getUsersStatsEntities = createSelector(getUsersStatsState, fromUsersStats.getUsersStatsEntities);
export const getSearchBySubjectPageState = createFeatureSelector< fromSearchBySubjectPage.State>(fromSearchBySubjectPage.featureKey);
export const getSearchBySubjectPageSearchCriteria = createSelector(getSearchBySubjectPageState, fromSearchBySubjectPage.getSearchCriteria);

export const getTagsForSearch = createSelector(
    getTags,
    getUsersStatsEntities,
    getSearchBySubjectPageSearchCriteria,
    (tags, stats, searchCriteria) => {
        const withStats: { [key: number]: SearchTagModel } = {};
        const rootIds: number[] = [];
        tags.filter((tag) => !tag.parentId).forEach((tag) => {
            withStats[tag.id] = {
                ...tag,
                stats: stats[tag.id],
                child: []
            };
            rootIds.push(tag.id);
        });
        tags.filter((tag) => tag.parentId).forEach((tag) => {
            withStats[tag.parentId.toString()].child.push({
                ...tag,
                stats: stats[tag.id]
            });
        });
        const result = rootIds.map((tagId) => withStats[tagId]);
        if (!searchCriteria) {
            return result;
        }
        return filterTags(result, searchCriteria);
    }
);

export const getHeaderState = createFeatureSelector('header');
export const getHeaderIsSupTopicsListExpanded = createSelector(getHeaderState, fromHeader.getIsSupTopicsListExpanded);

export const getActiveCallPageState = createFeatureSelector< fromCalls.State>(fromCalls.featureKey);
export const getCallInvite = createSelector(getActiveCallPageState, fromCalls.getCallInvite);
export const getActiveCallId = createSelector(getActiveCallPageState, fromCalls.getActiveCallId);
export const getActiveCallIsPending = createSelector(getActiveCallPageState, fromCalls.getIsPending);
export const getActiveCallStatus = createSelector(getActiveCallPageState, fromCalls.getCallStatus);

export const getVideoContestStatusState = createFeatureSelector< fromVideoContestStatus.State>('videoContestStatus');
export const getVideContestPrevious = createSelector(getVideoContestStatusState, fromVideoContestStatus.previous);
export const getVideContestCurrent = createSelector(getVideoContestStatusState, fromVideoContestStatus.current);
export const getVideContestNext = createSelector(getVideoContestStatusState, fromVideoContestStatus.next);

const getBlogPostsState = createFeatureSelector<fromBlogPosts.State>(fromBlogPosts.featureKey);
export const getBlogPosts = createSelector(getBlogPostsState, fromBlogPosts.getPosts);
export const getBlogpostsIsLoading = createSelector(getBlogPostsState, fromBlogPosts.getIsLoading);

const getAffirmationPopupState = createFeatureSelector<fromAffirmationPopup.State>(fromAffirmationPopup.featureKey);
export const getAffirmationPopupIsPending = createSelector(getAffirmationPopupState, fromAffirmationPopup.getIsPending);
export const getAffirmationPopupAffirmation = createSelector(getAffirmationPopupState, fromAffirmationPopup.getAffirmation);

const getAdsState = createFeatureSelector<fromAds.State>(fromAds.featureKey);
export const getAds = createSelector(getAdsState, fromAds.getAds);
export const getAd = (slotName: string) => {
    return createSelector(getAds, (ads: AdModel[]) => {
        return ads.find((ad) => ad.slot === slotName);
    });
};
