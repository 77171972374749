import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {AdModel} from '@core/models';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '@core/store';
import {WithDestroyedSubjectComponent} from '@shared/with-destroyed-subject-component';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-ad-banner',
    templateUrl: './ad-banner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdBannerComponent extends WithDestroyedSubjectComponent implements OnInit {

    @Input()
    adSlot: string;

    ad: AdModel;

    constructor(
        private store: Store<fromRoot.State>,
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            select(fromRoot.getAd(this.adSlot)),
            takeUntil(this.destroyed$)
        ).subscribe((ad) => this.ad = ad);
    }
}
