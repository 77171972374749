import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FeedPageActions } from '@core/store/feed/actions';
import { PublicUsersService } from '@core/services';
import { PublicUsersApiActions } from '@core/store/actions';
import { PublicUserProfilePageActions, SearchPageActions } from '@core/store/users/actions';
import { ViewMyProfilePageActions } from '@core/store/me/actions';
import {
    PrivateChatGuardActions,
    PrivateChatQuestionMessageGuardActions,
    PrivateChatSettingsMessageGuardActions
} from '@core/store/conversations/actions';
import { ReportAMemberPopupActions } from '@core/store/user/actions';


@Injectable()
export class PublicUsersApiEffects {

    follow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                FeedPageActions.followUser,
                PublicUserProfilePageActions.followUser,
                SearchPageActions.followUser
            ),
            mergeMap(({user}) => {
                return this.publicUsersService.follow(user.id).pipe(
                    map(() => PublicUsersApiActions.followUserSucceeded({
                        user: {
                            ...user,
                            isFollowed: true
                        }
                    })),
                    catchError((error) => of(PublicUsersApiActions.followUserFailed({error: error.error})))
                );
            })
        );
    });

    unFollow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                FeedPageActions.unFollowUser,
                PublicUserProfilePageActions.unFollowUser,
                SearchPageActions.unFollowUser,
                ViewMyProfilePageActions.unFollowUser
            ),
            mergeMap(({user}) => {
                return this.publicUsersService.unFollow(user.id).pipe(
                    map(() => PublicUsersApiActions.unFollowUserSucceeded({
                        user: {
                            ...user,
                            isFollowed: false
                        }
                    })),
                    catchError((error) => of(PublicUsersApiActions.unFollowUserFailed({error: error.error})))
                );
            })
        );
    });

    sendQuestionInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PrivateChatQuestionMessageGuardActions.usersFeedQuestionInteracted
            ),
            mergeMap(({userId, questionId}) => {
                return this.publicUsersService.sendFeedUserQuestionInteracted(userId, questionId).pipe(
                    map(() => PublicUsersApiActions.sendUserFeedQuestionInteractionSucceeded()),
                    catchError((error) => of(PublicUsersApiActions.sendUserFeedQuestionInteractionFailed({error: error.error})))
                );
            })
        );
    });

    sendSettingInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PrivateChatSettingsMessageGuardActions.usersFeedSettingInteracted
            ),
            mergeMap(({userId, settingId}) => {
                return this.publicUsersService.sendFeedUserSettingInteracted(userId, settingId).pipe(
                    map(() => PublicUsersApiActions.sendUserFeedSettingsInteractionSucceeded()),
                    catchError((error) => of(PublicUsersApiActions.sendUserFeedSettingsInteractionFailed({error: error.error})))
                );
            })
        );
    });

    reportUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ReportAMemberPopupActions.reportUser),
            mergeMap(({userId, text}) => {
                return this.publicUsersService.reportUser(userId, text).pipe(
                    map(() => PublicUsersApiActions.reportUserSucceeded()),
                    catchError((error) => of(PublicUsersApiActions.reportUserFailed({error: error.error})))
                );
            })
        );
    });

    blockUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ReportAMemberPopupActions.blockUser),
            mergeMap(({userId, text}) => {
                return this.publicUsersService.blockUser(userId, text).pipe(
                    map(() => PublicUsersApiActions.blockUserSucceeded({userId})),
                    catchError((error) => of(PublicUsersApiActions.blockUserFailed({error: error.error})))
                );
            })
        );
    });

    loadPublicUserProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PrivateChatGuardActions.loadPublicUserProfile),
            mergeMap(({id}) => {
                return this.publicUsersService.getUserProfile(id).pipe(
                    map((response) => PublicUsersApiActions.loadProfileSucceeded(response)),
                    catchError((error) => {
                        return of(PublicUsersApiActions.loadProfileFailed({error: error.error}));
                    })
                );
            })
        );
    });


    constructor(
        private actions$: Actions,
        private publicUsersService: PublicUsersService
    ) {
    }
}
