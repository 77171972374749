import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


import { AuthService } from '@core/services/auth/auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router) {
    }

    private check(state: RouterStateSnapshot): boolean | UrlTree {
        if (this.authService.getToken()) {
            return true;
        }
        return this.router.parseUrl(`/auth/login?redirectTo=${state.url}&fromGuard=true`);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.check(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.check(state);
    }

}
