import { Action, createReducer, on } from '@ngrx/store';
import { CallInviteModel, CallLocalStatus } from '@core/models/call.model';
import { PrivateMessagesWsActions } from '@core/store/actions';
import { CallActions, CallApiActions } from '@core/store/conversations/actions';


export const featureKey = 'calls';

export interface State {
    callStatus: CallLocalStatus;
    isPending: boolean;
    activeCallId: number;
    invite: CallInviteModel;
}

export const initialState: State = {
    callStatus: null,
    isPending: false,
    activeCallId: null,
    invite: null
};

const CallsReducer = createReducer(
    initialState,
    on(
        CallActions.offer,
        (state) => ({
            ...state,
            callStatus: CallLocalStatus.inviteSent
        })
    ),
    on(
        PrivateMessagesWsActions.callInviteReceived,
        (state) => ({
            ...state,
            callStatus: CallLocalStatus.inviteReceived
        })
    ),
    on(
        PrivateMessagesWsActions.callAcceptedReceived,
        (state) => ({
            ...state,
            callStatus: CallLocalStatus.acceptReceived
        })
    ),
    on(
        CallActions.answer,
        (state) => ({
            ...state,
            callStatus: CallLocalStatus.acceptSent
        })
    ),

    on(
        CallActions.end,
        CallActions.decline,
        (state) => ({
            ...state,
            callStatus: null
        })
    ),

    on(
        CallActions.offer,
        PrivateMessagesWsActions.callAcceptedReceived,
        (state) => ({
            ...state,
            isInviteAllowed: false
        })
    ),


    on(
        CallActions.answer,
        PrivateMessagesWsActions.callAcceptedReceived,
        CallActions.decline,
        (state) => ({
            ...state,
            isPending: true
        })
    ),
    on(
        CallActions.connected,
        CallApiActions.sendDeclineSucceeded,
        (state) => ({
            ...state,
            isPending: false
        })
    ),
    on(
        CallApiActions.sendOfferSucceeded,
        CallActions.answer,
        (state, {callId}) => ({
            ...state,
            activeCallId: callId
        })
    ),
    on(
        PrivateMessagesWsActions.callInviteReceived,
        (state, {callId, sdp, user}) => ({
            ...state,
            invite: {
                id: callId,
                createdOn: new Date().toISOString(),
                sdp,
                isRead: false,
                userId: user.id
            },
        })
    ),
    on(
        PrivateMessagesWsActions.callEndedReceived,
        PrivateMessagesWsActions.callDeclinedReceived,
        CallApiActions.sendEndSucceeded,
        CallApiActions.sendOfferFailed,
        CallApiActions.sendAnswerFailed,
        CallApiActions.sendEndFailed,
        PrivateMessagesWsActions.callAcceptedFromOtherSessionReceived,
        () => initialState
    ),
    on(
        PrivateMessagesWsActions.callEndedReceived,
        PrivateMessagesWsActions.callDeclinedReceived,
        (state, {callId}) => {
            if (state.activeCallId === callId) {
                return initialState;
            }
            return state;
        }
    ),
);

export function reducer(state: State | undefined, action: Action) {
    return CallsReducer(state, action);
}


export const getCallInvite = (state: State): CallInviteModel => state && state.invite;
export const getActiveCallId = (state: State): number => state && state.activeCallId;
export const getIsPending = (state: State): boolean => state && state.isPending;
export const getCallStatus = (state: State): CallLocalStatus => state && state.callStatus;
