import { PublicUserModel } from '@core/models/user.model';

export enum ReplyOnType {
    message = 'MESSAGE',
    question = 'QUESTION',
    userSetting = 'USER_SETTING'
}

export interface ChatReplyOnInputModel {
    replyOnId: number;
    replyOnType: ReplyOnType;
    userFromId: number;
    date: string;
    text: string;
}

export type ChatReplyOnInputFullModel = Omit<ChatReplyOnInputModel, 'userFromId'> & {
    userFrom: PublicUserModel
};
