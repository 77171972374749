import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { AppErrorStateMatcher } from '@shared/form-validators';
import { TagModel } from '@core/models';


export interface FeedQuestionFormPopupComponentData {
    tag: TagModel;
    text?: string;
    showDeleteButton?: boolean;
}

@Component({
    selector: 'app-feed-question-form-popup',
    templateUrl: './feed-question-form-popup.component.html',
    styleUrls: ['./feed-question-form-popup.component.scss']
})
export class FeedQuestionFormPopupComponent {

    readonly errorStateMatcher = new AppErrorStateMatcher();

    form = new UntypedFormGroup({
        text: new UntypedFormControl('', Validators.required)
    });

    tag: TagModel;

    showDeleteButton = false;

    disabled = false;

    set isDisabled(isDisabled: boolean) {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    set text(about: string) {
        this.patchValue('text', about);
    }

    @ViewChild(FormGroupDirective)
    formDirective: FormGroupDirective;

    submit$ = new Subject<{ text: string }>();

    cancel$ = new Subject<{ text: string }>();

    delete$ = new Subject<void>();

    private patchValue(controlName, value) {
        if (typeof value !== 'undefined') {
            this.form.get(controlName).patchValue(value);
        }
    }

    constructor(@Inject(MAT_DIALOG_DATA) data: FeedQuestionFormPopupComponentData) {
        this.tag = data.tag;
        this.text = data.text;
        this.showDeleteButton = data.showDeleteButton;
    }

    submit() {
        if (this.form.valid) {
            this.submit$.next(this.form.value);
        }
    }

    cancel() {
        this.cancel$.next(this.form.value);
    }

    delete() {
        this.delete$.next();
    }
}
