<div id="ios-fix" class="ios-fix">
    <div mat-dialog-content>
        <mat-icon>check</mat-icon>
        <div class="title">{{title}}</div>
        <div class="description">{{description}}</div>
        <button
            mat-flat-button
            color="primary"
            (click)="matDialogRef.close(true)"
        >Ok</button>
    </div>
</div>
