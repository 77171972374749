import { Pipe, PipeTransform } from '@angular/core';

import { facilitatorUsernamePostfix } from '@core/defaults';


@Pipe({
    name: 'username'
})
export class UsernamePipe implements PipeTransform {

    transform(
        username: string,
        isFacilitator = false,
        postfix = facilitatorUsernamePostfix
    ): string {
        if (!isFacilitator || !username) {
            return username;
        }
        return `${username}${postfix}`;
    }
}
