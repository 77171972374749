import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { AffirmationPopupComponent } from '@shared/containers';


@Injectable({
    providedIn: 'root'
})
export class AffirmationDailyPopupService {
    private popupShownKey = 'popupShown';
    private religionIdKey = 'religionId';

    constructor(
        private localStorageService: LocalStorageService,
        private matDialog: MatDialog
    ) {}

    shouldShowPopup(): boolean {
        const lastShownDate = this.localStorageService.getItem(this.popupShownKey);
        if (!lastShownDate) {
            return true;
        }

        const currentDate = new Date();
        const lastShownDateObj = new Date(lastShownDate);

        return currentDate.getFullYear() !== lastShownDateObj.getFullYear() ||
            currentDate.getMonth() !== lastShownDateObj.getMonth() ||
            currentDate.getDate() !== lastShownDateObj.getDate();
    }

    markPopupAsShown() {
        const currentDate = new Date();
        this.localStorageService.setItem(this.popupShownKey, currentDate.toDateString());
    }

    showPopup() {
        const religionId = this.localStorageService.getItem(this.religionIdKey);
        const popup = this.matDialog.open(AffirmationPopupComponent, {
                panelClass: [
                    'custom-popup',
                ],
                backdropClass: 'popup-backdrop-intensive',
                data: {religionId}
            }
        );

        popup.afterClosed().subscribe(() => {
            this.markPopupAsShown();
        })
    }
}
