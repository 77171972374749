import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import { PrivateChatComponent } from '@pages/conversations/containers';
import { messages } from '@core/messages';
import { switchMap, take } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ActiveCallGuard implements CanDeactivate<PrivateChatComponent> {

    constructor(
        private store: Store<fromRoot.State>
    ) {
    }

    canDeactivate(): Observable<boolean> {
        return this.store.pipe(
            select(fromRoot.getActiveCallId),
            take(1),
            switchMap((activeCallId) => {
                if (activeCallId) {
                    return of(confirm(messages.call.confirmRedirect));
                }
                return of(true);
            })
        );
    }
}
