<ins
    #ins
    class="adsbygoogle"
    style="width: 100%;"
    [style.display]="ad?.display"
    [style.height.px]="ad?.height"
    [attr.data-ad-format]="ad?.format"
    [attr.data-ad-client]=""
    [attr.data-ad-slot]="ad?.slot"
    [attr.data-adtest]="ad?.test ? 'on' : 'off'"
    [attr.data-full-width-responsive]="ad?.responsive"
></ins>
