import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromAgreementPage from './agreement-page.reducer';
import * as fromContestVideosPage from './contest-videos-page.reducer';
import { ContestStatus } from '@core/models';
import { environment } from '../../../../../environments/environment';
import { isWithinInterval, addMilliseconds } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';


export const featureKey = 'videoContest';

export interface VideoContestState {
    agreementPage: fromAgreementPage.State;
    contestVideosPage: fromContestVideosPage.State;
}

export interface State extends fromRoot.State {
    videoContest: VideoContestState;
}

export function reducers(state: VideoContestState | undefined, action: Action) {
    return combineReducers({
        agreementPage: fromAgreementPage.reducer,
        contestVideosPage: fromContestVideosPage.reducer
    })(state, action);
}


const getVideoContestState = createFeatureSelector< VideoContestState>(featureKey);

const getRulesPageState = createSelector(getVideoContestState, (state) => state.agreementPage);
export const getRulesPageIsPending = createSelector(getRulesPageState, fromAgreementPage.isPending);

const getContestVideosPageState = createSelector(getVideoContestState, (state) => state?.contestVideosPage);

export const getContestVideosPageHasMore = createSelector(getContestVideosPageState, fromContestVideosPage.getHasMore);
export const getContestVideosPageIsPending = createSelector(getContestVideosPageState, fromContestVideosPage.getIsPending);
export const getContestVideosPageListPage = createSelector(getContestVideosPageState, fromContestVideosPage.getPage);
export const getContestVideos = createSelector(getContestVideosPageState, fromContestVideosPage.getContestVideos);
export const getCurrentContestChances = createSelector(
    getContestVideos,
    fromRoot.getVideContestCurrent,
    (videos, currentContest): number => {
        if (!currentContest || currentContest.status === ContestStatus.finished) {
            return 0;
        }

        const timezoneOffset = getTimezoneOffset(currentContest.timezone);

        const interval: Interval = {
            start: addMilliseconds(new Date(currentContest.start), timezoneOffset),
            end: addMilliseconds(new Date(currentContest.end), timezoneOffset)
        };

        return environment.videoContest.maxUploadsCount - videos.filter(
            (video) => isWithinInterval(new Date(video.date), interval)
        ).length;
    }
);
