import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { pageTitle, projectName } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class PageTitleService {
    set(title: string, pageTitleWithoutProjectName = false) {
        const newTitle = title ? title : pageTitle;
        this.titleService.setTitle((pageTitleWithoutProjectName || !title) ? newTitle : newTitle + ' - ' + projectName);
    }

    constructor(private titleService: Title) {
    }
}
