import { Injectable, Optional } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RxStompService } from '@stomp/ng2-stompjs';
import { tap, withLatestFrom } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import { AppInitActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { AuthApiActions } from '@core/store/auth/actions';


@Injectable()
export class WsEffects {

    private readonly wsInvalidSessionErrorMessage = 'INVALID_SESSION';

    disconnect$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.logoutSucceeded,
            AppInitActions.userNotAuthenticated,
            WsActions.invalidSessionErrorReceived,
            PrivateMessagesWsActions.reportUserReceived,
        ),
        tap(() => {
            if (null === this.rxStomp) {
                return;
            }
            this.rxStomp.configure({
                reconnectDelay: 0
            });
            this.rxStomp.deactivate();
        })
    ), {dispatch: false});

    connect$ = createEffect(() => this.actions$.pipe(
        ofType(
            AppInitActions.userAuthenticated,
            UserApiActions.userProfileLoadSucceeded
        ),
        withLatestFrom(this.store.pipe(select(fromCurrentUser.getToken))),
        tap(([, token]) => {
            if (null === this.rxStomp) {
                return;
            }
            this.rxStomp.deactivate();
            this.rxStomp.configure({
                connectHeaders: {'x-auth-token': token},
                reconnectDelay: 1000
            });
            this.rxStomp.activate();
        })
    ), {dispatch: false});

    private dispatchErrorAction(errorHeaders: { [key: string]: string }) {
        if (!errorHeaders.message) {
            return;
        }
        if (this.wsInvalidSessionErrorMessage === errorHeaders.message) {
            this.store.dispatch(WsActions.invalidSessionErrorReceived());
        }
    }

    private initStompErrorListener() {
        if (null === this.rxStomp) {
            return;
        }
        this.rxStomp.stompErrors$.subscribe((error) => this.dispatchErrorAction(error.headers));
    }

    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        @Optional() private rxStomp: RxStompService,
    ) {
        this.initStompErrorListener();
    }
}
