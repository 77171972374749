import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import * as fromAuth from '@core/store/auth';
import { ErrorType, RegistrationTypeText, ServerErrorModel } from '@core/models';
import { AuthApiActions, RegistrationErrorPageActions } from '@core/store/auth/actions';
import * as fromCurrentUser from '@core/store/current-user';
import * as fromRoot from '@core/store';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';
import { WINDOW } from '@core/window';
import { messages } from '@core/messages';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { BlockedPopupComponent } from '@pages/auth/components';
import { CustomSvgIcon } from '@material/custom-svg-icon';
import { SocialButtonSignInResult } from '@shared/components';


@Component({
    selector: 'app-registration-error',
    templateUrl: './registration-error.component.html',
    styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent extends BaseAuthContainerComponent implements OnInit {
    readonly registrationTypeTexts = RegistrationTypeText;
    readonly customSvgIcons = CustomSvgIcon;

    error: ServerErrorModel;

    registrationType$ = this.store.pipe(select(fromAuth.getRegistrationErrorPageRegistrationType));

    location: RoiLocation;

    constructor(
        @Optional()
        @Inject(WINDOW) private window: Window | null,
        private actions$: Actions,
        protected store: Store<fromRoot.State>,
        protected router: Router,
        private matDialog: MatDialog
    ) {
        super(router, store);
    }

    ngOnInit(): void {
        this.store.pipe(
            select(fromAuth.getRegistrationErrorPageError),
            takeUntil(this.destroyed$)
        ).subscribe((error) => {
            if (error.type === ErrorType.externalUserTokenInvalid && this.window) {
                this.window.alert(messages.global.error);
            } else {
                this.error = error;
            }
        });

        this.store.pipe(
            select(fromAuth.getRegistrationSocialPageLocation),
            filter((location => !!location)),
            takeUntil(this.destroyed$)
        ).subscribe((location) => {
            this.location = location;
        });

        this.actions$.pipe(
            ofType(AuthApiActions.loginWithSocialNetworkSucceeded),
            switchMap(() => {
                return this.store.pipe(
                    select(fromCurrentUser.getUser),
                    filter((user) => !!user)
                );
            }),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.redirectToOnSuccess = '/#subjects';
            this.redirect(true);
        });

        this.actions$.pipe(
            ofType(AuthApiActions.userBlocked),
            takeUntil(this.destroyed$)
        ).subscribe((userBlockedProps) => {
            this.matDialog.open<BlockedPopupComponent>(BlockedPopupComponent, {
                data: {
                    username: userBlockedProps.username
                }
            }).afterClosed()
                .subscribe((redirectToRoot: boolean) => {
                    if (redirectToRoot) {
                        this.redirect();
                    }
                });
        });
    }

    get isRegistrationActions(): boolean {
        return this.error.type !== ErrorType.externalUserNotfound;
    }

    onSignedInSocial(data: SocialButtonSignInResult, isRegistrationActions: boolean) {
        if (isRegistrationActions) {
            this.store.dispatch(RegistrationErrorPageActions.submittedLoginWithSocialNetwork({
                externalSocialNetwork: data.externalSocialNetwork,
                token: data.token
            }));
        } else {
            this.store.dispatch(RegistrationErrorPageActions.submittedRegistrationWithSocialNetwork({
                ...data,
                location: this.location
            }));
        }
    }
}
