import { Action, createReducer, on } from '@ngrx/store';

import { Geolocation, Preferences, UserModel, UserRole } from '@core/models';
import { AuthApiActions } from '@core/store/auth/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { AppInitActions, PrivateMessagesWsActions, WsActions } from '@core/store/actions';
import { EditAvatarPopupActions } from '@core/store/me/actions';
import { FeedPageActions } from '@core/store/feed/actions';
import { WordsCloudPageActions } from '@core/store/onboarding/actions';
import { VideoContestAgreementGuardActions, VideoContestAPIActions } from '@core/store/video-contest/actions';


export interface State {
    token: string;
    user: UserModel;
    lastKnownRole: UserRole;
    geolocation: Geolocation;
    preferences: Preferences;
    xAuthSocketToken: string;
    videoContestAgreementSigned: boolean;
}

export const initialState: State = {
    token: null,
    user: null,
    lastKnownRole: null,
    geolocation: null,
    videoContestAgreementSigned: null,
    preferences: {
        isSendAffirmations: false,
        isShowAffirmationPopup: false,
        isShowMemberToolsPage: true,
        isSendNewChatMassageNotification: false
    },
    xAuthSocketToken: null
};

const GeneralDataReducer = createReducer(
    initialState,
    on(
        AuthApiActions.registrationSucceeded,
        AuthApiActions.loginSucceeded,
        AuthApiActions.registrationWithSocialNetworkSucceeded,
        AuthApiActions.loginWithSocialNetworkSucceeded,
        UserApiActions.changingPasswordSucceeded,
        (state, {token}): State => ({
            ...state,
            token
        })
    ),
    on(
        AppInitActions.userAuthenticated,
        (state, {token, user, geolocation, preferences}): State => ({
            ...state,
            token,
            user,
            geolocation,
            preferences
        })
    ),
    on(
        UserApiActions.userProfileLoadSucceeded,
        (state, {user, geolocation, preferences}): State => ({
            ...state,
            user,
            geolocation,
            preferences
        })
    ),
    on(
        AppInitActions.userNotAuthenticated,
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        (): State => initialState
    ),

    on(
        EditAvatarPopupActions.photoUpdated,
        (state, {photo}) => ({
            ...state,
            user: {
                ...state.user,
                photo
            }
        })
    ),
    on(
        UserApiActions.updatingPersonalInfoSucceeded,
        (state, {username, email}) => ({
            ...state,
            user: {
                ...state.user,
                username,
                email
            }
        })
    ),
    on(
        FeedPageActions.activeTabChanged,
        WordsCloudPageActions.roleChanged,
        (state, {role}) => ({
            ...state,
            lastKnownRole: role
        })
    ),
    on(
        UserApiActions.updateGeolocationSucceeded,
        ((state, action) => ({
            ...state,
            geolocation: action.geolocation
        }))
    ),
    on(
        UserApiActions.deleteGeolocationSucceeded,
        (state => ({
            ...state,
            geolocation: null
        }))
    ),
    on(
        UserApiActions.updatePreferencesSucceeded,
        ((state, {preferences}) => ({
            ...state,
            preferences
        }))
    ),
    on(
        PrivateMessagesWsActions.xAuthSocketTokenReceived,
        ((state, {token}) => ({
            ...state,
            xAuthSocketToken: token
        }))
    ),
    on(
        VideoContestAgreementGuardActions.agreementStatusLoaded,
        (state, {isAgreementSigned}) => ({
            ...state,
            videoContestAgreementSigned: isAgreementSigned
        })
    ),
    on(
        VideoContestAPIActions.signAgreementSucceeded,
        (state) => ({
            ...state,
            videoContestAgreementSigned: true
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return GeneralDataReducer(state, action);
}

export const getToken = (state: State) => state.token;
export const getUser = (state: State) => state.user;
export const getLastKnownRole = (state: State) => state.lastKnownRole;
export const getGeolocation = (state: State) => state.geolocation;
export const getIsVideoContestAgreementSigned = (state: State) => state.videoContestAgreementSigned;
export const getPreferences = (state: State) => state.preferences;
export const getXAuthSocketToken = (state: State) => state.xAuthSocketToken;
