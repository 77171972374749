import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouteReuseStrategy } from '@angular/router';

import { Actions, EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { InjectableRxStompConfig, RxStompService } from '@stomp/ng2-stompjs';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';

import { appInitializer } from '@core/app-initializer';
import { SessionStorageService } from '@core/services';
import { CoreModule } from '@core/core.module';
import { rootReducers } from '@core/store';
import {
    AdsEffects,
    AffirmationPopupEffects,
    AppInitEffects, BlogPostsEffects,
    CurrentUserEffects,
    GoogleAnalyticsEffects,
    HelpDeskApiEffects,
    InitialSettingsApiEffects,
    InviteFriendApiEffects,
    LocationServiceEffects,
    PageTitleEffects,
    PingApiEffects,
    PrivateMessagesWsEffects,
    PublicUsersApiEffects,
    RoiApiEffects,
    SEOEffects,
    UsersStatsEffects,
    WsEffects,

} from '@core/store/effects';
import { AppHammerGestureConfig } from './app-hammer-gesture.config';
import { appRxStompConfig } from './app-rx-stomp.config';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { appRxStompServiceFactory, getSocialAuthServiceConfigsFactory, MSALConfigFactory } from './factories';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { UserApiEffects } from '@core/store/current-user/effects';
import { AuthModule } from '@pages/auth/auth.module';
import * as fromCurrentUser from '@core/store/current-user';
import { CallApiEffects } from '@core/store/conversations/effects';
import { VideoContestStatusAPIEffects } from '@core/store/effects/video-contest-status.effects';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'extend-a-touch' }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule.forRoot(),
        StoreModule.forRoot(rootReducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
            },
        }),
        StoreModule.forFeature(fromCurrentUser.featureKey, fromCurrentUser.reducers),
        EffectsModule.forRoot([
            AppInitEffects,
            InitialSettingsApiEffects,
            CurrentUserEffects,
            WsEffects,
            LocationServiceEffects,
            PageTitleEffects,
            PublicUsersApiEffects,
            GoogleAnalyticsEffects,
            SEOEffects,
            PingApiEffects,
            UserApiEffects,
            InviteFriendApiEffects,
            HelpDeskApiEffects,
            RoiApiEffects,
            UsersStatsEffects,
            CallApiEffects,
            VideoContestStatusAPIEffects,
            BlogPostsEffects,
            AffirmationPopupEffects,
            AdsEffects
        ]),
        EffectsModule.forFeature([PrivateMessagesWsEffects]),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
            routerState: RouterState.Minimal
        }),
        AuthModule,
        AppRoutingModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        SocialLoginModule,
        MsalModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [Store, Actions, SessionStorageService],
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: AppHammerGestureConfig
        },
        {
            provide: InjectableRxStompConfig,
            useValue: appRxStompConfig
        },
        {
            provide: RxStompService,
            useFactory: appRxStompServiceFactory,
            deps: [
                InjectableRxStompConfig,
                PLATFORM_ID
            ]
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                backdropClass: 'popup-backdrop',
                panelClass: 'custom-popup',
                autoFocus: false,
                hasBackdrop: true,
                closeOnNavigation: true,
                disableClose: true,
                maxWidth: '98vw',
                position: {
                    top: '0px'
                }
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000
            }
        },
        {
            provide: RouteReuseStrategy,
            useClass: AppRouteReuseStrategy
        },
        {
            provide: 'SocialAuthServiceConfig',
            useFactory: getSocialAuthServiceConfigsFactory
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALConfigFactory
        },
        MsalService
    ]
})
export class AppModule {
}
