import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

import { environment } from 'src/environments/environment';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class StaticRedirectGuard implements CanActivate {


    private redirect(destination: string, statusCode: number = 301): UrlTree {
        if (isPlatformServer(this.platformId)) {
            this.response.redirect(statusCode, `${environment.baseHref}${destination}`);
        }
        return this.router.parseUrl(destination);
    }

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional() @Inject(RESPONSE) private response
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree {
        return this.redirect(
            route.data?.redirectTo || startPageNonAuthenticatedPath,
            route.data?.redirectStatusCode || 301,
        );
    }

}
