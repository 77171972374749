import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromResources from '@core/store/resources';
import { ResourcesService } from '@core/services/resources/resources.service';
import { ResourcesMenuActions, ResourcesApiActions } from '@core/store/resources/actions';


@Injectable()
export class ResourcesEffects {
    loadLocalResources$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ResourcesMenuActions.loadLocalResources),
            withLatestFrom(
                this.store.pipe(select(fromResources.getLocalResourcesPage)),
                this.store.pipe(select(fromResources.getLocalResourcesGeolocation))
            ),
            exhaustMap(([{tagId}, page, location]) => {
                return this.resourcesService.getLocalResources(tagId, location.latitude, location.longitude, page).pipe(
                    map((response) => {
                        return ResourcesApiActions.loadLocalResourcesSucceeded({resources: response});
                    }),
                    catchError((error) => of(ResourcesApiActions.loadLocalResourcesFailed({error: error.error})))
                );
            })
        );
    });

    loadNationalResources$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ResourcesMenuActions.initNationalResources,
                ResourcesMenuActions.loadNationalResources
            ),
            withLatestFrom(this.store.pipe(select(fromResources.getNationalResourcesPage))),
            exhaustMap(([{tagId}, page]) => {
                return this.resourcesService.getNationalResources(tagId, page).pipe(
                    map((response) => {
                        return ResourcesApiActions.loadNationalResourcesSucceeded({resources: response});
                    }),
                    catchError((error) => of(ResourcesApiActions.loadNationalResourcesFailed({error: error.error})))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private resourcesService: ResourcesService,
        private store: Store<fromRoot.State>
    ) {
    }
}
