import { createAction, props } from '@ngrx/store';

import { ExternalSocialNetwork, ServerErrorModel, UserBlockingReason } from '@core/models';
import { RoiLocation } from '@core/services/roi-api/roi-location';

export const prefix = '[Auth API]';

export const registrationSucceeded = createAction(
    `${prefix} registration Success`,
    props<{
        token: string,
        email: string,
        username: string,
        location: RoiLocation
    }>()
);
export const registrationFailed = createAction(
    `${prefix} registration Failure`,
    props<{ error: ServerErrorModel }>()
);

export const loginSucceeded = createAction(
    `${prefix} login Success`,
    props<{ token: string }>()
);

export const loginFailed = createAction(
    `${prefix} login Failure`,
    props<{ error: ServerErrorModel }>()
);

export const registrationWithSocialNetworkSucceeded = createAction(
    `${prefix} registration with social network succeeded`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        token: string,
        email: string,
        username: string,
        location: RoiLocation
    }>()
);

export const registrationWithSocialNetworkFailed = createAction(
    `${prefix} registration with social network failed`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        error: ServerErrorModel
    }>()
);

export const userBlocked = createAction(
    `${prefix} user blocked`,
    props<{
        reason: UserBlockingReason,
        username: string
    }>()
);

export const logoutSucceeded = createAction(
    `${prefix} logout succeeded`
);

export const logoutFailed = createAction(
    `${prefix} logout failed`
);

export const forgotPasswordSucceeded = createAction(
    `${prefix} forgot password success`
);
export const forgotPasswordFailed = createAction(
    `${prefix} forgot password failed`,
    props<{ error: any }>()
);

export const resetPasswordSucceeded = createAction(
    `${prefix} reset password Success`
);
export const resetPasswordFailed = createAction(
    `${prefix} reset password Failure`
);

export const validateTokenSucceeded = createAction(
    `${prefix} validate token succeeded`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        token: string,
        email: string,
        location: RoiLocation
    }>()
);

export const validateTokenFailed = createAction(
    `${prefix} validate token failed`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        error: ServerErrorModel
    }>()
);

export const loginWithSocialNetworkSucceeded = createAction(
    `${prefix} login with social network succeeded`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        token: string
    }>()
);

export const loginWithSocialNetworkFailed = createAction(
    `${prefix} login with social network failed`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        error: ServerErrorModel
    }>()
);
