import { WsMessageModel } from '@core/models/ws-message.model';
import { PublicChatMessageFullModel } from '@core/models/public-chat-message.model';
import { PublicUserModel } from '@core/models/user.model';


export enum PublicChatMessageType {
    message = 'MESSAGE',
    edit = 'EDIT',
    delete = 'DELETE',
    subscribe = 'SUBSCRIBE',
    unsubscribe = 'UNSUBSCRIBE'
}

export type PublicChatMessageMessage = WsMessageModel<PublicChatMessageType.message, PublicChatMessageFullModel>;
export type PublicChatMessageEdit = WsMessageModel<PublicChatMessageType.edit, PublicChatMessageFullModel>;
export type PublicChatMessageDelete = WsMessageModel<PublicChatMessageType.delete, PublicChatMessageFullModel>;
export type PublicChatMessageSubscribe = WsMessageModel<PublicChatMessageType.subscribe, PublicUserModel>;
export type PublicChatMessageUnsubscribe = WsMessageModel<PublicChatMessageType.unsubscribe, {userId: number}>;

export type PublicChatMessage = PublicChatMessageMessage
    | PublicChatMessageEdit
    | PublicChatMessageDelete
    | PublicChatMessageSubscribe
    | PublicChatMessageUnsubscribe;
