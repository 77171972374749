import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AppInitActions, PrivateMessagesWsActions, PublicUsersApiActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { AuthApiActions } from '@core/store/auth/actions';


export type State = EntityState<number>;

export const adapter = createEntityAdapter<number>({
    selectId: (entity) => entity
});

export const initialState: State = adapter.getInitialState();

const BlockedUsersReducer = createReducer(
    initialState,
    on(
        AuthApiActions.logoutSucceeded,
        (): State => initialState
    ),
    on(
        PublicUsersApiActions.blockUserSucceeded,
        PrivateMessagesWsActions.blockUserReceived,
        (state, {userId}) => {
            return adapter.addOne(userId, state);
        }
    ),
    on(
        AppInitActions.userAuthenticated,
        UserApiActions.userProfileLoadSucceeded,
        ((state, {blocks}) => {
            return adapter.setAll(blocks, state);
        })
    )
);

export const {
    selectEntities: getBlockedUsers
} = adapter.getSelectors();

export function reducer(state: State | undefined, action: Action) {
    return BlockedUsersReducer(state, action);
}
