<div id="ios-fix" class="ios-fix">
    <div class="wrapper">
        <button class="closeButton" (click)="matDialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="wrapper" *ngIf="affirmation">
            <div class="header">
                <div class="left">
                    <div class="imageWrapper">
                        <img [src]="religionUrl" alt="religious-image">
                    </div>
                    <div class="title">
                        <div>Your Affirmation</div>
                        <div class="religionName">{{affirmation.religion?.name || 'spiritual'}}</div>
                    </div>
                </div>
                <div class="right">
                    <div class="likeButtons">
                        <button [class.active]="affirmation?.reaction === AffirmationReaction.disLike"
                                (click)="onReactionClicked(AffirmationReaction.disLike)"
                                [disabled]="isPending"
                        >
                            <mat-icon [svgIcon]="CustomSvgIcon.thumbsUp"></mat-icon>
                        </button>
                        <button [class.active]="affirmation?.reaction === AffirmationReaction.like"
                                (click)="onReactionClicked(AffirmationReaction.like)"
                                [disabled]="isPending"
                        >
                            <mat-icon [svgIcon]="CustomSvgIcon.thumbsUp"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-wrapper">
            <div #content class="content" id="content"></div>
        </div>
    </div>
</div>
