<ng-container *ngIf="profile">
    <app-user-profile-main-info
        [user]="profile.user"
        [isCurrentUser]="isCurrentUser"
    ></app-user-profile-main-info>
    <div class="interactions-wrapper" *ngIf="!isCurrentUser">
        <app-private-chat-button
            [interlocutorId]="profile.user.id"
            [asLink]="true"
        >Direct message
        </app-private-chat-button>

        <div id="follow-report-block">
            <button
                mat-stroked-button
                class="btn-follow"
                [class.followed]="profile.user.isFollowed"
                (click)="$event.stopPropagation(); toggleFollow$.emit({user: profile.user, isFollow: !profile.user.isFollowed})"
            >
                <mat-icon>{{ profile.user.isFollowed ? 'favorite' : 'favorite_border'}}</mat-icon>
                {{ profile.user.isFollowed ? 'Unfollow' : 'Follow'}}
            </button>
            <button
                class="btn-block"
                mat-stroked-button
                matTooltip="Do you want this member blocked on the site?"
                (click)="reportAMember$.emit(profile.user)"
            >
                <mat-icon color="warn">block</mat-icon>
            </button>
        </div>
    </div>
    <app-user-profile-additional
        [user]="profile.user"
        [isCurrentUser]="isCurrentUser"
        [feedRecords]="profile.feedRecords || {}"
        [followings]="profile.followings"
        [expandedRecordsType]="expandedAdditionalInfoBlock"
        [expandedRecord]="expandedProfileFeedRecord"
        [currentUserId]="currentUserId"
        (deleteRecord$)="deleteFeedRecord$.emit($event)"
        (recordClick$)="feedRecordClick$.emit($event)"
        (recordsBlockTriggerClick$)="additionalInfoBlockTriggerClick$.emit($event)"
        (toggleFollow$)="toggleFollow$.emit($event)"
    >
    </app-user-profile-additional>
</ng-container>
