import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ResourceModel } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    public static pageLimit = 10;

    readonly prefix = '/resources';

    constructor(private http: HttpClient) {
    }

    getLocalResources(tagId: number, latitude: number, longitude: number, page: number): Observable<ResourceModel[]> {
        const params = {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            size: ResourcesService.pageLimit.toString(),
            page: page.toString()
        };

        return this.http.get<ResourceModel[]>(`${this.prefix}/local/${tagId}`, {params});
    }

    getNationalResources(tagId: number, page: number): Observable<ResourceModel[]> {
        const params = {
            size: ResourcesService.pageLimit.toString(),
            page: page.toString()
        };

        return this.http.get<ResourceModel[]>(`${this.prefix}/national/${tagId}`, {params});
    }
}
