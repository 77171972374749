import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/services/auth/auth.service';


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    public static HEADER_FORCE_NO_AUTH = 'force-no-auth';

    private stripExtraHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (!request.headers.has(AuthTokenInterceptor.HEADER_FORCE_NO_AUTH)) {
            return request;
        }
        return request.clone({
            headers: request.headers.delete(AuthTokenInterceptor.HEADER_FORCE_NO_AUTH)
        });
    }

    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            headers: request.headers.delete('ngsw-bypass')
        });
        if (null === this.authService.getToken()) {
            return next.handle(this.stripExtraHeaders(request));
        }
        let modified: HttpRequest<any>;
        if (request.headers.get(AuthTokenInterceptor.HEADER_FORCE_NO_AUTH)) {
            modified = this.stripExtraHeaders(request);
        } else {
            modified = request.clone({
                headers: request.headers.set('x-auth-token', `${this.authService.getToken()}`)
            });
        }
        return next.handle(modified);
    }
}
