export enum FeedPageTabs {
    canHelp,
    needHelp,
    questions,
    all
}

export enum FeedPageTabsFragments {
    canHelp = 'give-help',
    needHelp = 'get-help',
    questions = 'questions',
    all = 'all'

}
