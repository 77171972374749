<div>
    <div id="title">
        Access to geolocation
        <button mat-icon-button mat-dialog-close tabindex="3"><mat-icon>close</mat-icon></button>
    </div>
    <div id="message">
        {{ text }}
    </div>
    <div id="controls">
        <app-btn-geolocation-help tabindex="1"></app-btn-geolocation-help>
        <button mat-flat-button mat-dialog-close color="primary" tabindex="2">Ok</button>
    </div>
</div>
