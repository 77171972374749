import { createAction, props } from '@ngrx/store';

import { AfterFlattenFeedQuestionsApiModels, AfterFlattenFeedSettingsApiModels, AfterFlattenFeedAllApiModels } from '@core/models';


export const prefix = '[feed api actions]';

export const loadFeedCanHelpUsersSucceeded = createAction(
    `${prefix} load feed can help users succeeded`,
    props<AfterFlattenFeedSettingsApiModels>()
);

export const loadFeedCanHelpUsersFailed = createAction(
    `${prefix} load feed can help users failed`,
    props<{ error: any }>()
);

export const loadFeedNeedHelpUsersSucceeded = createAction(
    `${prefix} load feed need help users succeeded`,
    props<AfterFlattenFeedSettingsApiModels>()
);

export const loadFeedNeedHelpUsersFailed = createAction(
    `${prefix} load feed need help users failed`,
    props<{ error: any }>()
);

export const loadFeedQuestionsSucceeded = createAction(
    `${prefix} load feed questions succeeded`,
    props<AfterFlattenFeedQuestionsApiModels>()
);

export const loadFeedQuestionsFailed = createAction(
    `${prefix} load feed questions failed`,
    props<{ error: any }>()
);

export const loadFeedAllSucceeded = createAction(
    `${prefix} load feed all profiles succeeded`,
    props<AfterFlattenFeedAllApiModels>()
);

export const loadFeedAllFailed = createAction(
    `${prefix} load feed all profiles failed`,
    props<{ error: any }>()
);
