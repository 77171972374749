import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { fadeAnimation } from '@core/animations/fade.animation';
import { AdSenseHeightFixDirective } from '@shared/directives/ad-sense-height-fix/ad-sense-height-fix.directive';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [fadeAnimation]
})
export class MainComponent extends WithDestroyedSubjectComponent implements OnInit, OnDestroy {

    private adSenseFixHeight: AdSenseHeightFixDirective;

    @ViewChild('outlet', {static: true})
    outlet: RouterOutlet;

    data: any = {
        value: true,
        params: {
            enterBlockDisplay: 'block'
        }
    };

    constructor(
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        super();
    }

    ngOnInit(): void {
        this.adSenseFixHeight = new AdSenseHeightFixDirective(this.elementRef, this.platformId);
        this.adSenseFixHeight.ngOnInit();
        this.outlet.activateEvents.subscribe(() => {
            this.data = {
                value: !this.data.value,
                params: {
                    leaveBlockDisplay: this.data.params.enterBlockDisplay,
                    enterBlockDisplay: this.outlet.activatedRouteData.rootElementDisplay || 'block'
                }
            };
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.adSenseFixHeight.ngOnDestroy();
    }

    getData() {
        return this.data;
    }
}
