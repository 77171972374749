import { createAction, props } from '@ngrx/store';

import { UserPersonalInfoModel } from '@core/models';


export const prefix = '[User Personal Info Page]';

export const userPersonalInfoFormSubmitted = createAction(
    `${prefix} personal info form submitted`,
    props<UserPersonalInfoModel>()
);
