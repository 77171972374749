import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserMediaService } from '@core/services/user-media/user-media.service';
import { LoggerService } from '@core/services';


export type CallType = 'inbound' | 'outbound';

export class ActiveCallRef {
    private inputStream: MediaStream;
    private peerConnection: RTCPeerConnection;
    private remoteAudio: HTMLAudioElement;
    private outputDeviceId: string;
    private makingOffer: boolean;

    ended$ = new Subject<boolean>();
    connected$ = new Subject<boolean>();
    newIceCandidate$ = new BehaviorSubject<RTCIceCandidate>(null);
    offer$ = new BehaviorSubject<RTCSessionDescriptionInit>(null);

    private isSetSinkIdSupported(audio: HTMLAudioElement): boolean {
        // @ts-ignore
        return typeof audio.setSinkId === 'function';
    }

    private setOutputDeviceId(deviceId: string) {
        this.outputDeviceId = deviceId;
        if (this.remoteAudio && this.isSetSinkIdSupported(this.remoteAudio)) {
            // @ts-ignore
            this.remoteAudio.setSinkId(this.outputDeviceId);
        }
    }

    private addInputTracksToPeerConnection() {
        LoggerService.debug('addInputTracksToPeerConnection', this.inputStream, this.peerConnection);
        if (this.inputStream && this.peerConnection) {
            const track = this.inputStream.getTracks()[0];
            const sender = this.peerConnection.getSenders()[0];
            if (sender) {
                this.peerConnection.removeTrack(sender);
            }
            this.peerConnection.addTrack(track, this.inputStream);
            track.enabled = true;
        }
    }

    private setInputStream(stream: MediaStream) {
        this.inputStream = stream;
        this.addInputTracksToPeerConnection();
    }

    private createRemoteAudioElement(): void {
        if (!this.remoteAudio) {
            this.remoteAudio = new Audio();
            this.remoteAudio.setAttribute('autoplay', 'true');
            if (this.isSetSinkIdSupported(this.remoteAudio)) {
                // @ts-ignore
                this.remoteAudio.setSinkId(this.outputDeviceId);
            }
        }
        if (this.window && !this.remoteAudio.isConnected) {
            this.window.document.body.append(this.remoteAudio);
        }
    }

    private removeAudioElements(): void {
        if (this.remoteAudio) {
            this.remoteAudio.currentTime = 0;
            this.remoteAudio.remove();
        }
    }

    private createPeerConnection(): void {
        this.peerConnection = new RTCPeerConnection(this.rtcConfig);

        fromEvent(this.peerConnection, 'icecandidate')
            .pipe(takeUntil(this.ended$))
            .subscribe(this.handleICECandidateEvent.bind(this));

        fromEvent(this.peerConnection, 'iceconnectionstatechange')
            .pipe(takeUntil(this.ended$))
            .subscribe(this.handleICEConnectionStateChangeEvent.bind(this));

        fromEvent(this.peerConnection, 'signalingstatechange')
            .pipe(takeUntil(this.ended$))
            .subscribe(this.handleSignalingStateChangeEvent.bind(this));

        fromEvent(this.peerConnection, 'track')
            .pipe(takeUntil(this.ended$))
            .subscribe(this.handleTrackEvent.bind(this));

        fromEvent<RTCOfferOptions>(this.peerConnection, 'negotiationneeded')
            .pipe(takeUntil(this.ended$))
            .subscribe((options) => {
                LoggerService.debug('negotiationneeded', options);
                this.getNewOffer(options);
            });
    }

    private handleICECandidateEvent(event: RTCPeerConnectionIceEvent): void {
        LoggerService.debug('handleICECandidateEvent', this.peerConnection.iceConnectionState);
        if (event.candidate) {
            this.newIceCandidate$.next(event.candidate);
        }
    }

    private handleICEConnectionStateChangeEvent(): void {
        LoggerService.debug('handleICEConnectionStateChangeEvent', this.peerConnection.iceConnectionState);
        switch (this.peerConnection.iceConnectionState) {
            case 'connected':
                this.connected$.next(true);
                break;
            case 'disconnected':
            case 'failed':
                // @ts-ignore
                this.peerConnection.restartIce();
                break;
            case 'closed':
                this.endCall();
                break;
        }
    }

    private handleSignalingStateChangeEvent(e): void {
        LoggerService.debug('handleSignalingStateChangeEvent', this.peerConnection.signalingState, e);
        if (this.peerConnection.signalingState === 'closed') {
            this.endCall();
        }
    }

    private handleTrackEvent(event: RTCTrackEvent): void {
        LoggerService.debug('handleTrackEvent', event);
        event.track.onunmute = (() => {
            this.remoteAudio.srcObject = event.streams[0];
        });
    }

    private async getNewOffer(options?: RTCOfferOptions) {
        try {
            this.makingOffer = true;
            await this.peerConnection.setLocalDescription(options ? await this.peerConnection.createOffer(options) : undefined);
            this.offer$.next(this.peerConnection.localDescription);
        } finally {
            this.makingOffer = false;
        }
    }

    constructor(
        private rtcConfig: RTCConfiguration,
        private window: Window,
        private userMediaService: UserMediaService,
        public type: CallType
    ) {
        this.userMediaService
            .outputDeviceId$
            .pipe(takeUntil(this.ended$))
            .subscribe((outputDeviceId) => this.setOutputDeviceId(outputDeviceId));
        this.userMediaService
            .inputStream$
            .pipe(takeUntil(this.ended$))
            .subscribe({
                next: (stream) => this.setInputStream(stream),
                error: (error) => {
                    this.ended$.error(error);
                }
            });
    }

    async setRemoteDescription(remoteDescription: RTCSessionDescriptionInit) {
        LoggerService.debug('setRemoteDescription', this.peerConnection.localDescription?.sdp === remoteDescription.sdp);
        if (remoteDescription.type === 'answer' && this.peerConnection.signalingState !== 'have-local-offer') {
            return;
        }
        const offerCollision = (remoteDescription.type === 'offer') &&
            (this.makingOffer || this.peerConnection.signalingState !== 'stable');

        const ignoreOffer = this.type === 'outbound' && offerCollision;
        if (ignoreOffer) {
            return;
        }
        await this.peerConnection.setRemoteDescription(remoteDescription);
        if (remoteDescription.type === 'offer') {
            await this.peerConnection.setLocalDescription(undefined);
            this.offer$.next(this.peerConnection.localDescription);
        }
    }

    async initOffer() {
        try {
            this.createPeerConnection();
            this.createRemoteAudioElement();
            await this.userMediaService.inputStreamPromise;
            this.addInputTracksToPeerConnection();
            await this.getNewOffer();
        } catch (e) {
            this.ended$.error(e);
            throw e;
        }
    }

    async handleInviteMessage(callId: number, remoteDescription: RTCSessionDescriptionInit) {
        try {
            this.createPeerConnection();
            this.createRemoteAudioElement();
            await this.userMediaService.inputStreamPromise;
            this.addInputTracksToPeerConnection();
            this.setRemoteDescription(remoteDescription);
        } catch (e) {
            this.ended$.error(e);
            throw e;
        }
    }

    handleICECandidateMessage(iceCandidate: RTCIceCandidate): void {
        if (this.peerConnection) {
            LoggerService.debug('addICECandidate');
            this.peerConnection
                .addIceCandidate(iceCandidate)
                .catch((e) => {
                    LoggerService.debug(e);
                });
        }
    }

    endCall(): void {
        if (this.peerConnection) {
            this.peerConnection.getTransceivers()
                .forEach(transceiver => {
                    transceiver.stop();
                });
            this.peerConnection.close();
            this.peerConnection = null;
        }
        this.removeAudioElements();
        this.ended$.next(true);
        this.ended$.complete();
        this.connected$.next(false);
        this.connected$.complete();
        this.newIceCandidate$.complete();
        this.offer$.complete();
    }

    setVolume(level: number): void {
        if (this.remoteAudio) {
            this.remoteAudio.volume = level;
        }
    }

    toggleMute(): boolean {
        const track = this.inputStream.getTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            return !track.enabled;
        }
        return false;
    }
}
