import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { EmailValidator } from '@shared/form-validators';
import { ErrorType, RegistrationType, RegistrationTypeText, ServerErrorModel } from '@core/models';


@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html',
    styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent {

    form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', Validators.compose([Validators.required, EmailValidator.validate]))
    });

    isSubmitted = false;

    @Input()
    error: ServerErrorModel;

    @Output()
    submitted = new EventEmitter<{ email: string }>();

    @Input()
    set isPending(isPending: boolean) {
        if (isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    private getSocialNetworkNameByErrorType(errorType: ErrorType): string {
        switch (errorType) {
            case ErrorType.registeredViaMicrosoft:
                return RegistrationTypeText[RegistrationType.microsoft];
            case ErrorType.registeredViaGoogle:
                return RegistrationTypeText[RegistrationType.google];
            case ErrorType.registeredViaFacebook:
                return RegistrationTypeText[RegistrationType.facebook];
            case ErrorType.registeredViaApple:
                return RegistrationTypeText[RegistrationType.apple];
            default:
                return null;
        }
    }

    get errorText(): string {
        if (!this.error) {
            return null;
        }

        const socialNetworkName = this.getSocialNetworkNameByErrorType(this.error.type);
        if (socialNetworkName) {
            return `You account is connected to Caregiving Network through ${socialNetworkName} Account.
                Please access your ${socialNetworkName} Account to reset your password there.`;
        }

        return 'Account does not exist';
    }

    submit() {
        if (this.form.valid) {
            this.isSubmitted = true;
            this.submitted.emit(this.form.value);
        }
    }

}
