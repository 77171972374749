import { AfterViewInit, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';

import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { WINDOW } from '@core/window';


@Component({
    selector: 'app-scroll-to-top',
    templateUrl: './scroll-to-top.component.html',
    styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent extends WithDestroyedSubjectComponent implements AfterViewInit {

    isUpButtonVisible = false;

    @Input()
    minOffset = 600;

    @Output()
    clicked = new EventEmitter<MouseEvent>();

    constructor(@Optional() @Inject(WINDOW) private window) {
        super();
    }

    scrollTop($event: MouseEvent) {
        if (!this.window || $event.defaultPrevented) {
            return;
        }
        this.window.scrollTo({
            behavior: 'smooth',
            top: 0
        });
    }

    onScroll() {
        if (!this.window) {
            return;
        }
        this.isUpButtonVisible = this.window.pageYOffset > this.minOffset;
    }

    ngAfterViewInit() {
        if (!this.window) {
            return;
        }
        fromEvent(this.window.document, 'scroll', {passive: true}).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(this.onScroll.bind(this));
    }
}
