import { getFeedPageUrlCommands } from '@core/utils';
import { TagModel } from '@core/models';


export const environmentDefault = {
    canonicalBaseHref: 'https://caregivingnetwork.com',
    blogUrl: 'https://blog.test.caregivingnetwork.com',
    readingRoomSlug: 'reading-room',
    facebookUrl: 'https://www.facebook.com/TheCaregivingNetwork',
    linkedInUrl: 'https://www.linkedin.com/company/caregivingnetwork/',
    instagramUrl: 'https://www.instagram.com/TheCaregivingNetwork',
    youtubeUrl: 'https://youtube.com/@caregivingnetwork',
    redditUrl: '',
    mediumUrl: 'https://medium.com/@andycramer',
    specialTags: {
        defaultForHaveQuestion: 109
    },
    contactRequest: {
        messageMaxLength: 800
    },
    feed: {
        pageSize: 10,
        questionsPerTagLimit: 5,
        publicChat: {
            usersOnlinePageSize: 10,
            messagesHistoryPageSize: 100
        },
        resources: {
            local: {
                distance: 'closest to'
            }
        }
    },
    notifications: {
        pageSize: 10
    },
    registration: {
        defaultRedirect: [...getFeedPageUrlCommands({uri: 'caregiver-support'} as TagModel), 'public-chat'].join('/')
    },
    calls: {
        enabled: true,
        RTCPeerConfiguration: {
            iceServers: [
                {
                    urls: [
                        'stun:stun1.l.google.com:19302',
                        'stun:stun2.l.google.com:19302',
                    ]
                },
                {
                    urls: 'turn:turn1.extendatouch.com:3478',
                    username: 'extendatouch',
                    credential: 'noeiu5vh395p238h',
                    credentialType: 'password'
                }
            ],
        } as RTCConfiguration
    },
    videoContest: {
        showHeaderContestLink: false,
        maxUploadsCount: 5,
        pageSize: 5,
        prizeAmount: 250,
        fileUpload: {
            chunkSize: 5 * 1024 * 1024,
            maxFileSize: 1024 * 1024 * 1024
        }
    },
    affirmations: {
        font: {
            defaultSize: 16,
            maxSize: 28,
            minSize: 12
        },
        duration: {
            fadeIn: '1.5s ease-out',
            slideVertical: '1s ease-out',
            slideHorizontal: '1s ease-out',
        }
    },
    inviteForm: {
        maxEmailsNumber: 10
    },
    blogPosts: [
        {
            minWidth: 768,
            postsPerTab: 3
        },
        {
            minWidth: 480,
            postsPerTab: 2
        },
        {
            minWidth: 0,
            postsPerTab: 1
        },
    ],
    careLibrarian: {
        maxFormFieldLength: 1500,
        dateFormat: 'MMM d, y h:mmaaaaa\'m\''
    }
};
