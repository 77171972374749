import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BlogPostModel } from '@core/models/blog-post.model';
import { AuthTokenInterceptor } from '@core/interceptors/auth-token.interceptor';


@Injectable({
    providedIn: 'root'
})
export class BlogApiService {

    readonly prefix = `${environment.blogUrl}/wp-json/custom`;

    constructor(private http: HttpClient) {
    }

    searchPosts(criteria: string): Observable<BlogPostModel[]> {
        return this.http.get<BlogPostModel[]>(`${this.prefix}/search/${criteria}`, {
            headers: {[AuthTokenInterceptor.HEADER_FORCE_NO_AUTH]: 'true'},
        });
    }
}
