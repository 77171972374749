<div [ngSwitch]="blockParams.reason" id="content">
    <div>
        <img src="/assets/images/user-banned-icon.png" alt="user banned icon">
    </div>
    <div *ngSwitchCase="reasons.blockedByMe" class="text">
        <span class="color-red">You have blocked</span> {{blockParams.username}}<br>
        according to section 3 of the <a routerLink="/legal-info/terms" (click)="termsLinkClicked.emit()">Terms of Service</a>
    </div>
    <div *ngSwitchCase="reasons.blockedMe" class="text">
        <span class="color-red">You have been blocked</span> from {{blockParams.username}} <br>
        according to section 3 of the <a routerLink="/legal-info/terms" (click)="termsLinkClicked.emit()">Terms of Service</a> for inappropriate behavior.<br>
        Please be careful to abide by our Terms of Service with other members.
    </div>
    <div *ngSwitchCase="reasons.globalBlocked" class="text">
        {{blockParams.username}} <span class="color-red">has been blocked.</span><br>
        After investigation of a legitimate complaint, we have blocked this member from participating in Caregiving Network&trade;
        for violating section 3 of our <a routerLink="/legal-info/terms" (click)="termsLinkClicked.emit()">Terms of Service</a>.
    </div>
    <div *ngSwitchCase="reasons.authGlobalBlocked" class="text">
        <div class="bold-username">
            {{blockParams.username}}
        </div>
        <div class="blocked-message">
            <span class="color-red">You have been notified by email and blocked</span>
            from participating in Caregiving Network&trade; for violating section 3 of Our
            <a routerLink="/legal-info/terms" (click)="termsLinkClicked.emit()">Terms of Service</a> for inappropriate behavior.
            Please do not return to Caregiving Network&trade;. We wish you the best.
        </div>
    </div>
</div>
