import { PublicUserModel } from '@core/models/user.model';

export enum ContestStatus {
    inProgress = 'IN_PROGRESS',
    finished = 'FINISHED'
}

export enum ContestVideoStatus {
    new,
    approved,
    winner,
    deleted
}

export interface ContestVideoModel {
    id: number;
    title: string;
    description: string;
    status: ContestVideoStatus;
    date: string;
    url: string;
}

export type ContestWinnerModel = Omit<ContestVideoModel, 'id' | 'status' | 'date'> & {
    user: PublicUserModel;
};

export interface VideoContestStatusModel {
    end: string;
    start: string;
    status: ContestStatus;
    winner: ContestWinnerModel;
    timezone: string;
}

export interface VideoContestStatusApiResponse {
    previous: VideoContestStatusModel | null;
    current: VideoContestStatusModel | null;
    next: VideoContestStatusModel | null;
}
