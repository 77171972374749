import { Component } from '@angular/core';


@Component({
    selector: 'app-legal-info-links',
    templateUrl: './legal-info-links.component.html',
    styleUrls: ['./legal-info-links.component.scss']
})
export class LegalInfoLinksComponent {
}
