import { Pipe, PipeTransform } from '@angular/core';

import { differenceInSeconds, parseISO } from 'date-fns';


@Pipe({
    name: 'isOnline'
})
export class IsOnlinePipe implements PipeTransform {

    readonly maxIdle = 60 * 10;

    transform(lastActivity: string): boolean {
        return differenceInSeconds(new Date(), parseISO(lastActivity)) < this.maxIdle;
    }
}
