import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserBlockingReason } from '@core/models';


@Component({
    selector: 'app-blocked-popup',
    templateUrl: './blocked-popup.component.html',
    styleUrls: ['./blocked-popup.component.scss']
})
export class BlockedPopupComponent {
    readonly reason = UserBlockingReason.authGlobalBlocked;

    username: string;

    constructor(
        public matDialogRef: MatDialogRef<BlockedPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data: {
            username: string
        }
    ) {
        this.username = data.username;
    }
}
