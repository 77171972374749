<h2 class="mat-title">
    Forgot Password
</h2>
<form (ngSubmit)="submit()" [formGroup]="form">
    <div class="row error">
        <ng-container *ngIf="error && isSubmitted">
            {{errorText}}
        </ng-container>
    </div>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput type="email">
            <mat-error *ngIf="form.get('email').hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('emailFormat')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <button [disabled]="form.disabled" color="primary" mat-flat-button>Reset Password</button>
    </div>
    <div class="row">
        Back to <a class="link" routerLink="/auth/login">Login</a>
    </div>
</form>
