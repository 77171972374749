import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@material/material.module';
import {
    PrivateChatButtonComponent,
    DisclaimerComponent,
    ExpandableTagComponent,
    FeedRecordTextComponent,
    InfiniteScrollComponent,
    NewSubjectConfirmationComponent,
    ParentTagSelectionComponent,
    PrivateChatNotificationComponent,
    SearchExistingTagComponent,
    SearchInputComponent,
    StatsSelectComponent,
    UnAuthCoverComponent,
    UploadImageComponent,
    UserAvatarComponent,
    WordsCloudComponent,
    BlockedProfileComponent,
    SocialButtonsComponent,
    UsernameComponent,
    ScrollToTopComponent,
    BtnGeolocationHelpComponent,
    GeolocationErrorDialogComponent,
    ThankYouPopupComponent,
    LegalInfoLinksComponent,
    IncomingCallIconComponent,
    InviteNotificationPopupComponent,
    InformationPopupComponent,
    InviteFormComponent,
    BlogPostsComponent,
    BlogPostComponent,
    FontSizeButtonsComponent,
    AdsenseAdComponent,
    ImageAdComponent,
    TextAdComponent
} from '@shared/components';
import {
    BytesPipe,
    CareExperiencePipe,
    ChatDatePipe,
    DateDiffPipe,
    HighlightTextPipe,
    IsOnlinePipe,
    SexLabelPipe,
    StatsSumPipe,
    UsernamePipe,
} from '@shared/pipes';
import {
    FeatureFlagDirective,
    GaTrackEventDirective,
    LocationBackDirective,
    LongTouchDirective,
    SocialNetworksAvailableDirective,
    AdSenseHeightFixDirective
} from '@shared/directives';
import {
    ContestLinkComponent,
    NewSubjectRequestComponent,
    UnAuthPopupComponent,
    VideoPopupComponent,
    AffirmationPopupComponent,
    AdBannerComponent
} from '@shared/containers';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';


const declarations = [
    LocationBackDirective,
    FeatureFlagDirective,
    GaTrackEventDirective,
    LongTouchDirective,
    SocialNetworksAvailableDirective,
    AdSenseHeightFixDirective,

    VideoPopupComponent,
    AffirmationPopupComponent,
    ChatDatePipe,
    SexLabelPipe,
    IsOnlinePipe,
    DateDiffPipe,
    HighlightTextPipe,
    CareExperiencePipe,
    StatsSumPipe,
    UsernamePipe,
    BytesPipe,

    PrivateChatNotificationComponent,
    UnAuthPopupComponent,
    UserAvatarComponent,
    DisclaimerComponent,
    FeedRecordTextComponent,
    UploadImageComponent,
    UnAuthCoverComponent,
    PrivateChatButtonComponent,
    WordsCloudComponent,
    SearchInputComponent,
    StatsSelectComponent,
    ExpandableTagComponent,
    NewSubjectRequestComponent,
    SearchExistingTagComponent,
    ParentTagSelectionComponent,
    NewSubjectConfirmationComponent,
    BlockedProfileComponent,
    InfiniteScrollComponent,
    SocialButtonsComponent,
    UsernameComponent,
    ScrollToTopComponent,
    BtnGeolocationHelpComponent,
    GeolocationErrorDialogComponent,
    ThankYouPopupComponent,
    VideoPopupComponent,
    IncomingCallIconComponent,
    InviteNotificationPopupComponent,
    LegalInfoLinksComponent,
    InformationPopupComponent,
    InviteFormComponent,
    ContestLinkComponent,
    BlogPostsComponent,
    BlogPostComponent,
    AdBannerComponent,
    FontSizeButtonsComponent,
    AdsenseAdComponent,
    ImageAdComponent,
    TextAdComponent
];

@NgModule({
    declarations,
    exports: declarations,
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        YouTubePlayerModule,
        MatGridListModule,
        MatTabsModule,
        NgOptimizedImage
    ],
    providers: [
        ChatDatePipe,
        SexLabelPipe,
        DateDiffPipe,
        IsOnlinePipe,
        HighlightTextPipe,
        UsernamePipe,
        LocationBackDirective,
        GaTrackEventDirective
    ]
})
export class SharedModule {
}
