import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { FeedRecordType, QuestionModelWithTag, UserProfileFeedRecordDto, UserSettingsViewModel } from '@core/models';
import { gridRowAnimation, voidAnimation } from '@core/animations';
import { WINDOW } from '@core/window';
import { getFeedPageUrlCommands, getFeedQuestionPageUrlCommands, getFeedSettingsPageUrlCommands } from '@core/utils';
import { messages } from '@core/messages';
import { FeedPageTabsFragments } from '@core/enums';


@Component({
    selector: 'app-user-profile-feed-record',
    templateUrl: './user-profile-feed-record.component.html',
    styleUrls: ['./user-profile-feed-record.component.scss'],
    animations: [gridRowAnimation, voidAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileFeedRecordComponent {

    readonly feedRecordTypes = FeedRecordType;

    @Input()
    isExpanded = false;

    @Input()
    isCurrentUser: boolean;

    @Input()
    recordType: FeedRecordType;

    @Input()
    data: UserSettingsViewModel | QuestionModelWithTag;

    @Output()
    click$ = new EventEmitter<UserProfileFeedRecordDto>();

    @Output()
    delete$ = new EventEmitter<{ id: number, recordType: FeedRecordType }>();

    @ViewChild('menuTrigger')
    trigger: MatMenuTrigger;

    get editRoute(): any[] {
        if (FeedRecordType.question === this.recordType) {
            return getFeedQuestionPageUrlCommands(this.data.tag, this.data.id);
        } else {
            return getFeedSettingsPageUrlCommands((this.data as UserSettingsViewModel).role, this.data.tag);
        }

    }

    get feedRoute(): any[] {
        return getFeedPageUrlCommands(this.data.tag);
    }

    get feedRouteFragment(): FeedPageTabsFragments {
        switch (this.recordType) {
            case FeedRecordType.question:
                return FeedPageTabsFragments.questions;
            case FeedRecordType.canHelpSetting:
                return FeedPageTabsFragments.canHelp;
            case FeedRecordType.needHelpSetting:
                return FeedPageTabsFragments.needHelp;
        }
    }

    constructor(@Optional() @Inject(WINDOW) private window: Window) {
    }

    onDelete($event) {
        $event.stopPropagation();
        let confirmation: string;
        if (this.recordType === FeedRecordType.question) {
            confirmation = messages.feedQuestion.deleteConfirmation;
        } else {
            confirmation = messages.profile.deleteFeedSettingConfirmation(
                (this.data as UserSettingsViewModel).role,
                (this.data as UserSettingsViewModel).tag
            );
        }
        if (this.window.confirm(confirmation)) {
            this.delete$.emit({
                id: this.data.id,
                recordType: this.recordType
            });
        }
    }

    isMenuOpen(): boolean {
        if (!this.trigger) {
            return false;
        }

        return this.trigger.menuOpen;
    }
}
