import { Inject, Injectable, Optional } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, take, tap } from 'rxjs/operators';

import { RoiApiService } from '@core/services';
import { CaregiversSupportingCaregiversActions, OrganizationLandingPageActions, LearnWithRegistrationActions } from '@core/store/landing';
import { AuthApiActions, RegistrationPageActions, RegistrationSocialPageActions } from '@core/store/auth/actions';
import { WelcomePageActions } from '@core/store/onboarding/actions';
import { AboutUsPageActions, AppInitActions } from '@core/store/actions';
import { WINDOW } from '@core/window';


@Injectable()
export class RoiApiEffects {
    sendEmailSubmitted$ = createEffect(() => this.actions$.pipe(
        ofType(
            OrganizationLandingPageActions.emailSubmitted,
            CaregiversSupportingCaregiversActions.emailSubmitted,
            LearnWithRegistrationActions.emailSubmitted,
            WelcomePageActions.emailSubmitted,
            AboutUsPageActions.emailSubmitted,
            RegistrationPageActions.emailSubmitted,
            RegistrationSocialPageActions.emailSubmitted,
        ),
        tap(({email, location}) => this.rioApiService.sendEmailSubmitted(email, location).subscribe())
    ), {dispatch: false});

    sendRegistered$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.registrationSucceeded,
            AuthApiActions.registrationWithSocialNetworkSucceeded,
        ),
        tap(({email, username, location}) => this.rioApiService.sendRegistered(email, username, location).subscribe())
    ), {dispatch: false});

    sendCampaignClick$ = createEffect(() => this.actions$.pipe(
        ofType(AppInitActions.setCampaignSessionKey),
        filter((campaign) => !!campaign),
        take(1),
        tap(({campaign}) => {
            this.rioApiService.sendCampaignClick(campaign, this.window?.document.referrer).subscribe();
        })
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private rioApiService: RoiApiService,
        @Optional() @Inject(WINDOW) private window
    ) {
    }
}
