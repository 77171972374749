import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { ResourceModel } from '@core/models';
import { ResourcesService } from '@core/services/resources/resources.service';
import { ResourcesMenuActions, ResourcesApiActions } from '@core/store/resources/actions';


export interface State extends EntityState<ResourceModel> {
    page: number;
    isLoading: boolean;
    hasMore: boolean;
    error: any;
}

export const adapter = createEntityAdapter<ResourceModel>({
    selectId: (entity) => entity.id
});

export const initialState: State = adapter.getInitialState({
    page: 1,
    isLoading: false,
    hasMore: true,
    error: null
});

const NationalResourcesReducer = createReducer(
    initialState,
    on(ResourcesMenuActions.initNationalResources, () => initialState),
    on(ResourcesMenuActions.loadNationalResources, (state) => ({
        ...state,
        isLoading: true
    })),
    on(ResourcesApiActions.loadNationalResourcesFailed, (state, error) => ({
        ...state,
        error,
        isLoading: false,
        hasMore: false
    })),
    on(ResourcesApiActions.loadNationalResourcesSucceeded, (state, {resources}) =>
        adapter.upsertMany(resources, {
            ...state,
            error: null,
            page: state.page + 1,
            hasMore: resources.length === ResourcesService.pageLimit
        })
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return NationalResourcesReducer(state, action);
}

export const {
    selectAll: getNationalResources
} = adapter.getSelectors();

export const getHasMore = (state: State) => state.hasMore;
export const getPage = (state: State) => state.page;
export const getIsLoading = (state: State) => state.isLoading;
