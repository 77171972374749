<ng-container *ngIf="recordTypes.question === recordType; else forSettings">
    <div class="user-input">{{ data.text }}</div>
</ng-container>
<ng-template #forSettings>
    <div class="user-input" *ngIf="data?.about">{{ data.about }}</div>
    <div class="generated" *ngIf="data?.experience">
        I care(d) a total of
        <span class="color-primary">{{data.experience | careExperience:data.experiencePeriod }}</span>
        for a
        <span class="genders">
        <span
            *ngFor="let gender of getGenders(data.isForMan, data.isForWoman, data.isForNonBinary)"
            class="{{gender}} color-primary gender"
        >{{gender | sexLabel:data.tag.isDiagnosis}}</span>
        </span>
    </div>
</ng-template>
