<app-layout-auth>
    <ng-container *ngIf="!afterReset">
        <h2 class="mat-title">Member Login</h2>
        <ng-container *appSocialNetworksAvailable>
            <app-social-buttons
                [isSignIn]="true"
                (singedIn)="onSignedIn($event)"
            ></app-social-buttons>
            <div class="separator-with-text">or</div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="afterReset">
        <h2 class="mat-title">
            Success!<br>
            <span class="mat-body-strong">
                Your password has been updated
            </span>
            <br>
            <span class="mat-body">
                You can find your username in the original email
            </span>
        </h2>
    </ng-container>
    <app-login-form
        (submitted)="onSubmit($event)"
        [error]="error$ | async"
        [isPending]="isPending$ | async"
    ></app-login-form>
</app-layout-auth>
