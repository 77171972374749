import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TagModel } from '@core/models';


@Component({
    selector: 'app-parent-tag-selection',
    templateUrl: './parent-tag-selection.component.html',
    styleUrls: ['./parent-tag-selection.component.scss']
})
export class ParentTagSelectionComponent {
    selectedTagId: number = null;

    @Input()
    tagName: string;

    @Input()
    tags: TagModel[];

    @Output()
    selected$ = new EventEmitter<TagModel>();

    @Output()
    closed$ = new EventEmitter<any>();

    @Output()
    back$ = new EventEmitter<any>();

    selectTag(tagId: number) {
        this.selectedTagId = tagId;
    }

    onSelect() {
        this.selected$.emit(this.tags.find(tag => tag.id === this.selectedTagId));
    }
}
