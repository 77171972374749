import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { PublicUserModel, UserModel } from '@core/models';


@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent {
    @Input()
    user: UserModel | PublicUserModel;

    @Output()
    fileSelected = new EventEmitter<Blob>();

    @ViewChild('file', {static: true})
    fileInput: ElementRef<HTMLInputElement>;

    onClick() {
        this.fileInput.nativeElement.click();
    }

    onChange() {
        if (!this.fileInput.nativeElement.files || !this.fileInput.nativeElement.files.length) {
            return;
        }
        this.fileSelected.emit(this.fileInput.nativeElement.files[0]);
        this.fileInput.nativeElement.value = '';
    }
}
