import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthService, CookieService } from '@core/services';
import {
    BlockedPopupComponent,
    ForgotPasswordConfirmationComponent,
    ForgotPasswordFormComponent,
    LoginFormComponent,
    RegistrationFormComponent,
    ResetPasswordFormComponent,
    UserPersonalInfoFormComponent,
} from 'src/app/pages/auth/components';
import {
    ForgotPasswordComponent,
    LoginComponent,
    RegistrationComponent,
    RegistrationErrorComponent,
    RegistrationSocialComponent,
    ResetPasswordComponent
} from 'src/app/pages/auth/containers';
import { AuthEffects } from '@core/store/auth/effects';
import { reducers } from '@core/store/auth/reducers';
import { SharedModule } from '@shared/shared.module';
import { UserModule } from '@pages/user/user.module';
import { CoreModule } from '@core/core.module';


@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        RegistrationComponent,
        LoginFormComponent,
        ForgotPasswordFormComponent,
        RegistrationFormComponent,
        ForgotPasswordConfirmationComponent,
        ResetPasswordComponent,
        ResetPasswordFormComponent,
        BlockedPopupComponent,
        RegistrationSocialComponent,
        RegistrationErrorComponent,
        UserPersonalInfoFormComponent,
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        StoreModule.forFeature('auth', reducers),
        EffectsModule.forFeature([AuthEffects]),
        RecaptchaModule,
        RecaptchaFormsModule,
        UserModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        CoreModule
    ],
    providers: [
        AuthService,
        CookieService
    ]
})
export class AuthModule {
}
