import { filter, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ApiErrorInterceptorAction, AppInitActions } from '@core/store/actions';
import * as fromRoot from '@core/store/reducers';


export function initApp(store: Store<fromRoot.State>, actions: Actions): Promise<void> {
    return new Promise<void>(resolve => {
        store.dispatch(AppInitActions.startAppInit());
        store.pipe(
            select(fromRoot.getRouteQueryParams),
            filter((queryParams): boolean => !!queryParams),
            take(1)
        ).subscribe((queryParams) => {
            if (queryParams.campaign_id) {
                store.dispatch(AppInitActions.setCampaignSessionKey({campaign: queryParams.campaign_id}));
            }
        });
        store.dispatch(AppInitActions.loadSettings());
        store.dispatch(AppInitActions.checkAuth());
        store
            .pipe(
                select(fromRoot.getAppInitIsCompleted),
                filter((isLoaded): boolean => isLoaded),
                take(1),
                takeUntil(actions.pipe(ofType(ApiErrorInterceptorAction.errorIntercepted)))
            )
            .subscribe(() => {
                store.dispatch(AppInitActions.finishAppInit());
                resolve();
            });
    });
}

export function appInitializer(store: Store<fromRoot.State>, actions: Actions) {
    return () => {
        return new Promise((resolve) => {
            initApp(store, actions).then((value) => resolve(value));
            actions.pipe(
                ofType(ApiErrorInterceptorAction.errorIntercepted),
                take(1)
            ).subscribe((value) => resolve(value));
        });
    };
}
