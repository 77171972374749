import { Action, createReducer, on } from '@ngrx/store';

import { InitialSettingsApiActions } from '@core/store/actions';
import { FeatureFlags } from '@core/models';


export interface State {
    isLoaded: boolean;
    flags: FeatureFlags;
}

export const initialState: State = {
    isLoaded: false,
    flags: null
};

const FeatureFlagsReducer = createReducer(
    initialState,
    on(InitialSettingsApiActions.loadSucceeded, (state, {featureFlags}): State => ({
            isLoaded: true,
            flags: {...featureFlags}
        })
    ),
    on(InitialSettingsApiActions.loadFailed, (): State => ({
            isLoaded: true,
            flags: null
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return FeatureFlagsReducer(state, action);
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getFeatureFlags = (state: State) => state.flags;
