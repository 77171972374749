import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SearchTagModel, TagWithChildrenModel, UsersStatsModel } from '@core/models';


@Component({
    selector: 'app-expandable-tag',
    templateUrl: './expandable-tag.component.html',
    styleUrls: ['./expandable-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('toggleTag', [
            state('collapsed', style({height: 0})),
            state('expanded', style({height: '*'})),
            transition('collapsed <=> expanded', animate('0.3s'))
        ])
    ]
})
export class ExpandableTagComponent {

    highlightedText: string;

    isExpanded: boolean;

    @Input()
    showStats: boolean;

    @Input()
    tag: SearchTagModel | TagWithChildrenModel;

    @Input()
    statToShow: keyof UsersStatsModel;

    @Input()
    set highlightedTextInput(text: string) {
        this.highlightedText = text;
    }

    @Input()
    set tagIsExpanded(expanded: boolean) {
        if (this.highlightedText && this.hasChildren) {
            this.isExpanded = true;
            return;
        }
        this.isExpanded = expanded;
    }

    @Output()
    clicked$ = new EventEmitter<SearchTagModel | TagWithChildrenModel>();

    @Output()
    toggleExpanded$ = new EventEmitter<number>();

    get hasChildren(): boolean {
        return !!(this.tag.child && this.tag.child.length);
    }

    expand() {
        this.isExpanded = true;
        this.toggleExpanded$.emit(this.tag.id);
    }

    collapse() {
        this.isExpanded = false;
        this.toggleExpanded$.emit(null);
    }
}
