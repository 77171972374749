import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { processTagApiModels, TagApiModel, TagDescriptionModel, TagModel } from '@core/models';


export const TAGS_KEY = makeStateKey<TagModel[]>('tags');

@Injectable({
    providedIn: 'root'
})
export class TagsService {

    readonly prefix = '/tags';

    constructor(
        private http: HttpClient,
        private transferState: TransferState
    ) {
    }

    getTags(): Observable<TagModel[]> {
        let tags = this.transferState.get<TagModel[]>(TAGS_KEY, null as TagModel[]);

        if (tags) {
            return of(tags);
        }

        return this.http.get<TagApiModel[]>(this.prefix).pipe(
            map((response) => {
                tags = processTagApiModels(response);
                this.transferState.set<TagModel[]>(TAGS_KEY, tags);
                return tags;
            })
        );
    }

    getDescription(id: number): Observable<TagDescriptionModel> {
        const transferStateKey: StateKey<TagDescriptionModel> = makeStateKey<TagDescriptionModel>(`tagDescription-${id}`);
        const fromState: TagDescriptionModel = this.transferState.get(transferStateKey, null);
        if (fromState) {
            return of(fromState);
        }
        return this.http.get<TagDescriptionModel>(`${this.prefix}/${id}/description`)
            .pipe(tap((response) => {
                this.transferState.set(transferStateKey, response);
            }));
    }
}
