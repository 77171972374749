import { Action, createReducer, on } from '@ngrx/store';

import { NewTagRequestPopupActions } from '@core/store/onboarding/actions';
import { NewTagRequestPopupStep } from '@shared/containers/new-subject-request/new-tag-requestp-popup-step';


export interface State {
    step: NewTagRequestPopupStep;
    searchCriteria: string;
}

export const initialState: State = {
    step: null,
    searchCriteria: null
};

const NewTagRequestPopupReducer = createReducer(
    initialState,
    on(NewTagRequestPopupActions.init,
        (state, {searchCriteria}): State => ({
            ...state,
            searchCriteria,
            step: NewTagRequestPopupStep.searchExistingTag
        })),
    on(NewTagRequestPopupActions.stepChanged, (state, {step}): State => ({
        ...state,
        step,
        searchCriteria: null
    })),
    on(NewTagRequestPopupActions.searched, (state, {criteria}): State => ({
        ...state,
        searchCriteria: criteria
    })),
    on(NewTagRequestPopupActions.searchCleared, (state): State => ({
        ...state,
        searchCriteria: null
    })),
    on(NewTagRequestPopupActions.destroyed, (): State => initialState),
);

export function reducer(state: State | undefined, action: Action): State {
    return NewTagRequestPopupReducer(state, action);
}

export const getStep = (state: State): NewTagRequestPopupStep => state.step;
export const getSearchCriteria = (state: State) => state.searchCriteria;
