import { createAction, props } from '@ngrx/store';

import { TagDescriptionModel } from '@core/models';


export const prefix = '[Tag Description Guard]';

export const loadSucceeded = createAction(
    `${prefix} load succeeded`,
    props<{ description: TagDescriptionModel }>()
);
