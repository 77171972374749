import { Directive, HostListener, Input } from '@angular/core';
import { GoogleAnalyticsService } from '@core/services';


@Directive({
    selector: '[appGaTrackEvent]'
})
export class GaTrackEventDirective {

    @Input()
    gaEvent: string;

    @Input()
    gaEventCategory?: string;

    @Input()
    gaEventLabel?: string;

    @Input()
    gaEventValue?: number;

    @HostListener('click')
    onClick() {
        if (!this.gaEvent) {
            throw new Error('gaEvent is required');
        }
        this.googleAnalyticsService.trackEvent(this.gaEvent, this.gaEventCategory, this.gaEventLabel, this.gaEventValue);
    }

    constructor(private googleAnalyticsService: GoogleAnalyticsService) {
    }

}
