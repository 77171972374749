import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BlogApiService } from '@core/services';
import { BlogApiActions } from '@core/store/actions';
import { GetStartedPageActions } from '@core/store/feed/actions';
import { WelcomePageActions } from '@core/store/onboarding/actions';


@Injectable({
    providedIn: 'root'
})
export class BlogPostsEffects {
    search$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetStartedPageActions.init, WelcomePageActions.init),
            switchMap(() => {
                return this.blogApiService.searchPosts('').pipe(
                    map((response) => BlogApiActions.loadBlogPostsSucceeded({posts: response})),
                    catchError((error) => of(BlogApiActions.loadBlogPostsFailed({error: error.error})))
                );
            })
        );
    });
    constructor(
        private actions$: Actions,
        private blogApiService: BlogApiService
    ) {
    }
}
