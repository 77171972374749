import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromConversations from '@core/store/conversations';
import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import { ConversationsService } from '@core/services';
import { ConversationsApiActions, PrivateChatPageActions } from '@core/store/conversations/actions';
import { UserModel } from '@core/models';


@Injectable()
export class ConversationsApiEffects {

    readonly chatHistoryLimit = 100;

    private currentUser: UserModel;

    loadChatHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PrivateChatPageActions.pageInit,
                PrivateChatPageActions.loadChatHistory
            ),
            withLatestFrom(
                this.store.pipe(select(fromRoot.getConversationInterlocutorId)),
                this.store.pipe(select(fromConversations.getPrivateChatMessagesIds))
            ),
            exhaustMap(([, userId, ids]) => {
                return this.conversationsService.getHistory(
                    userId,
                    this.chatHistoryLimit,
                    ids.length ? -1 : 1,
                    ids.length ? +ids[0] : 0
                ).pipe(
                    map((messages) => {
                        return ConversationsApiActions.loadMessagesSucceeded({
                            messages,
                            hasMore: messages.length === this.chatHistoryLimit
                        });
                    }),
                    catchError((error) => of(ConversationsApiActions.loadMessagesFailed({error: error.error})))
                );
            })
        );
    });

    sendMessage$ = createEffect(() => this.actions$.pipe(
        ofType(
            PrivateChatPageActions.sendMessage
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getConversationInterlocutorId))),
        concatMap(([{text}, id]) => {
            return this.conversationsService.sendMessage(id, text).pipe(
                map((result) => ConversationsApiActions.sendMessageSucceeded(result)),
                catchError(({error}) => of(ConversationsApiActions.sendMessageFailed(error)))
            );
        })
    ));

    replyOnMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PrivateChatPageActions.replyOnMessage),
            withLatestFrom(this.store.pipe(select(fromRoot.getConversationInterlocutorId))),
            concatMap(([{replyOnId, text, replyOnType}, id]) => {
                return this.conversationsService.replyOnMessage(id, replyOnId, text, replyOnType).pipe(
                    map((result) => ConversationsApiActions.replyOnMessageSucceeded(result)),
                    catchError(({error}) => of(ConversationsApiActions.replyOnMessageFailed(error)))
                );
            })
        );
    });

    deleteMessage$ = createEffect(() => this.actions$.pipe(
        ofType(PrivateChatPageActions.deleteMessage),
        mergeMap(({id}) => this.conversationsService.deleteMessage(id)
            .pipe(
                map(() => ConversationsApiActions.deleteMessageSucceeded({id})),
                catchError(({error}) => of(ConversationsApiActions.deleteMessageFailed(error)))
            )
        )
    ));

    editMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PrivateChatPageActions.editMessage),
            mergeMap(({conversationId, messageId, text}) => {
                return this.conversationsService.editMessage(messageId, text).pipe(
                    map(() => ConversationsApiActions.editMessageSucceeded({
                            conversationId,
                            messageUpdate: {
                                id: messageId,
                                changes: {
                                    text,
                                    isEdited: true
                                }
                            }
                        })
                    ),
                    catchError(({error}) => of(ConversationsApiActions.editMessageFailed(error)))
                );
            })
        );
    });

    markMessageAsRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PrivateChatPageActions.markMessagesAsRead),
            mergeMap(({messageId, conversationId}) => {
                return this.conversationsService.markMessageAsRead(messageId).pipe(
                    map(() => ConversationsApiActions.markMessageAsReadSucceeded({messageId, conversationId})),
                    catchError(({error}) => of(ConversationsApiActions.markMessageAsReadFailed(error)))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private conversationsService: ConversationsService
    ) {
        this.store
            .pipe(select(fromCurrentUser.getUser))
            .subscribe((user: UserModel) => this.currentUser = user);
    }
}
