import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { PasswordFormatValidator, PasswordsMatchValidator } from '@shared/form-validators';


@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent {
    isPasswordHidden = true;
    isRePasswordHidden = true;

    form = new UntypedFormGroup({
        password: new UntypedFormControl('', Validators.compose([
            Validators.required,
            PasswordFormatValidator.validate
        ])),
        rePassword: new UntypedFormControl('', Validators.required)
    }, PasswordsMatchValidator.validate);

    isSubmitted = false;

    @Input()
    isError: boolean;

    @Input()
    set isPending(isPending: boolean) {
        if (isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    @Output()
    submitted = new EventEmitter<{ password: string }>();

    submit() {
        if (this.form.valid) {
            this.isSubmitted = true;
            this.submitted.emit({password: this.form.get('password').value});
        }
    }

}
