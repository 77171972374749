import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { BlogPostModel } from '@core/models';
import { AppInitActions, BlogApiActions } from '@core/store/actions';
import { GetStartedPageActions } from '@core/store/feed/actions';


export const featureKey = 'blogPosts';

export interface State extends EntityState<BlogPostModel> {
    isLoading: boolean;
    error: string;
}

export const getEntityId = (model: BlogPostModel): string => model.url;

export const adapter = createEntityAdapter<BlogPostModel>({
    selectId: getEntityId
});

export const initialState: State = adapter.getInitialState({
    isLoading: true,
    error: null
});

const BlogPostsReducer = createReducer(
    initialState,
    on(
        AppInitActions.loadSettings,
        GetStartedPageActions.init,
        () => ({
            ...initialState
        })),
    on(BlogApiActions.loadBlogPostsSucceeded, (state, { posts }) => {
        return adapter.addMany(posts, {
            ...state,
            isLoading: false,
            error: null
        });
    }),
    on(BlogApiActions.loadBlogPostsFailed, (state, { error }) => ({
        ...state,
        isLoading: false,
        error
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return BlogPostsReducer(state, action);
}

export const {
    selectAll: getPosts
} = adapter.getSelectors();

export const getIsLoading = (state) => state.isLoading;
