import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import * as fromCurrentUser from '@core/store/current-user';
import { VideoContestService } from '@core/services';
import { VideoContestAgreementGuardActions } from '@core/store/video-contest/actions';


@Injectable({
    providedIn: 'root'
})
export class VideoContestAgreementGuard implements CanActivate {

    private get agreementPageUrl(): UrlTree {
        return this.router.parseUrl('/me/video-contest/agreement');
    }

    private get newVideoPageUrl(): UrlTree {
        return this.router.parseUrl('/me/video-contest/new');
    }

    private resolve(isAgreementSigned: boolean, isForAgreementPage: boolean): boolean | UrlTree {
        if (isAgreementSigned) {
            return isForAgreementPage ? this.newVideoPageUrl : true;
        } else {
            return isForAgreementPage ? true : this.agreementPageUrl;
        }
    }

    private checkInStore(): Observable<boolean> {
        return this.store.pipe(select(fromCurrentUser.getIsVideoContestAgreementSigned));
    }

    constructor(
        private store: Store<fromCurrentUser.State>,
        private router: Router,
        private videoContestService: VideoContestService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        const isForAgreementPage = route.data?.agreementGuardIsForAgreementPage;
        return this.checkInStore().pipe(
            exhaustMap((isAgreementSigned) => {
                if (null !== isAgreementSigned) {
                    return of(this.resolve(isAgreementSigned, isForAgreementPage));
                } else {
                    return this.videoContestService.isAgreementSigned().pipe(
                        map(({videoContestAgreement}) => {
                            this.store.dispatch(VideoContestAgreementGuardActions.agreementStatusLoaded({
                                isAgreementSigned: videoContestAgreement
                            }));
                            return this.resolve(videoContestAgreement, isForAgreementPage);
                        }),
                        catchError(() => {
                            return of(false);
                        })
                    );
                }
            })
        );
    }
}
