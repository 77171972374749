import { createAction, props } from '@ngrx/store';

import { PublicUserModel, TagModel, UserRole } from '@core/models';


export const prefix = '[Feed page]';

export const pageInit = createAction(
    `${prefix} page init`,
    props<{ tagId: number }>()
);

export const activeTabChanged = createAction(
    `${prefix} active tab changed`,
    props<{ role: UserRole }>()
);

export const followUser = createAction(
    `${prefix} follow user`,
    props<{ user: PublicUserModel }>()
);

export const unFollowUser = createAction(
    `${prefix} un follow user`,
    props<{ user: PublicUserModel }>()
);

export const changeRolePopupShown = createAction(`${prefix} change role popup shown`);

export const changeRolePopupClosed = createAction(`${prefix} change role popup closed`);
