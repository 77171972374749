import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { messages } from '@core/messages';
import { EmailUniqueValidator, EmailValidator, UsernameFormatValidator, UsernameUniqueValidator } from '@shared/form-validators';
import { SocialRegistrationModel } from '@core/models';


@Component({
    selector: 'app-auth-user-personal-info-form',
    templateUrl: './user-personal-info-form.component.html',
    styleUrls: ['./user-personal-info-form.component.scss']
})
export class UserPersonalInfoFormComponent {
    readonly hints = messages.form.hints;

    isEmailTaken = false;

    isUsernameTaken = false;

    @Input()
    disabled = false;

    @Input()
    set model(model: SocialRegistrationModel) {
        if (!model) {
            return;
        }
        this.form.patchValue(model);
    }

    @Input()
    set usernameTakenError(input) {
        if (!input) {
            return;
        }
        this.isUsernameTaken = true;
        this.form.get('username').setErrors({usernameUnique: true});
        this.form.get('username').markAsDirty();
    }

    @Input()
    set emailTakenError(input) {
        if (!input) {
            return;
        }
        this.isEmailTaken = true;
        this.form.get('email').setErrors({emailUnique: true});
        this.form.get('email').markAsDirty();
    }

    @Output()
    submit$ = new EventEmitter<{ username: string, email: string }>();

    form: UntypedFormGroup = new UntypedFormGroup({
        username: new UntypedFormControl(
            '',
            {
                updateOn: 'change',
                validators: Validators.compose([
                    Validators.required,
                    UsernameFormatValidator.validate,
                    (): ValidationErrors | null => {
                        return this.isUsernameTaken ? {usernameUnique: true} : null;
                    }
                ]),
                asyncValidators: this.usernameUniqueValidator.getValidator()
            },
        ),
        email: new UntypedFormControl(
            '',
            {
                updateOn: 'change',
                validators: Validators.compose([
                    Validators.required,
                    EmailValidator.validate,
                    (): ValidationErrors | null => {
                        return this.isEmailTaken ? {emailUnique: true} : null;
                    }
                ]),
                asyncValidators: this.emailUniqueValidator.getValidator()
            }),
    });

    constructor(
        private usernameUniqueValidator: UsernameUniqueValidator,
        private emailUniqueValidator: EmailUniqueValidator
    ) {
    }

    submit() {
        if (this.form.valid) {
            this.submit$.emit(this.form.value);
        }
    }
}
