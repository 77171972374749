export enum RoiLocation {
    learnAbout = 'learn_about',
    connectingCaregivers = 'connecting_caregivers',
    connectingCaregivers2 = 'connecting_caregivers_2',
    connectingCaregiversCaringkind = 'connecting_caregivers_caringkind',
    connectingCaregiversCaringkind2 = 'connecting_caregivers_caringkind_2',
    organizationForAutismResearch = 'organization_for_autism_research',
    organizationCaringkind  = 'organization_caringkind',
    organizationSdvcFamilyLifeActionGroup = 'organization_sdvc_family_life_action_group',
    organizationMusicCareInc = 'organization_music_care_inc',
    organizationOar = 'organization_oar',
    organizationSdvcPhysicalAndEmotionalHealthGroup = 'organization_sdvc_physical_and_emotional_health_group',
    organizationStartout  = 'organization_startout',
    organizationSdvc = 'organization_sdvc',
    organizationHelpingPaws = 'organization_helping_paws',
    organizationPsychArmor = 'organization_psycharmor',
    organizationMapHabit = 'organization_maphabit',
    organizationSouthernCaregiverResourceCenter = 'organization_southern_caregiver_resource_center',
    organizationAutismTreeProjectFoundation = 'organization_autism_tree_project_foundation',
    organizationAutismSociety = 'organization_autism_society',
    organizationSoldiersAngels = 'organization_soldiers_angels',
    organizationNoLimitsMedia = 'organization_no_limits_media',
    organizationSdvcEducationEmploymentGroup = 'organization_sdvc_education_employment_entrepreneurship_e3_action_group',
    organizationSdvcVestActionGroup = 'organization_sdvc_vest_action_group',
    organizationCohenVeteransNetwork = 'organization_cohen_veterans_network',
    organizationOutburo = 'organization_outburo',
    organizationOneMoreWave = 'organization_one_more_wave',
    organizationElizabethHospice = 'organization_elizabeth_hospice',
    assistedByNicoleDauz = 'assisted-by-nicole-dauz',
    organizationMisterBAndB = 'organization_mister_b_and_b',
    organizationRhodesPerryConsulting = 'organization_rhodes_perry_consulting',
    organizationWoundedWarriorProject = 'organization_wounded_warrior_project',
    organizationHiddenHeroes = 'organization_hidden_heroes',
    organizationSteward = 'organization_steward',
    extendatouchCaregiversSupportingCaregivers = 'extendatouch_caregivers_supporting_caregivers',
    join = 'join',
    home = 'home',
    aboutUs = 'about_us'
}
