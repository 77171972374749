export interface CallInviteModel {
    id: number;
    sdp: string;
    userId: number;
    isRead: boolean;
    createdOn: string;
}

export enum CallLocalStatus {
    'inviteSent' = 'INVITE_SENT',
    'inviteReceived' = 'INVITE_RECEIVED',
    'acceptSent' = 'ACCEPT_SENT',
    'acceptReceived' = 'ACCEPT_RECEIVED',
}
