<ng-container *ngIf="ad" [ngSwitch]="ad.type">
    <app-adsense-ad
        *ngSwitchCase="'adSense'"
        [ad]="ad"
    >
    </app-adsense-ad>
    <app-image-ad
        *ngSwitchCase="'image'"
        [ad]="ad"
    ></app-image-ad>
    <app-text-ad
        *ngSwitchCase="'text'"
        [ad]="ad"
    ></app-text-ad>
</ng-container>
