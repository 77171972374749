import { Inject, Injectable, Optional } from '@angular/core';

import { WINDOW } from '@core/window';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})

export class GoogleAnalyticsService {

    readonly src = 'https://www.googletagmanager.com/gtag/js?id=';

    readonly isInit: boolean = false;

    private lastTrackedUri: string;

    private loadScript() {
        const script = (this.window as Window).document.createElement<'script'>('script');
        script.async = true;
        script.defer = true;
        script.src = `${this.src}${environment.gaMeasurementId}`;
        (this.window as Window).document.getElementsByTagName<'head'>('head')[0].append(script);
        return this;
    }

    private initHandler() {
        this.window.dataLayer = this.window.dataLayer || [];
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        this.window.gtag = function() {
            (window as any).dataLayer.push(arguments);
        };
        (window as any).gtag('js', new Date());
    }

    constructor(@Optional() @Inject(WINDOW) private window) {
        if (this.window && environment.gaMeasurementId) {
            this.loadScript()
                .initHandler();
            this.isInit = true;
        }
    }

    trackPageView(uri: string) {
        if (!this.isInit || this.lastTrackedUri === uri) {
            return;
        }
        this.lastTrackedUri = uri;
        (window as any).gtag('config', environment.gaMeasurementId, {
            page_path: uri
        });
    }

    trackEvent(event: string, category?: string, label?: string, value?: number) {
        if (!this.isInit || !event) {
            return;
        }
        (window as any).gtag('event', event, {
            event_category: category,
            event_label: label,
            value,
        });
    }
}
