import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-unauth-popup',
    templateUrl: './un-auth-popup.component.html',
    styleUrls: ['./un-auth-popup.component.scss']
})
export class UnAuthPopupComponent {
    readonly actions = {
        join: 'join',
        login: 'login',
        about: 'about',
    };

    constructor(
        private matDialog: MatDialog,
        private router: Router
    ) {
    }

    close(action: string) {
        this.matDialog.closeAll();
        switch (action) {
            case this.actions.join:
                this.router.navigate(['/auth/registration']);
                break;
            case this.actions.login:
                this.router.navigate(['/auth/login'], {queryParams: {redirectTo: this.router.url}});
                break;
            case this.actions.about:
                this.router.navigateByUrl('/about');
                break;
        }
    }
}
