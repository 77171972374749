import { createAction, props } from '@ngrx/store';

import { PublicUserProfileFlattenModel, UserBlockingReason } from '@core/models';


export const prefix = '[Public User Profile Guard]';

export const guardTriggered = createAction(`${prefix} guard triggered`);

export const userProfileLoadSucceeded = createAction(
    `${prefix} public user profile load succeeded`,
    props<PublicUserProfileFlattenModel>()
);

export const userProfileBlocked = createAction(
    `${prefix} public user profile blocked`,
    props<{ reason: UserBlockingReason, username: string }>()
);
