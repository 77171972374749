import { createAction, props } from '@ngrx/store';

import {
    AfterFlattenUserProfileApiModel,
    Geolocation,
    Preferences,
    PublicUserModel,
    QuestionModel,
    ServerErrorModel,
    UserPersonalInfoModel,
    UserSettingsModel
} from '@core/models';
import { Update } from '@ngrx/entity';


export const prefix = '[User Api]';

export const saveHelpSettingsSucceeded = createAction(
    `${prefix} save help settings succeeded`,
    props<{ settings: UserSettingsModel }>()
);

export const createQuestionSucceeded = createAction(
    `${prefix} create question succeeded`,
    props<{ question: QuestionModel }>()
);

export const createQuestionFailed = createAction(
    `${prefix} create question failed`,
    props<{ error: string }>()
);

export const editQuestionSucceeded = createAction(
    `${prefix} edit question succeeded`,
    props<{ id: number, text: string, updatedOn: string }>()
);

export const editQuestionFailed = createAction(
    `${prefix} edit question failed`,
    props<{ error: string }>()
);

export const deleteQuestionSucceeded = createAction(
    `${prefix} delete question succeeded`,
    props<{ id: number }>()
);

export const deleteQuestionFailed = createAction(
    `${prefix} delete question failed`,
    props<{ error: string }>()
);

export const saveSettingsFailed = createAction(
    `${prefix} save settings failed`,
    props<{ error: any }>()
);

export const deleteSettingsSucceeded = createAction(
    `${prefix} delete settings succeeded`,
    props<{ id: number }>()
);

export const deleteSettingsFailed = createAction(
    `${prefix} delete settings failed`,
    props<{ error: any }>()
);

export const userProfileLoadSucceeded = createAction(
    `${prefix} user profile load succeeded`,
    props<AfterFlattenUserProfileApiModel>()
);

export const userProfileLoadFailed = createAction(
    `${prefix} user profile load failed`,
    props<{ error: any }>()
);

export const changingPasswordSucceeded = createAction(
    `${prefix} changing password succeeded`,
    props<{ token: string }>()
);

export const changingPasswordFailed = createAction(
    `${prefix} changing password failed`,
    props<{ error: ServerErrorModel }>()
);

export const updatingPersonalInfoSucceeded = createAction(
    `${prefix} user personal info updating succeeded`,
    props<UserPersonalInfoModel>()
);

export const updatingPersonalInfoFailed = createAction(
    `${prefix} user personal info updating failed`,
    props<{ error: ServerErrorModel }>()
);

export const updateGeolocationSucceeded = createAction(
    `${prefix} update geolocation succeeded`,
    props<{ geolocation: Geolocation }>()
);

export const updateGeolocationFailed = createAction(
    `${prefix} update geolocation failed`,
    props<{ error: any }>()
);

export const deleteGeolocationSucceeded = createAction(
    `${prefix} delete geolocation succeeded`
);

export const deleteGeolocationFailed = createAction(
    `${prefix} delete geolocation failed`,
    props<{ error: any }>()
);

export const updateDistancesSucceeded = createAction(
    `${prefix} update distances succeeded`,
    props<{ updates: Update<PublicUserModel>[] }>()
);

export const updateDistancesFailed = createAction(
    `${prefix} update distances failed`,
    props<{ error: any }>()
);

export const updatePreferencesSucceeded = createAction(
    `${prefix} update preferences succeeded`,
    props<{ preferences: Preferences }>()
);

export const updatePreferencesFailed = createAction(
    `${prefix} update preferences failed`,
    props<{ error: string }>()
);
