import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { InitialSettingsService } from '@core/services';
import { AppInitActions, InitialSettingsApiActions } from '@core/store/actions';


@Injectable()
export class InitialSettingsApiEffects {

    loadSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppInitActions.loadSettings),
            exhaustMap(() => {
                return this.appSettingsService.get().pipe(
                    map((settings) => InitialSettingsApiActions.loadSucceeded(settings)),
                    catchError((error: HttpErrorResponse) => of(InitialSettingsApiActions.loadFailed({error: error.error})))
                );
            })
        );
    });

    constructor(private actions$: Actions, private appSettingsService: InitialSettingsService) {
    }

}
