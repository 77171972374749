<app-layout-auth>
    <ng-container *ngIf="isRegistrationActions else authErrors">
        <div class="title">Your account is already connected to Caregiving Network&trade;</div>
        <div class="sub-title">through your {{registrationTypeTexts[registrationType$ | async]}} Account</div>
        <app-social-buttons
            class="first-buttons"
            [isSignIn]="true"
            [currentRegistrationType]="registrationType$ | async"
            [useLongLabel]="true"
            (singedIn)="onSignedInSocial($event, true)"
        ></app-social-buttons>
        <div class="separator-with-text">or</div>
        <div class="color-primary social-buttons-title">
            Register with another account
        </div>
        <div class="social-buttons-wrapper">
            <app-social-buttons
                class="second-buttons"
                [isSignIn]="false"
                (singedIn)="onSignedInSocial($event, false)"
            ></app-social-buttons>
            <button
                mat-stroked-button
                class="control-button other-way-button"
                [routerLink]="'/registration'"
            >
                <mat-icon [svgIcon]="customSvgIcons.caregivingNetwork"></mat-icon>
                <span class="small-font">Caregiving<br>Network</span>
            </button>
        </div>
    </ng-container>
    <ng-template #authErrors>
        <div class="title">Sorry, this account isn’t connected to Caregiving Network&trade;</div>
        <div class="sub-title">Would you like to create a new account?</div>
        <app-social-buttons
            class="first-buttons"
            [isSignIn]="false"
            [currentRegistrationType]="registrationType$ | async"
            [useLongLabel]="true"
            (singedIn)="onSignedInSocial($event, false)"
        ></app-social-buttons>
        <button
            *ngIf="true"
            mat-stroked-button
            class="control-button join-button"
            [routerLink]="'/registration'"
        >
            <u class="color-primary-100">Join our community</u><br>
            <span>Using other ways to sign up</span>
        </button>
        <div class="separator-with-text">or</div>
        <div class="color-primary social-buttons-title">
            Login with another account
        </div>
        <div class="social-buttons-wrapper">
            <app-social-buttons
                class="second-buttons"
                [isSignIn]="true"
                (singedIn)="onSignedInSocial($event, true)"
            ></app-social-buttons>
            <button
                mat-stroked-button
                class="control-button other-way-button"
                [routerLink]="'/login'"
            >
                <mat-icon [svgIcon]="customSvgIcons.caregivingNetwork"></mat-icon>
                <span class="button-text small-font">Caregiving<br>Network</span>
            </button>
        </div>
    </ng-template>
</app-layout-auth>
