import { Component, EventEmitter, OnInit, Output } from '@angular/core';


import { UsersStatsModel } from '@core/models';


@Component({
    selector: 'app-stats-select',
    templateUrl: './stats-select.component.html',
    styleUrls: ['./stats-select.component.scss']
})
export class StatsSelectComponent implements OnInit {

    selected = 0;

    readonly stats: { name: keyof UsersStatsModel, label: string }[] = [
        {
            name: 'total',
            label: 'total'
        },
        {
            name: 'canHelp',
            label: 'give help'
        },
        {
            name: 'needHelp',
            label: 'need help'
        },
        {
            name: 'questions',
            label: 'questions'
        },
        {
            name: 'online',
            label: 'online'
        }
    ];

    @Output()
    changed = new EventEmitter<keyof UsersStatsModel>(true);

    ngOnInit(): void {
        this.changed.emit(this.stats[0].name);
    }

    change(newIndex: number) {
        if (newIndex === this.selected) {
            return;
        }
        this.selected = newIndex;
        this.changed.emit(this.stats[this.selected].name);
    }
}
