import { Pipe, PipeTransform } from '@angular/core';


const FILE_SIZE_UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

@Pipe({
    name: 'bytes'
})
export class BytesPipe implements PipeTransform {
    transform(sizeInBytes: number): string {
        let power = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
        power = Math.min(power, FILE_SIZE_UNITS.length - 1);

        const size = sizeInBytes / Math.pow(1024, power);
        const formattedSize = Math.round(size * 100) / 100;
        const unit = FILE_SIZE_UNITS[power];

        return `${formattedSize} ${unit}`;
    }
}
