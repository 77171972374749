import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PublicUserModel } from '@core/models';
import { FeedApiActions, PublicChatApiActions, PublicChatWsActions } from '@core/store/feed/actions';
import { AppInitActions, PrivateMessagesWsActions, PublicUsersApiActions, WsActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import { PublicUserProfileGuardActions } from '@core/store/users/actions';
import { AuthApiActions } from '@core/store/auth/actions';


export type State = EntityState<PublicUserModel>;

export const adapter: EntityAdapter<PublicUserModel> = createEntityAdapter<PublicUserModel>();

export const initialState: State = adapter.getInitialState();

const PublicUsersReducer = createReducer(
    initialState,
    on(
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        () => initialState
    ),
    on(
        PublicChatWsActions.chatMessageReceived,
        PublicChatApiActions.loadMessagesSucceeded,
        PublicChatApiActions.sendMessageSucceeded,
        FeedApiActions.loadFeedCanHelpUsersSucceeded,
        FeedApiActions.loadFeedAllSucceeded,
        FeedApiActions.loadFeedNeedHelpUsersSucceeded,
        FeedApiActions.loadFeedQuestionsSucceeded,
        AppInitActions.userAuthenticated,
        UserApiActions.userProfileLoadSucceeded,
        PublicChatApiActions.loadUsersSucceeded,
        (state, {users}) => {
            return adapter.upsertMany(users, state);
        }),
    on(
        PublicUserProfileGuardActions.userProfileLoadSucceeded,
        PublicUsersApiActions.followUserSucceeded,
        PublicUsersApiActions.unFollowUserSucceeded,
        PrivateMessagesWsActions.newConversationReceived,
        PublicChatWsActions.userSubscribeReceived,
        PublicUsersApiActions.loadProfileSucceeded,
        PrivateMessagesWsActions.callInviteReceived,
        (state, {user}) => {
            return adapter.upsertOne(user, state);
        }
    ),
    on(
        UserApiActions.updateDistancesSucceeded,
        (state, {updates}) => {
            return adapter.updateMany(updates, state);
        }
    ),
    on(
        UserApiActions.deleteGeolocationSucceeded,
        (state => adapter.map(entity => ({
                ...entity,
                distance: null
            }), state
        ))
    )
);


export function reducer(state: State | undefined, action: Action) {
    return PublicUsersReducer(state, action);
}

export const {
    selectEntities: getPublicUsersEntities,
    selectAll: getPublicUsers,
} = adapter.getSelectors();
