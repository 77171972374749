import { createAction, props } from '@ngrx/store';
import { ContestVideoModel, VideoContestStatusApiResponse } from '@core/models';


const prefix = '[Video Contest API]';

export const signAgreementSucceeded = createAction(`${prefix} sign agreement succeeded`);
export const signAgreementFailed = createAction(`${prefix} sign agreement failed`);

export const getContestVideosSucceeded = createAction(
    `${prefix} get contest videos succeeded`,
    props<{ contestVideos: ContestVideoModel[], hasMore: boolean }>()
);

export const getContestVideosFailed = createAction(
    `${prefix} get contest videos failed`,
    props<{ error: any }>()
);

export const contestVideoCreateSucceeded = createAction(
    `${prefix} contest video create succeeded`,
    props<{ contestVideo: ContestVideoModel }>()
);

export const contestVideoCreateFailed = createAction(
    `${prefix} contest video create failed`,
    props<{ error: any }>()
);

export const getStatusSucceeded = createAction(
    `${prefix} get status succeeded`,
    props<VideoContestStatusApiResponse>()
);

export const getStatusFailed = createAction(
    `${prefix} get status failed`,
    props<{ error: any }>()
);
