import { UserSettingsViewModel } from '@core/models/user-settings.model';
import { PublicUserModel } from '@core/models/user.model';
import { QuestionModel } from '@core/models/question.model';


export enum FeedRecordType {
    question,
    canHelpSetting,
    needHelpSetting
}

export interface FeedRecordWithQuestion {
    user: PublicUserModel;
    recordType: FeedRecordType.question;
    data: QuestionModel;
}

export interface FeedRecordWithSetting {
    user: PublicUserModel;
    recordType: FeedRecordType.canHelpSetting | FeedRecordType.needHelpSetting;
    data: UserSettingsViewModel;
}

export type FeedRecord = FeedRecordWithQuestion | FeedRecordWithSetting;

export type FeedRecords = FeedRecord[];
