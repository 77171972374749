import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
    selector: 'app-resources-block',
    templateUrl: './resources-block.component.html',
    styleUrls: ['./resources-block.component.scss']
})
export class ResourcesBlockComponent {

    @Input()
    title: string;

    @Input()
    hint: string;

    @Input()
    showHelpArrow: boolean;

    @ViewChild('resourcesMenuTrigger')
    resourcesMenuTrigger: MatMenuTrigger;

}
