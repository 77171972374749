import { createAction, props } from '@ngrx/store';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { ExternalSocialNetwork } from '@core/models';


export const prefix = '[Registration Error Page]';

export const submittedRegistrationWithSocialNetwork = createAction(
    `${prefix} submitted registration with social network`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        email: string,
        token: string,
        location: RoiLocation
    }>()
);

export const submittedLoginWithSocialNetwork = createAction(
    `${prefix} submitted login with social network`,
    props<{ externalSocialNetwork: ExternalSocialNetwork, token: string }>()
);
