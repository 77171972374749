import { Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RxStompService } from '@stomp/ng2-stompjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PublicChatMessage } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class PublicChatWsService {

    subscribe(chatId: number): Observable<PublicChatMessage> {
        return this.rxStompService.watch(`/topic/chat.${chatId}`)
            .pipe(map((message) => JSON.parse(message.body)));
    }

    constructor(
        private matSnackBar: MatSnackBar,
        @Optional() private rxStompService: RxStompService
    ) {
    }
}
