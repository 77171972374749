import { createAction, props } from '@ngrx/store';

import { Geolocation } from '@core/models';


export const prefix = '[Resources menu]';

export const initNationalResources = createAction(
    `${prefix} init national resources`,
    props<{ tagId: number }>()
);

export const loadNationalResources = createAction(
    `${prefix} load national resources`,
    props<{ tagId: number }>()
);

export const initLocalResources = createAction(
    `${prefix} init local resources`,
    props<{ tagId: number }>()
);

export const geolocationReceived = createAction(
    `${prefix} geolocation received`,
    props<{ geolocation: Geolocation }>()
);

export const loadLocalResources = createAction(
    `${prefix} load local resources`,
    props<{ tagId: number }>()
);
