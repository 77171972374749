import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-private-chat-button',
    templateUrl: './private-chat-button.component.html',
    styleUrls: ['./private-chat-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateChatButtonComponent {

    @Input()
    interlocutorId: number;

    @Input()
    disabled: boolean;

    @Input()
    asLink: boolean;

    @Input()
    privateMessageUrl: string;

    @Output()
    privateMessage$ = new EventEmitter<void>();

    get defaultPrivateMessageLink(): string {
        return `/me/conversations/${this.interlocutorId}`;
    }
}

