import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PublicUserModel, UserModel } from '@core/models';
import { UsernamePipe } from '@shared/pipes';


@Component({
    selector: 'app-username',
    templateUrl: './username.component.html',
    styleUrls: ['./username.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsernameComponent {

    @HostBinding('class.color-facilitator')
    get isFacilitator(): boolean {
        return this.user?.isFacilitator;
    }

    @HostBinding('title')
    get title(): string {
        if (!this.user) {
            return '';
        }
        return this.usernamePipe.transform(this.user.username, this.user.isFacilitator);
    }

    @Input()
    user: UserModel | PublicUserModel;

    constructor(private usernamePipe: UsernamePipe) {
    }

}
