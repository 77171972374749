import { Action, createReducer, on } from '@ngrx/store';

import { UserRole } from '@core/models';
import { WordsCloudPageActions } from '@core/store/onboarding/actions';
import { AuthApiActions } from '@core/store/auth/actions';
import { PrivateMessagesWsActions, WsActions } from '@core/store/actions';


export interface State {
    role: UserRole;
}

export const initialState: State = {
    role: UserRole.browse
};

const WordsCloudReducer = createReducer(
    initialState,
    on(WordsCloudPageActions.roleChanged, (state, {role}): State => ({...state, role})),
    on(
        AuthApiActions.logoutSucceeded,
        WsActions.invalidSessionErrorReceived,
        PrivateMessagesWsActions.reportUserReceived,
        () => initialState
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return WordsCloudReducer(state, action);
}

export const getUserRole = (state: State) => state.role;
