import { Injectable, Optional } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RxStompService } from '@stomp/ng2-stompjs';

import { PrivateMessage } from '@core/models';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/store';
import { PrivateMessagesWsActions } from '@core/store/actions';


@Injectable({
    providedIn: 'root'
})
export class PrivateMessagesWsService {

    subscribe(userId: number): Observable<PrivateMessage> {
        if (!this.rxStompService) {
            return null;
        }
        return this.rxStompService.watch(`/topic/user.${userId}`)
            .pipe(map((message) => JSON.parse(message.body)));
    }

    constructor(
        @Optional() private rxStompService: RxStompService,
        private store: Store<fromRoot.State>
    ) {
        if (this.rxStompService) {
            this.rxStompService.serverHeaders$
                .subscribe((args) => this.store.dispatch(PrivateMessagesWsActions.xAuthSocketTokenReceived({
                    token: args['x-auth-socket-token']
                })));
        }
    }
}
