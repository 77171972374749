import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';

import { of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@core/services';


@Injectable({
    providedIn: 'root'
})
export class EmailUniqueValidator {

    constructor(private authService: AuthService) {
    }

    getValidator(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return observableOf(null);
            }
            return this.authService.isEmailUnique(control.value).pipe(
                map((data: { isAvailable: boolean }) => {
                    return data.isAvailable ? null : {emailUnique: true};
                }),
                catchError(() => observableOf({emailUnique: true}))
            );
        };
    }
}
