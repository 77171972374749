import { Action, createReducer, on } from '@ngrx/store';

import { AuthApiActions } from '@core/store/auth/actions';
import { RegistrationType, ServerErrorModel } from '@core/models';


export interface State {
    isPending: boolean;
    registrationType: RegistrationType;
    error: ServerErrorModel | null;
}


export const initialState: State = {
    isPending: false,
    registrationType: null,
    error: null
};

const RegistrationErrorPageReducer = createReducer(
    initialState,
    on(
        AuthApiActions.loginWithSocialNetworkFailed,
        ((state, {externalSocialNetwork, error}) => ({
            ...state,
            error,
            registrationType: externalSocialNetwork
        }))),
    on(
        AuthApiActions.validateTokenFailed,
        ((state, {externalSocialNetwork, error}) => ({
            ...state,
            error,
            registrationType: externalSocialNetwork
        })))
);

export const reducer = (state: State | undefined, action: Action) => {
    return RegistrationErrorPageReducer(state, action);
};


export const getError = (state: State) => state.error;
export const getRegistrationType = (state: State) => state.registrationType;
