import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromVideoContest from '@core/store/video-contest';
import * as fromRoot from '@core/store';

import { VideoContestService } from '@core/services';
import {
    ContestVideosPageActions,
    VideoContestAgreementPageActions,
    VideoContestAPIActions,
    VideoContestNewVideoPageActions
} from '@core/store/video-contest/actions';
import { environment } from 'src/environments/environment';
import { ContestVideoStatus } from '@core/models';

@Injectable({
    providedIn: 'root'
})
export class VideoContestAPIEffects {

    agreementSigned$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VideoContestAgreementPageActions.sighAgreement),
            exhaustMap(() => {
                return this.videoContestService.signAgreement().pipe(
                    map(() => VideoContestAPIActions.signAgreementSucceeded()),
                    catchError(() => {
                        return of(VideoContestAPIActions.signAgreementFailed());
                    })
                );
            })
        );
    });

    getContestVideos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ContestVideosPageActions.init,
                ContestVideosPageActions.loadMore,
            ),
            withLatestFrom(this.store.pipe(select(fromVideoContest.getContestVideosPageListPage))),
            exhaustMap(([, page]) => {
                return this.videoContestService.getContestVideos(page, environment.videoContest.pageSize).pipe(
                    map((contestVideos) => {
                        contestVideos.forEach((video) => video.status = ContestVideoStatus[video.status.toString().toLowerCase()]);

                        return VideoContestAPIActions.getContestVideosSucceeded({
                            contestVideos,
                            hasMore: environment.videoContest.pageSize === contestVideos.length
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(VideoContestAPIActions.getContestVideosFailed({error: error.error}));
                    })
                );
            })
        );
    });

    createContest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VideoContestNewVideoPageActions.newVideoFormSubmitted),
            exhaustMap(({uploadId, title, description}) => {
                return this.videoContestService.createVideoContest(title, description, uploadId).pipe(
                    map((model) => VideoContestAPIActions.contestVideoCreateSucceeded({
                        contestVideo: {
                            ...model,
                            status: ContestVideoStatus[model.status.toString().toLowerCase()]
                        }
                    })),
                    catchError((error) => {
                        return of(VideoContestAPIActions.contestVideoCreateFailed({error: error.error}));
                    })
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private videoContestService: VideoContestService,
        private store: Store<fromRoot.State>
    ) {
    }
}
