import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromConversations from '@core/store/conversations';
import { PublicUsersService } from '@core/services';
import { PrivateChatSettingsMessageGuardActions } from '@core/store/conversations/actions';
import { ReplyOnType } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class PrivateChatSettingsMessageGuard implements CanActivate {

    constructor(
        private publicUsersService: PublicUsersService,
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return combineLatest([
            this.store.pipe(select(fromRoot.getConversationInterlocutorId)),
            this.store.pipe(select(fromConversations.getPrivateChatConversationModel)),
            this.store.pipe(select(fromRoot.getConversationSettingsId))
        ]).pipe(
            take(1),
            map(([interlocutorId, conversation, settingId]): UrlTree => {
                const conversationsUrl = this.router.parseUrl(`/me/conversations/${interlocutorId}`);
                if (!settingId) {
                    return conversationsUrl;
                }
                this.store.dispatch(PrivateChatSettingsMessageGuardActions.usersFeedSettingInteracted({
                    userId: interlocutorId,
                    settingId
                }));
                const lastMessageText = conversation?.lastMessageUserIdFrom === interlocutorId ? null : conversation?.text;
                this.publicUsersService.getSetting(interlocutorId, settingId).subscribe((settings) => {
                    if (settings && (settings.about !== lastMessageText)) {
                        this.store.dispatch(PrivateChatSettingsMessageGuardActions.replyOnUserSetting({
                            message: {
                                replyOnType: ReplyOnType.userSetting,
                                replyOnId: settings.id,
                                userFromId: interlocutorId,
                                text: settings.about,
                                date: settings.updatedOn
                            }
                        }));
                    }
                });
                return conversationsUrl;
            })
        );
    }
}
