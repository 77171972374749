import {Component, Input} from '@angular/core';
import {AdModel} from '@core/models';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-text-ad',
    templateUrl: './text-ad.component.html',
    styleUrls: ['./text-ad.component.scss']
})
export class TextAdComponent {

    adText: SafeHtml;

    @Input()
    set ad(ad: AdModel) {
        this.adText = this.domSanitizer.bypassSecurityTrustHtml(ad.text);
    }

    constructor(
        private domSanitizer: DomSanitizer
    ) {
    }

}
