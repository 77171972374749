import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

import { environment } from 'src/environments/environment';
import { LoggerService } from '@core/services';


export const appRxStompConfig: InjectableRxStompConfig = {
    brokerURL: environment.wsUrl,

    heartbeatIncoming: 20000,
    heartbeatOutgoing: 20000,

    reconnectDelay: 1000,

    debug: (msg: string): void => {
        LoggerService.debug('RxStomp:', msg);
    }
};
