<h2 class="mat-title">Reset Password</h2>

<form (ngSubmit)="submit()" [formGroup]="form">
    <div>
        <p *ngIf="isError && isSubmitted" class="error">
            We were unable to update your password for some reason.
            Please <a class="color-primary" routerLink="/auth/forgot-password">click on this link</a>
            and enter your email and we will send a link in your email to reset your password. Thank you.
        </p>
    </div>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>New Password</mat-label>
            <input [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password" matInput>
            <mat-icon (click)="isPasswordHidden = !isPasswordHidden" matSuffix>{{isPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint>At least 8 characters containing a symbol, a lower and upper case letter, and a number</mat-hint>
            <mat-error *ngIf="form.get('password').hasError('lowerCaseLetter')">
                Password should contain at least one lower case letter
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('upperCaseLetter')">
                Password should contain at least one upper case letter
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('digit')">
                Password should contain at least one digit
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('notAlpha')">
                Password should contain at least one special character
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('minlength')">
                Password should be at least 8 characters long
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('required')">
                Password is required
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>Confirm New Password</mat-label>
            <input [type]="isRePasswordHidden ? 'password' : 'text'" formControlName="rePassword" matInput>
            <mat-icon (click)="isRePasswordHidden = !isRePasswordHidden"
                      matSuffix>{{isRePasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('rePassword').hasError('required') || form.hasError('passwordsMatch')">
                Passwords don't match
            </mat-error>
        </mat-form-field>
    </div>

    <div class="row">
        <button [disabled]="form.disabled" color="primary" mat-flat-button>Done</button>
    </div>

</form>

