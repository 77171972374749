import { createAction, props } from '@ngrx/store';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { ExternalSocialNetwork } from '@core/models';


export const prefix = '[About Us Page]';

export const emailSubmitted = createAction(
    `${prefix} email submitted`,
    props<{ email: string, location: RoiLocation }>()
);

export const submittedWithSocialNetwork = createAction(
    `${prefix} submitted with social network`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        email: string,
        token: string,
        location: RoiLocation
    }>()
);
