import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { takeUntil, tap } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import * as fromAuth from '@core/store/auth';
import { AuthApiActions, ResetPasswordPageActions } from '@core/store/auth/actions';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseAuthContainerComponent implements OnInit {

    readonly token;

    isError$ = this.store.pipe(select(fromAuth.getResetPasswordPageIsError));

    isPending$ = this.store.pipe(select(fromAuth.getResetPasswordPageIsPending));

    constructor(
        private activatedRoute: ActivatedRoute,
        private actions$: Actions,
        protected store: Store<fromRoot.State>,
        protected router: Router,
    ) {
        super(router, store);
        this.token = activatedRoute.snapshot.paramMap.get('token');
    }

    ngOnInit() {
        this.store.dispatch(ResetPasswordPageActions.init());
        this.actions$.pipe(
            ofType(AuthApiActions.resetPasswordSucceeded),
            tap(this.onSuccess.bind(this)),
            takeUntil(this.destroyed$)
        ).subscribe();
    }

    onSubmit($data: { password: string }) {
        this.store.dispatch(ResetPasswordPageActions.submitted({
            password: $data.password,
            token: this.token
        }));
    }

    private onSuccess() {
        this.router.navigate(['/auth/login'], {queryParams: {afterReset: true}});
    }
}
