import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'app-font-size-buttons',
    templateUrl: './font-size-buttons.component.html',
    styleUrls: ['./font-size-buttons.component.scss']
})
export class FontSizeButtonsComponent {
    @Input()
    currentFontSize: number = 16;

    @Input()
    maxFontSize: number = 28;

    @Input()
    minFontSize: number = 10;

    @Output()
    fontSizeChanged = new EventEmitter<number>();

    adjustText($event: MouseEvent) {
        const target = $event.target as HTMLElement;
        if (target.classList.contains('increase')) {
            if (this.currentFontSize < this.maxFontSize) {
                this.currentFontSize = this.currentFontSize + 2;
            }
        }
        if (target.classList.contains('decrease')) {
            if (this.currentFontSize > this.minFontSize) {
                this.currentFontSize -= 2;
            }
        }
        this.fontSizeChanged.emit(this.currentFontSize);
    }

}
