import { createAction } from '@ngrx/store';


export const prefix = '[profile menu]';

export const init = createAction(`${prefix} init`);

export const logout = createAction(`${prefix} logout`);

export const destroyed = createAction(`${prefix} destroyed`);
