<app-user-avatar
    [user]="user"
></app-user-avatar>

<div id="message-wrapper">
    <app-username id="username" [user]="user"></app-username>
    <div id="message">{{message.text}}</div>
</div>

<a
    mat-flat-button
    color="primary"
    [routerLink]="['/me/conversations', user.id]"
    (click)="onLinkClick()"
>go to chat</a>

<button mat-icon-button id="btn-close" (click)="matSnackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
</button>
