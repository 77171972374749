import { Action, createReducer, on } from '@ngrx/store';
import { Religion } from '@core/models';
import { AffirmationsApiActions, AffirmationsPageActions } from '@core/store/affirmations/actions';


export interface State {
    religions: Array<Religion>;
}

export const initialState: State = {
    religions: []
};

const ReligionsReducer = createReducer(
    initialState,
    on(AffirmationsPageActions.getReligions, state => ({
        ...initialState,
    })),
    on(AffirmationsApiActions.getReligionsSucceeded,
        (state: State, { religions }) => ({
            religions
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return ReligionsReducer(state, action);
}

export const getReligions = (state: State) => state.religions;

