import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromCurrentUser from '@core/store/current-user';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { UnAuthPopupComponent } from '@shared/containers';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-un-auth-cover]',
    templateUrl: './un-auth-cover.component.html',
    styleUrls: ['./un-auth-cover.component.scss'],
})
export class UnAuthCoverComponent extends WithDestroyedSubjectComponent implements OnInit {

    private matDialogRef: MatDialogRef<UnAuthPopupComponent>;

    @HostBinding('class.un-auth-covered')
    isNotAuthenticated: boolean;

    constructor(
        private store: Store<fromCurrentUser.State>,
        private elementRef: ElementRef,
        private matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            select(fromCurrentUser.getUser),
            takeUntil(this.destroyed$)
        ).subscribe((user) => this.isNotAuthenticated = !user);
    }

    showPopup() {
        if (this.matDialogRef && MatDialogState.OPEN === this.matDialogRef.getState()) {
            return;
        }
        this.matDialogRef = this.matDialog.open<UnAuthPopupComponent>(UnAuthPopupComponent, {disableClose: false});
    }
}
