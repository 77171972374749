import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Affirmation } from '@core/models/affirmation.model';
import { Religion } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class AffirmationsService {
    readonly affirmationsPrefix = '/affirmations';
    readonly religionsPrefix = '/religions';

    getAffirmationById(id: number): Observable<Affirmation> {
        return this.http.get<Affirmation>(`${this.affirmationsPrefix}/${id}`);
    }

    getAffirmationByReligionId(id: number | null): Observable<Affirmation> {
        const religionId = id || 'none';
        return this.http.get<Affirmation>(`${this.religionsPrefix}/${religionId}/daily-affirmation`);
    }

    likeAffirmation(id: number): Observable<void> {
        return this.http.post<void>(`${this.affirmationsPrefix}/${id}/like`, {});
    }

    disLikeAffirmation(id: number): Observable<void> {
        return this.http.post<void>(`${this.affirmationsPrefix}/${id}/dislike`, {});
    }

    sendAffirmationToEmail(id: number, email: string): Observable<void> {
        return this.http.post<void>(`${this.affirmationsPrefix}/send-email`, {
            affirmationId: id,
            email: email
        });
    }

    getReligions(): Observable<Array<Religion>> {
        return this.http.get<Array<Religion>>(`${this.religionsPrefix}`);
    }

    constructor(private http: HttpClient) {
    }
}
