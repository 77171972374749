import { createAction, props } from '@ngrx/store';
import { Geolocation } from '@core/models';


export const prefix = '[Geolocation Page]';

export const enableGeolocation = createAction(
    `${prefix} enable geolocation`,
    props<{ geolocation: Geolocation }>()
);

export const updateGeolocation = createAction(
    `${prefix} update geolocation`,
    props<{ geolocation: Geolocation }>()
);

export const disableGeolocation = createAction(
    `${prefix} disable geolocation`
);

export const getDistances = createAction(
    `${prefix} get distances`,
    props<{ geolocation: Geolocation }>()
);
