<div id="tag-names" class="color-accent">
    <span *ngIf="parentTag">{{parentTag.name}} - </span><span>{{newTagName}}</span>
</div>
<div id="thank-you-text">
    Thank you for your
    community input!
</div>
<div id="review-text">
    We will review your request promptly. Lastly, please indicate if you want to get help or give help.
</div>
<div id="help-buttons">
    <button
        mat-stroked-button
        color="primary"
        [class.selected]="userRole === userRoles.needHelp"
        (click)="userRole = userRoles.needHelp"
    >get help</button>
    <button
        mat-stroked-button
        color="primary"
        [class.selected]="userRole === userRoles.canHelp"
        (click)="userRole = userRoles.canHelp"
    >give help
    </button>
</div>
<div>
    <button
        id="btn-send-request"
        mat-stroked-button
        color="primary"
        [disabled]="!userRole"
        (click)="submitted$.emit(userRole)"
    >send request</button>
</div>
<div>
    <button
        mat-stroked-button
        color="primary"
        (click)="closed$.emit()"
    >cancel</button>
</div>
<div id="help-desk">
    if you have questions contact us at<br>
    <a href="mailto:info@caregivingnetwork.com">info@caregivingnetwork.com</a>
</div>
<app-disclaimer></app-disclaimer>
