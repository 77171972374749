import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { ConversationPrivateChatMessageModel, PublicUserModel } from '@core/models';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-private-chat-notification',
    templateUrl: './private-chat-notification.component.html',
    styleUrls: ['./private-chat-notification.component.scss']
})
export class PrivateChatNotificationComponent {

    message: ConversationPrivateChatMessageModel;
    user: PublicUserModel;

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        public matSnackBarRef: MatSnackBarRef<PrivateChatNotificationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) data: { message: ConversationPrivateChatMessageModel, user: PublicUserModel }
    ) {
        this.message = data.message;
        this.user = data.user;
    }

    onLinkClick() {
        this.matDialog.closeAll();
        this.matSnackBarRef.dismiss();
    }

}
