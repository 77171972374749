<form [formGroup]="form">
    <div formArrayName="emails">
        <div class="input-wrapper" *ngFor="let email of emails.controls; index as i">
            <input
                type="email"
                class="email-input"
                title="Friends Email address"
                placeholder="{{emailPlaceholder || 'Friends Email address'}}"
                [formControlName]="i.toString()"
                (blur)="onBlur(i)"
            >
            <mat-error *ngIf="hasError(email, 'emailFormat')">
                Please enter a valid email address
            </mat-error>
            <mat-error
                *ngIf="hasError(emails, 'required') && emails.length === 1 && (email.dirty || email.touched)">
                Please enter at least one email address
            </mat-error>
        </div>
    </div>

    <div *ngIf="showAdditionalMessage" id="message">
        I like this free site for family caregivers by family caregivers and believe you would benefit from
        checking it out. <a href="https://caregivingnetwork.com/" tabindex="-1">https://caregivingnetwork.com/</a>
        <br><br>
        <span *ngIf="!!user">My username is
                    <a href="{{userLink}}" tabindex="-1" target="_blank" rel="noopener">
                        <app-username [user]="user"></app-username>
                    </a><br>
                </span>
        Look me up on the site!
        <br><br>
    </div>
    <div class="input-wrapper">
                <textarea
                    title="Please enter your text"
                    formControlName="text"
                    placeholder="{{textPlaceholder || 'Please enter your text'}}"
                ></textarea>
        <mat-error *ngIf="form && hasError(form.get('text'), 'required')">
            This field is required
        </mat-error>
    </div>
    <button
        mat-flat-button
        color="accent"
        [disabled]="isSendingDisabled"
        (click)="submit()"
    >send
    </button>
</form>
