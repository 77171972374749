import { createAction, props } from '@ngrx/store';


export const prefix = '[Help Desk Api]';

export const sendHelpDeskSucceeded = createAction(
    `${prefix} send help desk succeeded`
);

export const sendHelpDeskFailed = createAction(
    `${prefix} send help desk failed`,
    props<{ error: string }>()
);
