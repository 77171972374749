import { UntypedFormControl, ValidationErrors } from '@angular/forms';


export class UsernameFormatValidator {
    public static validate(control: UntypedFormControl): ValidationErrors | null {

        const REGEXP = /^[a-zA-Z0-9\-_]+$/;

        return (!control.value || REGEXP.test(control.value)) ? null : {
            usernameFormat: true
        };
    }
}
