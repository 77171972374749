import {Component, Input} from '@angular/core';
import {AdModel} from '@core/models';

@Component({
  selector: 'app-image-ad',
  templateUrl: './image-ad.component.html',
  styleUrls: ['./image-ad.component.scss']
})
export class ImageAdComponent {

  @Input()
  ad: AdModel;
}
