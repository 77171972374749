import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from 'src/app/pages/auth/containers/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/pages/auth/containers/login/login.component';
import { RegistrationComponent } from 'src/app/pages/auth/containers/registration/registration.component';
import { ResetPasswordComponent } from 'src/app/pages/auth/containers/reset-password/reset-password.component';
import { FeatureFlagGuard } from '@core/guards/feature-flag.guard';
import { RegistrationErrorComponent, RegistrationSocialComponent } from '@pages/auth/containers';
import { RegistrationSocialGuard } from '@core/guards';
import { RegistrationErrorGuard } from '@core/guards/registration-error.guard';


const routes: Routes = [
    {
        path: 'registration',
        data: {
            pageTitle: 'Registration'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: RegistrationComponent,
                canActivate: [FeatureFlagGuard],
            },
            {
                path: 'social',
                canActivate: [RegistrationSocialGuard],
                component: RegistrationSocialComponent,
            },
            {
                path: 'error',
                canActivate: [RegistrationErrorGuard],
                component: RegistrationErrorComponent,
            },
        ]
    },
    {
        path: 'login',
        data: {
            pageTitle: 'Login'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: LoginComponent,
            },
            {
                path: 'error',
                canActivate: [RegistrationErrorGuard],
                component: RegistrationErrorComponent,
            }
        ]

    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
            pageTitle: 'Forgot Password'
        }
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        data: {
            pageTitle: 'Reset Password'
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
