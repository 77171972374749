<div class="ios-fix">
    <div class="mat-headline popup-header" mat-dialog-title>
        new subject request
    </div>
    <div class="mat-dialog-content" [ngSwitch]="currentStep">
        <app-search-existing-tag
            *ngSwitchCase="steps.searchExistingTag"
            [tags]="tags"
            [criteria]="searchCriteria$ | async"
            (submitted$)="onSearchExistingTagSubmitted($event)"
            (searched$)="onSearch($event)"
            (searchCleared$)="onSearchCleared()"
            (closed$)="onClosed()"
        >
        </app-search-existing-tag>
        <app-parent-tag-selection
            *ngSwitchCase="steps.parentTagSelection"
            [tags]="rootTags"
            [tagName]="newTagName"
            (selected$)="onParentTagSelected($event)"
            (closed$)="onClosed()"
            (back$)="changeStep(steps.searchExistingTag)"
        ></app-parent-tag-selection>
        <app-new-subject-confirmation
            *ngSwitchCase="steps.newSubjectConfirmation"
            [parentTag]="parentTag"
            [newTagName]="newTagName"
            (submitted$)="onRequestSubmitted($event)"
            (closed$)="onClosed()"
        ></app-new-subject-confirmation>
    </div>
</div>
