import { createAction, props } from '@ngrx/store';
import { ChatReplyOnInputModel } from '@core/models';


export const prefix = '[Private chat settings message guard]';

export const usersFeedSettingInteracted = createAction(
    `${prefix} users feed setting interacted`,
    props<{userId: number, settingId: number}>()
);

export const replyOnUserSetting = createAction(
    `${prefix} reply on setting`,
    props<{message: ChatReplyOnInputModel}>()
);
