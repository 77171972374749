import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


import { UserExperiencePeriod } from '@core/models';
import { ExperienceModel } from '@pages/user/models';


@Component({
    selector: 'app-experience-period',
    templateUrl: './experience-period.component.html',
    styleUrls: ['./experience-period.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ExperiencePeriodComponent),
        multi: true
    }]
})
export class ExperiencePeriodComponent implements ControlValueAccessor {

    readonly periods = UserExperiencePeriod;

    experience = 1;

    period = UserExperiencePeriod.year;

    label = '1';

    isDisabled = false;

    onChange: any = () => {
    };

    onTouched: any = () => {
    };

    getValue(): ExperienceModel {
        return {
            experience: this.experience,
            experiencePeriod: this.period
        };
    }

    setData(experience: number, period?: UserExperiencePeriod): boolean {
        let isChanged = false;
        if (period && period !== this.period) {
            this.period = period;
            isChanged = true;
        }
        if (experience === this.experience) {
            return isChanged;
        }
        isChanged = true;
        switch (experience) {
            case 0 :
                if (UserExperiencePeriod.year === this.period) {
                    this.period = UserExperiencePeriod.month;
                    this.experience = 11;
                } else {
                    this.experience = 1;
                    isChanged = false;
                }
                break;
            case 12 :
                if (UserExperiencePeriod.month === this.period) {
                    this.period = UserExperiencePeriod.year;
                    this.experience = 1;
                } else {
                    this.experience = experience;
                }
                break;
            default :
                this.experience = experience;
                break;
        }
        this.label = this.experience > 50 ? '50+' : this.experience.toString();
        return isChanged;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: ExperienceModel): void {
        if (!value || typeof value !== 'object') {
            return;
        }

        if (this.setData(+value.experience, value.experiencePeriod)) {
            this.onChange(this.getValue());
        }
    }

    shiftValue(direction: number) {
        this.onTouched();
        if (this.setData(this.experience + direction, this.period)) {
            this.onChange(this.getValue());
        }
    }
}
