import { AfterContentChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';


@Component({
    selector: 'app-infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent extends WithDestroyedSubjectComponent implements AfterViewInit, AfterContentChecked {

    readonly offset = 200;

    @ViewChild('scrollIndicator')
    scrollIndicator: ElementRef<HTMLDivElement>;

    @Input()
    enabled = true;

    @Output()
    infiniteScroll$ = new EventEmitter<void>(true);

    constructor(private container: ElementRef) {
        super();
    }

    onScroll() {
        if (!this.scrollIndicator) {
            return;
        }
        const viewPort = this.container.nativeElement.offsetTop +
            this.container.nativeElement.scrollTop +
            this.container.nativeElement.clientHeight;
        if (viewPort + this.offset > this.scrollIndicator.nativeElement.offsetTop) {
            this.infiniteScroll$.emit();
        }
    }

    ngAfterViewInit(): void {
        fromEvent(this.container.nativeElement, 'scroll', {passive: true})
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.onScroll());
        this.onScroll();
    }

    ngAfterContentChecked() {
        this.onScroll();
    }
}
