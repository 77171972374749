<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row error">
        <ng-container *ngIf="error && isSubmitted">
            {{errorText}}
        </ng-container>
    </div>
    <div class="row">
        <mat-form-field appearance="fill" hideRequiredMarker="true">
            <mat-label>Username</mat-label>
            <input formControlName="username" maxlength="50" minlength="3" matInput>
            <mat-error *ngIf="form.get('username').hasError('required')">
                Username is required
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('minlength') || form.get('username').hasError('maxLength')">
                Username should be between 3-50 characters
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('usernameFormat')">
                Username should contain only letters and numbers
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password" matInput>
            <mat-icon
                (click)="isPasswordHidden = !isPasswordHidden"
                matSuffix>{{isPasswordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('password').hasError('required')">
                Password is required
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row forgot-password-wrapper">
        <a class="link" rel="nofollow" routerLink="/auth/forgot-password">Forgot password?</a>
    </div>
    <div class="row">
        <button [disabled]="form.disabled" color="primary" mat-flat-button>Log in</button>
    </div>

    <div class="row">
        New member? <a class="link" rel="nofollow" routerLink="/auth/registration">Register</a>
    </div>
</form>
