import { PublicUserModel } from '@core/models/user.model';
import { UserSettingsModel } from '@core/models/user-settings.model';
import { QuestionModel } from '@core/models/question.model';


export interface PublicUserProfileApiModel extends PublicUserModel {
    followings: PublicUserModel[];
    settings: UserSettingsModel[];
    questions: QuestionModel[];
}

export interface PublicUserProfileFlattenModel {
    user: PublicUserModel;
    followings: PublicUserModel[];
    settings: UserSettingsModel[];
    questions: QuestionModel[];
}

export function flattenPublicUserProfileApiModel(model: PublicUserProfileApiModel): PublicUserProfileFlattenModel {
    return {
        user: {
            id: model.id,
            username: model.username,
            photo: model.photo,
            isFollowed: model.isFollowed,
            distance: model.distance,
            lastActivity: model.lastActivity,
            createdOn: model.createdOn,
            isFounding: model.isFounding,
            isFacilitator: model.isFacilitator
        },
        followings: model.followings,
        settings: model.settings,
        questions: model.questions
    };
}
