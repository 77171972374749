import { createAction, props } from '@ngrx/store';

import { Update } from '@ngrx/entity';

import { PrivateChatMessageModel, PrivateChatNewMessageResult } from '@core/models';


export const prefix = '[Conversations API]';

export const loadMessagesSucceeded = createAction(
    `${prefix} load messages succeeded`,
    props<{
        messages: PrivateChatMessageModel[],
        hasMore: boolean
    }>()
);

export const loadMessagesFailed = createAction(
    `${prefix} load message failed`,
    props<{ error: any }>()
);

export const sendMessageSucceeded = createAction(
    `${prefix} send message succeeded`,
    props<PrivateChatNewMessageResult>()
);

export const sendMessageFailed = createAction(
    `${prefix} send message failed`,
    props<{ error: string }>()
);

export const deleteMessageSucceeded = createAction(
    `${prefix} delete message succeeded`,
    props<{ id: number }>()
);

export const deleteMessageFailed = createAction(
    `${prefix} delete message failed`,
    props<{ error: string }>()
);

export const editMessageSucceeded = createAction(
    `${prefix} edit message succeeded`,
    props<{
        conversationId: number,
        messageUpdate: Update<PrivateChatMessageModel>
    }>()
);

export const editMessageFailed = createAction(
    `${prefix} edit message failed`,
    props<{ error: string }>()
);

export const replyOnMessageSucceeded = createAction(
    `${prefix} reply on message succeeded`,
    props<PrivateChatNewMessageResult>()
);

export const replyOnMessageFailed = createAction(
    `${prefix} reply on message failed`,
    props<{ error: string }>()
);

export const markMessageAsReadSucceeded = createAction(
    `${prefix} mark message as read succeeded`,
    props<{ messageId: number, conversationId: number }>()
);

export const markMessageAsReadFailed = createAction(
    `${prefix} mark message as read failed`,
    props<{ error: string }>()
);

