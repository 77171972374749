import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment';


export const slideVertical = trigger('slideVertical', [
    state('center', style({
        transform: 'translateY(0)'
    })),
    state('down', style({
        transform: 'translateY(150%)'
    })),
    transition('center <=> down', animate(`${environment.affirmations.duration.slideVertical}`)),
]);

export const slideHorizontal = trigger('slideHorizontal', [
    state('left', style({
        transform: 'translateX(-100%)'
    })),
    state('center', style({
        transform: 'translateX(0)'
    })),
    state('right', style({
        transform: 'translateX(100%)'
    })),
    transition('left <=> center', animate(`${environment.affirmations.duration.slideHorizontal}`)),
    transition('center <=> right', animate(`${environment.affirmations.duration.slideHorizontal}`)),
    transition(':enter', [
        style({transform: 'translateX(100%)' }),
        animate(`${environment.affirmations.duration.slideHorizontal}`,
            style({ transform: 'translateX(0)' }))
    ]),
]);

export const fadeIn = trigger('fadeIn', [
    state('visible', style({
        opacity: 1
    })),
    state('invisible', style({
        opacity: 0
    })),
    transition('visible <=> invisible', animate(`${environment.affirmations.duration.fadeIn}`)),
    transition(':enter', [
        style({ opacity: 0 }),
        animate(`${environment.affirmations.duration.fadeIn}`,
            style({ opacity: 1 }))
    ]),
]);
