import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { TagsService } from '@core/services';
import { TagDescriptionGuardActions } from '@core/store/actions';
import * as fromRoot from '@core/store';
import * as fromFeed from '@core/store/feed';


@Injectable({
    providedIn: 'root'
})
export class TagDescriptionGuard implements CanActivateChild {

    constructor(
        private tagsService: TagsService,
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromRoot.getSelectedTag),
            withLatestFrom(this.store.pipe(select(fromFeed.getTagDescription))),
            exhaustMap(([tag, tagDescription]): Observable<UrlTree | boolean> => {
                if (!tag) {
                    return of(this.router.parseUrl('/404'));
                }
                if (tagDescription && tagDescription.id === tag.id) {
                    return of(true);
                }
                return this.tagsService.getDescription(tag.id).pipe(
                    map((description) => {
                        this.store.dispatch(TagDescriptionGuardActions.loadSucceeded({description}));
                        return true;
                    }),
                    catchError(() => {
                        return of(this.router.parseUrl('/404'));
                    })
                );
            }),
        );
    }


}
