import { createAction, props } from '@ngrx/store';

export const prefix = '[report a member popup]';

export const reportUser = createAction(
    `${prefix} report user`,
    props<{ userId: number, text: string }>()
);

export const blockUser = createAction(
    `${prefix} single user block`,
    props<{ userId: number, text: string }>()
);
