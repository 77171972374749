<section class="content">
    <div id="logo-container">
        <mat-icon color="primary" [svgIcon]="logoSvgIcon"></mat-icon>
    </div>
    <div class="message">Internet Congestion detected.<br>Please try again shortly.</div>
    <button
        class="mat-flat-button mat-button-base mat-primary"
        color="primary"
        mat-flat-button
        (click)="ping()"
        [disabled]="isLoading"
    >
        Retry
        <span class="spinner-container" *ngIf="isLoading">
            <mat-spinner diameter="35" color="primary"></mat-spinner>
        </span>
    </button>
</section>
