import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { PublicChatMessageFlatModel } from '@core/models';
import { PublicChatApiActions, PublicChatPageActions, PublicChatWsActions } from '@core/store/feed/actions';
import { getSortByDateComparer, SortDirection } from '@core/store/sort-comparer-by-date';


export interface State extends EntityState<PublicChatMessageFlatModel> {
    isPending: boolean;
    error: string;
    hasMore: boolean;
}

export const adapter: EntityAdapter<PublicChatMessageFlatModel> = createEntityAdapter<PublicChatMessageFlatModel>({
    sortComparer: getSortByDateComparer('createdOn', SortDirection.asc)
});

export const initialState: State = adapter.getInitialState({
    isPending: false,
    error: null,
    hasMore: true
});

const publicMessageReducer = createReducer(
    initialState,
    on(
        PublicChatPageActions.init,
        (() => initialState)
    ),
    on(
        PublicChatApiActions.allMessagesLoaded,
        ((state) => {
            return {
                ...state,
                isPending: false,
                error: null,
                hasMore: false
            };
        })
    ),
    on(
        PublicChatPageActions.init,
        PublicChatPageActions.loadChatHistory,
        (state: State) => {
            return {
                ...state,
                isPending: true,
                error: null
            };
        }
    ),
    on(
        PublicChatApiActions.loadMessagesFailed,
        (state: State, {error}) => {
            return {
                ...state,
                isPending: false,
                error
            };
        }
    ),
    on(PublicChatApiActions.loadMessagesSucceeded, (state: State, {messages, hasMore}) => {
        return adapter.addMany(messages, {
            ...state,
            hasMore,
            isPending: false,
            error: null
        });
    }),
    on(
        PublicChatWsActions.chatMessageReceived,
        PublicChatApiActions.sendMessageSucceeded,
        PublicChatApiActions.replyOnMessageSucceeded,
        (state: State, {message}) => {
            return adapter.upsertOne(message, {...state, isPending: false, error: null});
        }
    ),
    on(
        PublicChatApiActions.deleteMessageSucceeded,
        PublicChatWsActions.deleteMessageReceived,
        (state, {messageId}) => adapter.removeOne(messageId, state)
    ),
    on(PublicChatApiActions.deleteMessageFailed, (state, {error}) => ({
        ...state,
        error
    })),
    on(
        PublicChatApiActions.editMessageSucceeded,
        PublicChatWsActions.editMessageReceived,
        (
            state,
            {update}
        ) => adapter.updateOne(update, {
            ...state,
            error: null
        })),
);

export const reducer = (state: State | undefined, action: Action) => {
    return publicMessageReducer(state, action);
};


export const {
    selectIds: getPublicChatMessagesIds,
    selectAll: getPublicChatMessages,
} = adapter.getSelectors();


export const getHasMore = (state: State) => state.hasMore;
export const getIsPending = (state: State) => state.isPending;
