import { createAction, props } from '@ngrx/store';
import { ExternalSocialNetwork } from '@core/models';


export const prefix = '[Login Page]';

export const init = createAction(`${prefix} init`);

export const submitted = createAction(
    `${prefix} submitted`,
    props<{ username: string, password: string }>()
);

export const loginWithSocialNetwork = createAction(
    `${prefix} login with social network`,
    props<{ externalSocialNetwork: ExternalSocialNetwork, token: string }>()
);

