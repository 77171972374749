import { Action, createReducer, on } from '@ngrx/store';

import { Affirmation, AffirmationReaction } from '@core/models/affirmation.model';
import {
    AffirmationExistsGuardActions,
    AffirmationPageActions,
    AffirmationsApiActions,
    AffirmationsPageActions
} from '@core/store/affirmations/actions';


export interface State {
    affirmation: Affirmation;
    isPending: boolean;
}

export const initialState: State = {
    affirmation: null,
    isPending: false
};

const AffirmationReducer = createReducer(
    initialState,
    on(
        AffirmationsPageActions.religionSelected,
        AffirmationExistsGuardActions.guardTriggered,
        (state: State) => ({
            isPending: true,
            ...initialState
        })
    ),
    on(
        AffirmationPageActions.sendEmailToMe,
        AffirmationsPageActions.sendEmailToMe,
        (state: State) => ({
            ...state,
            isPending: true,
        })
    ),
    on(
        AffirmationsApiActions.sendEmailSucceeded,
        AffirmationsApiActions.sendEmailFailed,
        (state: State) => ({
            ...state,
            isPending: false,
        })
    ),
    on(
        AffirmationsApiActions.getAffirmationSucceeded,
        AffirmationExistsGuardActions.affirmationLoaded,
        (state: State, { affirmation }) => ({
            ...state,
            isPending: false,
            affirmation
        })
    ),
    on(
        AffirmationsApiActions.sendDisLikeSucceeded,
        (state: State) => {
            return {
                ...state,
                affirmation: {
                    ...state.affirmation,
                    reaction: getUpdatedReaction(state.affirmation.reaction, AffirmationReaction.disLike)
                }
            };
        }
    ),
    on(
        AffirmationsApiActions.sendLikeSucceeded,
        (state: State) => {
            return {
                ...state,
                affirmation: {
                    ...state.affirmation,
                    reaction: getUpdatedReaction(state.affirmation.reaction, AffirmationReaction.like)
                }
            };
        }
    )
);

export function reducer(state: State | undefined, action: Action) {
    return AffirmationReducer(state, action);
}

export const getAffirmation = (state: State) => state.affirmation;
export const getIsPending = (state: State) => state.isPending;

function getUpdatedReaction(oldReaction: AffirmationReaction, newReaction: AffirmationReaction): AffirmationReaction {
    if (oldReaction === newReaction) {
        return AffirmationReaction.none;
    }
    return newReaction;
}
