import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { RegistrationType } from '@core/models';
import * as fromCurrentUser from '@core/store/current-user';


@Injectable({
    providedIn: 'root'
})
export class ChangePasswordGuard implements CanActivate {
    constructor(
        private store: Store<fromCurrentUser.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(select(fromCurrentUser.getUser))
            .pipe(
                first(),
                map((user) => {
                    if (user?.registrationType === RegistrationType.siteRegistrationForm) {
                        return true;
                    }
                    return this.router.parseUrl('/me/edit');
                })
            );
    }
}
