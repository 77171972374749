import { Injectable } from '@angular/core';

import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { LocationService } from '@core/services';


@Injectable()
export class LocationServiceEffects {

    routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            tap(({payload}: RouterNavigatedAction) => {
                this.locationService.push(payload.routerState.url);
            })
        );
    }, {dispatch: false});

    constructor(
        private actions$: Actions,
        private locationService: LocationService
    ) {
    }
}
