<div class="buttons-wrapper">
    <button
        class="additional-info-block-button"
        type="button"
        [disabled]="!followings.length"
        [class.active]="expandedRecordsType === infoBlock.followings"
        (click)="recordsBlockTriggerClick$.emit(infoBlock.followings)"
    >
        <span class="icon" id="follow-icon"><mat-icon>favorite</mat-icon></span>
        <span class="badge">{{ followings.length }}</span>
        <span class="text">
            Who I follow
        </span>
    </button>
    <button
        class="additional-info-block-button"
        type="button"
        [class.active]="expandedRecordsType === infoBlock.feedQuestions"
        [disabled]="!feedRecords[infoBlockToFeedRecordMap[infoBlock.feedQuestions]]?.length"
        (click)="recordsBlockTriggerClick$.emit(infoBlock.feedQuestions)"
    >
        <span class="icon"><mat-icon>help_outline</mat-icon></span>
        <span
            class="badge">{{ feedRecords[infoBlockToFeedRecordMap[infoBlock.feedQuestions]]?.length || 0 }}</span>
        <span class="text">
            My questions
        </span>
    </button>
    <button
        class="additional-info-block-button"
        type="button"
        [disabled]="!feedRecords[infoBlockToFeedRecordMap[infoBlock.feedCanHelpSettings]]?.length"
        [class.active]="expandedRecordsType === infoBlock.feedCanHelpSettings"
        (click)="recordsBlockTriggerClick$.emit(infoBlock.feedCanHelpSettings)"
    >
        <span class="icon"><mat-icon>star_bordered</mat-icon></span>
        <span
            class="badge">{{ feedRecords[infoBlockToFeedRecordMap[infoBlock.feedCanHelpSettings]]?.length || 0 }}</span>
        <span class="text">
            My help given
        </span>
    </button>
    <button
        class="additional-info-block-button"
        type="button"
        [disabled]="!feedRecords[infoBlockToFeedRecordMap[infoBlock.feedNeedHelpSettings]]?.length"
        [class.active]="expandedRecordsType === infoBlock.feedNeedHelpSettings"
        (click)="recordsBlockTriggerClick$.emit(infoBlock.feedNeedHelpSettings)"
    >
        <span class="icon"><mat-icon>emoji_people</mat-icon></span>
        <span
            class="badge">{{ feedRecords[infoBlockToFeedRecordMap[infoBlock.feedNeedHelpSettings]]?.length || 0 }}</span>
        <span class="text">
            My help requests
        </span>
    </button>
</div>
<div class="profile-records-wrapper"
     *ngIf="
         expandedRecordsType !== infoBlock.followings
         && feedRecords[infoBlockToFeedRecordMap[expandedRecordsType]]
         && feedRecords[infoBlockToFeedRecordMap[expandedRecordsType]].length
     "
>
    <app-user-profile-feed-record
        *ngFor="let data of feedRecords[infoBlockToFeedRecordMap[expandedRecordsType]]"
        [data]="data"
        [recordType]="infoBlockToFeedRecordMap[expandedRecordsType]"
        [isCurrentUser]="isCurrentUser"
        [isExpanded]="expandedRecord?.id === data.id && expandedRecord?.recordType === infoBlockToFeedRecordMap[expandedRecordsType]"
        (click$)="recordClick$.emit($event)"
        (delete$)="deleteRecord$.emit($event)"
    >
        <app-private-chat-button
            [interlocutorId]="user.id"
            [asLink]="true"
            [privateMessageUrl]="getPrivateMessageUrl(data.id)"
        >Private subject message
        </app-private-chat-button>
    </app-user-profile-feed-record>
</div>

<app-users-list
    *ngIf="expandedRecordsType === infoBlock.followings && followings && followings"
    [currentUserId]="currentUserId"
    [users]="followings"
    [showFollowButton]="isCurrentUser"
    (toggleFollow$)="toggleFollow$.emit($event)"
></app-users-list>
