import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '@core/window';


@Injectable({
    providedIn: 'root'
})

export class YoutubeApiLoaderService {

    readonly src = 'https://www.youtube.com/iframe_api';

    private loadScript() {
        const script = (this.window as Window).document.createElement<'script'>('script');
        script.async = true;
        script.defer = true;
        script.src = this.src;
        (this.window as Window).document.getElementsByTagName<'body'>('body')[0].append(script);
    }

    constructor(@Optional() @Inject(WINDOW) private window) {
        if (this.window) {
            this.loadScript();
        }
    }
}
