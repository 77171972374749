import { Injectable } from '@angular/core';


@Injectable()
export class CookieService {
    private readonly millisecondsPerDay = 24 * 60 * 60 * 1000;
    private cookies = new Map<string, string>();

    constructor() {
        if (typeof document !== 'undefined') {
            document.cookie.split(';')
                .forEach(cookie => {
                    if (cookie) {
                        const [name, value] = cookie.split('=');
                        this.cookies.set(name.trim(), value.trim());
                    }
                });
        }
    }

    public getCookie(name: string): string {
        if (this.cookies.size) {
            return this.cookies.get(name) || '';
        }
        return '';
    }

    public deleteCookie(name: string): void {
        this.setCookie(name, '', -1);
    }

    public setCookie(name: string, value: string, expireDays: number, path: string = ''): void {
        if (typeof document !== 'undefined' && this.cookies.size) {
            const expires: Date = new Date();
            expires.setTime(expires.getTime() + expireDays * this.millisecondsPerDay);
            const parts = [`${name}=${value}`];
            parts.push(`expires=${expires.toUTCString()}`);
            if (path.length > 0) {
                parts.push(`path=${path}`);
            }
            document.cookie = parts.join(';');
            this.cookies.set(name, value);
        }
    }
}
