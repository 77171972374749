import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagsService } from '@core/services';
import { FeatureFlags } from '@core/models';


@Directive({
    selector: '[appFeatureFlag]'
})
export class FeatureFlagDirective implements OnInit {

    @Input()
    appFeatureFlag: keyof FeatureFlags | (keyof FeatureFlags)[];

    @Input()
    appFeatureFlagElse: TemplateRef<any>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private featureFlagsService: FeatureFlagsService
    ) {
    }

    ngOnInit(): void {
        if (this.featureFlagsService.isFeatureEnabled(this.appFeatureFlag)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else if (this.appFeatureFlagElse) {
            this.viewContainerRef.createEmbeddedView(this.appFeatureFlagElse);
        }
    }
}
