import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';


@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent extends WithDestroyedSubjectComponent implements OnInit {

    isDisabled = true;

    @Input()
    searchCriteria = '';

    @Input()
    minLength = 1;

    @ViewChild('searchInput', {static: true})
    searchInput: ElementRef;

    @Output()
    searched$ = new EventEmitter<string>();

    @Output()
    cleared$ = new EventEmitter<void>();

    updateIsDisabled() {
        if (!this.searchCriteria || !this.minLength) {
            this.isDisabled = true;
            return;
        }
        this.isDisabled = this.searchCriteria.length < this.minLength;
    }

    onEnter() {
        if (!this.isDisabled) {
            this.searched$.emit(this.searchCriteria);
        }
    }

    clear() {
        this.searchCriteria = '';
        this.updateIsDisabled();
        this.cleared$.emit();
    }

    ngOnInit(): void {
        this.updateIsDisabled();
    }
}
