import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';


@Injectable({
    providedIn: 'root'
})
export class ConfirmStepByStepGuideGuard implements CanActivate {
    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return combineLatest([
            this.store.pipe(select(fromCurrentUser.getPreferences)),
        ]).pipe(
            take(1),
            map(([preferences]) => {
                if (preferences.isShowMemberToolsPage) {
                    return true;
                }
                return this.router.parseUrl('/get-started');
            })
        );
    }
}
