import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { AffirmationsApiActions } from '@core/store/affirmations/actions';
import { AffirmationsService } from '@core/services/affirmations/affirmations.service';
import { Store } from '@ngrx/store';
import * as fromCurrentUser from '@core/store/current-user';
import { AffirmationReaction } from '@core/models/affirmation.model';
import { AffirmationPopupActions } from '@core/store/actions';


@Injectable()
export class AffirmationPopupEffects {
    getAffirmation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AffirmationPopupActions.init),
            exhaustMap(({religionId}) => {
                return this.affirmationsService.getAffirmationByReligionId(religionId).pipe(
                    map((response) => {
                        return AffirmationsApiActions.getPopupAffirmationSucceeded({ affirmation: response });
                    }),
                    catchError((error) => of(AffirmationsApiActions.getPopupAffirmationFailed({ error: error.error })))
                );
            })
        );
    });

    sendReaction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AffirmationPopupActions.reactionClicked),
            exhaustMap(({ id, reaction }) => {
                if (reaction === AffirmationReaction.like) {
                    return this.affirmationsService.likeAffirmation(id).pipe(
                        map(() => {
                            return AffirmationsApiActions.sendPopupLikeSucceeded();
                        }),
                        catchError((error) => of(AffirmationsApiActions.sendPopupDislikeFailed({ error: error.error })))
                    );
                } else {
                    return this.affirmationsService.disLikeAffirmation(id).pipe(
                        map(() => {
                            return AffirmationsApiActions.sendPopupDisLikeSucceeded();
                        }),
                        catchError((error) => of(AffirmationsApiActions.sendPopupDislikeFailed({ error: error.error })))
                    );
                }
            })
        );
    });

    constructor(
        private store: Store<fromCurrentUser.State>,
        private actions$: Actions,
        private affirmationsService: AffirmationsService
    ) {
    }
}
