import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {
    AuthGuard,
    MaintenanceModeGuard,
    StaticRedirectGuard,
    TagDescriptionGuard,
    TagExistsGuard,
    TagRedirectGuard,
    UnAuthGuard
} from '@core/guards';
import {EmptyComponent, MainComponent, MaintenancePageComponent, PageNotFoundComponent} from '@core/pages';
import {routes as landingPageRoutes} from './landing-pages-routes';
import {FeatureFlagGuard} from '@core/guards/feature-flag.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
        canActivate: [UnAuthGuard],
        data: {
            robotsNoIndex: true
        }
    },
    {
        path: 'maintenance',
        component: MaintenancePageComponent,
        canActivate: [MaintenanceModeGuard],
        canDeactivate: [MaintenanceModeGuard]
    },
    ...landingPageRoutes,
    {
        path: 'video-contest',
        loadChildren: () => import('./pages/landing/video-contest/video-contest.module').then(m => m.VideoContestModule),
        canActivate: [FeatureFlagGuard],
        data: {
            featureFlag: 'video-contest'
        }
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: 'me',
                loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule),
                canActivate: [AuthGuard],
                data: {
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex'
                }
            },
            {
                path: 'community/helpline',
                loadChildren: () => import('./pages/feed/feed.module').then(m => m.FeedModule),
                canActivate: [TagExistsGuard],
                canActivateChild: [
                    TagRedirectGuard,
                    TagExistsGuard,
                    TagDescriptionGuard
                ],
                data: {
                    isFeedPage: true,
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex'
                }
            },
            {
                path: 'community',
                canActivate: [StaticRedirectGuard],
                component: EmptyComponent,
            },
            {
                path: 'legal-info',
                loadChildren: () => import('./pages/legal-info/legal-info.module').then(m => m.LegalInfoModule)
            },
            {
                path: 'me/video-contest',
                loadChildren: () => import('./pages/video-contest/video-contest.module').then(m => m.VideoContestModule),
                canActivate: [AuthGuard, FeatureFlagGuard],
                data: {
                    featureFlag: 'video-contest',
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex'
                }
            },
            {
                path: 'me/conversations',
                loadChildren: () => import('./pages/conversations/conversions.module').then(m => m.ConversionsModule),
                canActivate: [AuthGuard],
                data: {
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex'
                }
            },
            {
                path: 'users',
                loadChildren: () => import('@pages/users/users.module').then(m => m.UsersModule),
                canActivateChild: [AuthGuard],
                data: {
                    robotsNoIndex: true,
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex',
                }
            },
            {
                path: 'about',
                loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule),
                data: {
                    pageTitle: 'About Us'
                }
            },
            {
                path: 'help-desk',
                loadChildren: () => import('@pages/help-desk/help-desk.module').then(m => m.HelpDeskModule),
                data: {
                    pageTitle: 'Contact Us'
                }
            },
            {
                path: 'invite-friend',
                loadChildren: () => import('./pages/invite-friend/invite-friend.module').then(m => m.InviteFriendModule),
                data: {
                    pageTitle: 'Invite a Friend',
                    featureFlag: 'tell-friend'
                }
            },
            {
                path: 'faq',
                loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
            },
            {
                path: 'geolocation-help',
                loadChildren: () => import('./pages/geolocation-help/geolocation-help.module').then(m => m.GeolocationHelpModule)
            },
            {
                path: 'affirmation',
                loadChildren: () => import('./pages/affirmation/affirmations.module').then(m => m.AffirmationModule),
                data: {
                    pageTitle: 'Affirmations',
                    appRootClass: 'screen-large'
                }
            },
            {
                path: 'search-subject',
                loadChildren: () => import('./pages/search-subject/search-subject.module').then(m => m.SearchSubjectModule),
                data: {
                    robotsNoIndex: true,
                    isLayoutFlex: true,
                    rootElementDisplay: 'flex',
                }
            },
            {
                path: '404',
                component: PageNotFoundComponent
            },
            {
                path: 'get-started',
                loadChildren: () => import('./pages/get-started/get-started.module').then(m => m.GetStartedModule),
                canActivateChild: [AuthGuard],
                data: {
                    isLayoutFlex: true
                }
            },
            {
                path: '',
                loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingModule),
                canActivate: [UnAuthGuard],
            },
            {
                path: 'learning-game',
                loadChildren: () => import('./pages/learning-game/learning-game.module').then(m => m.LearningGameModule),
                data: {
                    appRootClass: 'screen-large'
                }
            },
            {
                path: 'job-board',
                loadChildren: () => import('./pages/job-board/job-board.module').then(m => m.JobBoardModule),
                data: {
                    appRootClass: 'screen-large'
                }
            },
            {
                path: 'rapid-resources',
                loadChildren: () => import('./pages/rapid-resources/rapid-resources.module').then(m => m.RapidResourcesModule),
                data: {
                    appRootClass: 'screen-large'
                }
            },
            {
                path: 'marketplace',
                loadChildren: () => import('./pages/marketplaces/marketplaces.module').then(m => m.MarketplacesModule),
                data: {
                    appRootClass: 'screen-large'
                }
            },
            {
                path: 'invite-your-team',
                loadChildren: () => import('./pages/invite-your-team/invite-your-team.module').then(m => m.InviteYourTeamModule),
            },
            {
                path: 'care-librarian',
                loadChildren: () => import('./pages/care-librarian/care-librarian.module').then(m => m.CareLibrarianModule),
                canActivate: [FeatureFlagGuard],
                data: {
                    pageTitle: 'Care Librarian',
                    featureFlag: 'care-librarian',
                    appRootClass: 'screen-large',
                    isLayoutFlex: true
                }
            },
            {
                path: '**',
                component: PageNotFoundComponent
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
