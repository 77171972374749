import { createAction, props } from '@ngrx/store';

import { PublicUserModel, UserProfileFeedRecordDto } from '@core/models';
import { UserProfileAdditionalInfoBlock } from '@pages/user/containers';


export const prefix = '[View My Profile page]';

export const additionalBlockTriggerClick = createAction(
    `${prefix} additional block trigger click`,
    props<{ recordsType: UserProfileAdditionalInfoBlock }>()
);

export const feedRecordClick = createAction(
    `${prefix} toggle profile feed record`,
    props<UserProfileFeedRecordDto>()
);

export const deleteFeedSettings = createAction(
    `${prefix} deleted feed setting`,
    props<{ id: number }>()
);

export const deleteFeedQuestion = createAction(
    `${prefix} deleted feed question`,
    props<{ id: number }>()
);

export const unFollowUser = createAction(
    `${prefix} un follow user`,
    props<{ user: PublicUserModel }>()
);
