import { Action, createReducer, on } from '@ngrx/store';

import { AffirmationPopupActions } from '@core/store/actions';
import { Affirmation, AffirmationReaction } from '@core/models/affirmation.model';
import { AffirmationsApiActions } from '@core/store/affirmations/actions';


export const featureKey = 'affirmationPopup';

export interface State {
    isPending: boolean;
    affirmation: Affirmation;
}

export const initialState: State = {
    isPending: true,
    affirmation: null
};

const BlogPostsReducer = createReducer(
    initialState,
    on(
        AffirmationPopupActions.init,
        () => ({
            ...initialState
        })),
    on(AffirmationsApiActions.getPopupAffirmationSucceeded, (state, { affirmation }) => {
        return {
            ...state,
            isPending: false,
            affirmation
        };
    }),
    on(AffirmationsApiActions.getPopupAffirmationFailed, (state) => ({
        ...state,
        isPending: false,
    })),
    on(
        AffirmationsApiActions.sendPopupDisLikeSucceeded,
        (state: State) => {
            return {
                ...state,
                affirmation: {
                    ...state.affirmation,
                    reaction: state.affirmation.reaction === AffirmationReaction.disLike ? AffirmationReaction.none : AffirmationReaction.disLike
                }
            };
        }
    ),
    on(
        AffirmationsApiActions.sendPopupLikeSucceeded,
        (state: State) => {
            return {
                ...state,
                affirmation: {
                    ...state.affirmation,
                    reaction: state.affirmation.reaction === AffirmationReaction.like ? AffirmationReaction.none : AffirmationReaction.like
                }
            };
        }
    )
);

export function reducer(state: State | undefined, action: Action) {
    return BlogPostsReducer(state, action);
}

export const getIsPending = (state: State) => state.isPending;
export const getAffirmation = (state: State) => state.affirmation;
