import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Params, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { tap, withLatestFrom } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import { environment } from '../../../../environments/environment';


@Injectable()
export class SEOEffects {
    private readonly robotsTagName = 'robots';
    private readonly robotsTagSelector = `name="${this.robotsTagName}"`;
    private readonly canonicalLinkId = 'canonical-link';

    private canonicalLink: HTMLLinkElement;

    routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            withLatestFrom(this.store.pipe(select(fromRoot.getRouteData))),
            tap(([, routerData]) => {
                this.adjustRobotsMetaTag(routerData);
                this.adjustCanonicalLink();
            })
        );
    }, {dispatch: false});

    private getCanonicalLinkElement(): HTMLLinkElement {
        if (this.canonicalLink) {
            return this.canonicalLink;
        }
        const fromHtml = this.document.getElementById(this.canonicalLinkId);
        if (fromHtml) {
            this.canonicalLink = fromHtml as HTMLLinkElement;
            return this.canonicalLink;
        }
        this.canonicalLink = this.document.createElement<'link'>('link');
        this.canonicalLink.setAttribute('id', 'canonical-link');
        this.canonicalLink.setAttribute('rel', 'canonical');
        this.document.head.appendChild(this.canonicalLink);
        return this.canonicalLink;
    }


    private adjustCanonicalLink() {
        const urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams = {};
        urlTree.fragment = null;
        const href = this.router.serializeUrl(urlTree);
        this.getCanonicalLinkElement().href = environment.canonicalBaseHref + (href !== '/' ? href : '');
    }

    private adjustRobotsMetaTag(routerData: Params) {
        if (routerData && routerData.robotsNoIndex) {
            this.meta.addTag({
                name: this.robotsTagName,
                content: 'noindex'
            });
        } else if (this.meta.getTag(this.robotsTagSelector)) {
            this.meta.removeTag(this.robotsTagSelector);
        }
    }

    constructor(
        private meta: Meta,
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        @Inject(DOCUMENT) private document: Document
    ) {
    }
}
