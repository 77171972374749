import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TagsRedirects } from '@core/models';


export const TAGS_REDIRECTS_KEY = makeStateKey<TagsRedirects>('tagsRedirects');

@Injectable({
    providedIn: 'root'
})
export class TagsRedirectsService {

    private endpoint = '/tags-redirects';

    constructor(
        private http: HttpClient,
        private state: TransferState
    ) {
    }

    get(): Observable<TagsRedirects> {
        const tagsRedirects = this.state.get<TagsRedirects>(TAGS_REDIRECTS_KEY, null as TagsRedirects);

        if (tagsRedirects) {
            return of(tagsRedirects);
        }

        return this.http.get<TagsRedirects>(this.endpoint).pipe(
            map((response) => {
                this.state.set<TagsRedirects>(TAGS_REDIRECTS_KEY, response);
                return response;
            })
        );
    }
}
