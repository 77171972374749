import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { PrivateChatMessageModel, PrivateChatNewMessageResult, ReplyOnType } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class ConversationsService {

    readonly prefix = '/conversations';

    getHistory(userId: number, limit: number, direction: number = 0, cursor?: number): Observable<PrivateChatMessageModel[]> {
        const params = {
            limit: limit.toString(),
            direction: direction.toString(),
            cursor: '0'
        };
        if (cursor) {
            params.cursor = cursor.toString();
        }

        return this.http.get<PrivateChatMessageModel[]>(`${this.prefix}/${userId}/messages`, {params});
    }

    sendMessage(userId: number, message: string): Observable<PrivateChatNewMessageResult> {
        return this.http.post<PrivateChatNewMessageResult>(`${this.prefix}/${userId}/messages`, {
            message
        });
    }

    deleteMessage(messageId: number): Observable<void> {
        return this.http.delete<void>(`${this.prefix}/messages/${messageId}`);
    }

    editMessage(messageId: number, message: string): Observable<void> {
        return this.http.put<void>(`${this.prefix}/messages/${messageId}`, {
            message
        });
    }

    replyOnMessage(userId: number, replyOnId: number, message: string, replyOnType: ReplyOnType): Observable<PrivateChatNewMessageResult> {
        return this.http.post<PrivateChatNewMessageResult>(`${this.prefix}/${userId}/messages`, {
            replyOnId,
            message,
            replyOnType
        });
    }

    markMessageAsRead(messageId: number): Observable<void> {
        return this.http.put<void>(`${this.prefix}/messages/${messageId}/read`, {});
    }

    constructor(private http: HttpClient) {
    }
}
