import { createAction, props } from '@ngrx/store';

import { Religion } from '@core/models';
import { Affirmation } from '@core/models/affirmation.model';


const prefix = '[Affirmations Api]';

export const getReligionsSucceeded = createAction(
    `${prefix} get religions succeeded`,
    props<{ religions: Array<Religion> }>()
);

export const getReligionsFailed = createAction(
    `${prefix} get religions failed`,
    props<{ error: any }>()
);

export const getAffirmationSucceeded = createAction(
    `${prefix} get affirmation succeeded`,
    props<{ affirmation: Affirmation }>()
);

export const getAffirmationFailed = createAction(
    `${prefix} get affirmation failed`,
    props<{ error: any }>()
);

export const sendEmailSucceeded = createAction(
    `${prefix} send email succeeded`
);

export const sendEmailFailed = createAction(
    `${prefix} send email failed`,
    props<{ error: any }>()
);

export const sendLikeSucceeded = createAction(
    `${prefix} send Like succeeded`
);

export const sendLikeFailed = createAction(
    `${prefix} send Like failed`,
    props<{ error: any }>()
);

export const sendDisLikeSucceeded = createAction(
    `${prefix} send DisLike succeeded`
);

export const sendDislikeFailed = createAction(
    `${prefix} send DisLike failed`,
    props<{ error: any }>()
);

export const sendPopupLikeSucceeded = createAction(
    `${prefix} send Popup Like succeeded`
);

export const sendPopupLikeFailed = createAction(
    `${prefix} send Popup Like failed`,
    props<{ error: any }>()
);

export const sendPopupDisLikeSucceeded = createAction(
    `${prefix} send Popup DisLike succeeded`
);

export const sendPopupDislikeFailed = createAction(
    `${prefix} send Popup DisLike failed`,
    props<{ error: any }>()
);

export const getPopupAffirmationSucceeded = createAction(
    `${prefix} get Popup affirmation succeeded`,
    props<{ affirmation: Affirmation }>()
);

export const getPopupAffirmationFailed = createAction(
    `${prefix} get Popup affirmation failed`,
    props<{ error: any }>()
);
