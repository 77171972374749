import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import * as fromResources from '@core/store/resources';
import * as fromRoot from '@core/store';
import { ResourcesMenuActions } from '@core/store/resources/actions';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { Geolocation, ResourceModel } from '@core/models';
import { FeatureFlagsService, GeolocationService } from '@core/services';
import { GeolocationErrorDialogComponent } from '@shared/components';
import { environment } from 'src/environments/environment';
import { ShowHelpForParam } from '@core/enums';


@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent extends WithDestroyedSubjectComponent implements OnInit {

    nationalResources$ = this.store.pipe(select(fromResources.getNationalResources));

    localResourcesIsFirstResponseReceived$ = this.store.pipe(select(fromResources.getLocalResourcesIsFirstResponseReceived));

    localResourcesIsLoading = false;
    localResourcesHasMore = false;
    nationalResourcesIsLoading = false;
    nationalResourcesHasMore = false;
    localResources: ResourceModel[];
    localResourcesHint = `${environment.feed.resources.local.distance} your location`;
    geolocation: Geolocation;
    tagId: number;

    showHelpArrow = false;

    trackBy = (index: number, resource: ResourceModel) =>  resource.id;

    private getGeolocation(): Promise<Geolocation> {
        return new Promise<Geolocation>((resolve) => {
            this.geolocationService.getPosition()
                .then((geolocation) => {
                    resolve(geolocation);
                })
                .catch((error: GeolocationPositionError) => {
                    this.onPositionError(error);
                });
        });
    }

    private onPositionError(error: GeolocationPositionError) {
        GeolocationErrorDialogComponent.show(this.matDialog, error.code);
    }

    constructor(
        private store: Store<fromRoot.State>,
        private geolocationService: GeolocationService,
        private featureFlagsService: FeatureFlagsService,
        private matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.featureFlagsService.isFeatureEnabled(['local-resources', 'national-resources'])) {
            return;
        }
        if (this.featureFlagsService.isFeatureEnabled('local-resources')) {
            this.store.pipe(
                select(fromResources.getLocalResourcesGeolocation),
                withLatestFrom(this.store.pipe(select(fromRoot.getSelectedTag))),
                takeUntil(this.destroyed$)
            ).subscribe(([geolocation]) => {
                this.geolocation = geolocation;
                if (this.geolocation && this.tagId) {
                   this.store.dispatch(ResourcesMenuActions.loadLocalResources({tagId: this.tagId}));
                }
            });
        }

        this.store.pipe(
            select(fromRoot.getSelectedTag),
            takeUntil(this.destroyed$)
        ).subscribe((tag) => {
            if (!tag) {
                return;
            }
            this.tagId = tag.id;
            if (this.featureFlagsService.isFeatureEnabled('national-resources')) {
                this.store.dispatch(ResourcesMenuActions.initNationalResources({tagId: this.tagId}));
            }
            if (this.featureFlagsService.isFeatureEnabled('local-resources')) {
                this.store.dispatch(ResourcesMenuActions.initLocalResources({tagId: this.tagId}));
            }
        });

        this.store.pipe(
            select(fromResources.getLocalResources),
            takeUntil(this.destroyed$)
        ).subscribe((resources) => {
            this.localResources = resources;
        });

        this.store.pipe(
            select(fromRoot.getShowHelpFor),
            filter((showHelpFor) => !!showHelpFor),
            takeUntil(this.destroyed$)
        ).subscribe((showHelpFor) => {
            if (showHelpFor === ShowHelpForParam.find) {
                this.showHelpArrow = true;
            }
        });

        this.store.pipe(
            select(fromResources.getLocalResourcesIsLoading),
            takeUntil(this.destroyed$)
        ).subscribe((localIsLoading) => {
            this.localResourcesIsLoading = localIsLoading;
        });

        this.store.pipe(
            select(fromResources.getLocalResourcesHasMore),
            takeUntil(this.destroyed$)
        ).subscribe((localHasMore) => {
            this.localResourcesHasMore = localHasMore;
        });

        this.store.pipe(
            select(fromResources.getNationalResourcesIsLoading),
            takeUntil(this.destroyed$)
        ).subscribe((isLoading) => {
            this.nationalResourcesIsLoading = isLoading;
        });

        this.store.pipe(
            select(fromResources.getNationalResourcesHasMore),
            takeUntil(this.destroyed$)
        ).subscribe((hasMore) => {
            this.nationalResourcesHasMore = hasMore;
        });
    }

    loadNationalResources() {
        if (this.nationalResourcesHasMore && !this.nationalResourcesIsLoading) {
            this.store.dispatch(ResourcesMenuActions.loadNationalResources({ tagId: this.tagId }));
        }
    }

    loadLocalResources() {
        if (this.localResourcesHasMore && !this.localResourcesIsLoading) {
            this.store.dispatch(ResourcesMenuActions.loadLocalResources({tagId: this.tagId}));
        }
    }


    requestGeolocation() {
        this.getGeolocation()
            .then((geolocation) => {
                this.store.dispatch(ResourcesMenuActions.geolocationReceived({geolocation}));
            });
    }
}
