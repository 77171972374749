import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AdsActions, AppInitActions} from '@core/store/actions';
import {catchError, map} from 'rxjs/operators';
import {AdsService} from '@core/services/ads/ads.service';
import {of, switchMap} from 'rxjs';

@Injectable()
export class AdsEffects {
    loadAds$ = createEffect(() => this.actions$.pipe(
        ofType(AppInitActions.loadSettings),
        switchMap(() => {
             return this.adsService.getAds().pipe(
                map((ads) => AdsActions.adsLoadSuccess({ads})),
                catchError((error) => of(AdsActions.adsLoadFailed({error: error.error})))
             );
        })
    ));

    constructor(
        private adsService: AdsService,
        private actions$: Actions,
    ) {
    }
}
