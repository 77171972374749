import { StaticRedirectGuard, UnAuthGuard } from '@core/guards';
import { LandingPageType } from '@pages/landing/landing-pages/containers/organization-landing-page-template/landingTemplateParameters';
import { EmptyComponent } from '@core/pages';


const config = [
    {
        path: 'connecting_caregivers',
        data: {
            landingPageType: LandingPageType.connectingCaregivers
        }
    },
    {
        path: 'connecting_caregivers_2',
        data: {
            landingPageType: LandingPageType.connectingCaregivers2
        }
    },
    {
        path: 'connecting_caregivers_caringkind',
        data: {
            landingPageType: LandingPageType.connectingCaregiversCaringkind
        }
    },
    {
        path: 'connecting_caregivers_caringkind_2',
        data: {
            landingPageType: LandingPageType.connectingCaregiversCaringkind2
        }
    },
    {
        path: 'organization_for_autism_research',
        data: {
            landingPageType: LandingPageType.organizationForAutismResearch,
            pageTitle: 'Organization for autism research landing page'
        }
    },
    {
        path: 'organization_caringkind',
        data: {
            landingPageType: LandingPageType.organizationCaringkind,
            pageTitle: 'CaringKind'
        }
    },
    {
        path: 'organization_sdvc_family_life_action_group',
        data: {
            landingPageType: LandingPageType.organizationSdvcFamilyLifeActionGroup,
            pageTitle: 'Family Life Action Group'
        }
    },
    {
        path: 'organization_music_care_inc',
        data: {
            landingPageType: LandingPageType.organizationMusicCareInc,
            pageTitle: 'Music Care Inc'
        }
    },
    {
        path: 'organization_oar',
        data: {
            landingPageType: LandingPageType.organizationOar,
            pageTitle: 'OAR'
        }
    },
    {
        path: 'organization_sdvc_physical_and_emotional_health_group',
        data: {
            landingPageType: LandingPageType.organizationSdvcPhysicalAndEmotionalHealthGroup,
            pageTitle: 'SDVC Physical and Emotional Health Group (PEH)'
        }
    },
    {
        path: 'organization_startout',
        data: {
            landingPageType: LandingPageType.organizationStartout,
            pageTitle: 'StartOut'
        }
    },
    {
        path: 'organization_sdvc',
        data: {
            landingPageType: LandingPageType.organizationSdvc,
            pageTitle: 'San Diego Veterans Coalition'
        }
    },
    {
        path: 'organization_helping_paws',
        data: {
            landingPageType: LandingPageType.organizationHelpingPaws,
            pageTitle: 'Helping PAWS'
        }
    },
    {
        path: 'organization_psycharmor',
        data: {
            landingPageType: LandingPageType.organizationPsychArmor,
            pageTitle: 'PsychArmor'
        }
    },
    {
        path: 'organization_maphabit',
        data: {
            landingPageType: LandingPageType.organizationMapHabit,
            pageTitle: 'MapHabit'
        }
    },
    {
        path: 'organization_southern_caregiver_resource_center',
        data: {
            landingPageType: LandingPageType.organizationSouthernCaregiverResourceCenter,
            pageTitle: 'Southern Caregiver Resource Center'
        }
    },
    {
        path: 'organization_autism_tree_project_foundation',
        data: {
            landingPageType: LandingPageType.organizationAutismTreeProjectFoundation,
            pageTitle: 'Autism Tree Project Foundation'
        }
    },
    {
        path: 'organization_autism_society',
        data: {
            landingPageType: LandingPageType.organizationAutismSociety,
            pageTitle: 'Autism Society'
        }
    },
    {
        path: 'organization_soldiers_angels',
        data: {
            landingPageType: LandingPageType.organizationSoldiersAngels,
            pageTitle: 'Soldiers\' Angels'
        }
    },
    {
        path: 'organization_no_limits_media',
        data: {
            landingPageType: LandingPageType.organizationNoLimitsMedia,
            pageTitle: 'No Limits Media'
        }
    },
    {
        path: 'organization_sdvc_education_employment_entrepreneurship_e3_action_group',
        data: {
            landingPageType: LandingPageType.organizationSdvcEducationEmploymentGroup,
            pageTitle: 'SDVC Education, Employment, Entrepreneurship (E3) Action Group'
        }
    },
    {
        path: 'organization_sdvc_vest_action_group',
        data: {
            landingPageType: LandingPageType.organizationSdvcVestActionGroup,
            pageTitle: 'V.E.S.T. Action Group'
        }
    },
    {
        path: 'organization_cohen_veterans_network',
        data: {
            landingPageType: LandingPageType.organizationCohenVeteransNetwork,
            pageTitle: 'Cohen Veterans Network'
        }
    },
    {
        path: 'organization_outburo',
        data: {
            landingPageType: LandingPageType.organizationOutburo,
            pageTitle: 'OUTBURO'
        }
    },
    {
        path: 'organization_one_more_wave',
        data: {
            landingPageType: LandingPageType.organizationOneMoreWave,
            pageTitle: 'One More Wave'
        }
    },
    {
        path: 'organization_elizabeth_hospice',
        data: {
            landingPageType: LandingPageType.organizationElizabethHospice,
            pageTitle: 'Elizabeth Hospice'
        }
    },
    {
        path: 'assisted-by-nicole-dauz',
        data: {
            landingPageType: LandingPageType.assistedByNicoleDauz,
            pageTitle: 'Assisted by Nicole Dauz'
        }
    },
    {
        path: 'organization_mister_b_and_b',
        data: {
            landingPageType: LandingPageType.organizationMisterBAndB,
            pageTitle: 'Mister b&b'
        }
    },
    {
        path: 'organization_rhodes_perry_consulting',
        data: {
            landingPageType: LandingPageType.organizationRhodesPerryConsulting,
            pageTitle: 'Rhodes Perry Consulting'
        }
    },
    {
        path: 'organization_wounded_warrior_project',
        data: {
            landingPageType: LandingPageType.organizationWoundedWarriorProject,
            pageTitle: 'Wounded Warrior Project'
        }
    },
    {
        path: 'organization_hidden_heroes',
        data: {
            landingPageType: LandingPageType.organizationHiddenHeroes,
            pageTitle: 'Hidden Heroes'
        }
    },
    {
        path: 'organization_steward',
        data: {
            landingPageType: LandingPageType.organizationSteward,
            pageTitle: 'Steward'
        }
    }
];
export let routes = [];

config.forEach(({path, data}) => {
    routes.push({
        path,
        data,
        loadChildren: () => import('./pages/landing/landing-pages/landing-pages.module')
            .then(m => m.LandingPagesModule),
        canActivateChild: [UnAuthGuard]
    });
});

routes = routes.concat(
    {
        path: 'learn2',
        canActivate: [StaticRedirectGuard],
        component: EmptyComponent,
        data: {
            redirectTo: '/learn_about'
        }
    },
    {
        path: 'learn',
        loadChildren: () => import('./pages/landing/learn/learn.module').then(m => m.LearnModule)
    },
    {
        path: 'learn_about',
        canActivateChild: [UnAuthGuard],
        loadChildren: () => import('./pages/landing/learn-with-registration/learn-with-registration.module')
            .then(m => m.LearnWithRegistrationModule)
    },
    {
        path: 'extendatouch_caregivers_supporting_caregivers',
        canActivateChild: [UnAuthGuard],
        loadChildren: () => import('./pages/landing/caregivers-supporting-caregivers/caregivers-supporting-caregivers.module')
            .then(m => m.CaregiversSupportingCaregiversModule)
    }
);
