import { RoiLocation } from '@core/services';

export enum LogoPosition {
    top = 'top',
    left = 'left',
    right = 'right',
    bottom = 'bottom'
}

export enum LandingPageType {
    connectingCaregivers = 'connecting_caregivers',
    connectingCaregivers2 = 'connecting_caregivers_2',
    connectingCaregiversCaringkind = 'connecting_caregivers_caringkind',
    connectingCaregiversCaringkind2 = 'connecting_caregivers_caringkind_2',
    organizationForAutismResearch = 'organization_for_autism_research',
    organizationCaringkind = 'organization_caringkind',
    organizationSdvcFamilyLifeActionGroup = 'organization_sdvc_family_life_action_group',
    organizationMusicCareInc = 'organization_music_care_inc',
    organizationOar = 'organization_oar',
    organizationSdvcPhysicalAndEmotionalHealthGroup = 'organization_sdvc_physical_and_emotional_health_group',
    organizationStartout = 'organization_startout',
    organizationSdvc = 'organization_sdvc',
    organizationHelpingPaws = 'organization_helping_paws',
    organizationPsychArmor = 'organization_psycharmor',
    organizationMapHabit = 'organization_maphabit',
    organizationSouthernCaregiverResourceCenter = 'organization_southern_caregiver_resource_center',
    organizationAutismTreeProjectFoundation = 'organization_autism_tree_project_foundation',
    organizationAutismSociety = 'organization_autism_society',
    organizationSoldiersAngels = 'organization_soldiers_angels',
    organizationNoLimitsMedia = 'organization_no_limits_media',
    organizationSdvcEducationEmploymentGroup = 'organization_sdvc_education_employment_entrepreneurship_e3_action_group',
    organizationSdvcVestActionGroup = 'organization_sdvc_vest_action_group',
    organizationCohenVeteransNetwork = 'organization_cohen_veterans_network',
    organizationOutburo = 'organization_outburo',
    organizationOneMoreWave = 'organization_one_more_wave',
    organizationElizabethHospice = 'organization_elizabeth_hospice',
    assistedByNicoleDauz = 'assisted-by-nicole-dauz',
    organizationMisterBAndB = 'organization_mister_b_and_b',
    organizationRhodesPerryConsulting = 'organization_rhodes_perry_consulting',
    organizationWoundedWarriorProject = 'organization_wounded_warrior_project',
    organizationHiddenHeroes = 'organization_hidden_heroes',
    organizationSteward = 'organization_steward'
}

export enum CssVars {
    titleTextFontSize = '--title-text-font-size',
    titleTextLineHeight = '--title-text-line-height',
    titleTextFontSizeMobile = '--title-text-font-size-mobile',
    titleTextLineHeightMobile = '--title-text-line-height-mobile'
}

export type CssVarSetting = {
    name: CssVars,
    value: string
};

export type LandingParameters = {
    roiLocation: RoiLocation,
    title?: string,
    titleImage?: string,
    titleImageMaxWidth?: number,
    mainImage: string,
    logoPosition?: LogoPosition,
    withBorder?: boolean,
    titleTextStyles?: CssVarSetting[],
    showUsernameField?: boolean
};

export type LandingTemplateParams = {
    [key in LandingPageType]: LandingParameters
};

export const landingTemplateParameters: LandingTemplateParams = {
    [LandingPageType.connectingCaregivers]: {
        roiLocation: RoiLocation.connectingCaregivers,
        mainImage: '/assets/images/landing/connecting-caregivers/1.jpg',
    },
    [LandingPageType.connectingCaregivers2]: {
        roiLocation: RoiLocation.connectingCaregivers2,
        mainImage: '/assets/images/landing/connecting-caregivers/2.jpg',
    },
    [LandingPageType.connectingCaregiversCaringkind]: {
        roiLocation: RoiLocation.connectingCaregiversCaringkind,
        title: 'Welcome members and supporters',
        titleImage: '/assets/images/landing/connecting-caregivers/caringKindLogo.png',
        mainImage: '/assets/images/landing/connecting-caregivers/1.jpg',
    },
    [LandingPageType.connectingCaregiversCaringkind2]: {
        roiLocation: RoiLocation.connectingCaregiversCaringkind2,
        title: 'Welcome members and supporters',
        titleImage: '/assets/images/landing/connecting-caregivers/caringKindLogo.png',
        mainImage: '/assets/images/landing/connecting-caregivers/2.jpg',
    },
    [LandingPageType.organizationForAutismResearch]: {
        roiLocation: RoiLocation.organizationForAutismResearch,
        title: 'A warm welcome to the families of',
        titleImage: '/assets/images/landing/connecting-caregivers/oar-logo.png',
        titleImageMaxWidth: 262,
        mainImage: '/assets/images/landing/connecting-caregivers/oar-image.jpg',
    },
    [LandingPageType.organizationCaringkind]: {
        roiLocation: RoiLocation.organizationCaringkind,
        title: 'Welcome Caregivers',
        titleImage: '/assets/images/landing/caringkind/logo.png',
        titleImageMaxWidth: 690,
        mainImage: '/assets/images/landing/caringkind/main.jpg',
    },
    [LandingPageType.organizationSdvcFamilyLifeActionGroup]: {
        roiLocation: RoiLocation.organizationSdvcFamilyLifeActionGroup,
        title: 'Welcome Veterans and Caregivers Family Life Action Group (FLAG)',
        titleImage: '/assets/images/landing/family-life-action-group/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/family-life-action-group/main.jpg',
        logoPosition: LogoPosition.left
    },
    [LandingPageType.organizationMusicCareInc]: {
        roiLocation: RoiLocation.organizationMusicCareInc,
        title: 'Welcome Veterans  and Caregivers',
        titleImage: '/assets/images/landing/music-care-inc/logo.jpg',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/music-care-inc/main.jpg',
        logoPosition: LogoPosition.right,
        showUsernameField: true
    },
    [LandingPageType.organizationOar]: {
        roiLocation: RoiLocation.organizationOar,
        title: 'A warm welcome  to the  families supporting',
        titleImage: '/assets/images/landing/oar/logo.png',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/oar/main.jpg',
        logoPosition: LogoPosition.right,
        showUsernameField: true
    },
    [LandingPageType.organizationSdvcPhysicalAndEmotionalHealthGroup]: {
        roiLocation: RoiLocation.organizationSdvcPhysicalAndEmotionalHealthGroup,
        title: 'Welcome Veterans and Caregivers Physical and Emotional Health Group (PEH)',
        titleImage: '/assets/images/landing/physical-and-emotional-health/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/physical-and-emotional-health/main.jpg',
        logoPosition: LogoPosition.left
    },
    [LandingPageType.organizationStartout]: {
        roiLocation: RoiLocation.organizationStartout,
        title: 'Welcome LGBTQ+ Caregivers',
        titleImage: '/assets/images/landing/startout/logo.png',
        titleImageMaxWidth: 370,
        mainImage: '/assets/images/landing/startout/main.jpg',
        withBorder: true
    },

    [LandingPageType.organizationSdvc]: {
        roiLocation: RoiLocation.organizationSdvc,
        title: 'A warm welcome to the veterans and family caregivers',
        titleImage: '/assets/images/landing/sdvc/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/sdvc/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationHelpingPaws]: {
        roiLocation: RoiLocation.organizationHelpingPaws,
        title: 'Welcome Veterans and Caregivers who have been helped by',
        titleImage: '/assets/images/landing/helping-paws/logo.png',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/helping-paws/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationPsychArmor]: {
        roiLocation: RoiLocation.organizationPsychArmor,
        title: 'Welcome Veterans and Caregivers',
        titleImage: '/assets/images/landing/psycharmor/logo.png',
        titleImageMaxWidth: 220,
        mainImage: '/assets/images/landing/psycharmor/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationMapHabit]: {
        roiLocation: RoiLocation.organizationMapHabit,
        title: 'Welcome Caregivers who have been helped by',
        titleImage: '/assets/images/landing/maphabit/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/maphabit/main.jpg',
        logoPosition: LogoPosition.right,
        showUsernameField: true
    },
    [LandingPageType.organizationSouthernCaregiverResourceCenter]: {
        roiLocation: RoiLocation.organizationSouthernCaregiverResourceCenter,
        title: 'Welcome Veterans, Caregivers and Families who have been helped by',
        titleImage: '/assets/images/landing/caregiver-resource-center/logo.jpg',
        titleImageMaxWidth: 340,
        mainImage: '/assets/images/landing/caregiver-resource-center/main.jpg'
    },
    [LandingPageType.organizationAutismTreeProjectFoundation]: {
        roiLocation: RoiLocation.organizationAutismTreeProjectFoundation,
        title: 'A Warm Welcome to<br>the Families at the',
        titleImage: '/assets/images/landing/tree-project/logo.png',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/tree-project/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationAutismSociety]: {
        roiLocation: RoiLocation.organizationAutismSociety,
        title: 'Welcome Caregivers who have been<br>helped by the',
        titleImage: '/assets/images/landing/autism-society/logo.jpg',
        titleImageMaxWidth: 800,
        mainImage: '/assets/images/landing/autism-society/main.jpg',
        withBorder: true,
        showUsernameField: true
    },
    [LandingPageType.organizationSoldiersAngels]: {
        roiLocation: RoiLocation.organizationSoldiersAngels,
        title: 'Welcome Caregivers and<br>Soldiers\' Angels',
        titleImage: '/assets/images/landing/soldiers-angels/logo.png',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/soldiers-angels/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationNoLimitsMedia]: {
        roiLocation: RoiLocation.organizationNoLimitsMedia,
        title: 'Welcome to Caregivers who have been helped by',
        titleImage: '/assets/images/landing/no-limits-media/logo.jpg',
        titleImageMaxWidth: 200,
        mainImage: '/assets/images/landing/no-limits-media/main.jpg',
        logoPosition: LogoPosition.right
    },
    [LandingPageType.organizationSdvcEducationEmploymentGroup]: {
        roiLocation: RoiLocation.organizationSdvcEducationEmploymentGroup,
        title: 'Welcome Veterans and Caregivers Education, Employment, Entrepreneurship (E3) Action Group',
        titleImage: '/assets/images/landing/sdvc-education-employment-group/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/sdvc-education-employment-group/main.jpg',
        logoPosition: LogoPosition.left
    },
    [LandingPageType.organizationSdvcVestActionGroup]: {
        roiLocation: RoiLocation.organizationSdvcVestActionGroup,
        title: 'Welcome Veterans and Caregivers',
        titleImage: '/assets/images/landing/sdvc-vest-action-group/logo.jpg',
        titleImageMaxWidth: 650,
        mainImage: '/assets/images/landing/sdvc-vest-action-group/main.jpg'
    },
    [LandingPageType.organizationCohenVeteransNetwork]: {
        roiLocation: RoiLocation.organizationCohenVeteransNetwork,
        title: 'Welcome to Caregivers who have been helped by',
        titleImage: '/assets/images/landing/cohen-veterans-network/logo.png',
        titleImageMaxWidth: 330,
        mainImage: '/assets/images/landing/cohen-veterans-network/main.jpg'
    },
    [LandingPageType.organizationOutburo]: {
        roiLocation: RoiLocation.organizationOutburo,
        title: 'Introduces our<br>LGBTQ+ Caregivers',
        titleImage: '/assets/images/landing/outburo/logo.png',
        titleImageMaxWidth: 220,
        mainImage: '/assets/images/landing/outburo/main.jpg',
        logoPosition: LogoPosition.left
    },
    [LandingPageType.organizationOneMoreWave]: {
        roiLocation: RoiLocation.organizationOneMoreWave,
        title: 'Welcome to the disabled<br>Veterans whose lives have<br>been saved through surf therapy',
        titleImage: '/assets/images/landing/one-more-wave/logo.jpg',
        titleImageMaxWidth: 130,
        mainImage: '/assets/images/landing/one-more-wave/main.jpg',
        logoPosition: LogoPosition.right,
        withBorder: true
    },
    [LandingPageType.organizationElizabethHospice]: {
        roiLocation: RoiLocation.organizationElizabethHospice,
        title: 'Welcome to the families who have been compassionately assisted  by',
        titleImage: '/assets/images/landing/elizabeth-hospice/logo.jpg',
        titleImageMaxWidth: 180,
        mainImage: '/assets/images/landing/elizabeth-hospice/main.jpg',
        logoPosition: LogoPosition.right,
        withBorder: true
    },
    [LandingPageType.assistedByNicoleDauz]: {
        roiLocation: RoiLocation.assistedByNicoleDauz,
        title: 'Welcome to all who have<br>been compassionately<br>assisted by Nicole Dauz',
        titleImage: '/assets/images/landing/nicole-dauz/logo.jpg',
        titleImageMaxWidth: 240,
        mainImage: '/assets/images/landing/nicole-dauz/main.jpg',
        logoPosition: LogoPosition.right,
        withBorder: true
    },
    [LandingPageType.organizationMisterBAndB]: {
        roiLocation: RoiLocation.organizationMisterBAndB,
        title: 'Would like to introduce<br>our hosts, guests, and supporters',
        titleImage: '/assets/images/landing/mister-b-and-b/logo.png',
        titleImageMaxWidth: 750,
        mainImage: '/assets/images/landing/mister-b-and-b/main.jpg',
        logoPosition: LogoPosition.top,
        showUsernameField: true
    },
    [LandingPageType.organizationRhodesPerryConsulting]: {
        roiLocation: RoiLocation.organizationRhodesPerryConsulting,
        title: 'Welcome Caregivers and<br>fans of Rhodes Perry',
        titleImage: '/assets/images/landing/rhodes-perry-consulting/logo.png',
        titleImageMaxWidth: 750,
        mainImage: '/assets/images/landing/rhodes-perry-consulting/main.jpg',
        withBorder: true,
        showUsernameField: true
    },
    [LandingPageType.organizationWoundedWarriorProject]: {
        roiLocation: RoiLocation.organizationWoundedWarriorProject,
        title: 'Welcome Caregivers and members<br>of the Wounded Warrior Project<sup>&reg;</sup>',
        titleImage: '/assets/images/landing/wounded-warrior-project/logo.png',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/wounded-warrior-project/main.jpg',
        logoPosition: LogoPosition.left,
        withBorder: true
    },
    [LandingPageType.organizationHiddenHeroes]: {
        roiLocation: RoiLocation.organizationHiddenHeroes,
        title: 'Welcome Caregivers<br>and Supporters of<br>Hidden Heroes',
        titleImage: '/assets/images/landing/hidden-heroes/logo.jpg',
        titleImageMaxWidth: 170,
        mainImage: '/assets/images/landing/hidden-heroes/main.jpg',
        logoPosition: LogoPosition.left,
        withBorder: true
    },
    [LandingPageType.organizationSteward]: {
        roiLocation: RoiLocation.organizationSteward,
        title: 'Welcome<br>Caregivers<br>assigned by',
        titleImage: '/assets/images/landing/steward/logo.png',
        titleImageMaxWidth: 377,
        mainImage: '/assets/images/landing/steward/main.jpg',
        logoPosition: LogoPosition.right,
        withBorder: true,
        titleTextStyles: [
            {name: CssVars.titleTextFontSize, value: '50px'},
            {name: CssVars.titleTextLineHeight, value: '1.6'},
            {name: CssVars.titleTextFontSizeMobile, value: '25px'},
            {name: CssVars.titleTextLineHeightMobile, value: '1.2'}
        ]
    }
};
