import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TagModel, UserRole } from '@core/models';


@Component({
    selector: 'app-new-subject-confirmation',
    templateUrl: './new-subject-confirmation.component.html',
    styleUrls: ['./new-subject-confirmation.component.scss']
})
export class NewSubjectConfirmationComponent {
    readonly userRoles = UserRole;

    userRole: UserRole;

    @Input()
    parentTag: TagModel;

    @Input()
    newTagName: string;

    @Output()
    submitted$ = new EventEmitter<UserRole>();

    @Output()
    closed$ = new EventEmitter<any>();
}
