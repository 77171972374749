import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class MetaDescriptionService {
    set(content: string) {
        if (this.get()) {
            this.meta.updateTag({
                name: 'description',
                content
            });
        } else {
            this.meta.addTag({
                name: 'description',
                content
            });
        }
    }

    get() {
        return this.meta.getTag('name="description"');
    }

    remove() {
        this.meta.removeTag('name="description"');
    }

    constructor(private meta: Meta) {
    }
}
