import { createAction, props } from '@ngrx/store';


export const prefix = '[Call API]';

export const sendOfferSucceeded = createAction(
    `${prefix} send offer succeeded`,
    props<{ callId: number }>()
);

export const sendOfferFailed = createAction(
    `${prefix} send offer failed`,
    props<{ error: string }>()
);

export const sendCandidateSucceeded = createAction(
    `${prefix} send candidate succeeded`
);

export const sendCandidateFailed = createAction(
    `${prefix} send candidate failed`,
    props<{ error: string }>()
);

export const sendDeclineSucceeded = createAction(
    `${prefix} send decline succeeded`,
    props<{ callId: number }>()
);

export const sendDeclineFailed = createAction(
    `${prefix} send decline failed`,
    props<{ error: string }>()
);

export const sendAnswerSucceeded = createAction(
    `${prefix} send answer succeeded`
);

export const sendAnswerFailed = createAction(
    `${prefix} send answer failed`,
    props<{ error: string }>()
);

export const sendEndSucceeded = createAction(
    `${prefix} send end succeeded`
);

export const sendEndFailed = createAction(
    `${prefix} send end failed`,
    props<{ error: string }>()
);

export const sendNegotiateSucceeded = createAction(
    `${prefix} send negotiate succeeded`
);

export const sendNegotiateFailed = createAction(
    `${prefix} send negotiate failed'`,
    props<{ error: string }>()
);
