import { createAction, props } from '@ngrx/store';

import { Update } from '@ngrx/entity';

import {
    AfterFlattenConversationModel,
    CallInvitePayload,
    CallMessagePayload, CallMessagePayloadWithOffer,
    CallMessagePayloadWithSdp,
    PrivateChatMessageModel,
    PrivateChatNewMessageResult,
    PrivateMessagePrivateChatMessageDeletePayload,
} from '@core/models';


export const prefix = '[private messages WS]';

export const xAuthSocketTokenReceived = createAction(
    `${prefix} x-auth socket token received`,
    props<{ token: string }>()
);

export const chatMessageReceived = createAction(
    `${prefix} chat message received`,
    props<PrivateChatNewMessageResult>()
);

export const chatMessageEdited = createAction(
    `${prefix} chat message edited`,
    props<{
        conversationId: number,
        messageUpdate: Update<PrivateChatMessageModel>
    }>()
);

export const chatMessageDeleted = createAction(
    `${prefix} chat message deleted`,
    props<PrivateMessagePrivateChatMessageDeletePayload>()
);

export const newConversationReceived = createAction(
    `${prefix} new conversation received`,
    props<AfterFlattenConversationModel>()
);

export const reportUserReceived = createAction(
    `${prefix} report user received`
);

export const blockUserReceived = createAction(
    `${prefix} block user received`,
    props<{ userId: number }>()
);

export const callEndedReceived = createAction(
    `${prefix} call Ended received`,
    props<CallMessagePayload>()
);

export const callAcceptedReceived = createAction(
    `${prefix} call Accepted received`,
    props<CallMessagePayloadWithSdp>()
);

export const callAcceptedFromOtherSessionReceived = createAction(
    `${prefix} call Accepted from other session received`,
    props<CallMessagePayloadWithSdp>()
);

export const callDeclinedReceived = createAction(
    `${prefix} call Declined received`,
    props<CallMessagePayload>()
);

export const callIceCandidateReceived = createAction(
    `${prefix} call IceCandidate received`,
    props<CallMessagePayloadWithSdp>()
);

export const callInviteReceived = createAction(
    `${prefix} call Invite received`,
    props<CallInvitePayload>()
);

export const callInviteWhenCallIsActiveReceived = createAction(
    `${prefix} call Invite when call is active received`,
    props<CallInvitePayload>()
);

export const callNegotiateReceived = createAction(
    `${prefix} call negotiate received`,
    props<CallMessagePayloadWithOffer>()
);

export const wrongCallMessageReceived = createAction(
    `${prefix} wrong Call Message Received`,
);
