<div class="popup-header" mat-dialog-title>
    <div [innerHTML]="getTitleText"></div>
    <button
        class="btn-close-popup"
        type="button"
        (click)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <app-feed-user-settings-header
        [tag]="tag"
        [isCanHelp]="isCanHelp"
    ></app-feed-user-settings-header>
    <app-feed-user-settings-form
        [tag]="tag"
        [data]="data"
        [isCanHelp]="isCanHelp"
        (submit$)="submit($event)"
    ></app-feed-user-settings-form>
    <div id="buttons-wrapper">
        <button
            mat-stroked-button
            color="primary"
            class="action-button"
            (click)="formComponent.submit()"
        >Save and your story will show in the feed.
        </button>
    </div>
    <div id="privacy-text">
        Select “Save”, and your profile will appear in the feed of the subject above
    </div>
    <app-disclaimer></app-disclaimer>
</div>
