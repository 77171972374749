import { createAction, props } from '@ngrx/store';

import { PublicUserModel } from '@core/models';


export const prefix = '[Public user profile page]';

export const followUser = createAction(
    `${prefix} follow user`,
    props<{ user: PublicUserModel }>()
);

export const unFollowUser = createAction(
    `${prefix} un follow user`,
    props<{ user: PublicUserModel }>()
);


