<button
    mat-flat-button
    class="resources-button"
    #resourcesMenuTrigger="matMenuTrigger"
    [class.opened]="resourcesMenuTrigger.menuOpen"
    [mat-menu-trigger-for]="resourcesMenu"
>
    {{ title }}
    <span class="bouncing-button oblique" *ngIf="showHelpArrow"></span><mat-icon>play_arrow</mat-icon>
</button>
<mat-menu
    #resourcesMenu="matMenu"
    class="resources-menu"
>
    <div (click)="$event.stopPropagation()" class="resources-menu-content">
        <div class="title-wrapper">
            <span class="title">{{ title }}</span>
            <span class="hint" *ngIf="hint">{{ hint }}</span>
        </div>
        <ng-content></ng-content>
    </div>
</mat-menu>
