import { PublicUserModel } from '@core/models/user.model';


export interface PublicChatMessageFullModel {
    id: number;
    text: string;
    createdOn: string;
    tagId: number;
    isEdited: boolean;
    userFrom: PublicUserModel;
    replyOnText: string;
    replyOnCreatedOn: string;
    replyOnUser: PublicUserModel;
}

export type PublicChatMessageFlatModel = Omit<PublicChatMessageFullModel, 'userFrom' | 'replyOnUser'> & {
    userIdFrom: number,
    replyOnUserId: number
};

export const flattenPublicChatMessage = (message: PublicChatMessageFullModel): {
    message: PublicChatMessageFlatModel,
    users: PublicUserModel[]
} => {
    const users: { [userId: number]: PublicUserModel } = {
        [message.userFrom.id]: message.userFrom
    };
    if (message.replyOnUser) {
        users[message.replyOnUser.id] = message.replyOnUser;
    }
    return {
        message: {
            id: message.id,
            text: message.text,
            createdOn: message.createdOn,
            userIdFrom: message.userFrom.id,
            tagId: message.tagId,
            isEdited: message.isEdited,
            replyOnCreatedOn: message.replyOnCreatedOn,
            replyOnText: message.replyOnText,
            replyOnUserId: message.replyOnUser ? message.replyOnUser.id : null
        },
        users: Object.values(users)
    };
};

export const flattenPublicChatMessages = (messages: PublicChatMessageFullModel[]): {
    messages: PublicChatMessageFlatModel[],
    users: PublicUserModel[]
} => {
    const users: { [id: number]: PublicUserModel } = {};
    const flattenMessages: PublicChatMessageFlatModel[] = [];
    messages.forEach((message) => {
        const normalized = flattenPublicChatMessage(message);
        normalized.users.forEach((user) => {
            users[user.id] = user;
        });
        flattenMessages.push(normalized.message);
    });
    return {
        messages: flattenMessages,
        users: Object.values(users)
    };
};
