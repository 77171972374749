import { WsMessageModel } from '@core/models/ws-message.model';
import { ConversationApiModel } from '@core/models/conversation.model';
import { ConversationPrivateChatMessageModel, PrivateChatNewMessageResult } from '@core/models/private-chat-message.model';
import { PublicUserModel } from '@core/models/user.model';


export enum PrivateMessageType {
    privateChatMessageNew = 'PRIVATE_CHAT_MESSAGE_NEW',
    privateChatMessageEdit = 'PRIVATE_CHAT_MESSAGE_EDIT',
    privateChatMessageDelete = 'PRIVATE_CHAT_MESSAGE_DELETE',
    conversationNew = 'CONVERSATION_NEW',
    blockedGlobal = 'BLOCKED_GLOBAL',
    userBlock = 'USER_BLOCK',
    callEnded = 'CALL_ENDED',
    callAccepted = 'CALL_ACCEPTED',
    callDeclined = 'CALL_DECLINED',
    callIceCandidate = 'CALL_ICE_CANDIDATE',
    callInvite = 'CALL_INVITE',
    callNegotiationOffer = 'CALL_NEGOTIATION_OFFER',
}

export interface PrivateMessagePrivateChatMessageEditPayload {
    conversationId: number;
    id: number;
    text: string;
}

export interface PrivateMessagePrivateChatMessageDeletePayload {
    conversationId: number;
    id: number;
    newConversationMessage: ConversationPrivateChatMessageModel;
}

export interface CallMessagePayload {
    callId: number;
}

export interface CallMessagePayloadWithSdp extends CallMessagePayload {
    sdp: string;
}

export interface CallMessagePayloadWithOffer extends CallMessagePayload {
    offer: string;
}

export interface CallInvitePayload extends CallMessagePayloadWithSdp {
    user: PublicUserModel;
}


export type PrivateMessagePrivateChatMessageNew = WsMessageModel<PrivateMessageType.privateChatMessageNew,
    PrivateChatNewMessageResult>;
export type PrivateMessagePrivateChatMessageEdit = WsMessageModel<PrivateMessageType.privateChatMessageEdit,
    PrivateMessagePrivateChatMessageEditPayload>;
export type PrivateMessagePrivateChatMessageDelete = WsMessageModel<PrivateMessageType.privateChatMessageDelete,
    PrivateMessagePrivateChatMessageDeletePayload>;


export type PrivateMessageConversationNew = WsMessageModel<PrivateMessageType.conversationNew, ConversationApiModel>;
export type PrivateMessageBlockedGlobal  = WsMessageModel<PrivateMessageType.blockedGlobal, any>;
export type PrivateMessageUserBlock = WsMessageModel<PrivateMessageType.userBlock, any>;

export type PrivateMessageCallEnded = WsMessageModel<PrivateMessageType.callEnded, CallMessagePayload>;
export type PrivateMessageCallAccepted = WsMessageModel<PrivateMessageType.callAccepted, CallMessagePayloadWithSdp>;
export type PrivateMessageCallDeclined = WsMessageModel<PrivateMessageType.callDeclined, CallMessagePayload>;
export type PrivateMessageCallIceCandidate = WsMessageModel<PrivateMessageType.callIceCandidate, CallMessagePayloadWithSdp>;
export type PrivateMessageCallInvite = WsMessageModel<PrivateMessageType.callInvite, CallInvitePayload>;
export type PrivateMessageCallNegotiate = WsMessageModel<PrivateMessageType.callNegotiationOffer, CallMessagePayloadWithOffer>;

export type PrivateMessage = PrivateMessagePrivateChatMessageNew
    | PrivateMessagePrivateChatMessageEdit
    | PrivateMessagePrivateChatMessageDelete
    | PrivateMessageConversationNew
    | PrivateMessageBlockedGlobal
    | PrivateMessageUserBlock
    | PrivateMessageCallEnded
    | PrivateMessageCallAccepted
    | PrivateMessageCallDeclined
    | PrivateMessageCallIceCandidate
    | PrivateMessageCallInvite
    | PrivateMessageCallNegotiate
    ;
