import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

import * as fromRoot from '@core/store';
import { environment } from 'src/environments/environment';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class TagExistsGuard implements CanActivate, CanActivateChild {

    private redirect(destination: string, statusCode: number = 301): UrlTree {
        if (isPlatformServer(this.platformId)) {
            this.response.redirect(statusCode, `${environment.baseHref}${destination}`);
        }
        return this.router.parseUrl(destination);
    }

    private check(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromRoot.getSelectedTag),
            map((tag): UrlTree | boolean => {
                if (tag) {
                    return true;
                }
                return this.redirect(
                    route.data?.redirectTo || startPageNonAuthenticatedPath,
                    route.data?.redirectStatusCode || 301,
                );
            }),
        );
    }

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional() @Inject(RESPONSE) private response
    ) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.check(route);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.check(route);
    }
}
