import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Geolocation, ResourceModel } from '@core/models';
import { ResourcesMenuActions, ResourcesApiActions } from '@core/store/resources/actions';
import { ResourcesService } from '@core/services/resources/resources.service';


export interface State extends EntityState<ResourceModel> {
    page: number;
    isLoading: boolean;
    hasMore: boolean;
    isFirstResponseReceived: boolean;
    error: any;
    geolocation: Geolocation;
}

export const adapter = createEntityAdapter<ResourceModel>();

export const initialState: State = adapter.getInitialState({
    page: 1,
    isLoading: false,
    hasMore: true,
    isFirstResponseReceived: false,
    error: null,
    geolocation: null
});

const LocalResourcesReducer = createReducer(
    initialState,
    on(ResourcesMenuActions.initLocalResources, (state) => {
        return adapter.removeAll({
            ...initialState,
            geolocation: state.geolocation ? {...state.geolocation} : null
        });
    }),
    on(ResourcesMenuActions.loadLocalResources, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(ResourcesApiActions.loadLocalResourcesFailed, (state, error) => ({
        ...state,
        error,
        isLoading: false,
        hasMore: false,
        isFirstResponseReceived: true,
        isInitialized: true
    })),
    on(ResourcesApiActions.loadLocalResourcesSucceeded, (state, {resources}) =>
        adapter.upsertMany(resources, {
            ...state,
            page: resources.length ? state.page + 1 : state.page,
            isLoading: false,
            hasMore: resources.length === ResourcesService.pageLimit,
            isFirstResponseReceived: true,
            error: null,
            isInitialized: true
        })
    ),
    on(ResourcesMenuActions.geolocationReceived, (state, {geolocation}) => ({
        ...state,
        geolocation,
        hasMore: true
    })),
);

export function reducer(state: State | undefined, action: Action): State {
    return LocalResourcesReducer(state, action);
}

export const {
    selectAll: getLocalResources
} = adapter.getSelectors();

export const isLoading = (state: State) => state.isLoading;
export const getHasMore = (state: State) => state.hasMore;
export const getPage = (state: State) => state.page;
export const getGeolocation = (state: State) => state.geolocation;
export const getIsFirstResponseReceived = (state: State) => state.isFirstResponseReceived;
