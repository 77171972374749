import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { TagRedirectModel } from '@core/models';
import { InitialSettingsApiActions } from '@core/store/actions';


export const featureKey = 'tagsRedirects';

export type State = EntityState<TagRedirectModel>;

export const getEntityId = (model: TagRedirectModel): string => model.from;

export const adapter = createEntityAdapter<TagRedirectModel>({
    selectId: getEntityId
});

export const initialState: State = adapter.getInitialState();

const TagRedirectsReducer = createReducer(
    initialState,
    on(InitialSettingsApiActions.loadSucceeded, (state: State, {tagsRedirects}): State => {
        return adapter.setAll(tagsRedirects, initialState);
    })
);

export function reducer(state: State | undefined, action: Action): State {
    return TagRedirectsReducer(state, action);
}

export const {
    selectEntities: getRedirectsEntities
} = adapter.getSelectors();
