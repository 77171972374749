export enum ErrorType {
    wrongCurrentPassword = 'WRONG_CURRENT_PASSWORD',
    newPasswordIsSameAsCurrentPassword = 'NEW_PASSWORD_IS_SAME_AS_CURRENT_PASSWORD',
    usernameAlreadyExists = 'USERNAME_ALREADY_EXISTS',
    emailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
    recaptchaNotValid = 'RECAPTCHA_NOT_VALID',
    tagNotFound = 'TAG_NOT_FOUND',

    externalUserTokenInvalid = 'TOKEN_INVALID',
    externalUserNotfound = 'USER_NOT_FOUND',

    externalUserAlreadyExists = 'EXTERNAL_USER_ALREADY_EXISTS',
    registeredViaFacebook = 'REGISTER_VIA_FACEBOOK',
    registeredViaGoogle = 'REGISTER_VIA_GOOGLE',
    registeredViaMicrosoft = 'REGISTER_VIA_MICROSOFT',
    registeredViaApple = 'REGISTER_VIA_APPLE'
}

export interface ServerErrorModel {
    message: string;
    status: number;
    type: ErrorType;
}
