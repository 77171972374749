import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import Typed from 'typed.js';

import { CustomSvgIcon } from '@material/custom-svg-icon';
import * as fromRoot from '@core/store';
import { AffirmationPopupActions } from '@core/store/actions';
import { Affirmation, AffirmationReaction } from '@core/models/affirmation.model';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { AffirmationsApiActions } from '@core/store/affirmations/actions';
import { messages } from '@core/messages';


@Component({
    selector: 'app-affirmation-popup',
    templateUrl: './affirmation-popup.component.html',
    styleUrls: ['./affirmation-popup.component.scss'],
})
export class AffirmationPopupComponent extends WithDestroyedSubjectComponent {
    readonly AffirmationReaction = AffirmationReaction;
    readonly CustomSvgIcon = CustomSvgIcon;
    readonly spiritualImage = `/assets/images/affirmations/non-religion.png`;
    affirmation: Affirmation;
    isPending = false;
    typed: Typed;

    @ViewChild('content')
    content: ElementRef<HTMLElement>;

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private snackBar: MatSnackBar,
        public matDialogRef: MatDialogRef<AffirmationPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data: {
            religionId: string
        }
    ) {
        super();
        this.store.dispatch(AffirmationPopupActions.init({ religionId: + data.religionId || null }));
        this.store.pipe(
            select(fromRoot.getAffirmationPopupAffirmation),
            filter((affirmation) => !!affirmation),
            takeUntil(this.destroyed$)
        ).subscribe((affirmation) => {
            this.affirmation = affirmation;
            if (!this.typed) {
                this.typed = new Typed(this.content.nativeElement, {
                    strings: [this.affirmation.text || ''],
                    typeSpeed: 0,
                    showCursor: false
                });
            }
        });
        this.store.pipe(
            select(fromRoot.getAffirmationPopupIsPending),
            takeUntil(this.destroyed$)
        ).subscribe((isPending) => {
            this.isPending = isPending;
        });

        this.actions$.pipe(ofType(
                AffirmationsApiActions.sendPopupDislikeFailed,
                AffirmationsApiActions.sendPopupDislikeFailed,
            ),
            takeUntil(this.destroyed$)
        ).subscribe(() => {
            this.snackBar.open(messages.global.error);
        });

        this.actions$.pipe(ofType(AffirmationsApiActions.getPopupAffirmationFailed),
            takeUntil(this.destroyed$)
        ).subscribe(() => {
            this.snackBar.open(messages.global.error);
            this.matDialogRef.close();
        });
    }

    get religionUrl(): SafeUrl {
        return this.affirmation.religion?.icon || this.spiritualImage;
    }

    onReactionClicked(reaction: AffirmationReaction) {
        this.store.dispatch(AffirmationPopupActions.reactionClicked({ id: this.affirmation.id, reaction }));
    }
}
