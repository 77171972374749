import {createAction, props} from '@ngrx/store';
import {AdModel} from '@core/models';

export const prefix = '[Ads]';

export const adsLoadSuccess = createAction(
    `${prefix} ads loaded`,
    props<{ads: AdModel[]}>()
);

export const adsLoadFailed = createAction(
    `${prefix} ads load failed`,
    props<{error: any}>()
);

