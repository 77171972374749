import { Injectable } from '@angular/core';
import { MultipartFileUploadRef } from '@core/services/multipart-file-upload/multipart-file-upload-ref';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class MultipartFileUploadService {
    readonly apiPrefix = '/me/video-contest/multipart-upload';

    constructor(private httpClient: HttpClient) {
    }

    create(contentLength: number, contentType: string): Observable<{ uploadId: string }> {
        return this.httpClient.post<{ uploadId: string }>(`${this.apiPrefix}/create`, {
            contentLength,
            contentType
        });
    }

    complete(uploadId: string): Observable<void> {
        return this.httpClient.post<void>(`${this.apiPrefix}/${uploadId}/complete`, null);
    }

    delete(uploadId: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiPrefix}/${uploadId}`);
    }

    uploadPart(uploadId: string, partNumber: number, content: Blob): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.set('file', content);
        return this.httpClient.put(`${this.apiPrefix}/${uploadId}/${partNumber}`, formData, {
            params: {'ngsw-bypass': ''},
            reportProgress: true,
            observe: 'events'
        });
    }

    start(file: File): MultipartFileUploadRef {
        const ref = new MultipartFileUploadRef(file, this);

        this.create(file.size, file.type)
            .subscribe({
                next: ({ uploadId }) => {
                    ref.uploadId = uploadId;
                    ref.start();
                }, error: (error) => {
                    ref.onError(error.error.message);
                }
            });

        return ref;
    }

}
