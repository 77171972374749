<div class="user-profile-wrapper">
    <div id="avatar-wrapper">
        <app-user-avatar
            [user]="user"
            [isOnline]="isCurrentUser"
            [hideOnlineIndicator]="true"
        ></app-user-avatar>
    </div>
    <div id="info-wrapper">
        <app-username id="username" class="color-primary-700" [user]="user"></app-username>
        <div id="distance" class="color-primary-100" *ngIf="!isCurrentUser && user.distance !== null">
            <span *ngIf="user.distance">
                ~{{ user.distance }} miles away
            </span>
            <span *ngIf="!user.distance">
                near to you
            </span>
        </div>
        <div id="status" [class.color-accent]="isOnline">
            <span *ngIf="isOnline" id=online-marker></span>{{ isOnline ? 'online' : 'offline' }} now
        </div>
    </div>
</div>
