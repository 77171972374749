import { createAction, props } from '@ngrx/store';


export const prefix = '[Question page]';

export const createQuestion = createAction(
    `${prefix} create question`,
    props<{ tagId: number, text: string }>()
);

export const editQuestion = createAction(
    `${prefix} edit question`,
    props<{ id: number, text: string }>()
);

export const deleteQuestion = createAction(
    `${prefix} delete question`,
    props<{ id: number }>()
);
