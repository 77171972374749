import { Action, createReducer, on } from '@ngrx/store';

import { ConversationsPageFilter } from '@core/models';
import { ConversationsPageActions } from '@core/store/conversations/actions';


export interface State {
    filter: ConversationsPageFilter;
    isPending: boolean;
    error: any;
}

export const initialState: State = {
    filter: ConversationsPageFilter.all,
    isPending: true,
    error: null
};

const ConversationsListReducer = createReducer(
    initialState,
    on(ConversationsPageActions.pageInit, state => ({
        ...state,
        isPending: false,
        error: null
    })),
    on(ConversationsPageActions.changeFilter, (state, {filter}) => ({
        ...state,
        filter
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return ConversationsListReducer(state, action);
}

export const getIsPending = (state: State) => state.isPending;
export const getFilter = (state: State) => state.filter;
export const getError = (state: State) => state.error;
