import { Action, createReducer, on } from '@ngrx/store';

import { AuthApiActions, LoginPageActions, RegistrationPageActions } from '@core/store/auth/actions';
import { RegistrationType, ServerErrorModel } from '@core/models';


export interface State {
    isPending: boolean;
    isError: boolean;
    registrationType: RegistrationType;
    error: ServerErrorModel | null;
}

export const initialState: State = {
    isPending: false,
    isError: false,
    registrationType: RegistrationType.siteRegistrationForm,
    error: null
};

const LoginPageReducer = createReducer(
    initialState,
    on(LoginPageActions.init, () => initialState),
    on(LoginPageActions.submitted, (state): State => {
        return {
            ...state,
            isPending: true,
            isError: false,
        };
    }),
    on(AuthApiActions.loginSucceeded, (): State => {
        return {
            isPending: false,
            isError: false,
            registrationType: RegistrationType.siteRegistrationForm,
            error: null
        };
    }),
    on(
        AuthApiActions.loginFailed,
        (state, action): State => {
            return {
                ...state,
                isPending: false,
                error: action.error
            };
        }),
    on(LoginPageActions.loginWithSocialNetwork, (state, {externalSocialNetwork}): State => {
        return {
            ...state,
            isPending: true,
            isError: false,
            error: null,
            registrationType: externalSocialNetwork
        };
    }),
    on(
        AuthApiActions.loginWithSocialNetworkSucceeded,
        (state, {externalSocialNetwork}): State => {
            return {
                ...state,
                isPending: false,
                isError: false,
                error: null,
                registrationType: externalSocialNetwork
            };
        }),
    on(
        AuthApiActions.loginWithSocialNetworkFailed,
        (state, {externalSocialNetwork, error}): State => {
            return {
                ...state,
                isPending: false,
                isError: true,
                error,
                registrationType: externalSocialNetwork
            };
        }),
    on(
        RegistrationPageActions.submittedWithSocialNetwork,
        (state) => ({
            ...state,
            error: null
        })
    )
);

export const reducer = (state: State | undefined, action: Action) => {
    return LoginPageReducer(state, action);
};


export const getIsError = (state: State) => state.isError;
export const getIsPending = (state: State) => state.isPending;
export const getError = (state: State) => state.error;
export const getRegistrationType = (state: State) => state.registrationType;
