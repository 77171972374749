import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromAffirmation from './affirmation.reducer';
import * as fromReligions from './religions.reducer';


export const featureKey = 'affirmations';

export interface AffirmationsState {
    affirmation: fromAffirmation.State;
    religions: fromReligions.State;
}

export interface State extends fromRoot.State {
    affirmations: AffirmationsState;
}

export function reducers(state: AffirmationsState | undefined, action: Action) {
    return combineReducers({
        affirmation: fromAffirmation.reducer,
        religions: fromReligions.reducer
    })(state, action);
}

const getAffirmationsState = createFeatureSelector<AffirmationsState>(featureKey);

const getAffirmationState = createSelector(getAffirmationsState, (state) => state.affirmation);
export const getAffirmation = createSelector(getAffirmationState, fromAffirmation.getAffirmation);
export const getAffirmationIsPending = createSelector(getAffirmationState, fromAffirmation.getIsPending);

const getReligionsState = createSelector(getAffirmationsState, (state) => state.religions);
export const getReligions = createSelector(getReligionsState, fromReligions.getReligions);

export const getAffirmationIdFromRoute = createSelector(fromRoot.getRouteParams, (params) => params && +params.affirmationId);
