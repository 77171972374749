import { createAction, props } from '@ngrx/store';

import { UsersStatsModel } from '@core/models';


export const prefix = '[Users Stats Api]';

export const loadStatsForAllTagsSucceeded = createAction(
    `${prefix} load stats for all tags succeeded`,
    props<{ stats: UsersStatsModel[] }>()
);

export const loadStatsFailed = createAction(
    `${prefix} load stats failed`,
    props<{ error: string }>()
);
