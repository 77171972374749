import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-layout-auth',
    templateUrl: './layout-auth.component.html',
    styleUrls: ['./layout-auth.component.scss']
})
export class LayoutAuthComponent {
    @Input()
    showBtnClose = true;
}
