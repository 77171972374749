import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExternalSocialNetwork, RegistrationModel, RegistrationType, SocialRegistrationModel } from '@core/models';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    readonly tokenKey = '[eet]token';
    readonly campaignKey = 'campaign';

    readonly apiPrefix = '/auth';

    private getCampaignSessionKey(): string {
        return this.sessionStorageService.getItem(this.campaignKey);
    }

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService
    ) {
    }

    getToken(): string | null {
        return this.localStorageService.getItem(this.tokenKey);
    }

    setToken(token: string): AuthService {
        this.localStorageService.setItem(this.tokenKey, token);
        return this;
    }

    invalidateToken(): AuthService {
        this.localStorageService.removeItem(this.tokenKey);
        return this;
    }

    register(model: RegistrationModel): Observable<{ token: string }> {
        return this.http.post<{ token: string }>(`${this.apiPrefix}/register`, {
            ...model,
            campaign: this.getCampaignSessionKey()
        });
    }

    login(username: string, password: string): Observable<{ token: string }> {
        return this.http.post<{ token: string }>(`${this.apiPrefix}/login`, {
            username,
            password
        });
    }

    logout(): Observable<void> {
        return this.http.get<void>('/logout');
    }

    isUsernameUnique(username: string): Observable<{ isAvailable: boolean }> {
        return this.http.post<{ isAvailable: boolean }>(`${this.apiPrefix}/is-username-available`, {username});
    }

    isEmailUnique(email: string): Observable<{ isAvailable: boolean }> {
        return this.http.post<{ isAvailable: boolean }>(`${this.apiPrefix}/is-email-available`, {email});
    }

    resetPassword(email: string): Observable<unknown> {
        return this.http.post(`${this.apiPrefix}/reset-password`, {email});
    }

    updatePassword(password: string, token: string): Observable<unknown> {
        return this.http.post(`${this.apiPrefix}/update-password`, {password, token});
    }

    removeCampaignSessionKey() {
        this.sessionStorageService.removeItem(this.campaignKey);
    }

    registerWithSocialNetwork(externalSocialNetwork: ExternalSocialNetwork, model: SocialRegistrationModel): Observable<{ token: string }> {
        switch (externalSocialNetwork) {
            case RegistrationType.google:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/google/register`, {
                    ...model
                });
            case RegistrationType.facebook:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/facebook/register`, {
                    ...model
                });
            case RegistrationType.microsoft:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/microsoft/register`, {
                    ...model
                });
            case RegistrationType.apple:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/apple/register`, {
                    ...model
                });
        }
    }

    validateToken(externalSocialNetwork: ExternalSocialNetwork, token: string): Observable<void> {
        switch (externalSocialNetwork) {
            case RegistrationType.google:
                return this.http.post<void>(`${this.apiPrefix}/google/token-validate`, {
                    token
                });
            case RegistrationType.facebook:
                return this.http.post<void>(`${this.apiPrefix}/facebook/token-validate`, {
                    token
                });
            case RegistrationType.microsoft:
                return this.http.post<void>(`${this.apiPrefix}/microsoft/token-validate`, {
                    token
                });
            case RegistrationType.apple:
                return this.http.post<void>(`${this.apiPrefix}/apple/token-validate`, {
                    token
                });
        }
    }

    loginWithSocialNetwork(externalSocialNetwork: ExternalSocialNetwork, token: string): Observable<{ token: string }> {
        switch (externalSocialNetwork) {
            case RegistrationType.google:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/google/login`, {
                    token
                });
            case RegistrationType.facebook:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/facebook/login`, {
                    token
                });
            case RegistrationType.microsoft:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/microsoft/login`, {
                    token
                });
            case RegistrationType.apple:
                return this.http.post<{ token: string }>(`${this.apiPrefix}/apple/login`, {
                    token
                });
        }
    }
}
