import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { UsersStatsApiActions } from '@core/store/actions';
import { UsersStatsModel } from '@core/models';


export interface State extends EntityState<UsersStatsModel> {
    isLoading: boolean;
    isLoaded: boolean;
    error: string;
}

export const adapter: EntityAdapter<UsersStatsModel> = createEntityAdapter<UsersStatsModel>({
    selectId: (model: UsersStatsModel): number => {
        return model.tagId || 0;
    }
});

export const initialState: State = adapter.getInitialState({
    isLoaded: false,
    isLoading: false,
    error: null
});

const UsersStatsReducer = createReducer(
    initialState,
    on(
        UsersStatsApiActions.loadStatsForAllTagsSucceeded,
        (state, {stats}): State => {
            return adapter.setAll(stats, {...state, isLoaded: true, isLoading: false});
        }
    ),
    on(
        UsersStatsApiActions.loadStatsFailed,
        (state, {error}): State => ({
            ...state,
            isLoading: false,
            error
        })
    )
);

export function reducer(state: State | undefined, action: Action): State {
    return UsersStatsReducer(state, action);
}

export const {
    selectEntities: getUsersStatsEntities,
    selectIds: getUsersStatsIds,
    selectAll: getUsersStats,
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getError = (state: State) => state.error;
