import { Injectable } from '@angular/core';

import { PageLabelService } from '@core/services/page-label/page-label.service';
import { PublicUserModel, UserModel } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class ProfilePageLabelService {

    readonly default = 'Member Profile';
    readonly founding = `<span class='color-primary-100'>Founding Member </span><span>Profile</span>`;
    readonly facilitator = `<span class="long-text">
        <span class='color-primary-100'>Coach and Founding Member </span><span>Profile</span>
    </span>`;

    constructor(private pageLabelService: PageLabelService) {
    }

    setFromUser(user: UserModel | PublicUserModel, defaultLabel: string = this.default) {
        if (user.isFacilitator) {
            this.pageLabelService.setWithSanitizer(this.facilitator);
        } else if (user.isFounding) {
            this.pageLabelService.setWithSanitizer(this.founding);
        } else {
            this.pageLabelService.set(defaultLabel || this.default);
        }
    }

}
