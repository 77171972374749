import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { FeedApiActions, FeedPageActions, FeedPageTabsActions } from '@core/store/feed/actions';
import { environment } from 'src/environments/environment';
import { UserSettingsModel } from '@core/models';


export interface State extends EntityState<UserSettingsModel> {
    page: number;
    isLoading: boolean;
    hasMore: boolean;
    error: any;
}

const adapter = createEntityAdapter<UserSettingsModel>();

export const initialState: State = adapter.getInitialState({
    page: 1,
    isLoading: false,
    hasMore: true,
    error: null
});

const FeedCanHelpRecordsReducer = createReducer(
    initialState,
    on(FeedPageActions.pageInit, () => ({
        ...initialState
    })),
    on(FeedPageTabsActions.canHelpLoadMore, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(FeedApiActions.loadFeedCanHelpUsersFailed, (state, error) => ({
        ...state,
        isLoading: false,
        error
    })),
    on(FeedApiActions.loadFeedCanHelpUsersSucceeded, (state, {settings}) => {
        return adapter.addMany(settings, {
            ...state,
            page: state.page + 1,
            isLoading: false,
            hasMore: settings.length === environment.feed.pageSize,
            error: null
        });
    })
);

export function reducer(state: State | undefined, action: Action): State {
    return FeedCanHelpRecordsReducer(state, action);
}

export const {
    selectAll: getRecords
} = adapter.getSelectors();

export const getIsLoading = (state: State) => state.isLoading;
export const getHasMore = (state: State) => state.hasMore;
export const getPage = (state: State) => state.page;
