<h1 class="header-text">
    This page isn’t available
</h1>
<div class="description-text color-primary">
    The link that was followed may be broken,
    or the page may have been deleted
    If you have more than one account on
    a single computer, try loging into the
    correct account before clicking the link.
</div>
<img src="/assets/images/hands_v3.jpg" alt="hands image">
