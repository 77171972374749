import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, } from '@abacritt/angularx-social-login';
import { filter, takeUntil } from 'rxjs/operators';

import { CustomSvgIcon } from '@material/custom-svg-icon';
import { ExternalSocialNetwork, RegistrationType } from '@core/models';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { AppleLoginProvider } from 'src/app/providers';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { WINDOW } from '@core/window';
import { CookieService } from '@core/services';


declare let google: any;

@Component({
    selector: 'app-social-buttons',
    templateUrl: './social-buttons.component.html',
    styleUrls: ['./social-buttons.component.scss']
})
export class SocialButtonsComponent extends WithDestroyedSubjectComponent implements OnInit {
    readonly customSvgIcons = CustomSvgIcon;
    isSingedInWindowOpen = false;
    isFacebookLoginAvailable = !!environment.facebookClientId;
    isGoogleLoginAvailable = !!environment.googleClientId;
    isMicrosoftLoginAvailable = !!environment.microsoftClientId;
    isAppleLoginAvailable = !!environment.appleAppId;

    @Input()
    isSignIn = false;

    @Input()
    set currentRegistrationType(type) {
        if (!type) {
            return;
        }

        if (type === RegistrationType.google) {
            this.isGoogleLoginAvailable = !!environment.googleClientId;
            this.isFacebookLoginAvailable = false;
            this.isMicrosoftLoginAvailable = false;
            this.isAppleLoginAvailable = false;
        }
        if (type === RegistrationType.facebook) {
            this.isGoogleLoginAvailable = false;
            this.isFacebookLoginAvailable = !!environment.facebookClientId;
            this.isMicrosoftLoginAvailable = false;
            this.isAppleLoginAvailable = false;
        }
        if (type === RegistrationType.microsoft) {
            this.isGoogleLoginAvailable = false;
            this.isFacebookLoginAvailable = false;
            this.isMicrosoftLoginAvailable = !!environment.microsoftClientId;
            this.isAppleLoginAvailable = false;
        }
        if (type === RegistrationType.apple) {
            this.isGoogleLoginAvailable = false;
            this.isFacebookLoginAvailable = false;
            this.isMicrosoftLoginAvailable = false;
            this.isAppleLoginAvailable = !!environment.appleAppId;
        }
    }

    @Input()
    useLongLabel = false;

    @Output()
    singedIn = new EventEmitter<{ email: string, token: string, externalSocialNetwork: ExternalSocialNetwork }>();

    constructor(
        private authService: SocialAuthService,
        private msalService: MsalService,
        private cookieService: CookieService,
        @Inject(WINDOW) private window: Window | null,
    ) {
        super();
    }

    ngOnInit(): void {
        this.authService.authState.pipe(
            takeUntil(this.destroyed$),
            filter(user => !!user && user.provider === GoogleLoginProvider.PROVIDER_ID)
        )
            .subscribe((user) => {
                this.authService.signOut().catch(() => {});
                this.singedIn.emit({
                    email: user.email,
                    token: user.idToken,
                    externalSocialNetwork: RegistrationType.google
                });
            });
    }

    signInWithGoogle(): void {
        if (this.isGoogleLoginAvailable && this.window) {
            this.cookieService.deleteCookie('g_state');
            google.accounts.id.prompt();
        }
    }

    signInWithFacebook(): void {
        if (this.isFacebookLoginAvailable) {
            this.isSingedInWindowOpen = true;
            this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
                .then((user) => {
                    this.singedIn.emit({
                        email: user.email,
                        token: user.authToken,
                        externalSocialNetwork: RegistrationType.facebook
                    });
                })
                .finally(() => this.isSingedInWindowOpen = false);
        }
    }

    signInWithMicrosoft() {
        if (this.isMicrosoftLoginAvailable) {
            this.isSingedInWindowOpen = true;
            this.msalService.loginPopup()
                .pipe(takeUntil(this.destroyed$))
                .subscribe((response) => {
                    const claims = response.account.idTokenClaims as any;
                    this.singedIn.emit({
                        email: claims.email || null,
                        token: response.idToken,
                        externalSocialNetwork: RegistrationType.microsoft
                    });
                })
                .add(() => this.isSingedInWindowOpen = false);
        }
    }

    signInWithApple() {
        if (this.isAppleLoginAvailable) {
            this.isSingedInWindowOpen = true;
            this.authService.signIn(AppleLoginProvider.PROVIDER_ID)
                .then((user) => {
                        this.singedIn.emit({
                            email: user.email || null,
                            token: user.idToken,
                            externalSocialNetwork: RegistrationType.apple
                        });
                    }
                )
                .finally(() => this.isSingedInWindowOpen = false);
        }
    }
}
