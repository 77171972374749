import { createAction, props } from '@ngrx/store';

import { BlogPostModel } from '@core/models';


export const prefix = '[blog api actions]';

export const loadBlogPostsSucceeded = createAction(
    `${prefix} load blog posts succeeded`,
    props<{ posts: BlogPostModel[] }>()
);

export const loadBlogPostsFailed = createAction(
    `${prefix} load blog posts failed`,
    props<{ error: any }>()
);
