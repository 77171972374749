import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-information-popup',
    templateUrl: './information-popup.component.html',
    styleUrls: ['./information-popup.component.scss']
})
export class InformationPopupComponent {
    text: string;

    constructor(
        public matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: {
            text: string
        }
    ) {
        this.text = data.text;
    }

}
