import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLocalResources from '@core/store/resources/reducers/local-resources.reducer';
import * as fromNationalResources from '@core/store/resources/reducers/national-resources.reducer';
import * as fromRoot from '@core/store';


export const resourcesFeatureKey = 'resources';

export interface ResourcesState {
    localResources: fromLocalResources.State;
    nationalResources: fromNationalResources.State;
}

export interface State extends fromRoot.State {
    [resourcesFeatureKey]: ResourcesState;
}

export function reducers(state: ResourcesState | undefined, action: Action) {
    return combineReducers({
        localResources: fromLocalResources.reducer,
        nationalResources: fromNationalResources.reducer
    })(state, action);
}

const getResourcesState = createFeatureSelector< ResourcesState>(resourcesFeatureKey);

export const getLocalResourcesState = createSelector(getResourcesState, (state: ResourcesState) => state.localResources);
const getLocalResourcesRaw = createSelector(getLocalResourcesState, fromLocalResources.getLocalResources);
export const getLocalResources = createSelector(getLocalResourcesRaw, (resources) => {
    return resources?.length ? resources : null;
});
export const getLocalResourcesHasMore = createSelector(getLocalResourcesState, fromLocalResources.getHasMore);
export const getLocalResourcesIsLoading = createSelector(getLocalResourcesState, fromLocalResources.isLoading);
export const getLocalResourcesIsFirstResponseReceived = createSelector(
    getLocalResourcesState,
    fromLocalResources.getIsFirstResponseReceived
);
export const getLocalResourcesPage = createSelector(getLocalResourcesState, fromLocalResources.getPage);
export const getLocalResourcesGeolocation = createSelector(getLocalResourcesState, fromLocalResources.getGeolocation);

export const getNationalResourcesState = createSelector(getResourcesState, (state: ResourcesState) => state.nationalResources);
export const getNationalResourcesHasMore = createSelector(getNationalResourcesState, fromNationalResources.getHasMore);
export const getNationalResourcesIsLoading = createSelector(getNationalResourcesState, fromNationalResources.getIsLoading);
const getRawNationalResources = createSelector(getNationalResourcesState, fromNationalResources.getNationalResources);
export const getNationalResources = createSelector(
    getRawNationalResources,
    (resources) => {
        return resources?.length ? resources : null;
    }
);
export const getNationalResourcesPage = createSelector(getNationalResourcesState, fromNationalResources.getPage);
