import { isPlatformBrowser } from '@angular/common';

import { InjectableRxStompConfig, RxStompService } from '@stomp/ng2-stompjs';


export function appRxStompServiceFactory(config: InjectableRxStompConfig, platformId) {
    if (!isPlatformBrowser(platformId)) {
        return null;
    }
    const service = new RxStompService();
    service.configure(config);
    return service;
}
