import { Component } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import * as fromRoot from '@core/store/';
import * as fromAuth from '@core/store/auth';
import * as fromCurrentUser from '@core/store/current-user';

import { AuthApiActions, LoginPageActions } from '@core/store/auth/actions';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';
import { BlockedPopupComponent } from '@pages/auth/components';
import { SocialButtonSignInResult } from '@shared/components';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseAuthContainerComponent {
    afterReset: boolean;

    isPending$ = this.store.pipe(select(fromAuth.getLoginPageIsPending));

    error$ = this.store.pipe(select(fromAuth.getLoginPageError));

    constructor(
        private actions$: Actions,
        private matDialog: MatDialog,
        protected store: Store<fromRoot.State>,
        protected router: Router,
    ) {
        super(router, store);
        this.store.dispatch(LoginPageActions.init());
        this.actions$.pipe(
            ofType(
                AuthApiActions.loginSucceeded,
                AuthApiActions.loginWithSocialNetworkSucceeded
            ),
            switchMap(() => {
                return this.store.pipe(
                    select(fromCurrentUser.getUser),
                    filter((user) => !!user)
                );
            }),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.redirect(true);
        });
        this.store.pipe(
            select(fromRoot.getRouteQueryParams),
            take(1)
        ).subscribe((params: Params) => {
            this.afterReset = !!params.afterReset;
        });

        this.actions$.pipe(
            ofType(AuthApiActions.userBlocked),
            takeUntil(this.destroyed$)
        ).subscribe((userBlockedProps) => {
            this.matDialog.open<BlockedPopupComponent>(BlockedPopupComponent, {
                data: {
                    username: userBlockedProps.username
                }
            }).afterClosed()
                .subscribe((redirectToRoot: boolean) => {
                    if (redirectToRoot) {
                        this.redirectToOnClose = startPageNonAuthenticatedPath;
                        this.redirect();
                    }
                });
        });
    }

    onSubmit($data: { username: string, password: string }) {
        this.store.dispatch(LoginPageActions.submitted($data));
    }

    onSignedIn(model: SocialButtonSignInResult) {
        this.store.dispatch(LoginPageActions.loginWithSocialNetwork({
            externalSocialNetwork: model.externalSocialNetwork,
            token: model.token
        }));
    }
}
