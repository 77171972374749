import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';

import { HostUrlPrefixInterceptor } from '@core/interceptors/host-url-prefix.interceptor';
import { AuthTokenInterceptor } from '@core/interceptors/auth-token.interceptor';
import { FooterComponent, HeaderComponent, LayoutMainComponent } from '@core/layouts';
import { AppComponent, EmptyComponent, MainComponent, MaintenancePageComponent, PageNotFoundComponent } from '@core/pages';
import { SharedModule } from '@shared/shared.module';
import { windowProvider } from '@core/window';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';
import { LocalStorageService, SessionStorageService } from '@core/services';
import { localStorageServiceFactory, sessionStorageServiceFactory } from '../factories';
import { ResourcesModule } from '@pages/resources/resources.module';
import { HeaderAuthComponent, LayoutAuthComponent } from '@core/layouts/auth';


const components = [
    AppComponent,
    LayoutMainComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    PageNotFoundComponent,
    MaintenancePageComponent,
    EmptyComponent,
    HeaderAuthComponent,
    LayoutAuthComponent
];


@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ResourcesModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatBadgeModule
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ApiErrorInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HostUrlPrefixInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthTokenInterceptor,
                    multi: true
                },
                {
                    provide: LocalStorageService,
                    useFactory: localStorageServiceFactory,
                    deps: [
                        PLATFORM_ID
                    ]
                },
                {
                    provide: SessionStorageService,
                    useFactory: sessionStorageServiceFactory,
                    deps: [
                        PLATFORM_ID
                    ]
                },
                windowProvider
            ],
        };
    }
}
