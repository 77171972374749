<a
    mat-menu-item
    href="{{resource.url}}"
    target="_blank"
>
    <div class="image-wrapper">
        <img src="{{resource.image}}" alt="{{resource.title}}">
    </div>
    <div class="description-wrapper">
        <div class="description color-primary-700">
            {{resource.description}}
        </div>
        <div *ngIf="resource.city || resource.state" class="location color-primary">
            <mat-icon color="primary">location_on</mat-icon>
            <span class="text">{{ resourceLocationLabel }}</span>
        </div>
    </div>
</a>
