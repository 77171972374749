import { NgModule } from '@angular/core';


import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, ErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AppErrorStateMatcher } from '@shared/form-validators/app-error-state.matcher';


const modules = [
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatRippleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatListModule,
    MatSlideToggleModule
];

@NgModule({
    imports: modules,
    exports: modules,
    providers: [
        {provide: ErrorStateMatcher, useClass: AppErrorStateMatcher}
    ]
})
export class MaterialModule {
}
