import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { debounceTime } from 'rxjs/operators';

import { EmailValidator } from '@shared/form-validators';
import { UserModel } from '@core/models';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-invite-form',
    templateUrl: './invite-form.component.html',
    styleUrls: ['./invite-form.component.scss']
})
export class InviteFormComponent {
    readonly maxEmailsNumber = environment.inviteForm.maxEmailsNumber || 10;

    private bindOnChangeEvent(control: AbstractControl) {
        control.valueChanges
            .pipe(debounceTime(200))
            .subscribe(() => {
                this.onEmailChanged(this.emails.controls.lastIndexOf(control));
            });
    }

    private onEmailChanged(index: number) {
        if (this.isRemovingAllowed(index + 1)) {
            this.emails.removeAt(index + 1);
        }
        if (this.isAddingRequired()) {
            this.addEmail();
        }
    }

    private isRemovingAllowed(index: number): boolean {
        return this.emails.length > 1
            && (
                this.emails.at(index)
                && !this.emails.at(index).value
            );
    }

    private isAddingRequired(): boolean {
        return this.emails.length < this.maxEmailsNumber
            && this.emails.at(this.emails.length - 1).value;
    }

    private addEmail() {
        const emailFormControl = new FormControl('', EmailValidator.validate);
        this.emails.push(emailFormControl);
        this.bindOnChangeEvent(emailFormControl);
    }

    form: FormGroup;

    @Input()
    emailPlaceholder: string;

    @Input()
    textPlaceholder: string;

    @Input()
    isPending = false;

    @Input()
    user: UserModel;

    @Input()
    showAdditionalMessage: boolean;

    @Output()
    onSubmit = new EventEmitter<{
        emails: Array<string>,
        text: string
    }>();

    @ViewChild(FormGroupDirective)
    formDirective: FormGroupDirective;

    constructor(public errorStateMatcher: ErrorStateMatcher) {
        this.initForm();
    }

    initForm() {
        this.form = new FormGroup({
            emails: new FormArray(
                [
                    new FormControl('', EmailValidator.validate)
                ],
                (control): ValidationErrors | null => {
                    return control.value.filter(Boolean).length ? null : { required: true };
                }
            ),
            text: new FormControl('', Validators.required)
        });
        this.bindOnChangeEvent(this.emails.at(0));
    }

    hasError(control: AbstractControl, type: string): boolean {
        return this.errorStateMatcher.isErrorState(control as FormControl, this.formDirective)
            && control.hasError(type);
    }

    onBlur(index: number) {
        if (this.isRemovingAllowed(index) && this.emails.length > index + 1) {
            this.emails.removeAt(index);
        }
    }

    submit() {
        if (this.form.valid) {
            this.onSubmit.emit({
                emails: [...new Set<string>(this.emails.value.filter(Boolean)
                    .map((email: string) => email.trim()))],
                text: this.form.get('text').value
            });
        }
    }

    get emails(): FormArray | null {
        return this.form.get('emails') as FormArray;
    }

    get isSendingDisabled(): boolean {
        return this.isPending || !this.form.valid;
    }

    get userLink(): string {
        if (!this.user) {
            return null;
        }
        return `${environment.baseHref}/users/${this.user.id}`;
    }

}
