import { Action, createReducer, on } from '@ngrx/store';
import { VideoContestAPIActions, VideoContestAgreementPageActions } from '@core/store/video-contest/actions';


export interface State {
    isPending: boolean;
}

export const initialState: State = {
    isPending: false
};


const agreementPageStateReducer = createReducer<State, Action>(
    initialState,
    on(VideoContestAgreementPageActions.sighAgreement, (): State => ({isPending: true})),
    on(
        VideoContestAgreementPageActions.init,
        VideoContestAPIActions.signAgreementSucceeded,
        VideoContestAPIActions.signAgreementFailed,
        (): State => ({isPending: false}),
    )
);

export function reducer(state: State | undefined, action: Action) {
    return agreementPageStateReducer(state, action);
}

export const isPending = (state: State): boolean => state.isPending;
