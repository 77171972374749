import { isPlatformServer } from '@angular/common';
import { Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';


@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

    constructor(@Inject(PLATFORM_ID) private platformId, @Optional() @Inject(RESPONSE) private response) {
        if (isPlatformServer(this.platformId) && response) {
            this.response.status(404);
        }
    }
}
