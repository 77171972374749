import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromCurrentUser from '@core/store/current-user';
import { startPageAuthenticatedPath } from '@core/defaults';


@Injectable({
    providedIn: 'root'
})
export class UnAuthGuard implements CanActivate, CanActivateChild {

    private check(): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromCurrentUser.getToken),
            map((token) => {
                return token ? this.router.parseUrl(startPageAuthenticatedPath) : true;
            })
        );
    }

    constructor(private store: Store<fromCurrentUser.State>, private router: Router) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.check();
    }

    canActivateChild(): Observable<boolean | UrlTree> {
        return this.check();
    }
}
