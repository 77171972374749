<ng-content></ng-content>
<form (ngSubmit)="onSubmit()" [formGroup]="form" class="with-margin-top">
    <div class="row row-username">
        <mat-form-field appearance="fill">
            <mat-label>Username: What do you want to be called?</mat-label>
            <input (input)="isUsernameTaken = false" formControlName="username" matInput maxlength="50" minlength="3" name="username">
            <mat-error *ngIf="form.get('username').hasError('required')">
                Username is required
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('usernameUnique')">
                This Username is already taken
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('minlength') || form.get('username').hasError('maxLength')">
                Username should be between 3-50 characters
            </mat-error>
            <mat-error *ngIf="form.get('username').hasError('usernameFormat')">
                Username should contain only letters and numbers
            </mat-error>
        </mat-form-field>
    </div>
    <ng-container *appSocialNetworksAvailable>
        <div id="sign-up-text" (click)="onRegistrationTypeChanged()">
            SIGN UP WITH YOUR FAVORITE SOCIAL LOGIN BUTTON
            <mat-icon [class.closed]="!isLoginWithSocialSelected">keyboard_arrow_down</mat-icon>
        </div>
        <app-social-buttons
            *ngIf="isLoginWithSocialSelected"
            (singedIn)="onSocialSignedIn($event)"
        ></app-social-buttons>
        <div class="separator-with-text with-margin-top with-margin-bottom">or</div>
        <ng-container *ngIf="isLoginWithSocialSelected">
            <div class="signWithEmailText" (click)="onRegistrationTypeChanged()">
                Sign up using email and password
            </div>
            <div class="signWithEmailNote with-margin-bottom">Note: using this method you must remember your Username and Password to
                login
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isLoginWithSocialSelected">
        <div class="row row-email">
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input (input)="isEmailTaken = false" formControlName="email" matInput type="email">
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Email is required
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('emailFormat')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('emailUnique')">
                    This email is already taken
                </mat-error>
            </mat-form-field>
            <mat-hint>{{ hints.email }}</mat-hint>
        </div>
        <div class="row row-password">
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password" matInput>
                <mat-icon (click)="isPasswordHidden = !isPasswordHidden" matSuffix>
                    {{isPasswordHidden ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-error *ngIf="form.get('password').hasError('lowerCaseLetter')">
                    Password should contain at least one lower case letter
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('upperCaseLetter')">
                    Password should contain at least one upper case letter
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('digit')">
                    Password should contain at least one digit
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('notAlpha')">
                    Password should contain at least one special character
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('minlength')">
                    Password should be at least 8 characters long
                </mat-error>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    Password is required
                </mat-error>
            </mat-form-field>
            <mat-hint>{{ hints.password }}</mat-hint>
        </div>
        <div class="row">
            <mat-form-field appearance="fill">
                <mat-label>Confirm Password</mat-label>
                <input [type]="isRePasswordHidden ? 'password' : 'text'" formControlName="rePassword" matInput>
                <mat-icon (click)="isRePasswordHidden = !isRePasswordHidden" matSuffix>
                    {{isRePasswordHidden ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-error *ngIf="form.get('rePassword').hasError('required') || form.hasError('passwordsMatch')">
                    Passwords don't match
                </mat-error>
            </mat-form-field>
        </div>
    </ng-container>
    <div class="row agreement-row">
        By continuing you indicate that you have read and agree to our
        <a [queryParams]="{redirectTo: '/auth/registration'}" [routerLink]="['/legal-info/terms']" rel="nofollow">
            Terms of Service
        </a>
        and
        <a [queryParams]="{redirectTo: '/auth/registration'}" [routerLink]="['/legal-info/privacy']" rel="nofollow">
            Privacy Policy
        </a>
    </div>
    <div class="row">
        <button color="primary" mat-flat-button type="submit" [disabled]="isPending">Next</button>
    </div>
    <re-captcha
        #captcha="reCaptcha"
        (resolved)="resolved($event)"
        siteKey="{{key}}"
        formControlName="recaptchaResponse"
        size="invisible"
        badge="inline"
    ></re-captcha>
    <div class="row">
        Have an account?
        <a class="link"
           rel="nofollow"
           [routerLink]="['/auth/login']"
           [queryParams]="{redirectTo: redirectTo}"
        >Login</a>
    </div>
</form>
