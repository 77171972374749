<form (ngSubmit)="submit()" [formGroup]="formGroup" id="feed-profile-form">
    <textarea
        formControlName="about"
        [placeholder]="isCanHelp ? 'Please describe your own experience or thoughts' : 'Please explain the help you need'"
    ></textarea>
    <ng-container *ngIf="tag.isDiagnosis">
        <div id="optional-text">
            Optional:
        </div>
        <label class="checkbox-wrapper">
            <mat-checkbox formControlName="isForMan" color="accent" [disableRipple]="true">
                I care(d) for<br>
                a <span class="color-primary">{{'man' | sexLabel:tag.isDiagnosis}}</span> in this subject
            </mat-checkbox>
        </label>
        <label class="checkbox-wrapper">
            <mat-checkbox formControlName="isForWoman" color="accent" [disableRipple]="true">
                I care(d) for<br>
                a <span class="color-primary">{{'woman' | sexLabel:tag.isDiagnosis}}</span> in this subject
            </mat-checkbox>
        </label>
        <label class="checkbox-wrapper">
            <mat-checkbox formControlName="isForNonBinary" color="accent" [disableRipple]="true">
                I care(d) for<br>
                a <span class="color-primary">non-binary</span> in this subject
            </mat-checkbox>
        </label>
        <div class="checkbox-wrapper experience">
            <mat-checkbox
                formControlName="hasExperience"
                color="accent"
                [disableRipple]="true"
                (click)="$event.preventDefault()"
            >
                <app-experience-period formControlName="experience"></app-experience-period>
            </mat-checkbox>
        </div>
    </ng-container>
</form>
