import { createAction, props } from '@ngrx/store';

export const prefix = '[Change Password Page]';

export const init = createAction(`${prefix} init`);

export const changePasswordFormSubmitted = createAction(
    `${prefix} form submitted`,
    props<{
        currentPassword: string,
        newPassword: string
    }>()
);
