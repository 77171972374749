import { Action, createReducer, on } from '@ngrx/store';

import { ApiErrorInterceptorAction, MaintenancePageActions, PingApiActions } from '@core/store/actions';


export interface State {
    isLoading: boolean;
    isLastCheckFailed: boolean;
}

export const initialState: State = {
    isLoading: false,
    isLastCheckFailed: false
};

const MaintenancePageReducer = createReducer(
    initialState,
    on(MaintenancePageActions.init, (state): State => ({
        ...state,
        isLoading: false
    })),
    on(PingApiActions.ping, (state): State => ({
        ...state,
        isLoading: true
    })),
    on(PingApiActions.serverIsOffline, (state): State => ({
        ...state,
        isLoading: false,
        isLastCheckFailed: true
    })),
    on(ApiErrorInterceptorAction.errorIntercepted, (state): State => ({
        ...state,
        isLastCheckFailed: true
    })),
    on(PingApiActions.serverIsOnline, (state): State => ({
        ...state,
        isLastCheckFailed: false
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return MaintenancePageReducer(state, action);
}

export const getIsLoading = (state: State) => state.isLoading;
export const getIsLastCheckFailed = (state: State) => state.isLastCheckFailed;
