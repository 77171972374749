import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UsersStatsModel } from '@core/models';
import { UsersStatsService } from '@core/services';
import { UsersStatsApiActions, SearchBySubjectPageActions } from '@core/store/actions';


@Injectable()
export class UsersStatsEffects {

    loadStatsForAllTags = createEffect(() => {
        return this.actions.pipe(
            ofType(SearchBySubjectPageActions.init),
            switchMap(() => this.usersStatsService.getStatisticForAllTags().pipe(
                map((models: UsersStatsModel[]) => UsersStatsApiActions.loadStatsForAllTagsSucceeded({stats: models})),
                catchError((error) => of(UsersStatsApiActions.loadStatsFailed({error: error.error})))
            ))
        );
    });

    constructor(private actions: Actions, private usersStatsService: UsersStatsService) {
    }
}
