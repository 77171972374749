import { createAction, props } from '@ngrx/store';

import { ConversationsPageFilter } from '@core/models';


export const prefix = '[conversations page]';

export const pageInit = createAction(`${prefix} page init`);

export const changeFilter = createAction(
    `${prefix} change filter`,
    props<{ filter: ConversationsPageFilter }>()
);

export const declineIncomingCall = createAction(
    `${prefix} decline Incoming Call`,
    props<{ callId: number }>()
);
