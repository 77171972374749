import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';

import * as fromAuth from '@core/store/auth';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class RegistrationSocialGuard implements CanActivate {
    constructor(
        private store: Store<fromAuth.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromAuth.getRegistrationSocialPageExternalUserToken),
            first(),
            map((token) => {
                if (token) {
                    return true;
                }
                return this.router.parseUrl('/registration');
            })
        );
    }
}
