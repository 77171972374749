import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-contest-link',
    templateUrl: './contest-link.component.html',
    styleUrls: ['./contest-link.component.scss']
})
export class ContestLinkComponent {
    readonly prizeAmount = environment.videoContest.prizeAmount;

    @Input()
    isFullTextAllowed: boolean;
}
