import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import {
    FeedQuestionApiModel,
    flattenFeedQuestionsApiModels,
    flattenFeedSettingsApiModels,
    FeedSettingsApiModel,
    flattenFeedAllApiModels, FeedRecordWithSetting, FeedRecordWithQuestion
} from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class FeedService {

    readonly prefix = '/feed';

    constructor(private http: HttpClient) {
    }

    getFeedCanHelp(tagId: number, page: number, size: number) {
        const params = {
            size: size.toString(),
            page: page.toString()
        };
        return this.http.get<FeedSettingsApiModel[]>(`${this.prefix}/${tagId}/can-help`, {
            params
        }).pipe(map((models) => flattenFeedSettingsApiModels(models)));
    }

    getFeedNeedHelp(tagId: number, page: number, size: number) {
        const params = {
            size: size.toString(),
            page: page.toString()
        };
        return this.http.get<FeedSettingsApiModel[]>(`${this.prefix}/${tagId}/need-help`, {
            params
        }).pipe(map((models) => flattenFeedSettingsApiModels(models)));
    }

    getFeedQuestions(tagId: number, page: number, size: number) {
        const params = {
            size: size.toString(),
            page: page.toString()
        };
        return this.http.get<FeedQuestionApiModel[]>(`${this.prefix}/${tagId}/questions`, {
            params
        }).pipe(map((models) => flattenFeedQuestionsApiModels(models)));
    }

    getFeedAll(tagId: number, page: number, size: number) {
        const params = {
            size: size.toString(),
            page: page.toString()
        };
        return this.http.get<FeedRecordWithSetting[] | FeedRecordWithQuestion[]>(`${this.prefix}/${tagId}/all`, {
            params
        }).pipe(map((models) => flattenFeedAllApiModels(models)));
    }
}
