import { isPlatformBrowser } from '@angular/common';

import { LocalStorageService } from '@core/services';


export function localStorageServiceFactory(platformId): Storage {
    if (isPlatformBrowser(platformId)) {
        return localStorage;
    }
    return new LocalStorageService();
}
