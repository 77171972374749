import { animate, style, transition, trigger } from '@angular/animations';


export const gridRowAnimation = trigger('gridRowAnimation', [
    transition(':enter', [
        style({height: '0px'}),
        animate(300, style({height: '*'}))
    ]),
    transition(':leave', [
        style({height: '*'}),
        animate(300, style({height: '0px'})),
    ]),
]);
