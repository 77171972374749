import { Action, createReducer, on } from '@ngrx/store';

import { AuthApiActions, ForgotPasswordPageActions } from '@core/store/auth/actions';
import { ServerErrorModel } from '@core/models';


export interface State {
    isPending: boolean;
    error: ServerErrorModel;
}

export const initialState: State = {
    isPending: false,
    error: null
};

const ForgotPageReducer = createReducer(
    initialState,
    on(ForgotPasswordPageActions.init, () => initialState),
    on(ForgotPasswordPageActions.submitted, (): State => {
        return {
            isPending: true,
            error: null
        };
    }),
    on(AuthApiActions.forgotPasswordSucceeded, (): State => {
        return {
            isPending: false,
            error: null
        };
    }),
    on(AuthApiActions.forgotPasswordFailed,
        (state, error): State => {
            return {
                ...state,
                isPending: false,
                error: error.error
            };
        })
);

export const reducer = (state: State | undefined, action: Action) => {
    return ForgotPageReducer(state, action);
};

export const getError = (state: State) => state.error;
export const getIsPending = (state: State) => state.isPending;
