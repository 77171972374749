import { createAction, props } from '@ngrx/store';

import { PublicChatMessageFlatModel, PublicUserModel } from '@core/models';
import { Update } from '@ngrx/entity';


const prefix = '[Public chat API]';

export const loadMessagesSucceeded = createAction(
    `${prefix} load messages succeeded`,
    props<{
        messages: PublicChatMessageFlatModel[],
        users: PublicUserModel[],
        hasMore: boolean
    }>()
);

export const loadMessagesFailed = createAction(
    `${prefix} load message failed`,
    props<{ error: string }>()
);

export const allMessagesLoaded = createAction(
    `${prefix} all messages have been loaded`
);

export const sendMessageSucceeded = createAction(
    `${prefix} send message succeeded`,
    props<{
        message: PublicChatMessageFlatModel,
        users: PublicUserModel[]
    }>()
);

export const sendMessageFailed = createAction(
    `${prefix} send message failed`,
    props<{ error: string }>()
);

export const deleteMessageSucceeded = createAction(
    `${prefix} delete message succeeded`,
    props<{ messageId: number }>()
);

export const deleteMessageFailed = createAction(
    `${prefix} delete message failed`,
    props<{ error: string }>()
);

export const editMessageSucceeded = createAction(
    `${prefix} edit message succeeded`,
    props<{ update: Update<PublicChatMessageFlatModel> }>()
);

export const editMessageFailed = createAction(
    `${prefix} edit message failed`,
    props<{ error: string }>()
);

export const replyOnMessageSucceeded = createAction(
    `${prefix} reply on message succeeded`,
    props<{
        message: PublicChatMessageFlatModel,
        users: PublicUserModel[]
    }>()
);

export const replyOnMessageFailed = createAction(
    `${prefix} reply on message failed`,
    props<{ error: string }>()
);

export const loadOnlineUsersCountSucceeded = createAction(
    `${prefix} load online users count succeeded`,
    props<{ count: number }>()
);

export const loadOnlineUsersCountFailed = createAction(
    `${prefix} load online users count failed`,
    props<{ error: string }>()
);

export const loadUsersSucceeded = createAction(
    `${prefix} load users succeeded`,
    props<{ users: PublicUserModel[] }>()
);

export const loadUsersFailed = createAction(
    `${prefix} load users failed`,
    props<{ error: any }>()
);
