import { Action, createReducer, on } from '@ngrx/store';

import { ServerErrorModel } from '@core/models';
import { AuthApiActions, RegistrationPageActions } from '@core/store/auth/actions';


export interface State {
    isPending: boolean;
    error: ServerErrorModel | null;
    email: string;
}


export const initialState: State = {
    isPending: false,
    email: null,
    error: null
};

const RegistrationPageReducer = createReducer(
    initialState,
    on(RegistrationPageActions.init, () => initialState),
    on(RegistrationPageActions.submitted, (state): State => {
        return {
            ...state,
            isPending: true,
            error: null
        };
    }),
    on(AuthApiActions.registrationFailed, (state, action): State => {
        return {
            ...state,
            isPending: false,
            error: action.error
        };
    }),
    on(AuthApiActions.registrationSucceeded, (state): State => {
        return {
            ...state,
            isPending: false,
            error: null
        };
    })
);

export const reducer = (state: State | undefined, action: Action) => {
    return RegistrationPageReducer(state, action);
};


export const getError = (state: State) => state.error;
export const getIsPending = (state: State) => state.isPending;
export const getEmail = (state: State) => state.email;
