import { isPlatformBrowser } from '@angular/common';

import { SessionStorageService } from '@core/services';


export function sessionStorageServiceFactory(platformId): Storage {
    if (isPlatformBrowser(platformId)) {
        return sessionStorage;
    }
    return new SessionStorageService();
}
