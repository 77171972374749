import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class CopyToClipboardService implements OnDestroy {

    private isRTL = this.document.documentElement.getAttribute('dir') === 'rtl';

    private textArea: HTMLTextAreaElement;

    private textAreaContainer: HTMLElement;

    private supported = false;

    private createTextArea() {
        this.textArea = this.document.createElement<'textarea'>('textarea');
        this.textArea = document.createElement('textarea');
        // Prevent zooming on iOS
        this.textArea.style.fontSize = '12pt';
        // Reset box model
        this.textArea.style.border = '0';
        this.textArea.style.padding = '0';
        this.textArea.style.margin = '0';
        // Move element out of screen horizontally
        this.textArea.style.position = 'absolute';
        this.textArea.style[this.isRTL ? 'right' : 'left'] = '-9999px';

        this.textArea.setAttribute('readonly', '');
    }

    private init() {
        if (
            !this.document
            || (
                !this.document.queryCommandSupported
                || !this.document.queryCommandSupported('copy')
            )
        ) {
            return;
        }
        this.supported = true;
        this.textAreaContainer = document.body;
        this.createTextArea();
    }

    constructor(@Inject(DOCUMENT) @Optional() private document: Document) {
        this.init();
    }

    isSupported() {
        return this.supported;
    }

    copy(text: string): boolean {
        if (!this.supported) {
            return false;
        }
        this.textAreaContainer.appendChild(this.textArea);
        this.textArea.value = text;
        this.textArea.select();
        this.textArea.setSelectionRange(0, 999999);
        let result: boolean;
        try {
            result = this.document.execCommand('copy');
        } catch {
            result = false;
        }
        this.textAreaContainer.removeChild(this.textArea);
        return result;
    }

    ngOnDestroy(): void {
        if (this.textArea) {
            this.textAreaContainer.removeChild(this.textArea);
        }
    }

}
