import { createAction, props } from '@ngrx/store';

import { ExternalSocialNetwork, SocialRegistrationModel } from '@core/models';
import { RoiLocation } from '@core/services/roi-api/roi-location';


export const prefix = '[Registration Social Page]';

export const emailSubmitted = createAction(
    `${prefix} email submitted`,
    props<{ email: string, location: RoiLocation }>()
);

export const registrationWithSocialNetworkSubmitted = createAction(
    `${prefix} registration with social network submitted`,
    props<{
        externalSocialNetwork: ExternalSocialNetwork,
        model: SocialRegistrationModel,
        location: RoiLocation
    }>()
);
