import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromResources from '@core/store/resources';
import { ResourceComponent, ResourcesBlockComponent } from '@pages/resources/components';
import { ResourcesEffects } from '@core/store/resources/effects';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { ResourcesComponent } from '@pages/resources/containers';


@NgModule({
    declarations: [
        ResourceComponent,
        ResourcesComponent,
        ResourcesBlockComponent
    ],
    exports: [
        ResourceComponent,
        ResourcesComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        EffectsModule.forFeature([
            ResourcesEffects
        ]),
        StoreModule.forFeature(fromResources.resourcesFeatureKey, fromResources.reducers),
        SharedModule
    ],
})
export class ResourcesModule {
}
