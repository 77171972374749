import { createAction, props } from '@ngrx/store';

import { InitialSettingsModel } from '@core/models';


export const prefix = '[Initial Settings API]';

export const loadSucceeded = createAction(
    `${prefix} load succeeded`,
    props<InitialSettingsModel>()
);

export const loadFailed = createAction(
    `${prefix} load failed`,
    props<{ error: any }>()
);
