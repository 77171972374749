import { compareAsc, compareDesc, parseISO } from 'date-fns';


function sortComparerByCreatedOn<F extends string, T extends { [p in F]: string }>(
    field: F,
    direction: SortDirection,
    a: T,
    b: T
): number {
    const first = parseISO(a[field]);
    const second = parseISO(b[field]);
    return direction === SortDirection.asc ? compareAsc(first, second) : compareDesc(first, second);
}

export enum SortDirection {
    asc = 1,
    desc = -1
}

export function getSortByDateComparer<T extends { [p in F]: string }, F extends string>(
    field: F,
    direction: SortDirection = SortDirection.desc,
): (a: T, b: T) => number {
    return sortComparerByCreatedOn.bind(undefined, field, direction);
}
