import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';


export enum logLevel {
    DEBUG = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3
}

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    private static shouldLog(level: logLevel): boolean {
        return (level >= logLevel[environment.logLevel]);
    }

    private static writeToLog(level: logLevel, ...message: any) {
        if (this.shouldLog(level)) {
            if (level <= logLevel.INFO) {
                console.log(LoggerService.getLogDate(), ...message);
            } else if (level === logLevel.ERROR) {
                console.error(LoggerService.getLogDate(), ...message);
            } else if (level === logLevel.WARN) {
                console.warn(LoggerService.getLogDate(), ...message);
            }
        }
    }

    private static getLogDate(): string {
        const date = new Date();
        return '[' +
            date.getUTCFullYear() + '-' +
            (date.getUTCMonth() + 1).toString().padStart(2, '0') + '-' +
            date.getUTCDate().toString().padStart(2, '0') + ' ' +
            date.getUTCHours().toString().padStart(2, '0') + ':' +
            date.getUTCMinutes().toString().padStart(2, '0') + ':' +
            date.getUTCSeconds().toString().padStart(2, '0') + '.' +
            date.getMilliseconds() + ' UTC]';
    }

    public static debug(...message: any): void {
        LoggerService.writeToLog(logLevel.DEBUG, ...message);
    }

    public static log(...message: any) {
        LoggerService.writeToLog(logLevel.INFO, ...message);
    }

    public static warn(...message: any) {
        LoggerService.writeToLog(logLevel.WARN, ...message);
    }

    public static error(...message: any) {
        LoggerService.writeToLog(logLevel.ERROR, ...message);
    }
}
