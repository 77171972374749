import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { HelpDeskService } from '@core/services/';
import { HelpDeskApiActions, HelpDeskPageActions } from '@core/store/actions';


@Injectable()
export class HelpDeskApiEffects {
    sendHelpDeskRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                HelpDeskPageActions.submitted
            ),
            exhaustMap(({text}) => {
                return this.helpDeskService.send(text).pipe(
                    map(() => HelpDeskApiActions.sendHelpDeskSucceeded()),
                    catchError((error) => of(HelpDeskApiActions.sendHelpDeskFailed({error: error.error})))
                );
            })
        );
    });


    constructor(
        private actions$: Actions,
        private helpDeskService: HelpDeskService,
    ) {
    }
}
