import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceModel } from '@core/models';


@Component({
    selector: 'app-resource',
    templateUrl: './resource.component.html',
    styleUrls: ['./resource.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceComponent {

    @Input()
    resource: ResourceModel;

    get resourceLocationLabel(): string {
        if (!this.resource?.city && !this.resource?.state) {
            return null;
        }
        const location = [];
        if (this.resource.state) {
            location.push(this.resource.state);
        }
        if (this.resource.city) {
            location.push(this.resource.city);
        }
        return location.join(', ');
    }

}
