import { Inject, Injectable, Optional } from '@angular/core';

import { WINDOW } from '@core/window';
import { Geolocation } from '@core/models';


export enum GeolocationPositionErrors {
    PERMISSION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3
}

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    constructor(@Optional() @Inject(WINDOW) private window) {
    }

    getPosition(): Promise<Geolocation> {
        if (!this.window?.navigator?.geolocation) {
            return null;
        }

        return new Promise((resolve, reject) => {
            this.window.navigator.geolocation.getCurrentPosition(resp => {
                    resolve({
                        longitude: resp.coords.longitude,
                        latitude: resp.coords.latitude
                    });
                },
                (err) => {
                    reject(err);
                });
        });
    }
}
