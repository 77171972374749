import { createAction, props } from '@ngrx/store';


export const prefix = '[Forgot Password Page]';

export const init = createAction(`${prefix} init`);

export const submitted = createAction(
    `${prefix} submitted`,
    props<{ email: string }>()
);
