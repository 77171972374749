import { Injectable } from '@angular/core';

import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';

import { PageTitleService } from '@core/services';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@core/store';


@Injectable()
export class PageTitleEffects {
    routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            withLatestFrom(this.store.pipe(select(fromRoot.getRouteData))),
            tap(([, routerData]) => {
                if (!routerData.isFeedPage) {
                    this.pageTitleService.set(routerData.pageTitle, !!routerData.pageTitleWithoutProjectName);
                }
            })
        );
    }, {dispatch: false});

    constructor(
        private actions$: Actions,
        private pageTitleService: PageTitleService,
        private store: Store<fromRoot.State>
    ) {
    }
}
