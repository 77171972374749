import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    Optional,
    ViewChildren
} from '@angular/core';
import {AdModel} from '@core/models';
import {WINDOW} from '@core/window';

@Component({
    selector: 'app-adsense-ad',
    templateUrl: './adsense-ad.component.html',
    styleUrls: ['./adsense-ad.component.scss']
})
export class AdsenseAdComponent implements OnDestroy, AfterViewInit {

    @ViewChildren('ins', {read: ElementRef, emitDistinctChangesOnly: true})
    ins: ElementRef;

    @Input()
    ad: AdModel;

    constructor(
        @Optional() @Inject(WINDOW) private window: Window
    ) {
    }

    ngOnDestroy(): void {
        const iframe = this.ins?.nativeElement?.querySelector('iframe');
        if (iframe && iframe?.contentWindow) {
            iframe.src = 'about:blank';
            iframe.remove();
        }
    }

    ngAfterViewInit(): void {
        if (this.window && this.ins) {
            try {
                (this.window as any).adsbygoogle = (this.window as any).adsbygoogle || [];
                (this.window as any).adsbygoogle.push({});
            } catch {
                // pass
            }
        }
    }
}
