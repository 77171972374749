import { callMessages, ConversationPrivateChatMessageModel } from '@core/models/private-chat-message.model';
import { PublicUserModel } from '@core/models/user.model';


export enum ConversationsPageFilter {
    all = 'all',
    whoIFollow = 'who I follow'
}

export interface ConversationApiModel {
    id: number;
    userFirst: PublicUserModel;
    userSecond: PublicUserModel;
    message: ConversationPrivateChatMessageModel;
}

export interface ConversationFlatModel {
    id: number;
    callId?: number;
    lastMessageId: number;
    lastMessageUserIdFrom: number;
    interlocutorId: number;
    isRead: boolean;
    text: string;
    createdOn: string;
}

export type ConversationFullModel = Omit<ConversationFlatModel, 'interlocutorId' | 'lastMessageId' | 'lastMessageUserIdFrom'> & {
    interlocutor: PublicUserModel;
};

export interface AfterFlattenConversationModel {
    conversation: ConversationFlatModel;
    user: PublicUserModel;
    message: ConversationPrivateChatMessageModel;
}

export interface AfterFlattenConversationsModel {
    conversations: ConversationFlatModel[];
    users: PublicUserModel[];
}

export const flattenConversationApiModel = (currentUserId: number, model: ConversationApiModel): AfterFlattenConversationModel => {
    const user = {
        ...(model.userFirst.id !== currentUserId ? model.userFirst : model.userSecond)
    };
    const conversation: ConversationFlatModel = {
        id: model.id,
        lastMessageId: null,
        lastMessageUserIdFrom: null,
        interlocutorId: user.id,
        createdOn: null,
        text: null,
        isRead: true,
    };
    if (model.message) {
        conversation.lastMessageId = model.message.id;
        conversation.lastMessageUserIdFrom = model.message.userIdFrom;
        conversation.createdOn = model.message.createdOn;
        conversation.text = model.message.call ? callMessages[model.message.call.status] : model.message.text;
        conversation.isRead = model.message.isRead;
    }
    return {
        user,
        message: model.message,
        conversation
    };
};

export const flattenConversationApiModels = (currentUserId: number, models: ConversationApiModel[]): AfterFlattenConversationsModel => {
    const result: AfterFlattenConversationsModel = {
        conversations: [],
        users: []
    };
    if (!models) {
        return result;
    }
    models.forEach((model) => {
        const {conversation, user} = flattenConversationApiModel(currentUserId, model);
        result.conversations.push(conversation);
        result.users.push(user);
    });
    return result;
};
