import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import * as fromAuth from '@core/store/auth';
import * as fromRoot from '@core/store';
import { ErrorType, ExternalSocialNetwork, RegistrationModel, RegistrationSource, ServerErrorModel } from '@core/models';
import { AuthApiActions, RegistrationPageActions } from '@core/store/auth/actions';
import { BaseAuthContainerComponent } from '@pages/auth/containers/base-auth-container.component';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { WINDOW } from '@core/window';
import { messages } from '@core/messages';
import { environment } from 'src/environments/environment';
import { afterRegistrationRedirectPath } from '@core/defaults';


@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends BaseAuthContainerComponent implements OnInit, OnDestroy {
    readonly defaultRedirect = environment.registration.defaultRedirect;
    readonly videoContestNewVideoUrl = '/me/video-contest/new';
    readonly editProfileUrl = afterRegistrationRedirectPath;

    isPending$ = this.store.pipe(select(fromAuth.getRegistrationPageIsPending));

    location = RoiLocation.join;

    model: RegistrationModel = {
        username: null,
        email: null,
        password: null,
        recaptchaResponse: null
    };

    source: RegistrationSource;

    usernameTakenError$ = new BehaviorSubject<{ error: boolean }>(null);

    emailTakenError$ = new BehaviorSubject<{ error: boolean }>(null);

    protected onError(error: ServerErrorModel | null) {
        if (null === error) {
            return;
        }

        switch (error.type) {
            case ErrorType.tagNotFound:
                this.window?.alert(messages.global.error);
                break;
            case ErrorType.externalUserTokenInvalid:
            case ErrorType.recaptchaNotValid:
                this.window?.alert(messages.global.error);
                break;
            case ErrorType.usernameAlreadyExists :
                this.usernameTakenError$.next({error: true});
                break;
            case ErrorType.emailAlreadyExists:
                this.emailTakenError$.next({error: true});
                break;
        }
    }

    constructor(
        @Optional()
        @Inject(WINDOW) private window: Window | null,
        private actions$: Actions,
        protected store: Store<fromRoot.State>,
        protected router: Router
    ) {
        super(router, store);
    }

    ngOnInit() {
        this.store.dispatch(RegistrationPageActions.init());
        this.actions$.pipe(
            ofType(AuthApiActions.registrationSucceeded),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.router.navigateByUrl(
                this.source === RegistrationSource.landingVideoContest
                    ? this.videoContestNewVideoUrl
                    : this.editProfileUrl
            );
        });
        this.store.pipe(
            select(fromAuth.getRegistrationPageError),
            takeUntil(this.destroyed$)
        ).subscribe((error) => this.onError(error));
        this.store.pipe(
            select(fromRoot.getRouteQueryParams),
            filter(params => !!(params.email || params.location || params.username || params.source)),
            first(),
            takeUntil(this.destroyed$)
        ).subscribe((params) => {
            this.model.email = params.email;
            if (params.location) {
                this.location = params.location;
            }
            if (params.username) {
                this.model.username = params.username;
            }
            if (params.source) {
                this.source = params.source;
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(RegistrationPageActions.destroyed());
    }

    onRegistrationFormSubmit(model: RegistrationModel) {
        this.model = {...model};
        this.usernameTakenError$.next(null);
        this.emailTakenError$.next(null);
        this.store.dispatch(RegistrationPageActions.submitted({model: this.model, location: this.location}));
    }

    onSignedInSocial(data: { email: string, username: string, token: string, externalSocialNetwork: ExternalSocialNetwork }) {
        const model = {
            externalSocialNetwork: data.externalSocialNetwork,
            email: data.email,
            username: data.username,
            token: data.token,
            location: this.location
        };

        this.store.dispatch(RegistrationPageActions.submittedWithSocialNetwork(model));
    }

    get registrationText(): string {
        if (this.source === RegistrationSource.landingVideoContest) {
            return 'Register for the Video Contest';
        }
        return 'Create your Account';
    }

    get redirectToParameter(): string {
        if (this.source === RegistrationSource.landingVideoContest) {
            return '/me/video-contest/new';
        }
    }
}
