import { Component, ElementRef, HostBinding, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-layout-main',
    templateUrl: './layout-main.component.html',
    styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent extends WithDestroyedSubjectComponent {

    @HostBinding('class.layout-main')
    classLayoutMain = true;

    @HostBinding('class.layout-flex')
    isLayoutFlex: boolean;

    @ViewChild('content')
    content: ElementRef<HTMLElement>;

    constructor(
        @Inject(PLATFORM_ID) platformId: object,
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
        super();
        this.store.pipe(
            select(fromRoot.getRouteData),
            takeUntil(this.destroyed$)
        ).subscribe((data) => this.isLayoutFlex = !!(data && data.isLayoutFlex));

        this.router.events.subscribe((event: Event) => {
            if (isPlatformBrowser(platformId) && this.content && event instanceof NavigationEnd) {
                this.content.nativeElement.scroll(0, 0);
            }
        });
    }
}
