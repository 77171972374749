import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class InviteFriendService {

    readonly prefix = '/tell-friend';

    constructor(private http: HttpClient) {
    }

    send(text: string, emails: string[]): Observable<void> {
        return this.http.post<void>(`${this.prefix}/`, {text, emails});
    }
}
