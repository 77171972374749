<div class="main-tag-row">
    <ng-container *ngIf="hasChildren">
        <button
            *ngIf="!isExpanded; else btnCollapse"
            (click)="expand()"
            class="btn-flat color-primary-100"
        >+</button>
        <ng-template #btnCollapse>
            <button
                *ngIf="isExpanded"
                (click)="collapse()"
                class="btn-flat color-primary-100"
            >-</button>
        </ng-template>
    </ng-container>
    <div
        class="tag"
        [class.with-margin]="!hasChildren"
        (click)="clicked$.emit(tag)"
    >
        <span class="tag-name" [innerHTML]="tag.name | highlightText:highlightedText:'highlighted'"></span>
        <span
            class="stats"
            *ngIf="showStats"
        >
            <ng-container *ngIf="tag.stats">
                {{ tag.stats[statToShow] }}
                <ng-container *ngIf="!isExpanded">
                    | {{ (tag | statsSum)[statToShow] }}
                </ng-container>
            </ng-container>
        </span>
    </div>
</div>
<div
    class="child-tags-container"
    [@toggleTag]="isExpanded ? 'expanded' : 'collapsed'"
>
    <div class="tag child" *ngFor="let child of tag.child" (click)="clicked$.emit(child)">
        <span class="tag-name" [innerHTML]="child.name | highlightText:highlightedText:'highlighted'"></span>
        <span
            class="stats"
            *ngIf="showStats"
        >
            {{ child.stats ? child.stats[statToShow] : 0 }}
        </span>
    </div>
</div>
