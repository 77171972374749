import { Inject, Injectable, Optional } from '@angular/core';

import { WINDOW } from '@core/window';
import { environment } from 'src/environments/environment';
import { FeatureFlagsService } from '@core/services';


@Injectable({
    providedIn: 'root'
})

export class TawkChatLoaderService {
    readonly supportChatFeatureFlag = 'support-chat';
    readonly src = 'https://embed.tawk.to/';

    private loaded: boolean;
    private isVisible = true;

    constructor(
        private featureFlagService: FeatureFlagsService,
        @Optional() @Inject(WINDOW) private window
    ) {

        if (this.featureFlagService.isFeatureEnabled(this.supportChatFeatureFlag)
            && this.window
            && environment.tawkAcountKey
            && environment.tawkWidgetId
        ) {
            this.load();
        }
    }

    private load() {
        if (this.loaded) {
            return;
        }
        this.window.Tawk_API = this.window.Tawk_API || {};
        this.window.Tawk_API.onLoad = () => {
            this.loaded = true;
            if (!this.isVisible) {
                this.hideChatWidget();
            }
        };
        const script = (this.window as Window).document.createElement<'script'>('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = this.src + environment.tawkAcountKey + '/' + environment.tawkWidgetId;
        (this.window as Window).document.getElementsByTagName<'body'>('body')[0].append(script);
        return this;
    }

    public showChatWidget() {
        if (this.loaded) {
            this.window.Tawk_API.showWidget();
        }
    }

    public hideChatWidget() {
        this.isVisible = false;
        if (this.loaded) {
            this.window.Tawk_API.hideWidget();
        }
    }
}
