import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { first, map, switchMap } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import { NotificationSoundService, PrivateMessagesWsService } from '@core/services';
import { AppInitActions, PrivateMessagesWsActions } from '@core/store/actions';
import { UserApiActions } from '@core/store/current-user/actions';
import {
    CallInviteModel,
    CallLocalStatus,
    ConversationPrivateChatMessageModel,
    flattenConversationApiModel,
    PrivateChatNewMessageResult,
    PrivateMessage,
    PrivateMessageCallInvite,
    PrivateMessageType,
    UserModel
} from '@core/models';
import { InviteNotificationPopupComponent, PrivateChatNotificationComponent } from '@shared/components';
import { BlockedPopupComponent } from '@pages/auth/components';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Injectable()
export class PrivateMessagesWsEffects {

    private privateChatInterlocutorId: number | null;
    private currentUser: UserModel | null;
    private callInvite: CallInviteModel;
    private activeCallId: number;
    private callStatus: CallLocalStatus;

    watch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AppInitActions.userAuthenticated,
                UserApiActions.userProfileLoadSucceeded
            ),
            switchMap(({user}) => {
                return this.privateMessagesWsService.subscribe(user.id).pipe(
                    map((message) => {
                        switch (message.type) {
                            case PrivateMessageType.callInvite:
                            case PrivateMessageType.callIceCandidate:
                            case PrivateMessageType.callAccepted:
                            case PrivateMessageType.callDeclined:
                            case PrivateMessageType.callEnded:
                            case PrivateMessageType.callNegotiationOffer:
                                return this.onCallMessage(message);
                            default:
                                return this.onMessage(user, message);
                        }
                    })
                );
            })
        );
    });

    private onCallMessage(message: PrivateMessage) {
        switch (message.type) {
            case PrivateMessageType.callInvite:
                if (this.privateChatInterlocutorId !== message.payload.user.id && !this.callStatus) {
                    this.notifyOnIncomingCall(message);
                }
                if (this.callStatus) {
                    return PrivateMessagesWsActions.callInviteWhenCallIsActiveReceived(message.payload);
                }
                return PrivateMessagesWsActions.callInviteReceived(message.payload);
            case PrivateMessageType.callIceCandidate:
                return PrivateMessagesWsActions.callIceCandidateReceived(message.payload);
            case PrivateMessageType.callAccepted:
                if (this.callStatus === CallLocalStatus.acceptSent || this.callStatus === CallLocalStatus.inviteSent) {
                    return PrivateMessagesWsActions.callAcceptedReceived(message.payload);
                }
                return PrivateMessagesWsActions.callAcceptedFromOtherSessionReceived(message.payload);
            case PrivateMessageType.callDeclined:
                if (this.messageIsForCurrentCall(message.payload.callId)) {
                    return PrivateMessagesWsActions.callDeclinedReceived(message.payload);
                }
                return PrivateMessagesWsActions.wrongCallMessageReceived();
            case PrivateMessageType.callEnded:
                if (this.messageIsForCurrentCall(message.payload.callId)) {
                    return PrivateMessagesWsActions.callEndedReceived(message.payload);
                }
                return PrivateMessagesWsActions.wrongCallMessageReceived();
            case PrivateMessageType.callNegotiationOffer:
                return PrivateMessagesWsActions.callNegotiateReceived(message.payload);
        }
    }

    private notifyOnIncomingCall(invite: PrivateMessageCallInvite) {
        this.matSnackBar.openFromComponent<InviteNotificationPopupComponent>(
            InviteNotificationPopupComponent,
            {
                data: {
                    user: invite.payload.user,
                    callId: invite.payload.callId
                },
                verticalPosition: 'top',
                panelClass: 'private-chat-message-notification',
                duration: 10000
            }
        );
    }

    private onMessage(user: UserModel, message: PrivateMessage): Action {
        switch (message.type) {
            case PrivateMessageType.privateChatMessageNew:
                return this.onChatMessageNew(message.payload);
            case PrivateMessageType.privateChatMessageEdit:
                return PrivateMessagesWsActions.chatMessageEdited({
                    conversationId: message.payload.conversationId,
                    messageUpdate: {
                        id: message.payload.id,
                        changes: {
                            text: message.payload.text
                        }
                    }
                });
            case PrivateMessageType.privateChatMessageDelete:
                return PrivateMessagesWsActions.chatMessageDeleted(message.payload);
            case PrivateMessageType.conversationNew:
                return PrivateMessagesWsActions.newConversationReceived(flattenConversationApiModel(user.id, message.payload));
            case PrivateMessageType.userBlock:
                return PrivateMessagesWsActions.blockUserReceived({
                    userId: this.currentUser.id === message.payload.blockedId ? message.payload.userId : message.payload.blockedId
                });
            case PrivateMessageType.blockedGlobal:
                return this.onBlockedGlobal();
        }
    }

    private notifyOnNewChatMessage(message: ConversationPrivateChatMessageModel) {
        this.store.pipe(
            select(fromRoot.getPublicUser, {userId: message.userIdFrom}),
            first()
        ).subscribe((user) => {
            if (!user) {
                return;
            }
            this.notificationSoundService.play();
            this.matSnackBar.openFromComponent<PrivateChatNotificationComponent>(
                PrivateChatNotificationComponent,
                {
                    data: {
                        message,
                        user
                    },
                    verticalPosition: 'top',
                    panelClass: 'private-chat-message-notification',
                    duration: 10000
                }
            );
        });
    }

    private onChatMessageNew(payload: PrivateChatNewMessageResult): Action {
        const action = PrivateMessagesWsActions.chatMessageReceived(payload);
        if (
            !this.currentUser
            || this.currentUser.id === payload.message.userIdFrom
            || this.privateChatInterlocutorId === payload.message.userIdFrom
        ) {
            return action;
        }
        if (!payload.message.call) {
            this.notifyOnNewChatMessage(payload.message);
        }
        return action;
    }

    private onBlockedGlobal(): Action {
        const username = this.currentUser.username;

        if (this.matDialog.openDialogs.length) {
            this.matDialog.closeAll();
        }

        this.router.navigateByUrl(startPageNonAuthenticatedPath)
            .finally(() => this.matDialog.open<BlockedPopupComponent>(BlockedPopupComponent, {
                data: {username}
            }));

        return PrivateMessagesWsActions.reportUserReceived();
    }

    private messageIsForCurrentCall(callId: number): boolean {
        return this.callInvite?.id === callId || this.activeCallId === callId;
    }

    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private privateMessagesWsService: PrivateMessagesWsService,
        private matSnackBar: MatSnackBar,
        private notificationSoundService: NotificationSoundService,
        private matDialog: MatDialog,
        private router: Router
    ) {
        this.store
            .pipe(select(fromRoot.getConversationInterlocutorId))
            .subscribe((interlocutorId: number) => this.privateChatInterlocutorId = interlocutorId);
        this.store
            .pipe(select(fromCurrentUser.getUser))
            .subscribe((user: UserModel) => this.currentUser = user);
        this.store
            .pipe(select(fromRoot.getCallInvite))
            .subscribe((invite) => this.callInvite = invite);
        this.store
            .pipe(select(fromRoot.getActiveCallId))
            .subscribe((callId) => this.activeCallId = callId);
        this.store
            .pipe(select(fromRoot.getActiveCallStatus))
            .subscribe((status) => this.callStatus = status);
    }
}
