import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UsernameFormatValidator } from '@shared/form-validators';
import { ErrorType, RegistrationType, RegistrationTypeText, ServerErrorModel } from '@core/models';


@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
    isPasswordHidden = true;

    isSubmitted = false;

    form = new UntypedFormGroup({
        username: new UntypedFormControl(
            '',
            Validators.compose([Validators.required, UsernameFormatValidator.validate])
        ),
        password: new UntypedFormControl(
            '',
            Validators.required
        )
    });

    @Input()
    error: ServerErrorModel;

    @Input()
    set isPending(isPending: boolean) {
        if (isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    @Output()
    submitted = new EventEmitter<{ username: string, password: string }>();

    get errorText(): string {
        if (!this.error) {
            return null;
        }
        switch (this.error.type) {
            case ErrorType.registeredViaFacebook:
                return `You are currently registered at Caregiving Network using ${RegistrationTypeText[RegistrationType.facebook]}.
                Please use the appropriate service button for login to Caregiving Network`;
            case ErrorType.registeredViaGoogle:
                return `You are currently registered at Caregiving Network using ${RegistrationTypeText[RegistrationType.google]}.
                Please use the appropriate service button for login to Caregiving Network`;
            default:
                return 'Username and password didn\'t match';
        }
    }

    onSubmit() {
        if (this.form.valid) {
            this.isSubmitted = true;
            this.submitted.emit(this.form.value);
        }
    }

}
