import { Action, createReducer, on } from '@ngrx/store';

import { AuthApiActions, RegistrationPageActions, RegistrationSocialPageActions } from '@core/store/auth/actions';
import { ExternalSocialNetwork, ServerErrorModel } from '@core/models';
import { RoiLocation } from '@core/services/roi-api/roi-location';


export interface State {
    isPending: boolean;
    email: string;
    enteredUsername: string;
    externalUserToken: string;
    externalSocialNetwork: ExternalSocialNetwork;
    error: ServerErrorModel | null;
    location: RoiLocation | null;
}


export const initialState: State = {
    isPending: false,
    email: null,
    enteredUsername: null,
    externalUserToken: null,
    externalSocialNetwork: null,
    error: null,
    location: null
};

const RegistrationSocialPageReducer = createReducer(
    initialState,
    on(
        AuthApiActions.validateTokenSucceeded, (state, action): State => ({
            ...state,
            externalUserToken: action.token,
            email: action.email,
            externalSocialNetwork: action.externalSocialNetwork,
            location: action.location,
            error: null
        })),
    on(
        AuthApiActions.validateTokenFailed,
        (state, {externalSocialNetwork, error}): State => ({
            ...state,
            externalSocialNetwork,
            error
        })),
    on(
        RegistrationSocialPageActions.registrationWithSocialNetworkSubmitted,
        (state, {externalSocialNetwork}): State => ({
            ...state,
            externalSocialNetwork,
            isPending: true,
            error: null
        })),
    on(
        AuthApiActions.registrationWithSocialNetworkFailed,
        (state, action): State => ({
            ...state,
            externalSocialNetwork: action.externalSocialNetwork,
            error: action.error,
            isPending: false
        })),
    on(
        RegistrationPageActions.submittedWithSocialNetwork,
        (state, action): State => ({
            ...state,
            externalSocialNetwork: action.externalSocialNetwork,
            enteredUsername: action.username || null,
            isPending: false
        }))
);

export const reducer = (state: State | undefined, action: Action) => {
    return RegistrationSocialPageReducer(state, action);
};

export const getError = (state: State) => state.error;
export const getIsPending = (state: State) => state.isPending;
export const getSocialToken = (state: State) => state.externalUserToken;
export const getEmail = (state: State) => state.email;
export const getExternalSocialNetwork = (state: State) => state.externalSocialNetwork;
export const getLocation = (state: State) => state.location;
export const getEnteredUsername = (state: State) => state.enteredUsername;
