<ng-container *ngIf="isLoaded else spinner">
    <div id="tags" class="tags-cloud" *ngIf="tags.length else empty">
        <ng-container *ngFor="let tag of tags;">
            <a [routerLink]="getLinkToFeed(tag)" class="tag {{ tag.style }}" (click)="onClick($event, tag)">
                <mat-icon class="tag-icon"
                          *ngIf="subjectSvgIcons[slugify(tag.name)] as icon else infoIcon"
                          [svgIcon]="icon"></mat-icon>
                <div class="tag-name">{{tag.name}}</div>
            </a>
        </ng-container>
    </div>
</ng-container>
<ng-template #spinner>
    <mat-spinner diameter="40"></mat-spinner>
</ng-template>
<ng-template #empty>
    <div class="not-found"><span>No tags found</span></div>
</ng-template>
<ng-template #infoIcon>
    <mat-icon class="info-icon">info</mat-icon>
</ng-template>

