import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {YouTubePlayer} from '@angular/youtube-player';

import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-video-popup',
    templateUrl: './video-popup.component.html',
    styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent {
    readonly s3BucketUrl = environment.assetsS3BucketUrl;
    videoEl: HTMLVideoElement;
    fileName: string;
    youtubePlayer: YouTubePlayer;
    isYoutubeVideo: boolean;

    @ViewChild('videoElement', {static: false})
    set video(elementRef: ElementRef) {
        if (!elementRef) {
            return;
        }
        this.videoEl = elementRef.nativeElement;
        this.videoEl.play();
    }

    @ViewChild('youTubePlayer', {static: false})
    set YtPlayer(player) {
        if (!player) {
            return;
        }
        this.youtubePlayer = player;
        this.youtubePlayer.playVideo();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            video: string,
            isYoutubeVideo: boolean
        }) {
        this.isYoutubeVideo = data.isYoutubeVideo;
        this.fileName = data.video;
    }
}
