import { createAction, props } from '@ngrx/store';
import { AffirmationReaction } from '@core/models/affirmation.model';

export const prefix = '[Affirmation Popup]';

export const init = createAction(
    `${prefix} init`,
    props<{ religionId: number | null }>()
);

export const reactionClicked = createAction(
    `${prefix} reaction clicked`,
    props<{ id: number, reaction: AffirmationReaction }>()
);
