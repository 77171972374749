import { createAction, props } from '@ngrx/store';

import { UserRole } from '@core/models';


export const prefix = '[Words cloud page]';

export const pageInit = createAction(`${prefix} page init`);

export const roleChanged = createAction(
    `${prefix} role changed`,
    props<{ role: UserRole }>()
);

export const changeRolePopupShown = createAction(`${prefix} change role popup shown`);

export const changeRolePopupClosed = createAction(`${prefix} change role popup closed`);
