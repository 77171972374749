import { Action, createReducer, on } from '@ngrx/store';

import { SearchBySubjectPageActions } from '@core/store/actions';


export const featureKey = 'searchBySubjectPage';

export interface State {
    searchCriteria: string;
}

export const initialState: State = {
    searchCriteria: null
};

export const SearchBySubjectPageReducer = createReducer(
    initialState,
    on(
        SearchBySubjectPageActions.init,
        SearchBySubjectPageActions.searchCleared,
        () => initialState
    ),
    on(SearchBySubjectPageActions.searched, (state, {criteria}) => ({
        searchCriteria: criteria || null
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return SearchBySubjectPageReducer(state, action);
}

export const getSearchCriteria = (state: State) => state.searchCriteria;
