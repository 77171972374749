import { createAction, props } from '@ngrx/store';
import { Preferences } from '@core/models';


export const prefix = '[Preferences Page]';

export const SavePreferencesSubmitted = createAction(
    `${prefix} save preferences submitted`,
    props<{ preferences: Preferences }>()
);
