<a href="{{post.url}}"
   class="thumbnailWrapper"
   target="_blank"
   rel="noopener"
   [style.background-image]="'url(' + post.imageUrl + ')'"></a>
<div class="contentWrapper">
    <div class="subjects" [innerHTML]="post.subjects"></div>
    <a class="title" href="{{post.url}}" target="_blank" title="Caregiving Network Personal Support" rel="noopener">
        {{post.title}}
    </a>
</div>
