import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromAffirmations from '@core/store/affirmations/reducers';
import { AffirmationsService } from '@core/services/affirmations/affirmations.service';
import { AffirmationExistsGuardActions } from '@core/store/affirmations/actions';


@Injectable({
    providedIn: 'root'
})
export class AffirmationExistsGuard implements CanActivate {

    constructor(
        private affirmationsService: AffirmationsService,
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        this.store.dispatch(AffirmationExistsGuardActions.guardTriggered());
        return this.store.pipe(
            select(fromAffirmations.getAffirmationIdFromRoute),
            switchMap((affirmationId) => {
                if (!affirmationId) {
                    return of(this.router.parseUrl('/affirmation'));
                }
                return this.affirmationsService.getAffirmationById(affirmationId).pipe(
                    map((response) => {
                        this.store.dispatch(AffirmationExistsGuardActions.affirmationLoaded({ affirmation: response }));
                        return true;
                    }),
                    catchError((error) => {
                        return of(this.router.parseUrl('/affirmation'));
                    })
                );
            })
        );
    }
}
