import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class HelpDeskService {

    readonly prefix = '/contact-us';

    constructor(private http: HttpClient) {
    }

    send(text: string): Observable<void> {
        return this.http.post<void>(`${this.prefix}/`, {text});
    }
}
