import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CareLibrarianAnswerModel, CareLibrarianRequestModel } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class CareLibrarianService {
    public static answersLimit = 50;
    public static pageLimit = 10;

    readonly prefix = '/care-librarian';

    send(request: CareLibrarianRequestModel): Observable<CareLibrarianAnswerModel> {
        return this.http.post<CareLibrarianAnswerModel>(this.prefix, { ...request });
    }

    getHistory(page: number): Observable<CareLibrarianAnswerModel[]> {
        const params = {
            size: CareLibrarianService.pageLimit,
            page: page.toString()
        };

        return this.http.get<CareLibrarianAnswerModel[]>(`${this.prefix}/history`, { params });
    }

    constructor(private http: HttpClient) {
    }
}
