import { AbstractControl, ValidationErrors } from '@angular/forms';


export class EmailValidator {
    public static validate(control: AbstractControl): ValidationErrors | null {
        // eslint-disable-next-line
        const REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return (!control.value || REGEXP.test(control.value)) ? null : {
            emailFormat: true
        };
    }
}
