import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import * as fromRoot from '@core/store';
import { MaintenancePageActions, PingApiActions } from '@core/store/actions';
import { LocationService } from '@core/services';
import { initApp } from '@core/app-initializer';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { CustomSvgIcon } from '@material/custom-svg-icon';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Component({
    selector: 'app-maintenance-page',
    templateUrl: './maintenance-page.component.html',
    styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent extends WithDestroyedSubjectComponent implements OnInit {

    readonly logoSvgIcon = CustomSvgIcon.logoFull;

    isLoading: boolean;

    private init() {
        this.store.dispatch(MaintenancePageActions.init());
        this.matDialog.closeAll();
        this.actions$.pipe(
            ofType(PingApiActions.serverIsOnline),
            withLatestFrom(this.store.pipe(select(fromRoot.getAppInitIsCompleted))),
            takeUntil(this.destroyed$)
        ).subscribe(([, isAppInitCompleted]) => {
            if (!isAppInitCompleted) {
                initApp(this.store, this.actions$).then(() => this.locationService.back(startPageNonAuthenticatedPath));
                return;
            }
            return this.locationService.back(startPageNonAuthenticatedPath);
        });
        this.store.pipe(
            select(fromRoot.getMaintenancePageIsLoading),
            takeUntil(this.actions$.pipe(ofType(PingApiActions.serverIsOnline)))
        ).subscribe((isLoading) => this.isLoading = isLoading);
    }

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private router: Router,
        private locationService: LocationService,
        private matDialog: MatDialog
    ) {
        super();
    }

    ping() {
        this.store.dispatch(PingApiActions.ping());
    }

    ngOnInit(): void {
        this.init();
    }
}

