import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromConversations from '@core/store/conversations';
import { PublicUsersService } from '@core/services';
import { PrivateChatQuestionMessageGuardActions } from '@core/store/conversations/actions';
import { ReplyOnType } from '@core/models';


@Injectable({
    providedIn: 'root'
})
export class PrivateChatQuestionMessageGuard implements CanActivate {

    constructor(
        private publicUsersService: PublicUsersService,
        private store: Store<fromRoot.State>,
        private router: Router
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return combineLatest([
            this.store.pipe(select(fromRoot.getConversationInterlocutorId)),
            this.store.pipe(select(fromConversations.getPrivateChatConversationModel)),
            this.store.pipe(select(fromRoot.getConversationQuestionId))
        ]).pipe(
            take(1),
            map(([interlocutorId, conversation, questionId]): UrlTree => {
                const conversationsUrl = this.router.parseUrl(`/me/conversations/${interlocutorId}`);
                if (!questionId) {
                    return conversationsUrl;
                }
                this.store.dispatch(PrivateChatQuestionMessageGuardActions.usersFeedQuestionInteracted({
                    userId: interlocutorId,
                    questionId
                }));
                const lastMessageText = conversation?.lastMessageUserIdFrom === interlocutorId ? null : conversation?.text;
                this.publicUsersService.getQuestion(interlocutorId, questionId).subscribe((message) => {
                    if (message && (message.text !== lastMessageText)) {
                        this.store.dispatch(PrivateChatQuestionMessageGuardActions.replyOnQuestion({
                            message: {
                                replyOnType: ReplyOnType.question,
                                replyOnId: message.id,
                                userFromId: interlocutorId,
                                text: message.text,
                                date: message.updatedOn
                            }
                        }));
                    }
                });
                return conversationsUrl;
            }),
        );
    }
}
