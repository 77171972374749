import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import {
    FeedRecordType,
    PublicUserModel,
    UserProfileFeedRecordDto,
    UserProfileFeedRecordsModel
} from '@core/models';
import { UserProfileAdditionalInfoBlock } from '@pages/user/containers';


@Component({
    selector: 'app-user-profile-additional',
    templateUrl: './user-profile-additional.component.html',
    styleUrls: ['./user-profile-additional.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileAdditionalComponent {

    readonly infoBlock = UserProfileAdditionalInfoBlock;

    readonly infoBlockToFeedRecordMap = {
        [UserProfileAdditionalInfoBlock.feedQuestions]: FeedRecordType.question,
        [UserProfileAdditionalInfoBlock.feedCanHelpSettings]: FeedRecordType.canHelpSetting,
        [UserProfileAdditionalInfoBlock.feedNeedHelpSettings]: FeedRecordType.needHelpSetting
    };

    @Input()
    user: PublicUserModel;

    @Input()
    isCurrentUser: boolean;

    @Input()
    feedRecords: UserProfileFeedRecordsModel;

    @Input()
    followings: PublicUserModel[];

    @Input()
    expandedRecordsType: UserProfileAdditionalInfoBlock;

    @Input()
    expandedRecord: UserProfileFeedRecordDto;

    @Input()
    currentUserId: number;

    @Output()
    deleteRecord$ = new EventEmitter<UserProfileFeedRecordDto>();

    @Output()
    recordClick$ = new EventEmitter<UserProfileFeedRecordDto>();

    @Output()
    recordsBlockTriggerClick$ = new EventEmitter<UserProfileAdditionalInfoBlock>();

    @Output()
    toggleFollow$ = new EventEmitter<{ user: PublicUserModel, isFollow: boolean }>();

    getPrivateMessageUrl(id: number): string {
        return `/me/conversations/`
            + `${this.user.id}/`
            + (this.infoBlockToFeedRecordMap[this.expandedRecordsType] === FeedRecordType.question ? 'question' : 'settings')
            + `/${id}`;
    }
}
