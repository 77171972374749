import { Component, HostBinding, Input } from '@angular/core';
import { TagModel, UserRole, userRoleText } from '@core/models';


@Component({
    selector: 'app-feed-user-settings-header',
    templateUrl: './feed-user-settings-header.component.html',
    styleUrls: ['./feed-user-settings-header.component.scss']
})
export class FeedUserSettingsHeaderComponent {

    readonly userRolesText = userRoleText;

    readonly userRoles = UserRole;

    @HostBinding('class')
    get hostClass(): string {
        return this.isCanHelp ? 'give-help' : 'get-help';
    }

    @Input()
    tag: TagModel;

    @Input()
    isCanHelp: boolean;
}
