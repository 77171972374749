import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '@core/store';
import * as fromGeneralData from './general-data.reducer';
import * as fromConversations from './conversations.reducer';
import * as fromFeedSettings from './feed-settings.reducer';
import * as fromFeedQuestions from './feed-questions.reducer';
import * as fromBlockedUsers from './blocked-users.reducer';
import { ConversationFlatModel, PublicUserModel, QuestionModel, UserRole, UserSettingsViewModel } from '@core/models';
import { environment } from 'src/environments/environment';


export const featureKey = 'currentUser';

export interface CurrentUserState {
    generalData: fromGeneralData.State;
    conversations: fromConversations.State;
    feedSettings: fromFeedSettings.State;
    feedQuestions: fromFeedQuestions.State;
    blockedUsers: fromBlockedUsers.State;
}

export interface State extends fromRoot.State {
    [featureKey]: CurrentUserState;
}

export function reducers(state: CurrentUserState | undefined, action: Action) {
    return combineReducers({
        generalData: fromGeneralData.reducer,
        conversations: fromConversations.reducer,
        feedSettings: fromFeedSettings.reducer,
        feedQuestions: fromFeedQuestions.reducer,
        blockedUsers: fromBlockedUsers.reducer
    })(state, action);
}

const getCurrentUserState = createFeatureSelector<CurrentUserState>(featureKey);
const getGeneralDataState = createSelector(getCurrentUserState, (state) => state.generalData);
const getBlockedUsersState = createSelector(getCurrentUserState, (state) => state.blockedUsers);

export const getUser = createSelector(getGeneralDataState, fromGeneralData.getUser);
export const getUserId = createSelector(getUser, (user) => user ? user.id : null);
export const getToken = createSelector(getGeneralDataState, fromGeneralData.getToken);
export const getLastKnownRole = createSelector(getGeneralDataState, fromGeneralData.getLastKnownRole);
export const getGeolocation = createSelector(getGeneralDataState, fromGeneralData.getGeolocation);
export const getIsVideoContestAgreementSigned = createSelector(getGeneralDataState, fromGeneralData.getIsVideoContestAgreementSigned);
export const getPreferences = createSelector(getGeneralDataState, fromGeneralData.getPreferences);
export const getXAuthSocketToken = createSelector(getGeneralDataState, fromGeneralData.getXAuthSocketToken);
export const getPublicUserModel = createSelector(getUser, (user): PublicUserModel => {
    if (!user) {
        return null;
    }
    return {
        id: user.id,
        username: user.username,
        photo: user.photo,
        isFounding: user.isFounding,
        isFacilitator: user.isFacilitator,
        createdOn: user.createdOn,
        isFollowed: false,
        distance: null,
        lastActivity: null
    };
});

const getFeedSettingsState = createSelector(getCurrentUserState, (state) => state.feedSettings);
export const getFeedSettings = createSelector(getFeedSettingsState, fromFeedSettings.getSettings);

const getFeedQuestionsState = createSelector(getCurrentUserState, (state) => state.feedQuestions);
export const getFeedQuestions = createSelector(getFeedQuestionsState, fromFeedQuestions.getFeedQuestions);
const getFeedQuestionsEntities = createSelector(getFeedQuestionsState, fromFeedQuestions.getFeedQuestionsEntities);
export const getFeedQuestionById = (props: { id: number }) => createSelector(
    getFeedQuestionsEntities, (questions) => {
        if (!props || !props.id || !questions.hasOwnProperty(props.id)) {
            return null;
        }
        return questions[props.id];
    }
);

export const getBlockedUsers = createSelector(getBlockedUsersState, fromBlockedUsers.getBlockedUsers);

const getConversationsState = createSelector(getCurrentUserState, (state) => state.conversations);
const getUserConversationsRaw = createSelector(getConversationsState, fromConversations.getConversations);
export const getConversations = createSelector(
    getUserConversationsRaw,
    getUserId,
    getBlockedUsers,
    fromRoot.getCallInvite,
    (
        conversations,
        id,
        blockedUsers,
        invite): ConversationFlatModel[] => {
        if (!conversations || !conversations.length || !id) {
            return null;
        }
        const result: ConversationFlatModel[] = [];
        let inviteConversation: ConversationFlatModel;

        if (invite) {
            inviteConversation = {
                id: 0,
                callId: invite.id,
                lastMessageId: null,
                lastMessageUserIdFrom: invite.userId,
                interlocutorId: invite.userId,
                isRead: invite.isRead,
                text: null,
                createdOn: new Date().toISOString()
            };

            const existingConversation = conversations.find(conversation => conversation.interlocutorId === invite.userId);
            if (existingConversation) {
                inviteConversation.id = existingConversation.id;
            }

            result.push(inviteConversation);
        }

        conversations.filter(conversation => conversation.id !== inviteConversation?.id)
            .forEach((conversation) => {
                if (blockedUsers[conversation.interlocutorId]) {
                    return;
                }
                result.push({
                    ...conversation,
                    isRead: conversation.isRead || conversation.lastMessageUserIdFrom === id
                });
            });
        return result;
    }
);
export const getUnreadConversationsCount = createSelector(
    getConversations,
    (conversations) => {
        if (!conversations || !conversations.length) {
            return 0;
        }
        return conversations.filter(conversation => !conversation.isRead).length;
    }
);

export const getSettingsForSelectedTag = createSelector(
    fromRoot.getSelectedTag,
    getFeedSettings,
    (tag, settings): UserSettingsViewModel[] => {
        if (!tag || !settings) {
            return null;
        }
        const tagSettings = settings.filter(model => model.tagId === tag.id);

        if (!tagSettings.length) {
            return null;
        }

        return tagSettings.map(model => {
            return {
                ...model,
                tag
            };
        });
    }
);

export const getSettingsForSelectedTagAndRole = createSelector(
    fromRoot.getRoleFromRoute,
    getSettingsForSelectedTag,
    (role, tagSettings): UserSettingsViewModel => {
        if (!role || !tagSettings || role === UserRole.browse) {
            return null;
        }
        return tagSettings.find(model => model.role === role) || null;
    }
);

export const filterQuestionsByTagId = (questions: QuestionModel[], tagId: number): QuestionModel[] => {
    if (!questions || !questions.length || !tagId) {
        return null;
    }
    const result = questions
        .filter((question) => question.tagId === tagId)
        .map(q => ({ ...q }));
    return result.length ? result : null;
};

export const getQuestionsForSelectedTag = createSelector(
    fromRoot.getSelectedTag,
    getFeedQuestions,
    (tag, questions): QuestionModel[] => {
        if (!tag) {
            return null;
        }
        return filterQuestionsByTagId(questions, tag.id);
    }
);

export const getIsQuestionsLimitReachedForSelectedTag = createSelector(
    getQuestionsForSelectedTag,
    (questions) => {
        if (!questions) {
            return false;
        }
        return questions.length >= environment.feed.questionsPerTagLimit;
    }
);


