import { PublicUserModel } from '@core/models/user.model';

export interface ConversationPrivateChatMessageModel {
    id: number;
    text: string;
    isRead: boolean;
    isEdited: boolean;
    createdOn: string;
    userIdTo: number;
    userIdFrom: number;
    call?: CallInformationApiModel;
}

export enum CallStatus {
    'invite' = 'INVITE',
    'offer' = 'OFFER',
    'missed' = 'MISSED',
    'declined' = 'DECLINED',
    'inProgress' = 'IN_PROGRESS',
    'ended' = 'ENDED'
}

export type CallStatusMessage = { [key in CallStatus.ended | CallStatus.missed | CallStatus.declined]: string };

export const callMessages: CallStatusMessage = {
    [CallStatus.declined]: 'Call canceled',
    [CallStatus.ended]: 'Call ended',
    [CallStatus.missed]: 'Call Missed'
};

export interface CallInformationApiModel {
    duration: number;
    status: CallStatus;
}

export interface CallInformationModel extends CallInformationApiModel {
    interlocutor: PublicUserModel;
}

export interface PrivateChatMessageModel extends ConversationPrivateChatMessageModel {
    replyOnText: string;
    replyOnCreatedOn: string;
    replyOnUserId: number;
}

export interface PrivateChatNewMessageResult {
    conversationId: number;
    message: PrivateChatMessageModel;
}
