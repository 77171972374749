import { createAction, props } from '@ngrx/store';

export const prefix = '[Feed page tabs]';

export const canHelpLoadMore = createAction(
    `${prefix} canHelp loadMore`,
    props<{ tagId: number}>()
);

export const needHelpLoadMore = createAction(
    `${prefix} needHelp loadMore`,
    props<{ tagId: number}>()
);

export const questionsLoadMore = createAction(
    `${prefix} questions loadMore`,
    props<{ tagId: number}>()
);
export const allLoadMore = createAction(
    `${prefix} all profiles loadMore`,
    props<{ tagId: number}>()
);
