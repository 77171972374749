<div class="row-wrapper" *ngFor="let user of users; trackBy: trackByUserId">
    <app-user-avatar
        [user]="user"
        [asLink]="true"
        [isOnline]="user.lastActivity | isOnline"
    ></app-user-avatar>
    <a class="user-info" [routerLink]="['/users', user.id]">
        <app-username class="username color-primary" [user]="user"></app-username>
        <div class="distance" *ngIf="showDistance && user.distance !== null && user.id !== currentUserId">
            <span *ngIf="user.distance">
                ~{{ user.distance }} miles away
            </span>
            <span *ngIf="!user.distance">
                near to you
            </span>
        </div>
    </a>
    <app-private-chat-button
        *ngIf="showPrivateMessageButton && user.id !== currentUserId"
        [interlocutorId]="user.id"
        [asLink]="true"
    >Direct message
    </app-private-chat-button>
    <button
        *ngIf="showFollowButton && user.id !== currentUserId"
        type="button"
        class="btn-flat btn-follow"
        [class.followed]="user.isFollowed"
        (click)="$event.stopPropagation(); toggleFollow$.emit({user: user, isFollow: !user.isFollowed})"
    ><mat-icon>{{ user.isFollowed ? 'favorite' : 'favorite_border'}}</mat-icon></button>
</div>

