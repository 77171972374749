import { ActivatedRouteSnapshot, CanActivate, PRIMARY_OUTLET, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import * as fromCurrentUser from '@core/store/current-user';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TagQuestionsLimitGuard implements CanActivate {
    constructor(
        private store: Store<fromCurrentUser.State>,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.store.pipe(
            select(fromCurrentUser.getIsQuestionsLimitReachedForSelectedTag, {id: route.params.questionId}),
            first(),
            map((isReached) => {
                if (!isReached) {
                    return true;
                }
                const navigation = this.router.getCurrentNavigation();
                if (navigation.previousNavigation) {
                    return false;
                } else {
                    const tree = this.router.parseUrl(state.url);
                    tree.root.children[PRIMARY_OUTLET].segments.pop();
                    return tree;
                }
            })
        );
    }
}
