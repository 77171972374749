import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RoiLocation } from '@core/services/roi-api/roi-location';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';


@Injectable({
    providedIn: 'root'
})
export class RoiApiService {
    readonly campaignKey = 'campaign';

    private getCampaignSessionKey(): string {
        return this.sessionStorageService.getItem(this.campaignKey);
    }

    sendRegistered(email: string, username: string, location: RoiLocation): Observable<void> {
        return this.http.post<void>(`${environment.roiApiUrl}/registered`, {
            email,
            username,
            location,
            campaignId: this.getCampaignSessionKey()
        }, {
            headers: {
                'ngsw-bypass': ''
            }
        });
    }

    sendEmailSubmitted(email: string, location: RoiLocation): Observable<void> {
        return this.http.post<void>(`${environment.roiApiUrl}/email`, {
            email,
            location,
            campaignId: this.getCampaignSessionKey()
        }, {
            headers: {
                'ngsw-bypass': ''
            }
        });
    }

    sendFeedSettingsCreated(email: string): Observable<void> {
        return this.http.post<void>(`${environment.roiApiUrl}/feed-settings-created`, {email}, {
            headers: {
                'ngsw-bypass': ''
            }
        });
    }

    sendCampaignClick(campaignId: string, referer: string): Observable<void> {
        return this.http.post<void>(`${environment.roiApiUrl}/campaign-click`, {
            campaignId,
            referer
        }, {
            headers: {
                'ngsw-bypass': ''
            }
        });
    }

    constructor(
        private http: HttpClient,
        private sessionStorageService: SessionStorageService
    ) {
    }
}
