import { createAction, props } from '@ngrx/store';

import { ResourceModel } from '@core/models';


export const prefix = '[Resources API]';

export const loadLocalResourcesSucceeded = createAction(
    `${prefix} load local resources succeeded`,
    props<{ resources: ResourceModel[] }>()
);

export const loadLocalResourcesFailed = createAction(
    `${prefix} load local resources failed`,
    props<{ error: any }>()
);

export const loadNationalResourcesSucceeded = createAction(
    `${prefix} load national resources succeeded`,
    props<{ resources: ResourceModel[] }>()
);

export const loadNationalResourcesFailed = createAction(
    `${prefix} load national resources failed`,
    props<{ error: any }>()
);
