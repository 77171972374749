import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';

import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

import * as fromRoot from '@core/store';
import * as fromCurrentUser from '@core/store/current-user';
import { UserModel, UserRole } from '@core/models';
import { toggleHeightAnimation, voidAnimation } from '@core/animations';
import { PageLabelService } from '@core/services';
import { WithDestroyedSubjectComponent } from '@shared/with-destroyed-subject-component';
import { ProfileMenuActions } from '@core/store/actions';
import { CustomSvgIcon } from '@material/custom-svg-icon';
import { environment } from 'src/environments/environment';
import { AuthApiActions } from '@core/store/auth/actions';
import { startPageNonAuthenticatedPath } from '@core/defaults';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        voidAnimation,
        toggleHeightAnimation
    ]
})
export class HeaderComponent extends WithDestroyedSubjectComponent implements OnInit {

    readonly customSvgIcons = CustomSvgIcon;

    readonly blogUrl = environment.blogUrl;

    readonly readingRoomUrl = `${environment.blogUrl}/${environment.readingRoomSlug}`;

    readonly showHeaderContestLink = environment.videoContest.showHeaderContestLink;

    user: UserModel;

    unreadConversationsCount$ = this.store.pipe(select(fromCurrentUser.getUnreadConversationsCount));

    selectedTagData$ = this.store.pipe(select(fromRoot.getSelectedTagData));

    role: UserRole;

    isFeedPage = false;

    isLongContestLinkAllowed = true;

    pageLabel: string | SafeHtml;

    constructor(
        private store: Store<fromRoot.State>,
        private actions$: Actions,
        private pageLabelService: PageLabelService,
        public router: Router,
        public matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            select(fromRoot.getRouteData),
            takeUntil(this.destroyed$)
        ).subscribe((data) => {
            this.isFeedPage = !!(data && data.isFeedPage);
            this.isLongContestLinkAllowed = (data?.appRootClass === 'screen-large');
        });
        this.store.pipe(
            select(fromRoot.getRoleFromRoute),
            takeUntil(this.destroyed$)
        ).subscribe((role) => this.role = role);
        this.store.pipe(
            select(fromCurrentUser.getUser),
            takeUntil(this.destroyed$)
        ).subscribe((user) => this.user = user);
        this.actions$.pipe(
            ofType(AuthApiActions.logoutSucceeded),
            takeUntil(this.destroyed$),
        ).subscribe(() => this.router.navigateByUrl(startPageNonAuthenticatedPath));

        this.pageLabelService.pageLabel$.pipe(
            takeUntil(this.destroyed$),
        ).subscribe((label) => {
            this.pageLabel = label;
        });
    }

    logout() {
        this.store.dispatch(ProfileMenuActions.logout());
    }

    get urlWithoutParams(): string {
        return this.router.url.split('?')[0];
    }
}
