import { createAction, props } from '@ngrx/store';


export const prefix = '[Reset Password Page]';

export const init = createAction(`${prefix} init`);

export const submitted = createAction(
    `${prefix} submitted`,
    props<{ password: string, token: string }>()
);

