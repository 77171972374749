import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import {
    FeedQuestionFormPopupComponent,
    CareHistoryComponent,
    ExperiencePeriodComponent,
    UsersListComponent,
    UserProfileMainInfoComponent,
    UserProfileFeedRecordComponent,
    UserProfileAdditionalComponent,
    FeedUserSettingsFormComponent,
    FeedUserSettingsHeaderComponent,
} from '@pages/user/components';
import { ReportAMemberComponent, UserProfileComponent } from '@pages/user/containers';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';


export const components = [
    ExperiencePeriodComponent,
    CareHistoryComponent,
    FeedQuestionFormPopupComponent,
    ReportAMemberComponent,
    UserProfileComponent,
    UserProfileFeedRecordComponent,
    UserProfileAdditionalComponent,
    UserProfileMainInfoComponent,
    UsersListComponent,
    FeedUserSettingsFormComponent,
    FeedUserSettingsHeaderComponent,
];

@NgModule({
    declarations: components,
    exports: components,
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        MatCheckboxModule,
        MatTooltipModule
    ]
})
export class UserModule {
}
