<div id="caption" class="color-accent">
    {{tagName}}
</div>
<div id="description">
    If, you’re requesting a new sub-topic to appear under
    a main subject, select it, or select Add to Main
    Subjects and click the select button when visible.
</div>
<div class="container">
    <div class="parent-tags">
        <button
            id="main-tag"
            class="tag"
            (click)="selectTag(0)"
            [class.selected]="0 === selectedTagId"
        >Add to Main Subjects
        </button>
        <button
            *ngFor="let tag of tags"
            class="tag"
            [class.selected]="tag.id === selectedTagId"
            (click)="selectTag(tag.id)"
        >{{tag.name}}</button>
    </div>
    <div id="action-buttons">
        <button
            id="btn-select"
            class="action-button"
            mat-stroked-button
            color="primary"
            [disabled]="selectedTagId === null"
            (click)="onSelect()"
        >select
        </button>
        <button
            class="action-button"
            mat-stroked-button
            color="primary"
            (click)="back$.emit()"
        >back
        </button>
        <button
            class="action-button"
            mat-stroked-button
            color="primary"
            (click)="closed$.emit()"
        >cancel
        </button>
    </div>
</div>
<app-disclaimer></app-disclaimer>
