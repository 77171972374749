<ng-container *appFeatureFlag="'national-resources'">
    <app-resources-block
        title="National Resources"
        *ngIf="nationalResources$ | async as resources"
    >
        <app-infinite-scroll
            class="thin-scroll-bar"
            [enabled]="nationalResourcesHasMore"
            (infiniteScroll$)="loadNationalResources()"
        >
            <app-resource
                *ngFor="let resource of resources"
                [resource]="resource"
            ></app-resource>
        </app-infinite-scroll>
    </app-resources-block>
</ng-container>

<app-resources-block
    *appFeatureFlag="'local-resources'"
    title="Local Resources"
    [hint]="localResourcesHint"
    [showHelpArrow]="showHelpArrow"
    #localResourcesElement
>
    <div
        *ngIf="!geolocation; else secondStep"
        class="geolocation-request"
    >
        <div class="text">
            Please click this button to allow your browser to identify your current location to display
            local resources if they are available
        </div>
        <app-btn-geolocation-help class="btn"></app-btn-geolocation-help>
        <button
            class="btn"
            mat-flat-button
            color="primary"
            (click)="requestGeolocation()"
        >
            <mat-icon>gps_fixed</mat-icon>
            Allow
        </button>
    </div>
    <ng-template #secondStep>
        <div class="empty" *ngIf="!localResources && localResourcesIsFirstResponseReceived$ | async; else localResourcesList">
            <div class="text">
                There are no local resource listings in your area at this time.
                Please refer to national resources as we continue to add local resources to our database.
            </div>
            <button mat-flat-button color="primary" (click)="localResourcesElement.resourcesMenuTrigger.closeMenu()">Ok</button>
            <button
                mat-flat-button
                id="btn-retry"
                class="grey-button"
                (click)="loadLocalResources()"
                [disabled]="localResourcesIsLoading"
            >
                <mat-icon>gps_fixed</mat-icon>
                Try again
                <span id="spinner-container">
                    <mat-spinner diameter="35" color="primary"></mat-spinner>
                </span>
            </button>
        </div>
        <ng-template #localResourcesList>
            <app-infinite-scroll
                class="thin-scroll-bar"
                [enabled]="localResourcesHasMore"
                (infiniteScroll$)="loadLocalResources()"
            >
                <app-resource
                    *ngFor="let resource of localResources; trackBy: trackBy"
                    [resource]="resource"
                ></app-resource>
            </app-infinite-scroll>
        </ng-template>
    </ng-template>
</app-resources-block>
