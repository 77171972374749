import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-thank-you-popup',
    templateUrl: './thank-you-popup.component.html',
    styleUrls: ['./thank-you-popup.component.scss']
})
export class ThankYouPopupComponent {
    title: string;
    description: string;

    constructor(
        public matDialogRef: MatDialogRef<ThankYouPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data: {
            title: string,
            description: string
        }
    ) {
        this.title = data.title;
        this.description = data.description;
    }
}
